import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { getDiscount } from './checkoutSlice';
import { returnValueCouponCheck } from './CheckoutTypes';

import * as S from './styled/StyledDCC';
import { RootState } from '../../src/store';

const DiscountCouponCheck = () => {
  const [showInputField, setShowInputField] = useState(false);
  const [couponValue, setCouponValue] = useState('');
  const [returnedMessage, setReturnedMessage] = useState<null | returnValueCouponCheck>(null);
  const coupon = useSelector((state: RootState) => state.checkout.coupon);

  const i18n = useTranslation();

  //   redux
  const { returnValueCouponCheck } = useSelector((state: RootState) => state.checkout);
  const dispatch = useDispatch();

  //   setting the right message after checking coupon and valid/invalid
  useEffect(() => {
    if (returnValueCouponCheck) {
      setReturnedMessage({
        message: returnValueCouponCheck.message,
        valid: returnValueCouponCheck.valid,
      });
    }
  }, [returnValueCouponCheck]);

  const handleClick = () => {
    dispatch(getDiscount(couponValue));
  };

  const clickToShowEnterField = (
    <S.DoYouHaveCoupon
      data-testid="CouponCheckInputHidden"
      onClick={() => {
        setShowInputField(true);
      }}
    >
      {i18n.t('checkoutDoYouHaveCoupon')}
    </S.DoYouHaveCoupon>
  );

  const showReturnedMessage = returnedMessage ? (
    <div style={{ color: returnedMessage.valid ? 'green' : 'red' }}>{returnedMessage.message}</div>
  ) : (
    ''
  );
  const enterCode = (
    <S.Wrapper data-testid="CouponCheckInputShown">
      <S.InputWrapper>
        <Field
          as={S.Input}
          name="coupon"
          placeholder={i18n.t('coupon')}
          onKeyUp={(e: { target: { value: React.SetStateAction<string> } }) =>
            setCouponValue(e.target.value)
          }
          disabled={returnedMessage && returnedMessage.valid}
          value={coupon}
        />
        <S.Button
          type="button"
          onClick={handleClick}
          disabled={returnedMessage && returnedMessage.valid}
        >
          {i18n.t('checkoutCheckCoupon')}
        </S.Button>
      </S.InputWrapper>
      {showReturnedMessage}
    </S.Wrapper>
  );

  return showInputField ? enterCode : clickToShowEnterField;
};

export default DiscountCouponCheck;
