import React from 'react';

import translateComponent from '../helpers/translateI18n';
import * as S from './styled/styledFooter';

import NewsletterSignUp from '../NewsletterSignUp/NewsletterSignUp';
import SocialMediaContainer from './SocialMediaContainer';

interface FooterProps {
  howItWorks: string;
  returns: string;
  shipping: string;
  contact: string;
  startSelling: string;
  criteria: string;
  brandSelection: string;
  payoutPolicy: string;
  media: string;
  terms: string;
  privacyPolicy: string;
  appStoreLink: string;
  googleStoreLink: string;
  mission: string;
}

const Footer: React.FC<FooterProps> = ({
  howItWorks,
  returns,
  shipping,
  contact,
  startSelling,
  criteria,
  brandSelection,
  payoutPolicy,
  media,
  terms,
  privacyPolicy,
  appStoreLink,
  googleStoreLink,
  mission,
}) => {
  const translate = translateComponent('Footer');
  return (
    <>
      <div className="footer-menu">
        <a className="footer-submenu-expand collapsed" data-toggle="collapse" data-target="#footer-help" aria-expanded="false" aria-controls="footer-help">
          {translate('needHelp')}
          <span className="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
          <span className="glyphicon glyphicon-menu-down" aria-hidden="true"></span>
        </a>
        <div className="footer-submenu collapse" id="footer-help">
          <a href="https://thenextcloset.zendesk.com" target="_blank" className="footer-submenu-link">{translate('FAQ')}</a>
          <a href={translate('FAQ')} className="footer-submenu-link">{translate('contact')}</a>
        </div>

        <a className="footer-submenu-expand collapsed" data-toggle="collapse" data-target="#footer-sell" aria-expanded="false" aria-controls="footer-sell">
          {translate('sell')}
          <span className="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
          <span className="glyphicon glyphicon-menu-down" aria-hidden="true"></span>
        </a>
        <div className="footer-submenu collapse" id="footer-sell">
          <a href={startSelling} className="footer-submenu-link">{translate('startSelling')}</a>
          <a href={criteria} className="footer-submenu-link">{translate('conditions')}</a>
          <a href={brandSelection} className="footer-submenu-link">{translate('acceptedBrands')}</a>
          <a href={payoutPolicy} className="footer-submenu-link">{translate('sellerPayout')}</a>
        </div>

        <a className="footer-submenu-expand collapsed" data-toggle="collapse" data-target="#footer-buy" aria-expanded="false" aria-controls="footer-buy">
          {translate('buy')}
          <span className="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
          <span className="glyphicon glyphicon-menu-down" aria-hidden="true"></span>
        </a>
        <div className="footer-submenu collapse" id="footer-buy">
          <a href={'#'} className="footer-submenu-link">{translate('startBuying')}</a>
          <a href={shipping} className="footer-submenu-link">{translate('shippingPolicy')}</a>
          <a href={returns} className="footer-submenu-link">{translate('returnPolicy')}</a>
        </div>

        <a className="footer-submenu-expand collapsed" data-toggle="collapse" data-target="#footer-tnc" aria-expanded="false" aria-controls="footer-tnc">
          The Next Closet
          <span className="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
          <span className="glyphicon glyphicon-menu-down" aria-hidden="true"></span>
        </a>
        <div className="footer-submenu collapse" id="footer-tnc">
          <a href={mission} className="footer-submenu-link">{translate('mission')}</a>
          <a href="https://thenextcloset.bamboohr.com/jobs" target="_blank" className="footer-submenu-link">{translate('jobs')}</a>
          <a href={media} className="footer-submenu-link">{translate('media')}</a>
        </div>
      </div>
      <S.Container>
        <S.Row>
          <S.LinksContainer>
            <S.Column>
              <S.Title>{translate('needHelp')}</S.Title>
              {/* <a href={howItWorks}>{translate('howItWorks')}</a> */}
              <a href="https://thenextcloset.zendesk.com" target="_blank">
                {translate('FAQ')}
              </a>
              {/* <a href={returns}>{translate('returnPolicy')}</a> */}
              {/* <a href={shipping}>{translate('shippingPolicy')}</a> */}
              <a href={contact}>{translate('contact')}</a>
            </S.Column>
            <S.Column>
              <S.Title>{translate('sell')}</S.Title>
              <a href={startSelling}>{translate('startSelling')}</a>
              <a href={criteria}>{translate('conditions')}</a>
              <a href={brandSelection}>{translate('acceptedBrands')}</a>
              <a href={payoutPolicy}>{translate('sellerPayout')}</a>
            </S.Column>
            <S.Column>
              <S.Title>{translate('buy')}</S.Title>
              <a href={'#'}>{translate('startBuying')}</a>
              <a href={shipping}>{translate('shippingPolicy')}</a>
              <a href={returns}>{translate('returnPolicy')}</a>
            </S.Column>
            <S.Column>
              <S.Title>The Next Closet</S.Title>
              {/* <a href="https://mailchi.mp/7049aca427d1/giftcard">{translate('giftCard')}</a> */}
              <a href={mission}>{translate('mission')}</a>
              <a href="https://thenextcloset.bamboohr.com/jobs" target="_blank">
                {translate('jobs')}
              </a>
              <a href={media}>{translate('media')}</a>
              {/* <a href={terms}>{translate('termsConditions')}</a>
              <a href={privacyPolicy}>{translate('privacyPolicy')}</a> */}
            </S.Column>
          </S.LinksContainer>

          <S.SocialContainer>
            <NewsletterSignUp />
            <S.SocialLinks>
              <S.AppStoreLinks>
                <a href={appStoreLink} target="_blank">
                  <S.IOSImage />
                </a>
                <a href={googleStoreLink} target="_blank">
                  <S.GooglePlayImage />
                </a>
              </S.AppStoreLinks>
              <SocialMediaContainer />
            </S.SocialLinks>
          </S.SocialContainer>
        </S.Row>
        <a href="https://nl.trustpilot.com/review/www.thenextcloset.com" target="_blank">
          <S.TrustpilotImage />
        </a>
        <S.PartnersContainer>
          <S.VisaImage />
          <S.MastercardImage />
          <S.BancontactImage />
          <S.MaestroImage />
          <S.IdealImage />
          <S.SofortImage />
          <S.PostNLImage />
          <S.DPDImage />
        </S.PartnersContainer>
        <S.Disclaimer>
          <p>{translate('disclaimer')}</p>
          <p className="hide-mob"><a href={terms}>{translate('termsConditions')}</a></p>
          <p className="hide-mob"><a href={privacyPolicy}>{translate('privacyPolicy')}</a></p>
          <S.Copyright>&copy; {new Date().getFullYear()} The Next Closet</S.Copyright>
        </S.Disclaimer>
      </S.Container>
    </>
  );
};

export default Footer;
