import React from 'react';
import { screen } from '@testing-library/react';
import set from 'lodash/set';
import setupTest from '../../../test/setupTest';
import { ProductPage } from '../ProductPage';

import {
  DefaultProductPageProps,
  initialProductState,
  initialSellerState,
  initialUserState,
} from './initialProductPageData';

test('Should always show links to brand and type', () => {
  setupTest(
    {
      productPage: { product: initialProductState, user: initialSellerState },
      user: initialUserState,
    },
    <ProductPage {...DefaultProductPageProps} />
  );

  const typeLink = screen.getByTestId('typeLink');
  expect(typeLink.href).toEqual('http://localhost/categoryPath');

  const brandLink = screen.getByTestId('brandLink');
  expect(brandLink.href).toEqual('http://localhost/brandPath');
});

test('should always display price, condition, description, material, color, id, favs, views', () => {
  setupTest(
    {
      productPage: { product: initialProductState, user: initialSellerState },
      user: initialUserState,
    },
    <ProductPage {...DefaultProductPageProps} />
  );
  expect(screen.getByText(/€33/i)).toBeTruthy(); // price
  expect(screen.getByText(/conditie: zeer goed/i)).toBeTruthy(); // condition
  expect(screen.getByText(/gucci jurk in zeer goede staat/i)).toBeTruthy(); // description
  const material = screen.getByTestId('material').textContent;
  expect(material).toContain('katoen'); // material
  expect(screen.getByText(/rood/i)).toBeTruthy(); // color
  expect(screen.getByText(/131313/i)).toBeTruthy(); // id
  expect(screen.getByText(/3232/i)).toBeTruthy(); // number of favs
  expect(screen.getByText(/1212/i)).toBeTruthy(); // number of views
  expect(screen.getAllByText(/echtheidsgarantie/i).length).toEqual(2); // authenticity USP and popover title
  expect(screen.queryByAltText(/Sustainable item/i)).toBeFalsy(); // no sustainble icon present
  expect(screen.queryByText(/Heb je een soortgelijk item/i)).toBeFalsy(); // sell similar info box not present
});

test('Should show the right texts for PREMIUM items', () => {
  setupTest(
    {
      productPage: { product: set(initialProductState, 'premium', true), user: initialSellerState },
      user: initialUserState,
    },
    <ProductPage {...DefaultProductPageProps} />
  );
  expect(screen.getAllByText(/retourneren mogelijk/i)).toBeTruthy(); // return USP
  expect(screen.getByText(/Heb je een soortgelijk item?/i)).toBeTruthy(); // info box sell premium/sustainable items
});

test('Should show the right texts for DIY items', () => {
  setupTest(
    {
      productPage: { product: set(initialProductState, 'premium', false), user: initialSellerState },
      user: initialUserState,
    },
    <ProductPage {...DefaultProductPageProps} />
  );
  expect(screen.getAllByText(/doorverkopen mogelijk/i)).toBeTruthy(); // resell USP

  expect(screen.queryByText(/Heb je een soortgelijk item?/i)).toBeFalsy(); // info box sell premium/sustainable items
  expect(screen.queryByText(/verzending voor premium items/i)).toBeFalsy(); // USP title premium shipping
  expect(screen.queryByText(/retourneren mogelijk/i)).toBeFalsy(); // return USP
});

test('should show add to cart button and favorite button if state is APPROVED', () => {
  setupTest(
    {
      productPage: { product:  set(initialProductState, 'backend_state', 'approved'), user: initialSellerState },
      user: initialUserState,
    },
    <ProductPage {...DefaultProductPageProps} />
  );

  expect(screen.getByText(/in winkelwagen/i)).toBeTruthy();
  expect(screen.getByAltText(/favorite icon/i)).toBeTruthy(); // alt text favorite button
});

test('should NOT show add to cart button if sold_or_sold_invisible', () => {
  setupTest(
    {
      productPage: { product: set(initialProductState, 'sold_or_sold_invisible', true), user: initialSellerState },
      user: initialUserState,
    },
    <ProductPage {...DefaultProductPageProps} />
  );

  expect(screen.queryByText(/in winkelwagen/i)).toBeFalsy();
  const soldButton = screen.getByRole('button', { name: /verkocht/i });
  expect(soldButton).toBeTruthy();
  expect(soldButton.disabled).toBeTruthy();
  expect(screen.queryByAltText(/favorite icon/i)).toBeFalsy(); // alt text favorite button

});

test('should NOT show add to cart button if state is PREVIEW', () => {
  setupTest(
    {
      productPage: { product: set(initialProductState, 'backend_state', 'preview'), user: initialSellerState },
      user: initialUserState,
    },
    <ProductPage {...DefaultProductPageProps} />
  );

  expect(screen.queryByText(/in winkelwagen/i)).toBeFalsy();
  const previewButton = screen.getByRole('button', { name: /preview/i });
  expect(previewButton).toBeTruthy();
  expect(previewButton.disabled).toBeTruthy();
  expect(screen.queryByAltText(/favorite icon/i)).toBeFalsy(); // alt text favorite button
});

test('should show "sell similar" and sustainable icon for sustainable products', ()=>{
  setupTest(
    {
      productPage: { product: set(initialProductState, 'sustainable', 'true'), user: initialSellerState },
      user: initialUserState,
    },
    <ProductPage {...DefaultProductPageProps} />
  );

  expect(screen.getByAltText(/Sustainable item/i)).toBeTruthy() // sustainable icon
  expect(screen.getByText(/Heb je een soortgelijk item/i)).toBeTruthy() // sell similar info box
})

test('should have a gtm tag which contains the product id ', () => {
  setupTest(
    {
      productPage: { product: initialProductState, user: initialSellerState },
      user: initialUserState,
    },
    <ProductPage {...DefaultProductPageProps} />
  );
  const gtmTagProductId = document.getElementById('gtm-product-id')
  expect(gtmTagProductId).toBeTruthy()
  expect(gtmTagProductId?.textContent).toEqual('131313')
})
