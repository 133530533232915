import styled from 'styled-components';
import screen from '../../StyledGlobal/mediaQueries';

export const Wrapper = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 50%;
  margin-bottom: 3%;
  ${screen.xsMax`
  flex: 0 100%;`}
`;

export const Title = styled.div`
  background: rgba(255, 255, 255, 0.85);
  text-align: center;
  padding: 8px 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 95%;
`;

export const Image = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
`;

export const Text = styled.p`
  margin: 0;
`;
