/**
 * Repository service
 * The following service exposes a repository
 * to wrap the data types fetched from the TNC servers
 */

import { AxiosInstance } from 'axios';
import API from './api';

class Repository {
  api: AxiosInstance & any;

  constructor() {
    this.api = API;
  }

  async getSustainInfo() {
    return await this.api.get('/v4/impact-calculator');
  }

  getDegradationMessage() {
    return this.api.get('/v4/degradation-message');
  }

  // TODO: rewrite to consume API v4
  async getProduct(id: string) {
    return await this.api.get(`/v2/products/${id}`);
  }

  // TODO: rewrite to consume API v4
  async getSeller(id: string) {
    return await this.api.get(`/user/${id}`);
  }

  async addFavorites(params: any, headers: Object, type: string) {
    return await this.api.post(`/v4/favorites/${type}`, params, headers);
  }

  async removeFavorites(params: any, headers: Object, type: string) {
    return await this.api.delete(`/v4/favorites/${type}`, params, headers);
  }

  async getFavorites(headers: Object) {
    return await this.api.get('/v4/favorites', headers);
  }

  async getFavoriteBrands(headers: Object) {
    return await this.api.get('/v4/favorites/brands', headers);
  }

  async getUserNotifications(headers: Object) {
    return await this.api.get('v4/users/current/user-notifications', headers);
  }

  async updateUserNotifications(params: any, headers: Object) {
    return await this.api.patch('v4/users/current/user-notifications', params, headers);
  }

  async getOrders(id: string, headers: Object) {
    return await this.api.get(`v4/orders/${id}`, headers);
  }

  async updateLineItems(id: string, params: any, headers: Object) {
    return await this.api.patch(`v4/line-items/change-state/${id}`, params, headers);
  }

  async getLineItem(id: string, headers: Object) {
    return await this.api.get(`v4/line-items/${id}`, headers);
  }

  async getProductsImpact(params: unknown) {
    return await this.api.post(`v4/impact-calculator`, params);
  }

  async getUserSoldProductsCount(headers: Object) {
    return await this.api.get(`v4/users/current/sold_products_count`, headers);
  }

  async getFeatureFlag(feature: string, headers: Object) {
    return await this.api.get(`v4/feature_flags?feature=${feature}`, headers);
  }

  async getProductMargin(params: Object, headers: Object) {
    return await this.api.post(`v4/product_margin`, params, headers);
  }

  // TODO: Not written correctly, just written enough so it works.
  async getProductTypes() {
    const urlParams = new URLSearchParams(window.location.search);
    const category = urlParams.get('category');

    return await this.api.get(`v5/products/types?category=${category}`);
  }

  async getCartProductsUrl(headers: Object) {
    return await this.api.get(`v4/carts/products_urls`, headers);
  }

  async getOrderProductUrls(headers: Object) {
    return await this.api.get(`v4/carts/order_products_urls`, headers);
  }

  async searchBrandsFilter(query: string) {
    return await this.api.get(`v4/brands/filter_search?search=${query}`);
  }

  async fetchBrandsFilterList(page: number) {
    return await this.api.get(`v4/brands/filter_list?page=${page}`);
  }

}

export default new Repository();
