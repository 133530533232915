import React from 'react';
import { Provider } from 'react-redux';
import store from '../../src/store';

import Card from './Card';
import { CardProps } from './Card';
import * as S from './styled/styledCC';

interface CardContainerProps {
  cards: CardProps[];
}

const CardContainer: React.FC<CardContainerProps> = ({ cards }) => {
  return (
    <S.Container>
      {cards.map((card) => (
        <Card {...card} key={card.id} />
      ))}
    </S.Container>
  );
};

export default (props: CardContainerProps) => (
  <Provider store={store()}>
    <CardContainer {...props} />
  </Provider>
);
