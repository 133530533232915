import styled from 'styled-components';
import screen from '../../StyledGlobal/mediaQueries';

const Icon = styled.img`
  position: absolute;
  height: 26px;
`;

const Counter = styled.div`
  font-size: 11px;
  width: 30px;
  position: absolute;
  display: flex;
  justify-content: center;
`;

export const HeartIcon = styled(Icon)`
  right: 179px;
  top: 5px;
  margin-top: 5px;
  ${screen.xsMax`
  right: 52px`}
  @media (max-width: 360px) {
    right: 38px;
  }
`;

export const CartIcon = styled(Icon)`
  right: 140px;
  top: 7px;
  ${screen.xsMax`
  right: 8px`}
`;

export const HeartCounter = styled(Counter)`
  top: 10px;
  right: 178px;
  ${screen.xsMax`
  right: 51px`}
  @media (max-width: 360px) {
    right: 37px;
  }
`;

export const CartCounter = styled(Counter)`
  width: 30px;
  top: 10px;
  right: 138px;
  ${screen.xsMax`
  right: 6px`}
`;
