import React from 'react';
import { useTranslation } from 'react-i18next';
import {  useFormikContext } from 'formik';
import ReactMarkdown from 'react-markdown';

import FormError from '../global/FormError';
import * as S from './styled/styledBV';
import NextButton from './NextButton'

interface BankVerificationTwoProps {
  setFormProgress: Function;
}

const BankVerificationTwo: React.FC<BankVerificationTwoProps> = ({
  setFormProgress,
}) => {
  // hooks
  const i18n = useTranslation();
  const { setFieldValue, values } = useFormikContext();
  const documentTypeTranslation = i18n.t(values.typeOfId).toLowerCase();

  const selectImages = () => {
    return (
      <div>
        <S.FrontSideId>
          <S.TextId>{i18n.t('BV__frontsideId', { type: documentTypeTranslation }).toUpperCase()}</S.TextId>
          <input
            data-testid="fileFront"
            id="fileFront"
            name="fileFront"
            type="file"
            onChange={event => {
              setFieldValue(
                'fileFront',
                event.currentTarget.files ? event.currentTarget.files[0] : ''
              );
            }}
          />
          <FormError largeError name="fileFront" />
        </S.FrontSideId>

        { values.typeOfId !== 'passport' ? (
          <>
            <S.BackSideId>
              <S.TextId>{i18n.t('BV__backsideId', { type: documentTypeTranslation }).toUpperCase()}</S.TextId>
              <input
                data-testid="fileBack"
                id="fileBack"
                name="fileBack"
                type="file"
                onChange={event => {
                  setFieldValue(
                    'fileBack',
                    event.currentTarget.files ? event.currentTarget.files[0] : ''
                  );
                }}
              />
              <FormError largeError name="fileBack" />
            </S.BackSideId>
          </>
        ) : (
          ''
        )}
        <S.ButtonContainer marginTop={'50px'}>
          <S.SecondaryButton type="button" onClick={() => setFormProgress('stepTwo')} >
            {i18n.t('previous')}
          </S.SecondaryButton>
          <NextButton nextStep="stepFour" setFormProgress={setFormProgress} testid="stepThreeNext"/>
        </S.ButtonContainer>
      </div>
    );
  };

  return (
    <>
      <S.Title>{i18n.t('BV__stepThreeTitle')}</S.Title>
      <ReactMarkdown>
        {i18n.t('BV__stepThreeText', { type: documentTypeTranslation })}
      </ReactMarkdown>
      <S.InfoText>{i18n.t('BV__stepThreeAcceptedDocuments')}</S.InfoText>
      {selectImages()}
    </>
  );
};

export default BankVerificationTwo;
