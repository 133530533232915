import React from 'react';
import { render, fireEvent, screen } from '@testing-library/react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { I18nextProvider } from 'react-i18next';
import { act } from 'react-dom/test-utils';
import set from 'lodash/set';

import { Checkout } from '../Checkout';
import checkout from '../checkoutSlice';
import i18n from '../../../test/i18nForTest';
import { initialData } from './initialCheckoutData';

const setup = (data) => {
  const store = configureStore({ preloadedState: data, reducer: { checkout } });
  return render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Checkout />
      </I18nextProvider>
    </Provider>
  );
};

test('should load InvoiceAddress component if shipping address is NOT equal to invoice address', async () => {
  await act(() =>
    Promise.resolve(setup(set(initialData, 'checkout.initialAddressData.shippingIsInvoice', false)))
  );
  expect(screen.getByTestId('InvoiceAddress')).toBeTruthy();
});

test('should NOT load InvoiceAddress component if shipping address is equal to invoice address and don"t give invoice errors', async () => {
  window.scrollTo = jest.fn();

  await act(() =>
    Promise.resolve(setup(set(initialData, 'checkout.initialAddressData.shippingIsInvoice', true)))
  );
  expect(screen.queryByTestId('InvoiceAddress')).toBeFalsy();

  const submit = screen.getByTestId('submit');
  await act(() => Promise.resolve(fireEvent.click(submit)));

  expect(screen.queryByTestId('firstnameInvoiceError')).toBeFalsy();
  expect(screen.queryByTestId('lastname.invoice.error')).toBeFalsy();
  expect(screen.queryByTestId('streetInvoiceError')).toBeFalsy();
  expect(screen.queryByTestId('zipcodeInvoiceError')).toBeFalsy();
  expect(screen.queryByTestId('housenumberInvoiceError')).toBeFalsy();
  expect(screen.queryByTestId('cityInvoiceError')).toBeFalsy();
  expect(screen.queryByTestId('countryInvoiceError')).toBeFalsy();
});

test('should load printed address if all invoice data is complete and shipping is not equal to invoice', async () => {
  await act(() =>
    Promise.resolve(setup(set(initialData, 'checkout.initialAddressData.shippingIsInvoice', false)))
  );
  expect(screen.getByTestId('printedInvoiceAddress')).toBeTruthy();
  expect(screen.queryByTestId('inputInvoiceAddress')).toBeFalsy();
});

test('If printed address is visible it will hide when click to change and show input fields', async () => {
  await act(() =>
    Promise.resolve(setup(set(initialData, 'checkout.initialAddressData.shippingIsInvoice', false)))
  );
  const editInvoiceAddress = screen.getByTestId('editInvoiceAddress');
  await act(() => Promise.resolve(fireEvent.click(editInvoiceAddress)));
  // hide printed address
  expect(screen.queryByTestId('printedInvoiceAddress')).toBeFalsy();
  // show input fields
  expect(screen.getByTestId('inputInvoiceAddress')).toBeTruthy();
});
