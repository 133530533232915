import styled from 'styled-components';
import closeImage from '@images/icons/close-x.svg';

export const RemoveFilter = styled.button`
  display: flex;
  align-items: center;
  background: var(--sand-color);
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px 10px 4px 0;
  width: 150px;
  max-height: 35px;
  border: 1px solid #999999;
  border-radius: 5px;
  justify-content: space-between;
  position: relative;
  p {
    margin: 5px;
    font-size: 14px;
    font-weight: 400;
  }
  &:hover {
    background: transparent;
    cursor: pointer;
    opacity: 0.7;
  }
  &:focus {
    color: black;
  }
`;

export const CloseX = styled.div`
  width: 12px;
  height: 12px;
  padding: 7px;

  background-image: url(${closeImage});
  background-repeat: no-repeat;
  background-size: 12px;
`;
