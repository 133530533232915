import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Repository from '../../services/repository';
import {
  ProductPageProduct,
  ProductPageUser,
  ProductPageImpact,
} from '../../types/ProductPageTypes';

type ProductSlice = {
  product: ProductPageProduct | undefined;
  user: ProductPageUser | undefined;
  impact: ProductPageImpact | undefined;
};

const initialState: ProductSlice = {
  product: undefined,
  user: undefined,
  impact: undefined,
};

export const fetchProduct = createAsyncThunk('products/fetchProducts', async (id: string) => {
  const response = await Repository.getProduct(id);
  return {
    ...response.data,
  };
});

export const fetchUser = createAsyncThunk('products/fetchUser', async (id: string) => {
  const response = await Repository.getSeller(id);
  return {
    ...response.data,
  };
});

export const fetchImpact = createAsyncThunk('products/fetchImpact', async (id: string) => {
  const response = await Repository.getProductsImpact({ product_ids: [id] });
  return {
    ...response.data,
  };
});

const productSlice = createSlice({
  name: 'productPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProduct.fulfilled, (state, { payload }) => {
      state.product = payload;
    }),
      builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
        state.user = payload;
      });
    builder.addCase(fetchImpact.fulfilled, (state, { payload }) => {
      state.impact = payload;
    });
  },
});

export const {} = productSlice.actions;

export default productSlice.reducer;
