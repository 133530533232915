import styled from 'styled-components';
import screen from '../../StyledGlobal/mediaQueries';

import iosIcon from '@images/icons/ios.svg';
import googlePlayIcon from '@images/icons/android.svg';
import trustpilotIcon from '@images/icons/trustpilot.svg';


import visaIcon from '@images/logos/visa.svg';
import mastercardIcon from '@images/logos/mastercard.svg';
import bancontactIcon from '@images/logos/bancontact.svg';
import maestroIcon from '@images/logos/maestro.svg';
import idealIcon from '@images/logos/ideal.svg';
import sofortIcon from '@images/logos/sofort.svg';
import postNLIcon from '@images/logos/postnl.svg';
import dpdIcon from '@images/logos/dpd_black.svg';

export const Container = styled.div`
  padding-right: 40px;
  padding-left: 40px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  ${screen.xsMax`
    padding-right: 15px;
    padding-left: 15px;
  `}
`;

export const Row = styled.div`
  margin-left: -15px;
  display: flex;
  justify-content: space-between;
  @media (max-width:1300px){
    flex-direction: column-reverse;
  }
  ${screen.smMax`
    margin-left: 0;
  `}
`;

export const LinksContainer = styled.div`
  display: flex;
  justify-content: space-around;
  a {
    padding: 4px 0;
  }
  ${screen.smMax`
    display: none;
  `}
`;

export const Column = styled.div`
  flex-direction: column;
  margin: 0;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  width: 211px;
  ${screen.xsMax`
    width: 50%;
  `}
  ${screen.smMax`
    margin: 0px 0;
  `}
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 489px;
  p {
    font-size: 14px;
  }
  @media (max-width:1300px){
    margin: 0 auto 50px;
    p {
      text-align: left;
      font-size: 12px;
    }
  }
  ${screen.smMax`
    padding: 0;
  `}
`;

export const SocialLinks = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 30px 5px 10px;
  ${screen.xsMax`
    flex-wrap: wrap-reverse;
    padding: 0;
  `}
  @media (min-width:1300px){
    position: relative;
    bottom: -69px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  ${screen.xsMax`
        width: 50%;
        margin: auto;
        padding: 20px 0;
        margin-top: 0px;
        margin-bottom: 20px;
  `}
  a {
    padding: 0 15px;
    border-bottom: none;
    width: 24px;
    height: 24px;
    justify-content: center;
    display: flex;
  }
`;

export const AppStoreLinks = styled.div`
  display: inline-flex;
  a {
    width: 128px;
    margin-right: 10px;
    img {
      width: 100%;
    }
  }
  a:last-child {
    margin-right: 0px;
  } 
  ${screen.xsMax`
    width: 100%;
    justify-content: center;

    a {
      width: 116px;
      margin-left: 0;
      margin-right: 15px;
      
    }
  `}
`;

export const IOSImage = styled.img.attrs({ src: iosIcon, alt: 'Apple Store Icon' })``;
export const GooglePlayImage = styled.img.attrs({ src: googlePlayIcon, alt: 'Google Play Icon' })``;
export const TrustpilotImage = styled.img.attrs({ src: trustpilotIcon, alt: 'Trustpilot Icon' })`
  height: 54px;
  align-self:center;
  display: none;
  ${screen.xsMax`
  display: block;
    width: 100%;
    margin-top: -30px;
    margin-bottom: 40px;
    justify-content: center;
  `}
`;

export const Disclaimer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  p {
    padding-bottom: 10px;
    padding-right: 30px;
    line-height: 21px;
    font-size: 14px;
    font-weight: 400;
  }
  p:first-child {
    width: 100%;
    padding-right: 0;
  }
  ${screen.smMin`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 10px;
  `}
  ${screen.xsMax`
    p:first-child {
      padding: 0;
      text-align: center;
      font-size: 10px;
    }
    p:last-child {
      width: 100%;
      text-align: center;
    }
  `}
`;

export const Copyright = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  margin-bottom: 21px;
  ${screen.smMax`
    justify-content: center;
    font-family: Poppins;
    font-style: normal;
    justify-content: center;
    align-self: center;
    text-align: center;
  `}
  ${screen.xsMax`
    width: 100%;
    font-size: 10px;
  `}
`;

export const PartnersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px -15px 40px;
  justify-content: start;
  align-items: center;
  img {
    margin: 0 10px 10px;
  }
  ${screen.smMax`
    justify-content: center;
    img {
      height: 3vw;
      margin: 0 4px 10px;
    }
  `}
`;

export const VisaImage = styled.img.attrs({ src: visaIcon, alt: 'Visa logo' })`
  height: 15px;
`;
export const MastercardImage = styled.img.attrs({ src: mastercardIcon, alt: 'Mastercard logo' })`
  height: 35px;
`;
export const BancontactImage = styled.img.attrs({ src: bancontactIcon, alt: 'Bancontact logo' })`
  height: 25px;
`;
export const MaestroImage = styled.img.attrs({ src: maestroIcon, alt: 'Maestro logo' })`
  height: 35px;
`;
export const IdealImage = styled.img.attrs({ src: idealIcon, alt: 'Ideal logo' })`
  height: 32px;
`;
export const SofortImage = styled.img.attrs({ src: sofortIcon, alt: 'Sofort logo' })`
  height: 14px;
`;
export const PostNLImage = styled.img.attrs({ src: postNLIcon, alt: 'PostNL logo' })`
  height: 35px;
`;
export const DPDImage = styled.img.attrs({ src: dpdIcon, alt: 'DPD logo' })`
  height: 25px;
`;
