import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import * as S from './../styled/styledPOS';

import {
  getExtraFilters,
  toggleExtraFilter,
  fetchProducts,
  setCurrentPage,
} from '../../productFilterSlice';

const HideSoldSwitch = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const extraFilters = useSelector(getExtraFilters);
  const active = extraFilters.hideSold.active;

  const toggleCookieHideSold = () => {
    Cookies.get('hide_sold') === 'true'
      ? Cookies.set('hide_sold', 'false')
      : Cookies.set('hide_sold', 'true');
  };

  const handleApply = () => {
    dispatch(setCurrentPage(1));
    dispatch(toggleExtraFilter('hideSold'));
    toggleCookieHideSold();
    dispatch(fetchProducts());
  };

  return (
    <S.Switch onClick={handleApply}>
      <S.Wrapper>
        <S.PremiumText>{i18n.t('HideSoldSwitch.hide_sold_items')}</S.PremiumText>
        <S.SwitchIcon switchOn={active} />
      </S.Wrapper>
    </S.Switch>
  );
};

export default HideSoldSwitch;
