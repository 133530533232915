import styled from 'styled-components';
import screen from '../../StyledGlobal/mediaQueries';

export const StyledFollowProductButton = styled.button`
  border: none;
  background: transparent;
  img {
    width: 20px;
    height: 20px;
    ${screen.xsMax`
      width: 15px;
      height: 15px;
    `}
  }
`;
