import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import axios from 'axios';
import { set } from 'lodash';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import User from '../User';
import userSlice, { setUser } from '../userSlice';

const initialData = {
  userId: null,
  getUserPath: 'getUserPath',
};

const setup = (data: any) => {
  const store = configureStore({ reducer: { userSlice } });
  return render(
    <Provider store={store}>
      <User {...data} />
    </Provider>
  );
};

beforeEach(() => {
  localStorage.clear();
});


test('should remove the authentication of a user if there is no user logged in', () => {
  localStorage.setItem('auth', 'authenticationData');
  const removeAuth  = jest.spyOn(Storage.prototype, 'removeItem')
  setup(initialData);
  expect(removeAuth).toHaveBeenCalledTimes(1);
});

test('should request authentication data from Rails if a user is logged in and no user data present in localStorage', async () => {
  axios.post.mockResolvedValue({ data: 'authenticationData' });
const setItemSpy = jest.spyOn(Storage.prototype, 'setItem');

  setup(set(initialData, 'userId', 1000));

  await waitFor(() => {
    expect(setItemSpy).toHaveBeenCalledTimes(1);
    expect(setItemSpy).toHaveBeenCalledWith('auth', '"authenticationData"');
  });
});
