import styled from 'styled-components';
import closeImage from '../../../../../assets/images/icons/close-x.svg';

export const Container = styled.div`
  display: flex;
  padding-left: 0 !important;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-items: center;
`;

export const RemoveAllFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  max-height: 35px;
  margin: 0 10px 10px 0px;
  padding: 4px 10px 4px 0;
  
  color: black;
  border: 1px solid #999999;
  background-color: var(--sand-color);
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;

    background-color: transparent;

    transition: all 0.2s ease 0s;
  }
  p {
    margin: 5px;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const CloseX = styled.div`
  width: 12px;
  height: 12px;
  padding: 7px;

  background-image: url(${closeImage});
  background-repeat: no-repeat;
  background-size: 12px;
`;
