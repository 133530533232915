import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Switch, ThemeProvider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Provider, useSelector } from 'react-redux';

import { StyledPrimaryButton } from '../StyledGlobal/StyledButton';
import { muiTheme } from '../../services/muiTheme';

import * as S from './styled/styledCookieConsentBanner';
import { gtmLoader } from '../Analytics/gtmLoader';
import store from '../../src/store';
import { getAuthentication } from '../User/userSlice';

const CookieConsentBanner: React.FC = () => {
  const user = useSelector(getAuthentication);

  // we read this value in the cookies to determine whether we show the cookie banner or not
  const [functionalCookieConsentStatus, setFunctionalCookieConsentStatus] = useState(
    Cookies.get('functionalCookieConsent') === 'true' ? true : false
  );

  // the analytical cookies are by default set to true to make sure the user has to do another click to decline them
  const [analyticalCookieConsentStatus, setAnalyticalCookieConsentStatus] = useState(true);
  const [manageCookies, setManageCookies] = useState(false);
  const analyticalCookieConsentStatusKey = 'analyticalCookieConsentStatus';

  // Cleanup: removing the old cookieconsent cookie that isn't used anymore
  useEffect(() => {
    Cookies.remove('cookieconsent_status');
  }, []);

  const getStoredAnalyticalCookieConsentStatus = (): boolean => {
    const storedAnalyticalCookieConsentValue = localStorage.getItem(
      analyticalCookieConsentStatusKey
    );
    if (storedAnalyticalCookieConsentValue === null) {
      return true;
    } else {
      return storedAnalyticalCookieConsentValue === 'true';
    }
  };

  useEffect(() => {
    setAnalyticalCookieConsentStatus(getStoredAnalyticalCookieConsentStatus());
    if (getStoredAnalyticalCookieConsentStatus() === true) {
      if (process.env.NODE_ENV === 'production') {
        gtmLoader();
      }
    }
  }, [analyticalCookieConsentStatus, user?.userId]);

  const i18n = useTranslation();

  const translate = (text: string) => {
    return i18n.t(`cookieConsentBanner.${text}`);
  };

  // when the functional cookies are accepted (happens when clicking accept) the cookies are set.
  // the functional cookies are always accepted. The analytical cookies can be declined so we read out the state.
  const setCookies = () => {
    setFunctionalCookieConsentStatus(true); // so it hides the box
    Cookies.set('functionalCookieConsent', 'true', { expires: 10000 });
    Cookies.set('analyticalCookieConsent', analyticalCookieConsentStatus.toString(), {
      expires: 10000,
    });
  };

  const handleAcceptAll = () => {
    setAnalyticalCookieConsentStatus(true);
    setCookies();
  };

  const showCookieBanner = (functionalCookieConsentStatus: boolean) => {
    if (functionalCookieConsentStatus) {
      // don't show banner if functional cookies are accepted
      return null;
    }

    const handleAnalyticalCookieConsentPermissions = () => {
      const updatedAnalyticStatus = !analyticalCookieConsentStatus;
      setAnalyticalCookieConsentStatus(updatedAnalyticStatus);
      localStorage.setItem(
        analyticalCookieConsentStatusKey,
        updatedAnalyticStatus ? 'true' : 'false'
      );
    };

    const containerContent = (manageCookies: boolean) => {
      return manageCookies ? (
        <>
          <S.ManageCookiesWrapper>
            <S.SwitchWrapper marginBottom>
              <S.Title>{translate('functional_cookies_title')}</S.Title>
              <S.Label
                control={<Switch checked={true} disabled />}
                label={translate('functional_cookies_info')}
              />
            </S.SwitchWrapper>
            <S.SwitchWrapper>
              <S.Title>{translate('analytical_cookies_title')}</S.Title>
              <S.Label
                control={
                  <Switch
                    checked={analyticalCookieConsentStatus}
                    onChange={handleAnalyticalCookieConsentPermissions}
                    color="secondary"
                  />
                }
                label={translate('analytical_cookies_info')}
              />
            </S.SwitchWrapper>
          </S.ManageCookiesWrapper>

          <S.ButtonWrapper>
            <StyledPrimaryButton
              width="250px"
              onClick={() => {
                setCookies();
              }}
            >
              {translate('save_cookie_settings')}
            </StyledPrimaryButton>
          </S.ButtonWrapper>
        </>
      ) : (
        <>
          <S.Text>{translate('main_text')}</S.Text>
          <S.ButtonWrapper>
            <S.AcceptCookieButton width="250px" onClick={() => handleAcceptAll()}>
              {translate('accept_cookies')}
            </S.AcceptCookieButton>
            <S.Link
              onClick={() => {
                setManageCookies(true);
              }}
            >
              {translate('manage_cookies')}
            </S.Link>
          </S.ButtonWrapper>
        </>
      );
    };

    return (
      <>
        <S.Overlay />
        <S.Container data-testid="cookieConsentBanner">
          {containerContent(manageCookies)}
        </S.Container>
      </>
    );
  };

  return showCookieBanner(functionalCookieConsentStatus);
};

export default () => (
  <Provider store={store()}>
    <ThemeProvider theme={muiTheme}>
      <CookieConsentBanner />
    </ThemeProvider>
  </Provider>
);
