import styled from 'styled-components';

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 20px;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
`;

export const TextWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TitleWrapper = styled.div`
  font-size: 15px;
  display: flex;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  height: 42px;
  margin-bottom: 40px;
  a:first-child {
    display: block;
  }
`;

export const Description = styled.div`
  /* multiple lines ellipsis */
  /* autoprefixer: off */
  display: -webkit-box;
  /* autoprefixer: on */
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 10px 0;
  height: 7em; // to have min height if description is not 5 lines high
`;
