import styled from 'styled-components';

import dress from '@images/icons/dress.svg';
import pen from '@images/icons/pen.svg';
import camera from '@images/icons/camera.svg';
import euro from '@images/icons/euro.svg';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-bottom: 2px solid var(--light-grey);
  border-top: 2px solid var(--light-grey);
`;

export const Step = styled.div<{ active: boolean }>`
  border-left: 2px solid var(--light-grey);
  width: 100%;
  padding: 15px;
  background-color: ${props => (props.active ? 'var(--light-green)' : 'transparent')};
  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  height: 25px;
  padding-left: 10px;
  padding-right: 15px;
`;

export const DressIcon = styled(Icon).attrs({ src: dress })``;
export const PenIcon = styled(Icon).attrs({ src: pen })``;
export const CameraIcon = styled(Icon).attrs({ src: camera })``;
export const EuroIcon = styled(Icon).attrs({ src: euro })``;
