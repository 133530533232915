import styled from 'styled-components';
import screen from '../../StyledGlobal/mediaQueries';
import co2Icon from '@images/icons/CO2.svg';
import waterIcon from '@images/icons/waterdrop.svg';

export const Container = styled.div`
  background-color: #eef1ee;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 40px;
  ${screen.xsMax`margin-left: 20px;`}
  ${screen.xsMax`margin-top: 20px;`}
`;

export const Title = styled.h1`
  font-family: Overpass;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  color: #323232;
  margin-bottom: 20px;
`;

export const ImpactText = styled.div`
  font-size: 18px;
  padding-right: 6px;
`;

export const ImpactValue = styled.div`
  height: 25.85px;
  font-family: Overpass;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #323232;
`;

export const MaterialSaved = styled.div`
  font-size: 12px;
`;

export const CO2Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const CO2Icon = styled.img.attrs({ src: co2Icon, alt: 'CO2 Icon' })`
  height: 15px;
  padding-right: 15px;
`;

export const WaterdropWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 30px;
`;

export const WaterdropIcon = styled.img.attrs({ src: waterIcon, alt: 'Waterdrop Icon' })`
  height: 15px;
  margin-left: 5px;
  padding-right: 22px;
`;
