/* eslint-disable jest-dom/prefer-checked */

import React from 'react';
import { render, screen, fireEvent, act, waitFor } from '@testing-library/react';
import { I18nextProvider } from 'react-i18next';

import { goToStepTwo, goToStepThree } from './utils';
import i18n from '../../../test/i18nForTest';
import BankVerification from '../BankVerification';

const setup = () => {
  return render(
    <I18nextProvider i18n={i18n}>
      <BankVerification updateBankPath="mockPath" />
    </I18nextProvider>
  );
};

test('Not allowed to go to step 3 if no documentType is chosen', async () => {
  setup();
  await goToStepTwo();
  // step 2 is shown
  expect(screen.getByText(/STAP 2: UPLOAD DOCUMENT/i)).toBeTruthy();
  const stepTwoNext = screen.getByTestId('stepTwoNext');
  // not allowed to proceed if not selected a type of document
  await act(() => Promise.resolve(fireEvent.click(stepTwoNext)));
  expect(screen.getByText(/Je moet een documenttype selecteren/i)).toBeTruthy();
  // error message should dissappear when selecting an option
  const radioButtonDriversLicense = screen.getByText(/Rijbewijs/);
  await act(() => Promise.resolve(fireEvent.click(radioButtonDriversLicense)));
  expect(screen.queryByText(/Je moet een documenttype selecteren/i)).toBeFalsy();
  // now it's possible to go to step three
  await act(() => Promise.resolve(fireEvent.click(stepTwoNext)));
});

test('should show ONE uploadfield in next step when selecting passport', async () => {
  setup();
  await goToStepThree(/paspoort/i);
  expect(screen.getByTestId('fileFront')).toBeTruthy();
  expect(screen.queryByTestId('fileBack')).toBeFalsy();
});

test('should show TWO uploadfield in next step when selecting ID card', async () => {
  setup();
  await goToStepThree(/identiteitskaart/i);
  expect(screen.getByTestId('fileFront')).toBeTruthy();
  expect(screen.getByTestId('fileBack')).toBeTruthy();
});

test('should show TWO uploadfields in next step when selecting drivers license', async () => {
  setup();
  await goToStepThree(/rijbewijs/i);
  expect(screen.getByTestId('fileFront')).toBeTruthy();
  expect(screen.getByTestId('fileBack')).toBeTruthy();
});
