// hide dropdown my items filter on click
$('.dropdown-item-filter-my-items').on('click', e =>
  $('#dropdown-my-items-filter').removeClass('open')
);

$(function() {
  $('#my-items-filter-toggle').on('click', function(e) {
    return $(this)
      .find('p')
      .toggleClass('active-dropdown');
  });

  $(document).click(function() {
    $('.dropdown-toggle').removeClass('active-dropdown');
    $('p').removeClass('active-dropdown');
  });

  // items per page
  $('.number-of-items select').change(function(e) {
    const d = new Date(2100, 0, 1).toUTCString();
    document.cookie = `number_of_items=${$(this).val()}; path=/; expires=${d}`;
    return location.replace(location.href.replace(/\Wpage=\d+/, ''));
  });

  // follow products show page
  $('.follow-product-show').on('click', function(e) {
    e.preventDefault();
    $(this).addClass('disabled');
    return $.ajax({
      url: $(this).attr('href'),
      context: this,
      dataType: 'json',
    }).done(function(res) {
      if (res.redirect_to != null) {
        window.location = res.redirect_to;
        return res.redirect_to;
      }
      $(this).removeClass('disabled');
      return $(this).html(`<img src='${res.new_fav_icon}'>`);
    });
  });

  //show share buttons on products show
  $('.views-shared-row').on('click', '.share', function() {
    $(this).hide();
    return $('#share-buttons')
      .fadeIn(300)
      .show();
  });

  // accordion show item page
  const accords = $('.accordion-row > dd');
  const accordsheading = $('.accordion-row > dt');
  $('.accordion-row')
    .find('dd:first')
    .css('display', 'block');
  accordsheading.click(function(e) {
    $('.accordion-arrow').removeClass('rotate-arrow');
    if ($(this).hasClass('active')) {
      accords.slideUp();
      accordsheading.removeClass('active');
    } else {
      accordsheading.removeClass('active');
      accords.slideUp();
      $(this)
        .next()
        .slideDown();
      $(this).addClass('active');
      $(this)
        .find('.accordion-arrow')
        .toggleClass('rotate-arrow');
    }

    return false;
  });

  $('.follow-user').click(function(e) {
    e.preventDefault();
    return $.ajax({
      url: $(this).attr('href'),
      context: this,
    }).done(function(res) {
      if (res.redirect_to != null) {
        return (window.location = res.redirect_to);
      } else {
        $(this).html(`${res.new_link_text.substring(0, 28)}`);
        return $(this).toggleClass('stop-follow follow');
      }
    });
  });

  // Photo mouseover
  const $container = $('#products-container').parent();
  $container.on('mouseenter', '.product', function() {
    if ($(this).data('touched')) {
      return;
    }
    const $img = $(this).find('img[data-back]');
    const back = $img.data('back');
    $img.data('front', $img.attr('src'));

    return $img.attr('src', back);
  });

  $container.on('mouseleave', '.product', function() {
    if ($(this).data('touched')) {
      return;
    }
    const $img = $(this).find('img:first');
    const front = $img.data('front');
    return $img.attr('src', front);
  });

  $container.on('touchstart', '.product', function() {
    return $(this).data('touched', true);
  });
});
