import styled from 'styled-components';

export const Image = styled.img`
  box-sizing: border-box;
  height: 100%;
  // width: 100%;
`;

export const SmallImageContainer = styled.div`
  // border: 1px solid rgba(0, 0, 0, 0.30);
  // aspect-ratio: 3/4;
`;

export const SmallImage = styled.img`
  width:100%;
  height: auto;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const SliderWrapper = styled.div`
  position: relative;
  // border: 1px solid rgba(0, 0, 0, 0.30);
  border-bottom: none;
  box-sizing: border-box;
  @media (max-width: 991px) {
    border: none;
    max-height: none;
  }
`;

export const PremiumLogo = styled.p`
  background-color: ${(props) => props.theme.colors.sand};
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100000000;
  text-align: center;
  width: 30%;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export const Sticky = styled.div`
  position: sticky;
  top: 0;
  border-bottom: none;
`;
