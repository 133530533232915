import React from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import Pagination from '../global/Pagination';
import store from '../../src/store';
import { getExtraFilters, fetchProducts, setCurrentPage } from './productFilterSlice';

const categoryParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('category');
}

const PaginationContainer = () => {
  const {
    pagination: { currentPage, totalPages },
  } = useSelector(getExtraFilters);
  const dispatch = useDispatch();

  const handlePageChange = newPage => {
    dispatch(setCurrentPage(newPage));
    dispatch(fetchProducts(categoryParam()));
  };

  return <Pagination currentPage={currentPage} pageCount={totalPages} setPage={handlePageChange} />;
};

export default () => (
  <Provider store={store()}>
    <PaginationContainer />
  </Provider>
);
