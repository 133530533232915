import React from 'react';

import * as S from './styled/styledDropDownCard';
interface DropdownCardProps {
  title: string,
  image: string,
  link: string,
  id: string,
  width: string,
}

const DropdownCard: React.FC<DropdownCardProps> = ({
  title = '',
  image = null,
  link = '',
  id = '',
  width = ''
}) => {

  const contents = image ? (
    <S.Link href={link} id={id} width={width} image={image} data-testid="link">
      <S.TextWrapper>
        <S.Text data-testid="text">{title}</S.Text>
      </S.TextWrapper>
    </S.Link>
  ) : null;

  return <div data-testid="component-dropdown-card">{contents}</div>;
};

export default DropdownCard;
