import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { TreeView, TreeItem } from '@material-ui/lab';

import { Theme } from '../../../services/theme';

export const MenuIconContainer = styled.div`
  width: 35px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  @media (min-width: 360px) and (max-width: $screen-sm-max) {
    width: 45px;
  }
`;

export const MainMenu = styled.div`
  background-color: #ffffff;
`;

export const Footer = styled.div`
  padding-top: 15px;
`;

export const StyledBurger = styled(Button)`
  min-width: 0 !important;
  top: -1px;
  left: 0;
  height: 45px;
  padding: 0 !important;
  img {
    opacity: 0.5;
  }
`;

export const StyledMenu = styled.nav<{ open: boolean }>`
  color: #000000;
  width: 100%;
  min-height: calc(100vh - 30px);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transform: ${props => (props.open ? 'translateX(0)' : 'translateX(-100%)')};
  z-index: 99999;
  background-color: #ffffff;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
  font-family: ${Theme.fonts.text};
  padding-bottom: 75px;
  .subgroup-item {
    padding-left: 60px;
  }
  .group-subgroup-class {
    .subgroup-item {
      padding-left: 95px;
    }
  }
  & li[aria-selected="true"][aria-expanded="true"] {
    > .MuiTreeItem-content {
      > .MuiTreeItem-label {
        font-weight: 400;
        text-decoration: none;
        color: ${Theme.colors.blueHover};
        &:hover {
          background-color: transparent;
        }
        &:focus-visible {
          outline: none !important;
        }
      }
    }
  }
  .MuiTreeItem-label {
    background-color: transparent !important;
    &:hover {
      background-color: transparent;
    }
  }
  .subgroup-item {
    &:hover, &:focus {
      text-decoration: none !important;
      color: ${Theme.colors.blueHover};
      outline: none !important;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
`;

export const Icon = styled.img`
  position: absolute;
  right: 24px;
  height: 15px;
`;

export const Logo = styled.img`
  height: 16px;
`;


export const TreeContainer = styled(TreeView)`
  & .MuiTypography-body1 {
    font-family: Poppins;
    font-size: 14px;
    line-height: 2em;
  }
  a {
    font-family: Poppins;
    font-size: 14px;
    color: #000000;
    line-height: 2em;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
`;

export const NavItem = styled(TreeItem)<{ $changeColor?: boolean, marginBottom?: string, $borderColor?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;


  margin-bottom: ${props => (props.marginBottom ? '20px' : 'inherit')};
  & .MuiTreeItem-content {
     padding: 10px 25px;
     border-bottom: 1px solid rgba(0, 0, 0, 0.15)
   }
  & .MuiTreeItem-label {
    color: ${props => (props.$changeColor ? 'red' : 'inherit')};
    padding-left: 0;
    }
    
  }

  & .MuiTreeItem-iconContainer {
    position: fixed;
    right: 20px;
    svg {
      font-size: 25px;
    }
  }

  & .MuiCollapse-wrapperInner {
    display: flex;
    flex-direction: column;
  }
  & .MuiTreeItem-group {
    margin-left: 0;
  }
  & .nav-item-submenu {
    padding: 0;
    & .MuiTreeItem-content {
      padding-left: 60px;
    }
    a {
      padding-left: 95px;
    }
  }
`;

export const HighlightedCollection = styled.span<{ $changeColor?: boolean }>`
padding-top: 40px;
  a {
    color: ${props => (props.$changeColor ? 'red' : 'inherit')};
  }
`;

export const NavContainer = styled.div<{ $paddingTop? : boolean}>`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  padding-top: ${props => (props.$paddingTop ? '40px !important' : 'inherit')};
`;

export const LanguageWidget = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
  a {
    padding: 10px;
  }
`;

export const Language = styled.a<{ bold: boolean }>`
  border-right: 1px solid black;
  font-weight: ${props => (props.bold ? 'bold' : '300')};
  padding: 10px;
`;
