/* eslint-disable jest-dom/prefer-checked */

import React from 'react';
import { render, screen, fireEvent, act } from '@testing-library/react';
import { I18nextProvider } from 'react-i18next';

import i18n from '../../../test/i18nForTest';
import BankVerification from '../BankVerification';


const setup = () => {
  return render(
    <I18nextProvider i18n={i18n}>
      <BankVerification updateBankPath="mockPath"  />
    </I18nextProvider>
  );
};

test('Should block going to step two if not accept terms', async () => {
  setup();
  // step 1 is show initially
  expect(screen.getByText(/Stap 1: waarom verificatie?/i)).toBeTruthy();
  const stepOneNext = screen.getByTestId('stepOneNext');
  // not allowed to proceed if not accepted terms
  await act(() => Promise.resolve(fireEvent.click(stepOneNext)));
  // display error
  expect(screen.getByText(/Je moet akkoord gaan om verder te gaan/i)).toBeTruthy();
  // accept terms and conditions
  const termsCheckbox = screen.getByText(/Ik heb bovenstaande gelezen en ga akkoord./i);
  await act(() => Promise.resolve(fireEvent.click(termsCheckbox)));
  expect(screen.queryByText(/Je moet akkoord gaan om verder te gaan/i)).toBeFalsy();
  // now it's possible to go to step two
  await act(() => Promise.resolve(fireEvent.click(stepOneNext)));

  // step 2 is shown
  expect(screen.getByText(/STAP 2: UPLOAD DOCUMENT/i)).toBeTruthy();


});



