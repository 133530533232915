import React, { memo } from 'react';
import PropTypes from 'prop-types';

import * as S from './../styled/styledMI';

const MenuItem = ({ title, subTitle, count, handleClick, icon }) => {
  return (
    <S.StyledItem type="button" onClick={handleClick}>
      <S.Left>
        {title} {count && <S.Count>({count})</S.Count>}
        {subTitle && <S.Subtitle>{subTitle}</S.Subtitle>}
      </S.Left>
      {icon && <S.Icon className={icon} />}
    </S.StyledItem>
  );
};

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  count: PropTypes.number,
  icon: PropTypes.string,
  subTitle: PropTypes.string,
};

MenuItem.defaultProps = {
  count: null,
  icon: null,
  subTitle: null,
};

export default memo(MenuItem);
