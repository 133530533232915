import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../src/store';

// State Type
export type ProductListItem = {
  id: string;
  brand: string;
  type: string;
  typeEN: string;
  image: string;
  placeholder: string;
  backImage: string;
  imageAlt: string;
  ProductPageUrl: string;
  isPremium: boolean;
  origPrice: number;
  tncPrice: number,
  price: [boolean, string];
  priceWithoutCurrency: number;
  size: string;
  favoriteLink: string;
  currentFavorite: boolean;
  sold: boolean;
  topSeller: boolean;
  displayName: string;
  levelRecommendation: string;
  addToBagLink: string;
  isInShoppingbag: boolean;
  linkToCart: string;
  isSustainable: boolean;
  conditionId: 1;
  condition: string;
  material: string;
  color: string;
  collections: string[] | null;
  sellerType: string;
};

type ProductList = {
  itemIds: string[];
  items: { [key: string]: ProductListItem };
};

const initialState: ProductList = {
  itemIds: [],
  items: {},
};

// Create slice
const productListSlice = createSlice({
  name: 'productList',
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<ProductListItem[]>) => {
      state.itemIds = action.payload.map((i) => i.id);
      state.items = Object.fromEntries(action.payload.map((i) => [i.id, i]));
    },
  },
});

// Selectors
export const getState = (state: RootState): RootState => state;
export const getItemIds = (state: RootState): string[] => state.productList.itemIds;
export const getItems = (state: RootState): { [key: string]: ProductListItem } =>
  state.productList.items;

// exports
export default productListSlice.reducer;

export const { setItems } = productListSlice.actions;
