import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { gtmProductListSerializer } from '../Analytics/gtmProductSerializer';
import store from '../../src/store';

const ProductCardTypeC = React.lazy(() => import('./ProductCardTypeC'));

function PCSerializerTypeC({
  products,
}) {
  useEffect(() => {
    // GTM Tracking
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        currency: 'EUR',
        items: products && Object.entries(products).map(gtmProductListSerializer),
        items_google_ads: products && Object.entries(products).map((product) => {
          return {
            item_id: product[1].id,
            google_business_vertical: 'retail'
          }
        })
      },
    });
  }, [products]);
  return (
    <div className="cards-type-c">
      {products.map(product => (
        <div key={product.id} className='serializer-card-wrapper'>
          <Suspense fallback={<div>Loading...</div>}>
            <ProductCardTypeC {...product} />
          </Suspense>
        </div>
      ))}
    </div>
  );
}

export default (props) => (
  <Provider store={store()}>
    <PCSerializerTypeC {...props} />
  </Provider>
);
