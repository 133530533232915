import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledLogo, StyledTitleWrapper, StyledCloseIcon } from './styled/styledModal';

import closeIcon from '../../icons/close.svg';
import logoImage from '@images/tnc_logo_black.svg';

interface ModalTitleProps {
  title?: string;
  hide: Function;
  logo?: boolean;
}

const ModalTitle: React.FC<ModalTitleProps> = ({ title = null, hide, logo = false }) => {
  const { i18n } = useTranslation();

  const showLogo = (
    <StyledLogo>
      <img src={logoImage} alt="TNC logo" />
    </StyledLogo>
  );

  return (
    <>
      {logo ? showLogo : ''}
      <StyledTitleWrapper onClick={() => hide()}>
        <div>{title}</div>
        <StyledCloseIcon src={closeIcon} alt={i18n.t('ModalTitle.close')} />
      </StyledTitleWrapper>
    </>
  );
};

export default ModalTitle;
