import styled from 'styled-components';
import screen from '../../StyledGlobal/mediaQueries';

import topSellerIcon from '@images/icons/top_seller.svg';
import sustainableIcon from '@images/icons/sustainable.svg'


export const Wrapper = styled.div`
  position: relative;
  padding: 30px 15px 10px;
  background-color: white;
  margin-bottom: 30px;
  width: 100% !important;
  height: 100% !important;
  ${screen.xsMax`margin-bottom: 10px;
    padding: 30px 0 0;`}
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100% !important;
  padding-bottom: 100%;
  margin-bottom: 20px;
`;

export const InfoWrapper = styled.div`
  position: relative;
  padding: 10px;
  height: 90px;
  z-index: 1;
`;

export const FlexSpaced = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Brand = styled.p`
  margin: 0;
  ${screen.xsMax`font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}
`;

export const Flex = styled.div`
  display: flex;
`;

export const Type = styled.p`
  margin: 0 5px 0 0;
  font-size: 14px;
`;

interface PriceProps {
  red: boolean;
}

export const Price = styled.span<PriceProps>`
  font-family: 'Playfair Display', serif;
  font-size: 18px;
  color: ${props => (props.red ? 'var(--brand-warning)' : 'black')};
`;

export const Size = styled.p`
  font-size: 14px;
`;

export const PriceOrig = styled.span`
  margin-left: 7px;
  text-decoration: line-through;
  font-family: 'Playfair Display', serif;
  font-size: 14px;
  color: var(--medium-grey);
`;

const Badge = styled.div`
  font-weight: 600;
  position: absolute;
  left: 0;
  height: 31px;
  z-index: 1;
  padding-left: 20px;
  &:after {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

interface PremiumProps {
  text: string;
}

export const Premium = styled(Badge)<PremiumProps>`
  background: var(--light-green);
  top: 15px;
  width: 96px;
  &:after {
    content: '${(props) => props.text}';
  }
`;

export const Sale = styled(Badge)`
  background: var(--brand-warning);
  top: 53px;
  width: 58px;
  color: white;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Leaf = styled.img.attrs({ src: sustainableIcon })`
position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
  display: inline-block;
  height: 25px;
`;


export const TopSeller = styled.img.attrs({
  src: topSellerIcon,
  alt: 'TNC Top Seller',
})`
  height: 40px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
`;

export const Sold = styled.div`
  font-size: 2.5em;
  z-index: 2;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  ${screen.xsMax`font-size: 1.8em;`}
  @media (max-width: 600px) {
    font-size: 1.5em;
  }
  p {
    font-size: 20px;
    line-height: 40px;
    text-align: center;
  }
  p:first-child {
    font-size: 1em;
    margin: 25px 0;
  }
`;

export const SoldHover = styled(Sold)`
  display: flex;
  flex-direction: column;
`;
