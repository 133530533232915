type Category = 'women' | 'men' | 'boys' | 'girls';

function getCategoryFromQueryParams(): Category {
  const queryParams = new URLSearchParams(window.location.search);
  const categoryParam = queryParams.get('category');

  if (categoryParam && isValidCategory(categoryParam)) {
    return categoryParam as Category;
  }

  return 'women'; // Default value
}

function isValidCategory(value: string): value is Category {
  return ['women', 'men', 'boys', 'girls'].includes(value);
}

export const category: Category = getCategoryFromQueryParams()