import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function DiscountCalculation(props) {
  const { i18n } = useTranslation();
  const { discount, currentPrice, shippingFee } = props;

  return (
    <div>
      <div className="flex flex--spaced">
        <div className="red">{i18n.t('disMod_discount')}</div>
        <div className="red">{`€ ${discount || 0}`}</div>
      </div>
      <div className="calc__divider" />
      <div className="flex flex--spaced">
        <div>{i18n.t('disMod_newPrice')}</div>
        <div>{`€ ${currentPrice - discount}`}</div>
      </div>
      <div className="flex flex--spaced">
        <div>{i18n.t('disMod_shipping')}</div>
        <div>{`€ ${shippingFee}`}</div>
      </div>
      <div className="calc__divider" />
      <div className="flex flex--spaced">
        <div>{i18n.t('disMod_newPriceShip')}</div>
        <div className="DM__newPriceShipping">{`€ ${currentPrice - discount + shippingFee}`}</div>
      </div>
    </div>
  );
}

DiscountCalculation.propTypes = {
  discount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currentPrice: PropTypes.number.isRequired,
  shippingFee: PropTypes.number.isRequired,
};

export default DiscountCalculation;
