import React from 'react';
import { render } from '@testing-library/react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { I18nextProvider } from 'react-i18next';

import i18n from '../../../../test/i18nForTest';
import productFilter, * as productFilterActions from '../../productFilterSlice';
import { DesktopFilterContainer } from '../DesktopFilterContainer';

const setup = data => {
  const store = configureStore({ preloadedState: { productFilter: data }, reducer: { productFilter } });

  return render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <DesktopFilterContainer />
      </I18nextProvider>
    </Provider>
  );
};

export default setup
