import React from 'react';

import NextButton from './NextButton';
import PreviousButton from './PreviousButton';
import MaterialTextField from '../global/MaterialTextField';
import translateComponent from '../helpers/translateI18n';

import * as S from './styled/styledUPP';

import { UploadProductThreeProps } from './UploadProductPageTypes';
import { showTooltipDescription } from './ProductUploadToolTips';

const UploadProductThree: React.FC<UploadProductThreeProps> = ({ setFormProgress }) => {
  const translate = translateComponent('UploadProduct');

  return (
    <S.Container>
      <h2>{translate('unleashInnerSalesperson')}</h2>
      <S.TitleAndToolTip>
        <p>
          {translate('describeItem')}
          {translate('dontForgetSignsWear')}
        </p>
        {showTooltipDescription()}
      </S.TitleAndToolTip>

      <MaterialTextField
        name="description"
        placeholderText={translate('writeDescription')}
        multiline
        rows={10}
      />

      <S.CommentsHeader>{translate('additionalComments')}</S.CommentsHeader>
      <MaterialTextField name="comment" multiline rows={2} />
      <S.ButtonContainer>
        <PreviousButton previousStep="stepTwo" setFormProgress={setFormProgress} />
        <NextButton nextStep="stepFour" setFormProgress={setFormProgress} />
      </S.ButtonContainer>
    </S.Container>
  );
};

export default UploadProductThree;
