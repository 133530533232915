import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const checkoutSchema = (shippingIsInvoice: boolean) => {
  const invoiceSchema = shippingIsInvoice
    ? ''
    : yup.object().shape({
        firstname: yup.string().required('firstname_required'),
        lastname: yup.string().required('lastname_required'),
        street: yup.string().required('street_required'),
        housenumber: yup
          .number()
          .typeError('must_be_number')
          .required('housenumber_required'),
        housenumberAddition: yup.string().max(3, 'houseNumberAdditionMaxLength'),
        zipcode: yup.string().required('zipcode_required'),
        city: yup.string().required('city_required'),
        country: yup.string().required('ShippingCountry.country_required'),
      });

  return yup.object().shape({
    shipping: yup.object().shape({
      firstname: yup.string().required('firstname_required'),
      lastname: yup.string().required('lastname_required'),
      street: yup.string().required('street_required'),
      housenumber: yup
        .number()
        .typeError('must_be_number')
        .required('housenumber_required'),
      housenumberAddition: yup.string().max(3, 'houseNumberAdditionMaxLength'),
      zipcode: yup.string().required('zipcode_required'),
      city: yup.string().required('city_required'),
      country: yup.string().required('ShippingCountry.country_required')
    }),
    invoice: invoiceSchema,
    paymentMethod: yup.string().required('payment_method_required'),
    creditcardType: yup.string().when('paymentMethod', {
      is: 'creditcard',
      then: yup.string().required('creditcardType_required'),
    }),
  });
};
