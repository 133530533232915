import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled/StyledPagination';

function Pagination(props) {
  const { currentPage, pageCount, setPage } = props;

  const handleClick = newPage => {
    setPage(newPage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const previousPages =
    //   only show when not on the first page
    currentPage > 1 ? (
      <>
        <S.FirstButton onClick={() => handleClick(1)} />
        <S.PreviousButton onClick={() => handleClick(currentPage - 1)} />
      </>
    ) : (
      ''
    );

  const nextPages =
    //   only show next pages if not yet on the last page
    currentPage < pageCount ? (
      <>
        <S.NextButton onClick={() => handleClick(currentPage + 1)} />
        <S.LastButton onClick={() => handleClick(pageCount)} />
      </>
    ) : (
      ''
    );

  const content =
    //   only show the component if there is more than one page
    pageCount > 1 ? (
      <>
        {previousPages}
        <div>
          {currentPage} / {pageCount}
        </div>
        {nextPages}
      </>
    ) : (
      ''
    );

  return <S.Container>{content}</S.Container>;
}

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
};

Pagination.defaultProps = {
  currentPage: 1,
};

export default Pagination;
