import styled, { css } from 'styled-components';

import searchImage from '../../../../../assets/images/icons/filter_search.svg';
import checkImage from '../../../../../assets/images/icons/check-bold.svg';
import arrowDown from '../../../../../assets/images/icons/arrow_down_filter.svg';
import arrowUp from '../../../../../assets/images/icons/arrow_up.svg';

export const Filter = styled.div`
  position: relative;
`;

export const DropdownButton = styled.div`
 position: relative;
 box-sizing: border-box;
 width: 100%;
 padding: 10px 12px;
 ${({ withoutArrow, isExpanded }) => !withoutArrow ? isExpanded ? css`
    background-image: url(${arrowUp});
    font-weight: 500;
  ` : css`
    background-image: url(${arrowDown});
    ` : ''
  }
  background-repeat: no-repeat;
  background-position: right 14px top 18px;
  background-size: 14px;
  border-top: 1px solid var(--black-25);
  cursor: pointer;
  user-select: none;
`;

export const ListContainer = styled.div`
  top: 44px;
  z-index: 3;

  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 12px;

  background-color: white;
  cursor: pointer;

  &:nth-of-type(1) {
    grid-column: 1 / 3;
  }
`;

export const List = styled.div`
  max-height: 210px;
  position: relative;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  overflow-y: auto;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  &::-webkit-scrollbar {
    width: 5px !important;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px #999999; 
    -webkit-border-radius: 6px;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
    background-color: #000000;
    height: 10% !important;
  }
`;

export const ApplyButton = styled.button`
  width: 100%;
  height: 40px;
  padding: 0 !important;

  background: var(--light-green);
  border: 0;
`;

export const SearchField = styled.input`
  width: 100%;
  margin-bottom: 20px;
  padding: 5px;

  font-size: 14px !important;
  text-indent: 40px;

  background-image: url(${searchImage});
  background-repeat: no-repeat;
  background-position-y: 6px;
  background-position-x: 10px;
  border: 1px solid #999999;
  opacity: 0.4
  &:focus {
    background-color: transparent;
    outline: 0 !important;
  }
  &:focus-visible {
    outline: 0 !important;
  }
`;

export const SelectedItems = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  color: $gray-999;
  opacity: 0.65;
`;
