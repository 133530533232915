import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Repository } from '@thenextcloset/shared-javascript';
import { setHeaders } from '../../services/helpers';
import { FormValues, SubmitterProps } from './UploadProductPageTypes';

const Submitter: React.FC<SubmitterProps> = ({
  authentication,
  setSubmittingError,
  uploadingAssets,
  productToEdit,
}) => {
  const { isSubmitting, setSubmitting, values } = useFormikContext<FormValues>();
  // Submit form
  useEffect(() => {
    const uploadProduct = async (values: FormValues) => {
      authentication &&
        Repository.uploadProduct({ product: values }, { headers: {...setHeaders(authentication), 'Upload-Origin': 'web'} })
          .then(res => {
            if (res.data === true) {
              window.location.assign('/thankyou');
            } else {
              setSubmitting(false);
              setSubmittingError(res.data.error);
            }
          })
          .catch(() => {
            setSubmitting(false);
            setSubmittingError('Something went wrong');
          });
    };

    const updateProduct = (values: FormValues) => {
      authentication &&
        Repository.updateProduct(
          { id: values.id, product: values },
          { headers: setHeaders(authentication) }
        )
          .then(res => {
            // redirect to my items
            if (res.data === true) {
              window.location.assign(`/member/${authentication.userId}/my-items`);
            } else {
              setSubmitting(false);
              setSubmittingError(res.data.error);
            }
          })
          .catch(() => {
            setSubmitting(false);
            setSubmittingError('Something went wrong');
          });
    };
    // waiting for the uploading of assets to have finished in the background
    if (isSubmitting && uploadingAssets === 0) {
      productToEdit ? updateProduct(values) : uploadProduct(values);
    }
  }, [
    values,
    uploadingAssets,
    productToEdit,
    authentication,
    setSubmitting,
    setSubmittingError,
    isSubmitting,
  ]);

  return null;
};

export default Submitter;
