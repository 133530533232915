import { Theme } from './theme';
import { createTheme } from '@material-ui/core';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: Theme.colors.lightGreen,
    },
    secondary: {
      main: Theme.colors.green,
    },
  },
  typography: {
    fontFamily: Theme.fonts.text,
  },
});

export { muiTheme };
