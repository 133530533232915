import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, Provider } from 'react-redux';

import store from '../../../src/store';
import Filter from './Filter';
import { useTranslation } from 'react-i18next';
import * as HideSortStyle from './styled/styledHSPC';
import * as S from './styled/styledDFC';
import { setLocaleURLS } from '../utils';
import {
  getFilters,
  getExtraFilters,
  getTotalProductsCount,
  toggleExtraFilter,
  setCurrentPage,
  setBrandsFilterData,
  fetchProducts,
} from '../productFilterSlice';
import SizeFilterDropdown from './SizeFilterDropdown';
import TypesFilterDropdown from './TypesFilterDropdown';
import Repository from '../../../services/repository';
import Spinner from '../../global/Spinner';

const DesktopFilterContainer = () => {
  const filters = useSelector(getFilters);
  const extraFilters = useSelector(getExtraFilters);
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const totalProductsCount = useSelector(getTotalProductsCount);

  // sequence list, is the sequence the filters are displayed
  const availableFilters = [
    'types',
    'brands',
    'seasons',
    'conditions',
    'materials',
    'colors',
    'seller-type',
    'price-groups',
    'saleCollections',
    'discount-range'
  ].filter(name => name === 'seller-type' || filters[name].itemIds.length >= 0);

  const sizeFilters = [
    'clothing-sizes',
    'jeans-sizes',
    'shoe-sizes',
    'kids-clothing-sizes',
    'kids-shoe-sizes',
  ];

  const enableSearchInFilter = filterName => {
    // return ['types', 'brands', 'colors', 'materials'].includes(filterName);
    return ['brands'].includes(filterName);
  };

  const searchBrandsFilter = (query) => {
    if(query.length >= 2) {
      Repository.searchBrandsFilter(query).then((res) => {
        dispatch(setBrandsFilterData({ data: res.data, append: false }))
      })
    }
    else {
      Repository.fetchBrandsFilterList(1).then((res) => {
        dispatch(setBrandsFilterData({ data: res.data, append: false }))
      })
    }
  }

  const fetchBrandsForFilter = (page) => {
    Repository.fetchBrandsFilterList(page).then((res) => {
      dispatch(setBrandsFilterData({ data: res.data, append: true }))
    })
  }
  const loading = useSelector((state) => state.productFilter.loading);

  useEffect(() => {
    // Make sure the 'change-language' urls work
    setLocaleURLS(filters, extraFilters);

    // If the user pressed the back-button, make sure to reload the page
    const onPopState = e => {
      if (e.state?.filterUsed) {
        window.location.reload();
      }
    };
    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  });

  const hideSoldText = () => {
    if (extraFilters.hideSold.active) {
      return i18n.t('HSPC__show_sold_items');
    }
    return i18n.t('HSPC__hide_sold_items');
  };

  const toggleCookieHideSold = () => {
    if (Cookies.get('hide_sold') === 'true') {
      Cookies.set('hide_sold', 'false');
    } else {
      Cookies.set('hide_sold', 'true');
    }
  };

  const handleApply = () => {
    dispatch(toggleExtraFilter('hideSold'));
    dispatch(setCurrentPage(1));
    toggleCookieHideSold();
    dispatch(fetchProducts());
  };

  const [clothingSizeOpen, setClothingSizeOpen] = useState(true);

  return (
    <>
      {loading && <Spinner />}
      <S.Container>
        <Filter
          key={'gender'}
          name={'gender'}
          searchEnabled={enableSearchInFilter('gender')}
        />
        {sizeFilters.length > 0 && (
          <SizeFilterDropdown
            sizeFilters={sizeFilters}
            clothingSizeOpen={clothingSizeOpen} 
            setClothingSizeOpen={setClothingSizeOpen}
          >
          </SizeFilterDropdown>
        )}
        {availableFilters.map(filterName => (
          filterName === 'types' ? (
            <TypesFilterDropdown
              key={filterName}
              name={filterName}
            />
          ) : (
            <Filter
              key={filterName}
              name={filterName}
              searchEnabled={enableSearchInFilter(filterName)}
              searchBrandsFilter={searchBrandsFilter}
              fetchBrandsForFilter={fetchBrandsForFilter}
            />
          )
        ))}
      </S.Container>
      <HideSortStyle.HideSoldToggle className="underline" onClick={handleApply}>
        {hideSoldText()}
      </HideSortStyle.HideSoldToggle>
    </>
  );
};

export { DesktopFilterContainer };

export default props => (
  <Provider store={store()}>
    <DesktopFilterContainer {...props} />
  </Provider>
);
