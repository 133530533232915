import {
  ProductPageProduct,
  ProductPageUser,
  ProductPageProps,
} from '../../../types/ProductPageTypes';
import { UserState } from '../../User/userSlice';

export const DefaultProductPageProps: ProductPageProps = {
  id: 131313,
  sellerPath: 'sellerPath',
  askQuestionPath: 'askQuestionPath',
  uploadProductPath: 'uploadProductPath',
  productIsInCart: false,
  productForCart: { linkToCart: 'linkToCart', addToBagLink: 'addToBagLink', id: 1 },
  userLoggedIn: false,
  logInPath: 'logInPath',
  categoryPath: 'categoryPath',
  brandPath: 'brandPath',
  optionalSecondAncestorName: 'Jurken & mantels',
  optionalSecondAncestorPath: 'optionalSecondAncestorPath',
  ancestorCategoryPath: 'ancestorCategoryPath',
  rootPath: 'rootPath',
  urlForBreadCrumbTypeAndBrand: 'urlForBreadCrumbTypeAndBrandPath',
};

export const initialProductState: ProductPageProduct = {
  id: 131313,
  user_id: 12,
  price_original: 44,
  display_price: 33,
  large_assets: ['image_1_l.jpeg', 'image_2_l.jpeg', 'image_3_l.jpeg'],
  name: 'Jurk',
  brand: 'Gucci',
  sustainable: false,
  sold: false,
  price: [true, '15.5', '12.0'],
  size: 'M',
  premium: false,
  assets: ['image_1.jpeg', 'image_2.jpeg', 'image_3.jpeg'],
  favs: 3232,
  views: 1212,
  main_type: 'Kleding',
  type: 'Jurken',
  type_en: 'Dress',
  condition: 'Zeer goed',
  color: 'red',
  material: 'katoen',
  collection: 'collection',
  seller_type: 'seller-type',
  condition_en: '(As good as) new',
  description: 'Gucci jurk in zeer goede staat',
  product_properties: [
    { name: 'kleur', value: 'rood' },
    { name: 'materiaal', value: 'katoen' },
  ],
  product_properties_en: [
    { name: 'kleur', value: 'rood' },
    { name: 'materiaal', value: 'katoen' },
  ],
  type_id: 2,
  sold_or_sold_invisible: false,
  backend_state: 'approved',
};

export const initialSellerState: ProductPageUser = {
  firstname: 'Cruella',
  lastname: 'De Vil',
  member_since: '2015-12-06T12:04:17.000+01:00',
  items_for_sale: 200,
  items_sold: 100,
  followers: 50,
  avatar_small: 'avatar_small.jpeg',
  verified: true,
};

export const initialUserState: UserState = {
  authentication: {
    uid: 'someone@something.com',
    accessToken: 'randomn',
    client: 'client',
    userId: 123,
  },
  favoriteProductCount: 0,
  favorites: {
    sellerIds: [],
    productIds: [],
    brandIds: [],
  },
};
