import styled from 'styled-components';

export const Table = styled.table`
  font-size: 14px;
`;

export const Th = styled.th`
  width: 40px;
`;

export const ThBig = styled(Th)`
  width: 50px;
`;

export const ThBiggest = styled(Th)`
  width: 90px;
`;

export const TrTitle = styled.tr`
  border-bottom: 1px solid;
`;

export const Td = styled.td`
  padding-bottom: 5px;
`;

export const SizeTableTitle = styled.p`
  width: auto;
  max-width: 300px;
  margin-bottom: 15px !important;
`;
