import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Provider, useDispatch } from 'react-redux';
import store from '../../src/store';

import { toggleInCart } from '../Header/headerSlice';

import './style/ShoppingCartProduct.scss';
import trashIcon from './icons/trash.svg';

const ShoppingCartProduct = (props) => {
  const {
    product: { brand, type, price, origPrice, tncPrice, image, imageAlt, size, isPremium },
  } = props;

  const { lineItemId } = props;
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const deleteFromShoppingBagLink = `/line_items/${lineItemId}`;

  function handleCart() {
    dispatch(toggleInCart([deleteFromShoppingBagLink, 'delete', false]));
  }

  const premium = isPremium ? <div className="ShoppingCartProduct__watermark" /> : '';

  const showSize = size ? (
    <div className="t__f--s">
      {i18n.t('size')}
      {size}
    </div>
  ) : (
    ''
  );

  const originalPrice =
    origPrice > 0 ? (
      <span className="h__f--s font--mediumgrey SCP__price__original">
        {`€ ${parseInt(origPrice, 10)}`}
      </span>
    ) : (
      ''
    );

  return (
    <div className="SCP flex flex--spaced">
      <div className="SCP__image__container">
        <img src={image} alt={imageAlt} className="img-responsive" />
      </div>
      <div className="SCP__info">
        <div className="SCP__brand">{brand}</div>
        <div className="t__f--s">{type}</div>
        {showSize}
        {premium}
      </div>
      <div className="SCP__price">
        {originalPrice}
        <span className={cx('h__f--m', price[0] ? 'red' : '')}>{price[1]}</span>
      </div>
      <div className="SCP__delete">
        <div onClick={handleCart}>
          <img src={trashIcon} alt="Delete" />
        </div>
      </div>
      <p> </p>
    </div>
  );
};

ShoppingCartProduct.propTypes = {
  product: PropTypes.shape({
    brand: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    price: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string])).isRequired,
    origPrice: PropTypes.string,
    tncPrice: PropTypes.string,
    image: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    size: PropTypes.string,
    isPremium: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    material: PropTypes.string.isRequired,
  }).isRequired,
  lineItemId: PropTypes.number.isRequired,
};

// HOC for redux
export default (props) => (
  <Provider store={store()}>
    <ShoppingCartProduct {...props} />
  </Provider>
);
