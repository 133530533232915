import styled from 'styled-components';
import { StyledPrimaryButton, StyledSecondaryButton } from '../../StyledGlobal/StyledButton';

export const Icon = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 17px;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const PrimaryButton = styled(StyledPrimaryButton)`
  margin: auto;
`;

export const SecondaryButton = styled(StyledSecondaryButton)`
  margin: 15px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
