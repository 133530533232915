import React from 'react';
import PropTypes from 'prop-types';
import * as S from './../styled/styledMI';

const CloseButton = ({ handleClose }) => (
  <button type="button" className="btn btn-link pull-right" onClick={handleClose}>
    <S.CloseX />
  </button>
);

CloseButton.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default CloseButton;
