import { filter } from 'lodash';
import { FormValues, Product } from './UploadProductPageTypes';

export const editFormValues = (product: Product): FormValues => {
  const getProperty = (
    property: 'heel_height' | 'sleeve_length' | 'seizoen' | 'materiaal' | 'kleur'
  ) => {
    return filter(product.product_properties, { name: property })[0]?.value;
  };

  const heel_height = getProperty('heel_height');
  const sleeve_length = getProperty('sleeve_length');
  const season = getProperty('seizoen');

  return {
    id: product.id,
    rootType: product.root_type,
    type: product.type,
    brand: product.brand,
    needSize: !!product.size,
    size: product.size,
    needHeelHeight: !!heel_height,
    needSleeveLength: !!sleeve_length,
    needSeason: !!season,
    condition: product.condition,
    description: product.description,
    comment: '',
    assets: product.assets_with_ids.map((a) => a.assetId),
    price_original:
      product.price_original && product.price_original > 0 ? product.price_original : '',
    price: Number(product.price_product),
    country: '',
    price_including_shipping: product.display_price,
    properties: {
      material: getProperty('materiaal'),
      season: season,
      color: getProperty('kleur'),
      heel_height: heel_height,
      sleeve_length: sleeve_length,
    },
    typeId: product.type_id,
    typeName: `${product.type_id}|${product.type}`,
  };
};

export const newFormValues = (
  rootType: string | undefined,
  country: 'NL' | 'BE' | '',
  category: string
): FormValues => {
  return {
    audience: 'women',
    rootType: rootType,
    category: category,
    type: '',
    brand: null, // needs to be null for no value in MUI AutoComplete
    needSize: false,
    size: '',
    needHeelHeight: false,
    needSleeveLength: false,
    needSeason: false,
    condition: '',
    description: '',
    comment: '',
    assets: [],
    price_original: '',
    price: '',
    country: ['NL', 'BE'].includes(country) ? country : '',
    price_including_shipping: 0,
    properties: {
      material: '',
      season: '',
      color: '',
      heel_height: '',
      sleeve_length: '',
    },
    typeId: undefined,
    typeName: '',
  };
};
