/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { sortableElement, sortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { useFormikContext } from 'formik';
import { uid } from 'react-uid';
import axios from 'axios';
import produce from 'immer';

import * as S from './styledU';
import Field from './Field';
import useWindowWidth from '../helpers/hooks/useWindowWidth';
import { photosAndLabels } from '../Uploader/photoTipsAndLabels';
import translateComponent from '../helpers/translateI18n';
import PhotoTips from './PhotoTips';

const SortableItem = sortableElement((props) => <Field {...props} />);
const SortableContainer = sortableContainer(({ children }) => <S.Grid>{children}</S.Grid>);

/** Uploader */
const Uploader = ({
  initialPhotos,
  restricted,
  setPreviewImage,
  productRootType,
  uploadingAssets,
  setUploadingAssets,
}) => {
  const { i18n } = useTranslation();
  const width = useWindowWidth();
  const { setFieldValue, errors } = useFormikContext();

  const translate = translateComponent('Uploader');

  const [uploaders, setUploaders] = useState(
    [...Array(12)].map((_, i) => {
      const { assetId, src } = initialPhotos[i] || {};
      return { id: uid('uploader', i), file: null, assetId: assetId || '', src };
    })
  );
  const [uploadError, setUploadError] = useState(false);

  useEffect(() => {
    const assetIds = Object.fromEntries(
      uploaders.filter((el) => el.assetId).map((el, index) => [index, el.assetId])
    );

    setFieldValue('assets', assetIds);
    setPreviewImage(uploaders[0].src);
  }, [uploaders, setFieldValue]);

  useEffect(() => {
    const firstUploader = window.$('#js-diy-uploader img:first');
    window.$('#your-item-image img').attr('src', firstUploader.attr('src'));
  }, []);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setUploaders((_uploaders) => arrayMove(_uploaders, oldIndex, newIndex));
  };

  // Photo tips and labels
  const photosAndLabelsForType = photosAndLabels(productRootType);
  const labels = photosAndLabelsForType.map((p) => p.label);

  // Updates the state of an uploader
  const updateUploader = (id, data) => {
    setUploaders((prev) => {
      const index = prev.findIndex((u) => u.id === id);
      return produce(prev, (draft) => {
        Object.assign(draft[index], data); // merge data
      });
    });
  };

  const handleDelete = (id) => {
    updateUploader(id, { file: null, assetId: '', src: null });
  };

  const updateSrc = (id, src) => {
    updateUploader(id, { src });
  };

  const handleFileSelect = (id, selectedFiles) => {
    // let i = (id && uploaders.findIndex(u => u.id === id)) || 0;
    let i = 0;
    Array.from(selectedFiles).forEach((f) => {
      if (!['image/jpeg', 'image/png'].includes(f.type)) return; // allow jpg/png

      // find the first available uploader
      while (i < uploaders.length) {
        if (!uploaders[i].src) {
          break;
        }
        i += 1;
      }
      const currentId = uploaders[i].id;

      // Update file in state
      updateUploader(currentId, { file: f });

      // Upload file
      const formData = new FormData();
      formData.append('file', f);

      const config = {
        onUploadProgress(/* progressEvent */) {
          // const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      setUploadingAssets((c) => c + 1);
      const res = axios.post('/api/v4/assets', formData, config);

      res.then(({ data }) => {
        setUploaders((prev) => {
          return produce(prev, (draft) => {
            const uploader = draft.find((u) => u.id === currentId);
            // Only set id, if file is still there
            if (uploader.file) {
              setUploadError(false);
              uploader.assetId = String(data);
            }
          });
        });
      });
      res.finally(() => {
        setUploadingAssets((c) => c - 1);
      });

      res.catch(error => {
        handleDelete(currentId)
        setUploadError(true);
      });

      i += 1;
    });
  };

  const onDrop = (e) => {
    handleFileSelect(null, e.dataTransfer.files);
    e.preventDefault();
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div id="js-diy-uploader" onDragOver={onDragOver} onDrop={onDrop}>
      {uploadingAssets > 0 && (
        <S.Message green>
          <p>{i18n.t('uploading', { count: uploadingAssets })}</p>
        </S.Message>
      )}

      {uploadingAssets === 0 && errors.assets ? (
        <S.Flex>
          <S.Error>{errors.assets}</S.Error>
        </S.Flex>
      ) : null}

      {uploadingAssets === 0 && uploadError ? (
        <S.Flex>
          <S.Error>{i18n.t('failedImageUpload')}</S.Error>
        </S.Flex>
      ) : null}

      <S.Flex>
        <SortableContainer onSortEnd={onSortEnd} axis="xy" distance={5}>
          {uploaders.map(({ id, assetId, file, src }, index) => {
            const C = (restricted && index) === 0 ? Field : SortableItem; // use the non-sortable 'Field' when the first image is restricted
            return (
              <C
                id={id}
                assetId={assetId}
                key={id}
                restricted={restricted && index === 0}
                index={index}
                file={file}
                src={src}
                handleFileSelect={handleFileSelect}
                handleDelete={handleDelete}
                updateSrc={updateSrc}
                label={productRootType !== 'accessories' && labels[index]}
              />
            );
          })}
        </SortableContainer>
        {width < 768 && <PhotoTips photosAndLabels={photosAndLabelsForType} />}
      </S.Flex>
    </div>
  );
};

Uploader.propTypes = {
  restricted: PropTypes.bool,
  setPreviewImage: PropTypes.func.isRequired,
  initialPhotos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      assetId: PropTypes.string,
    })
  ),
};

Uploader.defaultProps = {
  initialPhotos: [],
  restricted: false,
};

export default Uploader;
