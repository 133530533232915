import styled from 'styled-components';
import { LargeBoldHeader } from '../../StyledGlobal/StyledFont';
import { StyledPrimaryLink } from '../../StyledGlobal/StyledButton';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;
export const MobileContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 600px;
`;

interface DesktopContainerProps {
  imageLeft: boolean;
  margin: string;
  backgroundColor: string;
  padding: string;
}

export const DesktopContainer = styled(Container)<DesktopContainerProps>`
  flex-direction: ${(props) => (props.imageLeft ? 'row-reverse' : 'row')};
  background-color: ${(props) => props.backgroundColor};
  margin: ${(props) => props.margin};
  padding: 40px 75px;
`;

export const DesktopImageWrapper = styled.div`
  width: 49%;
`;

export const DesktopImage = styled.img`
  width: 100%;
  height: auto;
`;

export const TextWrapper = styled.div`
  width: 35%;
`;

export const Title = styled(LargeBoldHeader)`
  max-width: 380px;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  margin-bottom: 40px;
`;

export const Button = styled(StyledPrimaryLink)`
  background-color: transparent;
  border: 2px solid black;
  padding: 9px 17px;
  &:hover {
    background-color: #cedecc;
  }
`;
