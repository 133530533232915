import React, { Dispatch, SetStateAction } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import * as S from './styled/styledNewsletter';
import store from '../../src/store';
import { Provider, useSelector } from 'react-redux';
import { getAuthentication } from '../User/userSlice';
import axios from 'axios';

const NEWSLETTER_API_ENDPOINT = '/api/v4/newsletter_signup';

export interface NewsletterSignUpFormProps {
  setSignupSuccess: Dispatch<SetStateAction<boolean>>;
  justifyContent?: string;
}

const initialState = {
  email: '',
};

const emailValidation = yup.object().shape({
  email: yup
    .string()
    .email('no_valid_email')
    .required('email_required'),
});

const NewsletterSignUpForm: React.FC<NewsletterSignUpFormProps> = ({
  setSignupSuccess,
  justifyContent = null,
}) => {
  const i18n = useTranslation();
  const translate = (text: string) => i18n.t(`newsletterSignUp.${text}`);

  return (
    <Formik
      initialValues={initialState}
      onSubmit={async ({ email }) => {
        await axios.post(NEWSLETTER_API_ENDPOINT, { email });
        setSignupSuccess(true);
      }}
      validationSchema={emailValidation}
    >
      {({ handleSubmit, dirty, isSubmitting }) => (
        <S.Form onSubmit={handleSubmit}>
          <S.FormWrapper justifyContent={justifyContent}>
            <S.Field name="email" label={i18n.t('email')} />
            <S.Button type="submit" disabled={!dirty && isSubmitting}>
              {translate('subscribe')}
            </S.Button>
          </S.FormWrapper>
        </S.Form>
      )}
    </Formik>
  );
};

export default (props: NewsletterSignUpFormProps) => (
  <Provider store={store()}>
    <NewsletterSignUpForm {...props} />
  </Provider>
);
