import React, { FC } from 'react';

import { StyledText } from './styled/styledModal';

interface ModalTextProps {
  text: string;
}

const ModalText: FC<ModalTextProps> = ({ text }) => {
  return <StyledText>{text}</StyledText>;
};


export default ModalText;
