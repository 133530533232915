import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import piexifjs from 'piexifjs';
import { StyledField, Label, Delete } from './styledF';

/* Field component */
const Field = ({
  restricted,
  src,
  updateSrc,
  handleDelete,
  handleFileSelect,
  file,
  label,
  id,
  className,
  assetId,
  hidden
}) => {
  const [dragging, setDragging] = useState(false);

  const canvasRef = useRef();
  const inputRef = useRef();

  // create the preview image
  const createPreview = () => {
    if (!file) {
      // setSrc(null);
      return;
    }
    const canvas = canvasRef.current;
    const reader = new FileReader();
    const ctx = canvas.getContext('2d');

    reader.onload = ev => {
      const img = document.createElement('img');

      img.onload = () => {
        canvas.width = Math.min((img.width / img.height) * 600, 600);
        canvas.height = Math.min((img.height / img.width) * 600, 600);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        let dataURL = canvas.toDataURL(file.type);
        if (file.type === 'image/jpeg') {
          try {
            const exifStr = piexifjs.dump(piexifjs.load(ev.target.result));
            dataURL = piexifjs.insert(exifStr, dataURL);
          } catch (err) {
            console.error(err);
          }
        }

        updateSrc(id, dataURL);
      };
      img.src = ev.target.result;
    };

    reader.readAsDataURL(file);
  };

  /*
   * Effects
   */
  useEffect(() => {
    // Create or remove preview
    createPreview();
  }, [file]);

  /*
   * Event handlers
   */
  const onDrop = e => {
    e.preventDefault();
    // uploadFile(e.dataTransfer.files[0])
    setDragging(false);
    if (restricted) return;
    handleFileSelect(id, e.dataTransfer.files);
  };

  const onDragOver = e => {
    setDragging(true);
    e.preventDefault();
  };

  const onDragLeave = () => setDragging(false);

  const onChange = e => {
    handleFileSelect(id, e.target.files);
    // uploadFile(e.target.files[0]);
  };

  /*
   * JSX
   */
  return (
    <StyledField
      dragging={dragging}
      className={className}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      hidden={hidden}
    >
      <div>
        <canvas style={{ display: 'none' }} ref={canvasRef} />
        {label && <Label>{label}</Label>}
        {src && !restricted && (
          <Delete
            type="button"
            onClick={() => {
              handleDelete(id);
            }}
          >
            &times;
          </Delete>
        )}
        {/* <input type="hidden" name="diy_entry_form[assets][]" value={assetId} /> */}
        {!src && !restricted && (
          <label htmlFor={id}>
            <input
              accept=".jpg,.jpeg,.png,image/jpeg,image/png"
              ref={inputRef}
              id={id}
              onChange={onChange}
              type="file"
              multiple
            />
          </label>
        )}
        {/* preview */}
        {src && <img src={src} alt={label ?? ''} />}
      </div>
    </StyledField>
  );
};

/*
 * Proptypes
 */
Field.propTypes = {
  id: PropTypes.string.isRequired,
  handleFileSelect: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  updateSrc: PropTypes.func.isRequired,
  restricted: PropTypes.bool,
  assetId: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  className: PropTypes.string,
  file: PropTypes.objectOf(String),
  src: PropTypes.string,
};

Field.defaultProps = {
  label: false,
  restricted: true,
  className: '',
  file: null,
  assetId: '',
  src: null,
};

export default Field;
