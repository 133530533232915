import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  pushPage,
  getFilters,
  reset,
  fetchProducts,
  setMobileOpen,
  getExtraFilters,
  setBrandsFilterData,
  setCurrentPage,
  toggleExtraFilter,
} from '../productFilterSlice';
import CloseButton from './components/CloseButton';
import MenuItem from './components/MenuItem';
import Filter from '../DesktopFilterContainer/Filter';
import SizeFilterDropdown from '../DesktopFilterContainer/SizeFilterDropdown';
import TypesFilterDropdown from '../DesktopFilterContainer/TypesFilterDropdown'
import Repository from '../../../services/repository';

import * as HideSortStyle from '../DesktopFilterContainer/styled/styledHSPC';
import * as S from './styled/styledMP';

const MainPage = ({ handleClose }) => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const filters = useSelector(getFilters);
  const extraFilters = useSelector(getExtraFilters);
  const [clothingSizeOpen, setClothingSizeOpen] = useState(true);

  const getSelected = filterName => {
    const filter = filters[filterName];
    return filter.itemIds.filter(id => filter.items[id].selected);
  };

  const handleReset = () => {
    dispatch(reset());
    dispatch(fetchProducts());
  };
  const closeFilter = () => {
    dispatch(setCurrentPage(1));
    dispatch(setMobileOpen(false));
  };

  const handleTypesClick = () => {
    dispatch(pushPage({ filterName: 'types' }));
    const selected = getSelected('types');
    if (selected.length > 0) {
      const parentId = filters.types.items[selected[0]].parent;
      parentId && dispatch(pushPage({ filterName: 'types', id: parentId }));
    }
  };

  const searchBrandsFilter = (query) => {
    if(query.length >= 2) {
      Repository.searchBrandsFilter(query).then((res) => {
        dispatch(setBrandsFilterData({ data: res.data, append: false }))
      })
    }
    else {
      Repository.fetchBrandsFilterList(1).then((res) => {
        dispatch(setBrandsFilterData({ data: res.data, append: false }))
      })
    }
  }

  const fetchBrandsForFilter = (page) => {
    Repository.fetchBrandsFilterList(page).then((res) => {
      dispatch(setBrandsFilterData({ data: res.data, append: true }))
    })
  }

  const enableSearchInFilter = filterName => {
    // return ['types', 'brands', 'colors', 'materials'].includes(filterName);
    return ['brands'].includes(filterName);
  };

  const hideSoldText = () => {
    if (extraFilters.hideSold.active) {
      return i18n.t('HSPC__show_sold_items');
    }
    return i18n.t('HSPC__hide_sold_items');
  };

  const handleApply = () => {
    dispatch(toggleExtraFilter('hideSold'));
    dispatch(setCurrentPage(1));
    toggleCookieHideSold();
    dispatch(fetchProducts());
  };

  const toggleCookieHideSold = () => {
    if (Cookies.get('hide_sold') === 'true') {
      Cookies.set('hide_sold', 'false');
    } else {
      Cookies.set('hide_sold', 'true');
    }
  };

  // sequence list, is the sequence the filters are displayed
  const availableFilters = [
    'types',
    'brands',
    'seasons',
    'conditions',
    'materials',
    'colors',
    'seller-type',
    'price-groups',
    'saleCollections',
    'discount-range'
  ].filter(name => name === 'seller-type' || filters[name].itemIds.length >= 0);

  const sizeFilters = [
    'clothing-sizes',
    'jeans-sizes',
    'shoe-sizes',
    'kids-clothing-sizes',
    'kids-shoe-sizes',
  ];

  return (
    <S.MobileFilterContainer>
      <S.Wrapper>
        <S.FilterText>{i18n.t('MFMC__filter')}</S.FilterText>
        <CloseButton handleClose={handleClose} />
      </S.Wrapper>
      <S.Container>
        <Filter
          key={'gender'}
          name={'gender'}
          searchEnabled={enableSearchInFilter('gender')}
          isMobile={true}
        />
        {sizeFilters.length > 0 && (
          <SizeFilterDropdown
            sizeFilters={sizeFilters}
            clothingSizeOpen={clothingSizeOpen} 
            setClothingSizeOpen={setClothingSizeOpen}
            isMobile={true}
          >
          </SizeFilterDropdown>
        )}
        {availableFilters.map(filterName => (
          filterName === 'types' ? (
            <TypesFilterDropdown
              key={filterName}
              name={filterName}
              isMobile={true}
            />
          ) : (
            <Filter
              key={filterName}
              name={filterName}
              searchEnabled={enableSearchInFilter(filterName)}
              searchBrandsFilter={searchBrandsFilter}
              fetchBrandsForFilter={fetchBrandsForFilter}
              isMobile={true}
            />
          )
        ))}
        <HideSortStyle.HideSoldToggle className="underline" onClick={handleApply}>
          {hideSoldText()}
        </HideSortStyle.HideSoldToggle>
        <S.ResetButtonContainer>
          <S.ResetButton onClick={handleReset}>
            {i18n.t('clear_filters')}
          </S.ResetButton>
        </S.ResetButtonContainer>
      </S.Container>
    </S.MobileFilterContainer>
  );
};

MainPage.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default MainPage;
