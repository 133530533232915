import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styled/StyledSeoSection';

const SeoSection = ({ tags, totalUploadedToday }) => {
	const i18n = useTranslation();

	return(
		<S.Container>
			<S.InfoContent>
				<S.Title>{i18n.t('seoSection.title')}</S.Title>
				<S.UploadsNumber>{totalUploadedToday}</S.UploadsNumber>
				<S.Description>{i18n.t('seoSection.info')}</S.Description>
				<S.WhatsNewButton href="/just-in">{i18n.t('seoSection.whatsNew')}</S.WhatsNewButton>
			</S.InfoContent>
			<S.SeoTags>
				{tags.map(tag => (
					<S.SeoTag key={tag.id}>
						<S.SeoTagLink href={`/items/${tag.slug}`}>{tag.name}</S.SeoTagLink>
					</S.SeoTag>
				))}
			</S.SeoTags>
		</S.Container>
	)
}

export default SeoSection;
