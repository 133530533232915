import React, { FC, useEffect, useState } from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { Provider, useSelector } from 'react-redux';

import * as S from './styled/StyledUserImpact';
import { getAuthentication } from '../User/userSlice';
import Repository from '../../services/repository';
import translateComponent from '../helpers/translateI18n';
import { setHeaders } from '../../services/helpers';
import store from '../../src/store';
import useWindowWidth from '../helpers/hooks/useWindowWidth';

import leftArrow from '@images/left.svg';
import rightArrow from '@images/right.svg';

const sliderStyle = {
  width: '100%',
};

const initialState = {
  kgOfCo2: 0,
  bathtubsOfWater: 0,
  flightsAroundTheWorld: 0,
};

const innerSlideStyle = {
  paddingBottom: '220px',
};

const buttonStyle = {
  background: 'transparent',
  border: 'none',
  position: 'absolute',
  top: '80px',
  backgroundSize: 'cover',
  height: '35px',
  width: '35px',
};

const backButtonStyle = {
  ...buttonStyle,
  left: '10px',
  background: `url(${leftArrow})`,
};

const forwardButtonStyle = {
  ...buttonStyle,
  right: '10px',
  background: `url(${rightArrow})`,
};

const UserImpact: FC<{ productIds: number[] }> = ({ productIds }) => {
  const translate = translateComponent('Impact');

  const [impact, setImpact] = useState(initialState);
  const [soldProductsCount, setSoldProductsCount] = useState(0);

  const authentication = useSelector(getAuthentication);
  const width = useWindowWidth();

  const fetchBuyerImpact = () => {
    if (!productIds.length) {
      return;
    }

    Repository.getProductsImpact({ product_ids: productIds })
      .then((res) => {
        setImpact(res.data);
      })
      .catch((err: unknown) => {
        console.error('Fetch buyer impact', err);
      });
  };

  const fetchSoldItems = () => {
    authentication &&
      Repository.getUserSoldProductsCount({ headers: setHeaders(authentication) })
        .then((res) => {
          setSoldProductsCount(res.data.count);
        })
        .catch((err) => {
          console.error('Fetch seller impact', err);
        });
  };

  useEffect(() => {
    fetchBuyerImpact();
    fetchSoldItems();
  }, [authentication, productIds]);

  const buyerItems = () => {
    return (
      <S.Wrapper>
        <S.CardTitle>{translate('designerFashion')}</S.CardTitle>
        <S.IconContainer>
          <S.ClothingImg />
        </S.IconContainer>
        <S.ImpactNumber>
          {addThousandSeparators(productIds.length || 0)}
          <S.Span>{translate('items')}</S.Span>
        </S.ImpactNumber>
        <S.Text>{translate('boughtSecondHand')}</S.Text>
      </S.Wrapper>
    );
  };

  const addThousandSeparators = (num: number): string => {
    let separator = '.';

    if (window.I18n === 'en') {
      separator = ',';
    }

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  };

  const emissions = () => {
    return (
      <S.Wrapper>
        <S.CardTitle>{translate('co2Emissions')}</S.CardTitle>
        <S.co2IconContainer>
          <S.EarthImg />
        </S.co2IconContainer>
        <S.ImpactNumber>
          {impact.kgOfCo2}
          <S.Span>Kg</S.Span>
        </S.ImpactNumber>
        <S.Text>
          {translate('co2Prevented', {
            flightsNum: impact.flightsAroundTheWorld,
          })}
        </S.Text>
      </S.Wrapper>
    );
  };

  const wasteWater = () => {
    return (
      <S.Wrapper>
        <S.CardTitle>{translate('wasteWater')}</S.CardTitle>
        <S.IconContainer>
          <S.BathTubsImg />
        </S.IconContainer>
        <S.ImpactNumber>
          {impact.bathtubsOfWater}
          <S.Span>{translate('bathtubs2')}</S.Span>
        </S.ImpactNumber>
        <S.Text>{translate('waterSaved')}</S.Text>
      </S.Wrapper>
    );
  };

  const itemsSold = () => {
    return (
      <S.Wrapper>
        <S.CardTitle>{translate('itemsSold')}</S.CardTitle>
        <S.IconContainer>
          <S.ClothingImg />
        </S.IconContainer>
        <S.ImpactNumber>
          {soldProductsCount}
          <S.Span>{translate('items')}</S.Span>
        </S.ImpactNumber>
        <S.Text>
          {translate('receiveNewOwner', {
            numItemsSold: soldProductsCount,
          })}
        </S.Text>
      </S.Wrapper>
    );
  };

  const footprint = () => {
    return (
      <S.Wrapper>
        <S.CardTitle>{translate('footprint')}</S.CardTitle>
        <S.co2IconContainer>
          <S.EarthImg />
        </S.co2IconContainer>
        <S.ImpactNumber>-73 %{/* number provided by Lieke */}</S.ImpactNumber>
        <S.Text>{translate('averageCo2')}</S.Text>
      </S.Wrapper>
    );
  };

  const buyerSection = () => {
    return width < 768 ? getSmallBuyerScreen() : getLargeBuyerScreen();
  };

  const getLargeBuyerScreen = () => {
    return (
      <>
        <S.CardHeader>{translate('youRock')}</S.CardHeader>
        <S.SectionContainer>
          <S.BorderRight>{buyerItems()}</S.BorderRight>

          <S.BorderRight>{wasteWater()}</S.BorderRight>

          {emissions()}
        </S.SectionContainer>
      </>
    );
  };

  const getSmallBuyerScreen = () => {
    return (
      <>
        <S.CardHeader>{translate('youRock')}</S.CardHeader>
        <CarouselProvider naturalSlideWidth={100} totalSlides={3} style={sliderStyle}>
          <Slider>
            <Slide style={innerSlideStyle} index={0}>
              <S.FlexCenter>{buyerItems()}</S.FlexCenter>
            </Slide>

            <Slide style={innerSlideStyle} index={1}>
              <S.FlexCenter>{wasteWater()}</S.FlexCenter>
            </Slide>

            <Slide style={innerSlideStyle} index={2}>
              <S.FlexCenter>{emissions()}</S.FlexCenter>
            </Slide>
          </Slider>
          <ButtonBack style={backButtonStyle} />
          <ButtonNext style={forwardButtonStyle} />
        </CarouselProvider>
      </>
    );
  };

  const sellerSection = () => {
    return width < 768 ? getSmallSellerScreen() : getLargeSellerScreen();
  };

  const getLargeSellerScreen = () => {
    return (
      <>
        <S.CardHeader>{translate('sellerImpact')}</S.CardHeader>
        <S.SectionContainer>
          <S.BorderRight>{itemsSold()}</S.BorderRight>
          {footprint()}
        </S.SectionContainer>
      </>
    );
  };

  const getSmallSellerScreen = () => {
    return (
      <>
        <S.CardHeader>{translate('sellerImpact')}</S.CardHeader>
        <CarouselProvider naturalSlideWidth={100} totalSlides={2} style={sliderStyle}>
          <Slider>
            <Slide style={innerSlideStyle} index={0}>
              <S.FlexCenter>{itemsSold()}</S.FlexCenter>
            </Slide>

            <Slide style={innerSlideStyle} index={1}>
              <S.FlexCenter>{footprint()}</S.FlexCenter>
            </Slide>
          </Slider>
          <ButtonBack style={backButtonStyle} />
          <ButtonNext style={forwardButtonStyle} />
        </CarouselProvider>
      </>
    );
  };

  const addLocale = (locale: string) => {
    if (locale !== 'nl') {
      return `/${locale}`;
    }
    return '';
  };

  const getUploadLink = () => {
    return 'https://thenextcloset.com/' + addLocale(window.I18n) + '/diy/new';
  };

  return (
    <>
      <S.Subtitle>{translate('profileSubtitle')}</S.Subtitle>

      <S.Container>{buyerSection()}</S.Container>
      <S.RoundButton>
        <a href="https://thenextcloset.com/page/ons-verhaal">{translate('ourMission')}</a>
      </S.RoundButton>
      <br />
      <S.Container>{sellerSection()}</S.Container>
      <S.RoundButton>
        <a href={getUploadLink()}>{translate('uploadItems')}</a>
      </S.RoundButton>
    </>
  );
};

export default (props) => (
  <Provider store={store()}>
    <UserImpact {...props} />
  </Provider>
);
