import styled from 'styled-components';

interface LinkProps {
  width: string;
  image: string;
}

export const Link = styled.a<LinkProps>`
  position: relative;
  width: ${props => (props.width ? props.width : '270px')};
  height: ${props => (props.width === '270px' ? '165px' : '150px')};
  background-image: url(${props => props.image}) !important;
  background-size: cover;
`;

export const Image = styled.img`
  width: 100%;
`;

export const TextWrapper = styled.div`
  padding: 8px 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.85);
  text-align: center;
  width: 175px;
`;

export const Text = styled.p`
  margin: 0;
`;
