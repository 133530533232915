import styled from 'styled-components';

import { Theme } from '../../../services/theme';
import screen from '../../StyledGlobal/mediaQueries';

export const LettersHeaderWrapper = styled.div`
  position: sticky;
  top: 93px;
  background: white;
  ${screen.smMax`
    top: 75px;
  `}
  ${screen.xsMax`
    right: 0px;
    bottom: 0;
    position: fixed;
    background-color: ${Theme.colors.lightGrey};
  `}
`;

export const LetterLinks = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 26px;
  margin: 0 10%;
  a {
    color: ${Theme.colors.mediumGrey};
    &:hover {
      color: black;
      cursor: pointer;
    }
    &.active {
      color: black;
    }
  }

  &::-webkit-scrollbar {
    display:none;
  }

  ${screen.smMax`
    margin: 0 4%;
  `}
  ${screen.xsMax`
    margin: 0;
    padding: 0 10px;
    height: 100%;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: scroll;
    font-size: 13px;    
  `}
`;

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
  ${screen.xsMax`
    flex-direction: column;
  `}
`;

export const ToggleLabel = styled.label`
  margin: 0 30px;
  padding-top: 3px;
  color: black;
  ${screen.xsMax`
    margin-bottom: 20px;
  `}
`;
