import styled from 'styled-components';
import { StyledField, StyledSelect, StyledFormError } from '../../StyledGlobal/StyledForm';
import { StyledSecondaryButton } from '../../StyledGlobal/StyledButton';

export const SmallField = styled(StyledField)`
  width: 98%;
  margin-top: 10px;
  display: ${props => (props.hidden ? 'none' : 'inline-block')};
`;
export const LargeField = styled(StyledField)`
  width: 99%;
  margin-top: 10px;
  display: ${props => (props.hidden ? 'none' : 'block')};
`;

export const Select = styled(StyledSelect)`
  height: 56px;
  margin-bottom: 15px;
  width: 100%;
  margin-top: 10px;
`;

export const SelectWrapper = styled.div`
  position: relative;
  margin-top: 5px;
`;

export const Label = styled.label`
  position: absolute;
  left: 7px;
  font-size: 12px;
  background: white;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.54);
`;

interface PrintedWrapperProps {
  marginTop?: boolean;
}

export const PrintedWrapper = styled.div<PrintedWrapperProps>`
  margin-top: ${props => (props.marginTop ? '30px' : '')};
`;

export const EditButton = styled.button`
  height: 25px;
  padding: 0 10px;
  text-decoration: underline;
  border: none;
  margin-top: -3px;
  background-color: transparent;
`;

export const CheckBoxWrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 35px;
`;

interface TitleProps {
  marginTop?: boolean;
}

export const Title = styled.div<TitleProps>`
  margin-top: ${props => (props.marginTop ? '30px' : '')};
  margin-bottom: 15px;
  font-weight: 800;
`;

interface ErrorProps {
  largeFont?: boolean;
  link?: boolean;
  black?: boolean;
}

export const Error = styled(StyledFormError)<ErrorProps>`
  border: none;
  width: 100%;
  display: inline-block;
  font-size: ${props => (props.largeFont ? '16px' : '12px')};
  text-decoration: ${props => (props.link ? 'underline' : 'none')};
  cursor: ${props => (props.link ? 'pointer' : 'arrow')};
  color: ${props => (props.black ? 'black' : 'var(--brand-warning)')};
  padding: 0 5px;
`;

export const SmallFieldWrapper = styled.div`
  width: 100%;
  display: ${props => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
`;

interface FlexProps {
  marginTop?: string;
}

export const Flex = styled.div<FlexProps>`
  justify-content: space-between;
  display: ${props => (props.hidden ? 'none' : 'flex')};
  margin-top: ${props => (props.marginTop ? props.marginTop : 'inherit')};
`;

export const RetrievedAddress = styled.div`
  background: var(--light-grey);
  padding: 20px;
  margin-top: 20px;
`;

interface AdditionsProps {
  error?: boolean;
}

export const Additions = styled.div<AdditionsProps>`
  border: ${props => (props.error ? ' 1px solid red' : '1px solid black')};
  margin-top: 10px;
  padding: 5px;
`;

export const Addition = styled.button.attrs({
  type: 'button',
})`
  border: none;
  background: transparent;
  text-decoration: underline;
`;

export const Button = styled(StyledSecondaryButton)`
  width: 100%;
  margin-top: 15px;
`;

export const UnderlinedButton = styled.button.attrs({
  type: 'button',
})`
  border: none;
  background: transparent;
  text-decoration: underline;
  margin-top: 5px;
`;

export const Spacer = styled.div`
  margin: 0 3px;
`;

export const ErrorMessage = styled.div`
  color: red;
`;