import styled from 'styled-components';
import { StyledSelect, StyledRadio } from '../../StyledGlobal/StyledForm';

import IdealImage from '@images/icons/payment/ideal.svg';
import SofortImage from '@images/icons/payment/sofort.svg';
import BancontactImage from '@images/icons/payment/bancontact.svg';
import MasterCardImage from '@images/icons/payment/mastercard.svg';
import MaestroImage from '@images/icons/payment/maestro.svg';
import VisaImage from '@images/icons/payment/visa.svg';

export const Label = styled.label`
  display: flex;
  p {
    margin-left: 5px;
    margin-top: 2px;
  }
`;

export const Select = styled(StyledSelect)`
  height: auto;
  padding-right: 35px;
  margin: -5px 0 0 10px;
`;

export const Radio = styled(StyledRadio)``;

export const Form = styled.form`
  margin-top: 40px;
`;

export const Ideal = styled.img.attrs({
  src: IdealImage,
})`
  height: 25px;
  margin-left: 5px;
`;

export const Sofort = styled.img.attrs({
  src: SofortImage,
})`
  height: 12px;
  margin: 7px 0 0 5px;
`;

export const Bancontact = styled.img.attrs({
  src: BancontactImage,
})`
  height: 25px;
  margin-left: 5px;
`;

export const Mastercard = styled.img.attrs({
  src: MasterCardImage,
})`
  height: 30px;
`;

export const Maestro = styled.img.attrs({
  src: MaestroImage,
})`
  height: 22px;
  margin-top: 4px;
`;

export const Visa = styled.img.attrs({
  src: VisaImage,
})`
  height: 18px;
  margin-top: 4px;
  margin-right: 4px;
`;
