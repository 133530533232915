import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RemoveFilter from './RemoveFilter';
import { reset, fetchProducts, getSelectedFilters, getExtraFilters } from '../productFilterSlice';

import * as S from './styled/styledRFC';

const RemoveFiltersContainer = () => {
  const selected = useSelector(getSelectedFilters);
  const dispatch = useDispatch();
  const i18n = useTranslation();

  const [removeAll, setRemoweAll] = useState(() => {
    return (selected && selected.length > 0) || (extraFilters && extraFilters.premiumOnly.active);
   });
  const handleReset = () => {
    dispatch(reset());
    dispatch(fetchProducts());
    setRemoweAll(false);
  };

  const extraFilters = useSelector(getExtraFilters);

  const showRemoveFilters = (
    <S.RemoveAllFilters onClick={handleReset}>
      <p>{i18n.t('RFC__reset')}</p>
      <S.CloseX />
    </S.RemoveAllFilters>
  );

  return (
    <S.Container className="col-md-7">
      <S.Grid>
        {/* Step 1: Delete all filters. Reset the store. */}
        { selected && selected.length > 0 || extraFilters && extraFilters.premiumOnly.active ? showRemoveFilters : ''}
        {/* extraFilters.premiumOnly.active */}
        {extraFilters && extraFilters.premiumOnly.active &&(
          <RemoveFilter key={'premium'} filterName={'premium'} id={'premium'} />
        )}
        {/* Step 2: For each active sub-filter we plot a remove selection */}
        {selected && selected.map(({ filterName, id }) => (
          <RemoveFilter key={`${filterName}-${id}`} filterName={filterName} id={id} />
        ))}
      </S.Grid>
    </S.Container>
  );
};

export default RemoveFiltersContainer;
