import styled from 'styled-components';
import { StyledSecondaryButton } from '../../StyledGlobal/StyledButton';

export const DoYouHaveCoupon = styled.div`
  font-size: 15px;
  opacity: 0.6;
  cursor: pointer;
  margin-bottom: 20px;
  text-decoration: underline;
`;

export const InputWrapper = styled.div`
  display: flex;
`;

export const Input = styled.input`
  border-radius: 0;
  height: 45px;
  width: 100%;
  padding-left: 5px;
  border: 1px solid;
  &:focus {
    outline: none;
  }
`;

export const Button = styled(StyledSecondaryButton)`
  margin-left: 5px;
  padding: 10px;
  &:disabled {
    color: var(--medium-grey);
    border: 2px solid var(--medium-grey);
    pointer-events: none;
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 15px;
`;
