import React, { FC, useState } from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, ImageWithZoom,Dot, DotGroup, } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useSelector } from 'react-redux';

import { ProductPageProduct } from '../../types/ProductPageTypes';
import * as S from './styled/StyledProductImages';
import leftArrow from '@images/arrow-left.svg';
import rightArrow from '@images/arrow-right.svg';
import styled, { css } from 'styled-components';
import './style/product_show.scss';
import useWindowWidth from '../helpers/hooks/useWindowWidth';

const StyledCarouselProvider = styled(CarouselProvider)`
  max-height: 600px;
  @media (max-width: 991px) {
    max-height: none;
  }
`;

const buttonStyle = css`
  position: absolute;
  top: 45% !important;
  width: 40px;
  height: 40px;
  background: transparent;
  background-size: cover;
  border: none;
  @media (max-width: 991px) {
    display: none;
  }
`;

const StyledBackButton = styled(ButtonBack).attrs({ children: <></> })`
  ${buttonStyle};
  left: 10px;
  background: url(${leftArrow});
  background-repeat: no-repeat;
`;

const StyledForwardButton = styled(ButtonNext).attrs({ children: <></> })`
  ${buttonStyle};
  right: 10px;
  background: url(${rightArrow});
  background-repeat: no-repeat;
`;

const ProductImages: FC = () => {
  // hooks
  const { large_assets, brand, name, premium, isTrustedSeller } = useSelector(
    (state: { productPage: { product: ProductPageProduct } }) => state.productPage.product
  );
  const SLIDES_LENGTH = large_assets.length;

  const [isClicked, setIsClicked] = useState(false);
  const handleZoomClick = () => {
    isClicked ? setIsClicked(false) : setIsClicked(true)
  };

  const displaySlides = (images: Array<string>) => {
    return images.map((image, index) => {
      return (
        <Slide index={index} key={index}>
          <S.ImageWrapper>
            <S.Image src={image} alt={`${brand} ${name}`} />
          </S.ImageWrapper>
        </Slide>
      );
    });
  };

  const displayZoomSlides = (images: Array<string>) => {
    return images.map((image, index) => {
      return (
        <Slide index={index} key={index}>
          <ImageWithZoom src={image}>
            <S.ImageWrapper>
              <S.Image src={image} alt={`${brand} ${name}`} />
            </S.ImageWrapper>
          </ImageWithZoom>
        </Slide>
      );
    });
  };

  const displaySmallImages = (images: Array<string>) => {
    return (
      <div className="col-xs-12 no-padding pd-thumbnails">
        {images.map((image, index) => (
          <Dot slide={index} key={index} className='col-xs-4 no-padding'>
            <S.SmallImageContainer>
              <S.SmallImage src={image} alt={`${brand} ${name}`} />
            </S.SmallImageContainer>
          </Dot>
        ))}
      </div>
    );
  };

  const width = useWindowWidth();

  return (
    <S.Sticky data-testid="productImages">
      {large_assets && (
        <StyledCarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={80}
          // isIntrinsicHeight
          totalSlides={SLIDES_LENGTH}
          infinite
        >
          {/* wrapper needed for position buttons correctly */}
          <S.SliderWrapper>
            {(isTrustedSeller || premium) && <S.PremiumLogo>{isTrustedSeller ? 'Trusted Seller' : 'Premium'}</S.PremiumLogo>}
            <Slider onClick={() => handleZoomClick()}>
              {isClicked && width > 991 ? (displayZoomSlides(large_assets)) : (displaySlides(large_assets))}
            </Slider>
            <StyledBackButton />
            <StyledForwardButton />
          </S.SliderWrapper>
          <DotGroup className="product-carousel-dots" />
          {displaySmallImages(large_assets)}
        </StyledCarouselProvider>
      )}
    </S.Sticky>
  );
};

export default ProductImages;
