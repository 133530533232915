import styled from 'styled-components';
import ReactModal from 'react-modal';
import { Theme } from '../../../services/theme';

import screen from '../../StyledGlobal/mediaQueries';

interface StyledModalProps {
  changePadding: string | null,
  maxWidth: string | null,
  changeHeight: string | null,
  changeWidth: string | null,
}

export const StyledModal = styled(ReactModal)<StyledModalProps>`
  padding: ${props => props.changePadding ?'changePadding' : '13px 35px 35px'}; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: ${props => props.maxWidth ? 'maxWidth' : '400px'};
  max-height: 100vh;
  background: white;
  overflow-y: auto;
  &:focus {
    outline: none;
  }
  ${screen.xsMax`
  padding: props.changePadding ? 'changePadding' : '13px 20px 20px';
  height: props.changeHeight ? 'changeHeight' : 'auto';
  width: props.changeWidth ? 'changeWidth' : 'auto';
  `};
`;

export const StyledText = styled.div`
  padding: 15px 0;
`;

export const StyledLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  img {
    width: 160px;
  }
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 28px;
`;

export const StyledCloseIcon = styled.img`
  height: 22px;
  position: absolute;
  right: 20px;
  top: 20px;
  &:hover {
    cursor: pointer;
  }
`;
