import React, { useState, useEffect } from 'react';
import {  useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FormError from '../global/FormError';
import MaterialTextField from '../global/MaterialTextField';

import * as S from './styled/StyledAddress';
import CountrySelect from './CountrySelect';
import { setInvoiceAddressComplete, setShippingIsInvoice } from './checkoutSlice';
import { RootState } from '../../src/store';
import { InitialAddress } from './CheckoutTypes';

const InvoiceAddress = () => {
  const addressComplete = useSelector(
    (state: RootState) => state.checkout.initialAddressData.invoice.addressComplete
  );
  const shippingIsInvoice = useSelector(
    (state: RootState) => state.checkout.initialAddressData.shippingIsInvoice
  );
  const { values } = useFormikContext<InitialAddress>();
  const { i18n } = useTranslation();
  const [showEditAddress, setShowEditAddress] = useState(!addressComplete);
  const dispatch = useDispatch();

  useEffect(() => {
    // show edit address if shipping = invoice is not checked and if addressComplete is set to false
    setShowEditAddress(!shippingIsInvoice && !addressComplete);
  });

  return (
    <div data-testid="InvoiceAddress">
      {showEditAddress ? (
        <div data-testid="inputInvoiceAddress">
          <S.Title marginTop>{i18n.t('invoiceAddress')}</S.Title>
          <S.Flex>
            <MaterialTextField
              name="invoice.firstname"
              label={i18n.t('firstname')}
              autoComplete="given-name"
            />
            <S.Spacer />
            <MaterialTextField
              label={i18n.t('lastname')}
              name="invoice.lastname"
              autoComplete="family-name"
            />
          </S.Flex>
          <S.Flex marginTop="15px">
            <MaterialTextField label={i18n.t('street')} name="invoice.street" />
            <S.Spacer />
            <MaterialTextField name="invoice.city" label={i18n.t('city')} autoComplete="city" />
          </S.Flex>
          <S.Flex marginTop="15px">
            <MaterialTextField label={i18n.t('zipcode')} name="invoice.zipcode" />
            <S.Spacer />
            <MaterialTextField label={i18n.t('housenumber')} name="invoice.housenumber" />
            <S.Spacer />
            <MaterialTextField
              label={i18n.t('housenumberAddition')}
              name="invoice.housenumberAddition"
            />
          </S.Flex>
          <CountrySelect fieldName="invoice.country" />
          <FormError name="invoice.country" testid="countryInvoiceError" />
        </div>
      ) : (
        <S.PrintedWrapper marginTop data-testid="printedInvoiceAddress">
          <S.Flex>
            <S.Title>{i18n.t('invoiceAddress')}</S.Title>
            <S.EditButton
              type="button"
              onClick={() => {
                dispatch(setShippingIsInvoice(false));
                dispatch(setInvoiceAddressComplete(false));
              }}
              data-testid="editInvoiceAddress"
            >
              {i18n.t('editAddress')}
            </S.EditButton>
          </S.Flex>
          <div>
            <p>
              {values.invoice.firstname} {values.invoice.lastname}
            </p>
            <p>
              {values.invoice.street} {values.invoice.housenumber}
              {values.invoice.housenumberAddition ? `-${values.invoice.housenumberAddition}` : ''}
            </p>
            <p>
              {values.invoice.zipcode} {values.invoice.city}
            </p>
            <p>{i18n.t(values.invoice.country)}</p>
          </div>
        </S.PrintedWrapper>
      )}
    </div>
  );
};

export default InvoiceAddress;
