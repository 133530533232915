import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 28px;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    justify-content: flex-start;
  }
`;

export const UspWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  height: 25px;
  align-items: center;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const UspImage = styled.img`
  width: 17px;
  display: flex;
  justify-content: center;
  height: 17px;
  @media (max-width: 767px) {
    width: 14px;
    height: 14px;
  }
`;

export const UspText = styled.div`
  color: rgba(0, 0, 0, 0.70);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;


export const ImageWrapper = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
`;

export const UspLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UspRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  @media (max-width: 480px) {
    margin-left: 0;
    width: 100%;
  }
`;
