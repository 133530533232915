import React from 'react';
import * as S from './styled/styledUPP';

interface ProgressBarProps {
  formProgress: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ formProgress }) => {
  const progressBarStatus = (formProgress: string) => {
    switch (formProgress) {
      case 'stepOne':
        return 20;
      case 'stepTwo':
        return 40;
      case 'stepThree':
        return 60;
      case 'stepFour':
        return 80;
      case 'stepFive':
        return 95;
      default:
        return 0;
    }
  };

  const fillerStyles = {
    height: '100%',
    width: `${progressBarStatus(formProgress)}%`,
    backgroundColor: '#CEDFCD',
    transition: 'width 1s ease-in-out',
  };

  return (
    <S.ProgressBarContainer>
      <div style={fillerStyles}></div>
    </S.ProgressBarContainer>
  );
};

export default ProgressBar;
