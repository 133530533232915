export const isHoliday = (date: Date): boolean => {
  const dayAndMonth = `${date.getDate()}-${date.getMonth() + 1}`;
  const christmas = dayAndMonth === '25-12' || dayAndMonth === '26-12';
  const newYears = dayAndMonth === '1-1';
  const kingsDay = dayAndMonth === '27-4';
  const liberationDay = dayAndMonth === '5-5';
  return christmas || newYears || kingsDay || liberationDay;
};

export default isHoliday;
