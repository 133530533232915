import styled from 'styled-components';
import screen from '../../StyledGlobal/mediaQueries';
import { Theme } from '../../../services/theme';

export const Wrapper = styled.div`
  width: fit-content;
  margin: 0 auto;
  position: relative;
  ${screen.xsMax`
  margin-bottom: 20px`}
`;

export const Icon = styled.img`
  position: absolute;
  height: 15px;
  top: 10px;
  left: 7px;
`;

export const Input = styled.input`
  background-color: ${Theme.colors.lightGrey};
  border: none;
  height: 35px;
  width: 260px;
  padding-left: 30px;
  &:focus {
    outline: 0;
  }
  @media (max-width: 500px) {
    width: 210px;
  }
`;
