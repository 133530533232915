import styled from 'styled-components';
import screen from '../../StyledGlobal/mediaQueries';

export const Container = styled.div`
  padding: 40px 60px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3%;
  ${screen.smMax`
		padding: 20px;
	`}
`;
