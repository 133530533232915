import styled from 'styled-components';

export const Wrapper = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  background: rgba(255, 255, 255, 0.85);
  text-align: center;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
`;

export const Title = styled.p`
  margin: 0;
`;

export const TextWrapper = styled.div`
  height: 70px;
  width: 100%;
  background: var(--light-grey)
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.div`
  /* multiple lines ellipsis */
  /* autoprefixer: off */
  display: -webkit-box;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 15px;
  text-align: center;
`;
