import React, { Dispatch, SetStateAction } from 'react';
import translateComponent from '../helpers/translateI18n';

import * as S from './styled/styledUPP';
import { ProductTypeObject } from './UploadProductOne';

interface Props {
  types: ProductTypeObject[];
  disabled: boolean;
  setStep: Dispatch<SetStateAction<string>>;
  rootType: string;
}

const SelectType: React.FC<Props> = ({ types, disabled, setStep, rootType }) => {
  const rootTypeObj = types.find((type) => type.name === rootType);

  const createOptionElement = (element: ProductTypeObject) => {
    //To refactor
    //Why: it's better to use id for everything but our types' state is stored locally in this component and therefore we have to share state.

    return (
      <option value={`${element.id}|${element.name}`} key={element.id}>
        {element.name}
      </option>
    );
  };

  const translate = translateComponent('UploadProduct');

  const generateSubMenu = (subTypes: ProductTypeObject[]) => {
    return subTypes.map((el) => createOptionElement(el));
  };

  const generateMenu = (types: ProductTypeObject[] = []) => {
    return types.map((el) => {
      // create optgroups for subSubTypes
      return !el.children ? (
        createOptionElement(el)
      ) : (
        <optgroup label={el.name} key={el.id}>
          {generateSubMenu(el.children)}
        </optgroup>
      );
    });
  };

  return (
    <S.SelectField
      onClick={() => setStep('typeName')}
      labelText={translate(`${rootType}TypeLabel`)}
      placeholderText={translate('type_placeholder')}
      name="typeName"
      selectOptions={generateMenu(rootTypeObj?.children)}
      disabled={disabled}
    />
  );
};

export default SelectType;
