import styled from 'styled-components';

import { Theme } from '../../../services/theme';

import houseIcon from '@images/icons/house.svg';
import locationIcon from '@images/icons/location-pin.svg';

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const Image = styled.img`
  height: 65px;
  margin-right: 10px;
`;

export const SubWrapper = styled.div`
  display: flex;
  width: 269px;
  flex-direction: column;
`;

interface ProductInfoContainerProps {
  pickup?: boolean;
}

export const ProductInfoContainer = styled.div<ProductInfoContainerProps>`
  display: inline-flex;
  justify-content: space-between;
  ${props => !props.pickup && `border-bottom: 1px solid #ecf1ed;`}
  padding-bottom: 10px;
`;

export const ToggleContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ecf1ed;
`;

export const ClickCollectWrapper = styled.div`
  background-color: ${Theme.colors.green};
  color: ${Theme.colors.white};
  border-radius: 5px 5px 0px 0px;
  width: 345px;
  height: 108px;
  left: 929px;
  top: 252px;
  display: inline-flex;
`;

export const ClickCollectBannerText = styled.div`
  padding: 20px 0 25px 20px;
`;

export const Title = styled.p`
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  padding-bottom: 9px;
`;

export const Text = styled.p`
  font-size: 13px;
  line-height: 22px;
`;

export const ClickCollectBannerImage = styled.img.attrs({ src: houseIcon, alt: 'house icon' })`
  width: 108px;
`;

export const CollectionAddress = styled.div`
  width: 345px;
  height: 55px;
  border: 1px solid #ecf1ed;
  box-sizing: border-box;
  border-radius: 0px 0px 5px 5px;
  margin-bottom: 30px;
  display: inline-flex;
  align-items: center;
  padding: 20px;
`;

export const Link = styled.a`
  display: inline-flex;
`;

export const AddressLocationPinIcon = styled.img.attrs({ src: locationIcon, alt: 'location icon' })`
  width: 12px;
  height: 16px;
`;

export const Address = styled.p`
  padding-left: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  margin: 0;
`;
