import React, { useEffect, useState } from 'react';
import { Link, scrollSpy } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import BrandLetterContainer from './BrandLetterContainer';
import SearchField from '../global/SearchField';
import { Brand } from './BrandLetterContainer';
import useWindowWidth from '../helpers/hooks/useWindowWidth';

import * as S from './styled/styledBC';

interface BrandsContainer {
  brands: { [key: string]: Brand[] };
}

const BrandsContainer: React.FC<BrandsContainer> = ({ brands }) => {
  const i18n = useTranslation();
  const width = useWindowWidth();

  const [searchTerm, setSearchTerm] = useState('');
  const [showOnlySustainable, setShowOnlySustainable] = useState(false);
  const [showOnlyPremium, setShowOnlyPremium] = useState(false);
  const [offsetWidth, setOffsetWidth] = useState(getOffSet(width));

  useEffect(() => {
    // used to hightlight the right letter in the select bar
    scrollSpy.update();
  });

  useEffect(() => {
    setOffsetWidth(getOffSet(width));
  }, [width]);

  const getLettersAndBrands = Object.keys(brands).map((key) => {
    return (
      <div key={key}>
        <BrandLetterContainer
          brandsPerLetter={brands[key]}
          letter={key}
          searchTerm={searchTerm}
          showOnlySustainable={showOnlySustainable}
          showOnlyPremium={showOnlyPremium}
        />
      </div>
    );
  });

  function getOffSet(width: number) {
    // tablet
    if (width < 992 && width > 767) {
      return -150;
    }
    // mobile
    if (width < 768) {
      return -45;
    }
    return -200;
  }

  // Q needs to be added manually if we add a brand with a q
  const letters = [
    '#',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];

  const letterLinks = letters.map((letter) => {
    return (
      <Link
        to={letter}
        duration={1000}
        smooth
        key={letter}
        activeClass="active"
        spy
        offset={offsetWidth}
      >
        {letter.toUpperCase()}
      </Link>
    );
  });

  return (
    <div className="content-container">
      <SearchField
        onChangeAction={(e: { target: { value: React.SetStateAction<string> } }) =>
          setSearchTerm(e.target.value)
        }
        placeholder={i18n.t('BC__search_brand')}
      />

      <S.ToggleWrapper>
        <input
          id="togglePremiumBrands"
          name="togglePremiumBrands"
          type="checkbox"
          className="hidden"
          checked={showOnlyPremium}
          onChange={() => setShowOnlyPremium(!showOnlyPremium)}
        />
        <S.ToggleLabel htmlFor="togglePremiumBrands">
          {i18n.t('BC__show_premium_brands')}
        </S.ToggleLabel>

        <input
          id="toggleSustainableBrands"
          name="toggleSustainableBrands"
          type="checkbox"
          className="hidden"
          checked={showOnlySustainable}
          onChange={() => setShowOnlySustainable(!showOnlySustainable)}
        />
        <S.ToggleLabel htmlFor="toggleSustainableBrands">
          {i18n.t('BC__show_sustainable_brands')}
        </S.ToggleLabel>
      </S.ToggleWrapper>

      <S.LettersHeaderWrapper>
        <S.LetterLinks>{letterLinks}</S.LetterLinks>
      </S.LettersHeaderWrapper>
      {getLettersAndBrands}
    </div>
  );
};

export default BrandsContainer;

// array positions:
// :name, :slug, :desirable, :sustainable
