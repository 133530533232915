import React, { FC } from 'react';
import { Provider, useSelector } from 'react-redux';
import { Box, Grid, GridSize, List, ListItem } from '@material-ui/core';

import store from '../../src/store';
import { getFilters, getSelectedFilters } from '../Filters/productFilterSlice';
import translateComponent from '../helpers/translateI18n';

// Types
type Link = {
  label: string;
  url: string;
};

type FilterItem = {
  id: string;
  name: string;
  children?: string[];
  parent?: string;
  selected: boolean;
  slug?: string;
  slugs?: { [key: string]: string };
  grandparent?: string;
  count: number;
  sustainable?: boolean;
}

// Component
const SeoFooter = () => {
  // const s = useSelector(s => s);
  const selectedFilters = useSelector(getSelectedFilters);
  const filters = useSelector(getFilters);
  const t = translateComponent('SeoFooter');

  const links: { brands: Link[]; types: Link[] } = { brands: [], types: [] };

  // Create links for subtypes (only if 1 type is selected)
  const selectedTypes = selectedFilters?.filter(f => f.filterName === 'types');
  const selectedBrands = selectedFilters?.filter(f => f.filterName === 'brands');

  function getDesignerCategoryLinks(ct: FilterItem, c: string) {
    if (selectedBrands?.length) {
      const brands = selectedBrands.map(({id}) => (filters.brands.items[id].slug)).join('_')
      links.types.push({ label: ct.name, url: t('urlPatterns.brand', { type: c, brand: brands }) });
    } else {
      links.types.push({ label: ct.name, url: t('urlPatterns.type', { type: c }) });
    }
  }

  function getRandomNumber() {
    var rand = Math.random() * (10000 - 1) + 1;
    return rand.toString();
   }

  if (selectedTypes?.length === 1) {
    const type = filters.types.items[selectedTypes[0].id];
    if (type.children?.length) {
      // add the children of the selected type
      type.children.forEach(c => {
        const ct = filters.types.items[c];
        getDesignerCategoryLinks(ct, c)
      });
    } else if (type.parent) {
      // If there are no children, take the siblings instead
      filters.types.items[type.parent].children?.forEach(c => {
        const ct = filters.types.items[c];
        getDesignerCategoryLinks(ct, c)
      });
    }

    // brand links
    filters.brands.itemIds.slice(0, 30).forEach(brandId => {
      const brand = filters.brands.items[brandId];
      links.brands.push({
        label: brand.name,
        url: t('urlPatterns.brand', { type: type.slug, brand: brand.slug }),
      });
    });
  } else if (selectedBrands?.length) {
    const brands = selectedBrands.map(({id}) => (filters.brands.items[id].slug)).join('_')
    filters.types.itemIds.forEach(typeId => {
      const type = filters.types.items[typeId];
      links.types.push({
        label: type.name,
        url: t('urlPatterns.brand', { type: type.slug, brand: brands }),
      });
    });
  }

  let typeColumns = 3;
  links.brands.length && typeColumns--;
  links.brands.length > links.types.length && typeColumns--;

  return (
    <Grid container spacing={2}>
      {links.types.length > 0 && (
        <Grid item xs={12} sm={(typeColumns * 4) as GridSize}>
          <Box px={2}>
            <h3>{t('more_categories')}</h3>
            <List dense style={{ columns: typeColumns }}>
              {links.types.map(({ label, url }) => (
                <ListItem key={url + getRandomNumber()} component="a" dense disableGutters href={url}>
                  {label}
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      )}

      {links.brands.length > 0 && (
        <Grid item xs={12} sm={((3 - typeColumns) * 4) as GridSize}>
          <Box px={2}>
            <h3>{t('more_brands')}</h3>
            <List dense style={{ columns: 3 - typeColumns }}>
              {links.brands.map(({ label, url }) => (
                <ListItem key={url} component="a" dense disableGutters href={url}>
                  {label}
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

const WrappedSeoFooter: FC = () => (
  <Provider store={store()}>
    <SeoFooter />
  </Provider>
);

export default WrappedSeoFooter;
