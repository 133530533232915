import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { toggle, pushPage } from '../productFilterSlice';
import MenuItem from './components/MenuItem';

const Item = ({ filterName, id }) => {
  const item = useSelector(state => state.productFilter.filters[filterName].items[id]);
  const dispatch = useDispatch();

  const handleClick = () => {
    if (item.children) {
      dispatch(toggle({ filterName, id, active: true }));
      dispatch(pushPage({ filterName, id }));
    } else {
      dispatch(toggle({ filterName, id }));
    }
  };

  let icon;
  if (item.children) {
    icon = 'glyphicon-chevron-right';
  } else if (item.selected) {
    icon = 'glyphicon-ok';
  }

  return <MenuItem handleClick={handleClick} title={item.name} count={item.count} icon={icon} />;
};

Item.propTypes = {
  filterName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default memo(Item);
