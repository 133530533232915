import React, { memo } from 'react';
import { Provider, useSelector } from 'react-redux';

import store, { RootState } from '../../src/store';

import * as S from './styled/styledCounters'

import cartIcon from './icons/nav-cart.svg';

interface NavbarCounterProps {
  link: string;
}

const NavbarCounter: React.FC<NavbarCounterProps> = memo(({ link }) => {
  const count = useSelector(
    (state: RootState) => state.header.cartCount
  );


  const showCount = (count: number) => {
    if (count === undefined) {
      return 0;
    } else if (count < 99) {
      return count;
    } else {
      return 99;
    }
  };

  return (
    <a href={link}>
      <S.CartIcon
        src={cartIcon}
        alt='Shopping bag icon'

      />
      <S.CartCounter>
        {showCount(count)}
      </S.CartCounter>
    </a>
  );
});

// HOC for redux
export default (props: NavbarCounterProps) => (
  <Provider store={store()}>
    <NavbarCounter {...props} />
  </Provider>
);
