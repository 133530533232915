import styled from 'styled-components';

import firstIcon from '../../../../assets/images/icons/first.svg';
import previousIcon from '../../../../assets/images/icons/left.svg';
import nextIcon from '../../../../assets/images/icons/right.svg';
import lastIcon from '../../../../assets/images/icons/last.svg';

const Button = styled.button`
  border: none;
  background-repeat: no-repeat;
  background-position: 0px 1px;
  background-color: transparent;
  &:focus {
    outline: none;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const FirstButton = styled(Button)`
  background-image: url(${firstIcon});
  background-size: 28px;
  width: 23px;
`;

export const PreviousButton = styled(Button)`
  background-image: url(${previousIcon});
  background-size: 18px;
  width: 22px;
  margin-left: 10px;
`;

export const NextButton = styled(Button)`
  background-image: url(${nextIcon});
  background-size: 18px;
  width: 22px;
  margin-left: 10px;
`;

export const LastButton = styled(Button)`
  background-image: url(${lastIcon});
  background-size: 28px;
  width: 23px;
`;
