import React, { useEffect } from 'react';
import axios from 'axios';
import { Provider, useDispatch, useSelector } from 'react-redux';

import { fetchFavoriteIds, getAuthentication, setAuthentication } from './userSlice';
import store from '../../src/store';

interface Props {
  userId: number | null;
  getUserPath: string;
}

const User: React.FC<Props> = ({ userId, getUserPath }) => {
  const dispatch = useDispatch();


  const userAuthentication = useSelector(getAuthentication);

  const fetchAuthentication = async (id: number) => {
    try {
      const csrf = document?.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

      // fetching authentication data from rails and storing in localStorage
      const res = await axios.post(getUserPath, id, {
        headers: { Accept: 'application/json', 'X-CSRF-TOKEN': csrf },
      });
      dispatch(setAuthentication(res.data));
      localStorage.setItem('auth', JSON.stringify(res.data));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // when a user is not logged in we remove authentication from the localStorage
    if (userId === null) {
      localStorage.removeItem('auth');
      return;
    }

    const storedAuth = JSON.parse(localStorage.getItem('auth') || '{}');

    if (storedAuth.userId) {
      //  a check to make sure the user in localStorage is the same as the user coming from Rails
      if (storedAuth.userId === userId) {
        dispatch(setAuthentication(storedAuth));
        return;
      }
    }
    fetchAuthentication(userId);
  }, []);

  useEffect(() => {
    if (userAuthentication?.uid) {
      dispatch(fetchFavoriteIds(userAuthentication));

    }
  }, [userAuthentication]);

  // this component is only used to get the authentication data for communicating with the api for Redux
  return null;
};

export default (props: Props) => (
  <Provider store={store()}>
    <User {...props} />
  </Provider>
);

