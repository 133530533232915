const screen = {
  xsMax: (...args) => {
    const styles = args;
    return `@media (max-width:767px){
        ${styles};
      }`;
  },
  smMin: (...args) => {
    const styles = args;
    return `@media (min-width:768px){
            ${styles};
          }`;
  },
  smMax: (...args) => {
    const styles = args;
    return `@media (max-width:991px){
        ${styles};
      }`;
  },
  mdMin: (...args) => {
    const styles = args;
    return `@media (min-width:992px){
            ${styles};
          }`;
  },
  mdMax: (...args) => {
    const styles = args;
    return `@media (max-width:1199px){
        ${styles};
      }`;
  },
  lgMin: (...args) => {
    const styles = args;
    return `@media (min-width:1200px){
        ${styles};
      }`;
  },
  xlMin: (...args) => {
    const styles = args;
    return `@media (min-width:1400px){
        ${styles};
      }`;
  },
};

export default screen;
