import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { getFilters } from '../productFilterSlice';
import { RootState } from '../../../src/store';
import GlobalBreadcrumb from '../../global/GlobalBreadCrumb';

type Props = {
  brandParentName?: number;
  filterName: string;
  id: string;
};

const BreadCrumb: FC<Props> = ({ filterName, id, brandParentName = null }) => {
  const locale = window.I18n;
  // get brand or categorie breadcrumb
  const breadcrumb = useSelector((state: RootState) => getFilters(state)[filterName].items[id]);

  // get optional parent breadcrumb of category
  const parentBreadcrumb = useSelector(
    (state: RootState) =>
      breadcrumb.parent && getFilters(state)[filterName].items[breadcrumb.parent]
  );

  // get optional grandparent breadcrumb of category
  const grandParentBreadcrumb = useSelector(
    (state: RootState) =>
      parentBreadcrumb &&
      parentBreadcrumb.parent &&
      getFilters(state)[filterName].items[parentBreadcrumb.parent]
  );

  // get optional category parent breadcrumb of brand
  const brandParentBreadcrumb = useSelector(
    (state: RootState) => brandParentName && getFilters(state)["types"].items[brandParentName]
  );


  // we only want the locale in the URL in language is not NL
  const addLocaleToUrl = (locale: string) => {
    return locale === 'nl' ? '' : `${locale}/`;
  };

  const brandLink = brandParentBreadcrumb
    ? `/${addLocaleToUrl(locale)}${brandParentBreadcrumb.slug}/${breadcrumb.slug}`
    : `/items/${breadcrumb.slug}`;

  const link = filterName === 'brands' ? brandLink : `/${addLocaleToUrl(locale)}${breadcrumb.slug}`;

  return (
    <>
      {/* optional grandparent breadcrumb for categories */}
      {parentBreadcrumb &&
        grandParentBreadcrumb &&
        grandParentBreadcrumb.name !== parentBreadcrumb.name && (
          <GlobalBreadcrumb
            name={grandParentBreadcrumb.name}
            link={`/${addLocaleToUrl(locale)}${grandParentBreadcrumb.slug}`}
            dataTestId="breadcrumb-type"
          />
        )}
      {/* optional parent breadcrumb for categories */}
      {parentBreadcrumb && parentBreadcrumb.name !== breadcrumb.name && (
        <GlobalBreadcrumb
          name={parentBreadcrumb.name}
          link={`/${addLocaleToUrl(locale)}${parentBreadcrumb.slug}`}
          dataTestId="breadcrumb-type"
        />
      )}
      <GlobalBreadcrumb name={breadcrumb.name} link={link} dataTestId="breadcrumb-type" />
    </>
  );
};

export default BreadCrumb;
