import React from 'react';

import * as S from './styled/styledSearchField'
import searchIcon from '@images/icons/search.svg';


interface SearchFieldProps {
  onChangeAction: Function;
  placeholder: string;
  inputStyle?: { width: string };
  containerStyle?: { width: string };
}

const SearchField: React.FC<SearchFieldProps> = ({
  onChangeAction,
  placeholder,
  inputStyle = undefined,
  containerStyle = undefined,
}) => {
  return (
    <S.Wrapper style={containerStyle}>
      <S.Icon src={searchIcon} alt="Search icon"/>
      <S.Input
        type="text"
        onChange={e => onChangeAction(e)}
        placeholder={placeholder}
        style={inputStyle}
      />
    </S.Wrapper>
  );
};

export default SearchField;
