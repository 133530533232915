import * as yup from 'yup';
import i18n from '../../src/I18n';

import translateComponent from '../helpers/translateI18n';
const translate = translateComponent('UploadProduct');
// Select the correct schema based on the step of the form
export const uploadProductSchema = (stepNumber: string, editProductPrice: number | null) => {
  switch (stepNumber) {
    case 'stepOne':
      return stepOne;
    case 'stepTwo':
      return stepTwo;
    case 'stepThree':
      return stepThree;
    case 'stepFour':
      return stepFour(editProductPrice);
    case 'stepFive':
      return stepFive;
    default:
      break;
  }
};

const stepOne = () => {
  return yup.object().shape({
    type: yup.string().required('UploadValidationTypeRequired'),
    brand: yup.string().required('UploadValidationBrandRequired').nullable(),

    condition: yup.string().required('UploadValidationConditionRequired'),

    needSize: yup.boolean(),
    needSleeveLength: yup.boolean(),
    needSeason: yup.boolean(),
    needHeelHeight: yup.boolean(),
    // // Conditional validations
    size: yup.string().when('needSize', {
      is: true,
      then: yup.string().required('UploadValidationSizeRequired'),
      otherwise: yup.string().nullable(),
    }),
    properties: yup.object().shape({
      material: yup.string().required('UploadValidationMaterialRequired'),
      heel_height: yup.string().when('needHeelHeight', {
        is: true,
        then: yup.string().required('UploadValidationHeelHeightRequired'),
      }),
      sleeve_length: yup.string().when('needSleeveLength', {
        is: true,
        then: yup.string().required('UploadValidationSleeveLengthRequired'),
      }),
      color: yup.string().required('UploadValidationColorRequired'),
      season: yup.string().when('needSeason', {
        is: true,
        then: yup.string().required('UploadValidationSeasonRequired'),
      }),
    }),
  });
};

const checkLength = (value: any) => {
  return value && Object.keys(value).length > 2;
};

const stepTwo = () => {
  return yup.object().shape({
    assets: yup
      .object()
      .typeError(translate('UploadValidationThreePhotosRequired'))
      .required('UploadValidationThreePhotosRequired')
      .test({
        message: translate('UploadValidationThreePhotosRequired'),
        test: (value) => checkLength(value),
      }),
  });
};

const stepThree = () => {
  return yup.object().shape({
    description: yup
      .string()
      .required('UploadValidationDescription')
      .min(15, 'UploadValidationDescription')
      .max(800, 'UploadValidationDescription'),
  });
};

// add i18n for pricing
const stepFour = (editProductPrice: number | null) => {
  return yup.object().shape({
    price_original: yup
      .number()
      .min(20, 'UploadValidationMinimumPrice')
      .positive(i18n.t('UploadValidationMinimumPrice'))
      .integer(i18n.t('UploadValidationInteger')),
    price: editProductPrice
      ? yup
          .number()
          .min(20, 'UploadValidationMinimumPrice')
          .required(i18n.t('UploadValidationMinimumPrice'))
          .max(100000, i18n.t('UploadValidationPriceOnEdit', { price: 100000 }))
          .required(i18n.t('UploadValidationPriceOnEdit', { price: editProductPrice })) // didnt see another way of interpolating the price
          .integer(i18n.t('UploadValidationInteger'))
      : yup.number().when('price_original', {
          is: (val) => val > 0,
          then: yup
            .number()
            .min(20, 'UploadValidationMinimumPrice')
            .required('UploadValidationMinimumPrice')
            .integer(i18n.t('UploadValidationInteger'))
            .max(yup.ref('price_original'), (obj) => `max price is ${obj.max}`),
          otherwise: yup
            .number()
            .min(20, 'UploadValidationMinimumPrice')
            .required('UploadValidationMinimumPrice')
            .integer(i18n.t('UploadValidationInteger'))
        }),
  });
};

const stepFive = () => {
  return yup.object().shape({
    country: yup.string().required('UploadValidationShippingCountryRequired'),
  });
};
