import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';

import { Theme } from '../../services/theme';
import store from '../../src/store';
import { fetchProduct } from './productPageSlice';
import { ProductPageProps, ProductPageProduct } from '../../types/ProductPageTypes';

import * as S from './styled/StyledPP';
import * as InfoStyle from './styled/StyledProductInfo';

import GlobalBreadcrumb from '../global/GlobalBreadCrumb';
import ProductInfo from './ProductInfo';
import ProductImages from './ProductImages';
import SellerInfo from './SellerInfo';
import FollowProduct from '../Follow/FollowProduct';
import { StyledBreadcrumbsWrapper } from '../global/styled/styledGlobalBreadCrumb';
import backArrow from '@images/icons/back_arrow.svg';

const ProductPage: React.FC<ProductPageProps> = ({
  id,
  sellerPath,
  askQuestionPath,
  uploadProductPath,
  productIsInCart,
  productForCart,
  userLoggedIn,
  logInPath,
  categoryPath,
  brandPath,
  ancestorCategoryPath,
  rootPath,
  optionalSecondAncestorPath,
  optionalSecondAncestorName,
  urlForBreadCrumbTypeAndBrand,
  sellerCountry,
  pdpBanner,
  chatDisabled
}) => {
  const dispatch = useDispatch();
  const product = useSelector(
    (state: { productPage: { product: ProductPageProduct } }) => state.productPage.product
  );

  useEffect(() => {
    dispatch(fetchProduct(String(id)));
  }, [dispatch, id]);

  if (!product) {
    // only return component when product is fetched
    return <div>Loading...</div>;
  }

  const showBreadcrumbs = () => {
    return (
      <StyledBreadcrumbsWrapper aria-label="breadcrumb" className='breadcrumbs'>
        <GlobalBreadcrumb link={rootPath} name="Home" firstBreadcrumb />
        <GlobalBreadcrumb name={product.main_type} link={ancestorCategoryPath} />
        {optionalSecondAncestorPath ? (
          <GlobalBreadcrumb name={optionalSecondAncestorName} link={optionalSecondAncestorPath} />
        ) : null}
        <GlobalBreadcrumb name={product.type} link={categoryPath} dataTestId="breadcrumb-type" />
        <GlobalBreadcrumb
          name={product.brand}
          link={urlForBreadCrumbTypeAndBrand}
          dataTestId="breadcrumb-brand"
        />
      </StyledBreadcrumbsWrapper>
    );
  };
  const itemColor = product.product_properties_en?.find((property) => property.name === 'kleur')?.value;
  const itemMaterial = product.product_properties_en?.find(
    (property) => property.name === 'materiaal'
  )?.value;

  const showFavoriteButton = (isSoldOrSoldInvisible: boolean, backendState: string) => {
    return isSoldOrSoldInvisible || backendState === 'preview' ? null : (
      <div className="pd-fav">
        <p>{product.favs}</p>
        <FollowProduct
          iconFor="hearticon"
          id={product.id}
          sellerType={product.premium ? 'premium' : 'diy'}
          productType={product.type_en}
          rootTypeEN={product.rootType_en}
          brand={product.brand}
          price={product.display_price.toString()}
          condition={product.condition_en}
          color={itemColor}
          material={itemMaterial}
          origPrice={product.price_original}
        />
      </div>
    );
  };

  return (
    <div className="content-container">
      <Helmet>
        <meta name="robots" content="noindex;follow" />
      </Helmet>
      {showBreadcrumbs()}
      <div className="back-button mobile" onClick={() => window.history.back()}><img src={backArrow} /><p>Back</p></div>
      <S.Wrapper className="product-show-wrapper">
        <S.ProductImagesWrapper>
          {product.sustainable && <InfoStyle.SustainableIcon />}
          <InfoStyle.FavWrapper>{showFavoriteButton(product.sold_or_sold_invisible, product.backend_state)}</InfoStyle.FavWrapper>
          <ProductImages />
        </S.ProductImagesWrapper>

        <S.ProductInfoAndSellerWrapper>
          <ProductInfo
            pdpBanner={pdpBanner}
            uploadProductPath={uploadProductPath}
            productIsInCart={productIsInCart}
            productForCart={productForCart}
            categoryPath={categoryPath}
            brandPath={brandPath}
            sellerCountry={sellerCountry}
            askQuestionPath={askQuestionPath}
            userLoggedIn={userLoggedIn}
            logInPath={logInPath}
            chatDisabled={chatDisabled}
          />
          <SellerInfo
            userId={String(product.user_id)}
            sellerPath={sellerPath}
            askQuestionPath={askQuestionPath}
            isPremium={product.premium}
            userLoggedIn={userLoggedIn}
            logInPath={logInPath}
            chatDisabled={chatDisabled}
          />
        </S.ProductInfoAndSellerWrapper>
      </S.Wrapper>
    </div>
  );
};

export { ProductPage }; // for testing, prevents double Provider

export default (props: ProductPageProps) => (
  <Provider store={store()}>
    <ThemeProvider theme={Theme}>
      <ProductPage {...props} />
    </ThemeProvider>
  </Provider>
);
