import React from 'react';

import * as S from './styled/styledUPP';
import NextButton from './NextButton';
import { UploadProductTwoProps } from './UploadProductPageTypes';
import PreviousButton from './PreviousButton';
import translateComponent from '../helpers/translateI18n';
import Uploader from '../Uploader';

const UploadProductTwo: React.FC<UploadProductTwoProps> = ({
  setFormProgress,
  setPreviewImage,
  productToEdit,
  productRootType,
  uploadingAssets,
  setUploadingAssets,
}) => {
  const translate = translateComponent('UploadProduct');

  // hooks
  return (
    <S.Container>
      <h2>{translate('howToPhotographItems')}</h2>
      <p>{translate('UploadValidationThreePhotosRequired')}</p>
      <Uploader
        setPreviewImage={setPreviewImage}
        initialPhotos={productToEdit?.assets_with_ids}
        productRootType={productRootType}
        restricted={!!productToEdit}
        uploadingAssets={uploadingAssets}
        setUploadingAssets={setUploadingAssets}
      />

      <S.ButtonContainer>
        <PreviousButton previousStep="stepOne" setFormProgress={setFormProgress} />
        <NextButton nextStep="stepThree" setFormProgress={setFormProgress} />
      </S.ButtonContainer>
    </S.Container>
  );
};

export default UploadProductTwo;
