import React, { useEffect, useCallback } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';

import { StyledSecondaryButton } from '../StyledGlobal/StyledButton';
import translateI18n from '../helpers/translateI18n';
import store from '../../src/store';
import { getSelectedFilters, getFilters } from '../Filters/productFilterSlice';
import { addFavorites, getAuthentication, getFavoriteBrandIds } from '../User/userSlice';
import { UserAuthentication } from '../../types/UserTypes';

const FollowBrand: React.FC = () => {
  const translate = translateI18n('FollowBrand');
  // Selectors
  const favoriteBrandIds = useSelector(getFavoriteBrandIds);
  const authentication = useSelector(getAuthentication);
  const selectedFilters = useSelector(getSelectedFilters);
  const filters = useSelector(getFilters);

  // Hooks
  const dispatch = useDispatch();

  const selectedBrands = selectedFilters?.filter(({ filterName }) => filterName === 'brands');
  const selectedBrand =
    selectedBrands &&
    !!selectedBrands.length &&
    filters['brands'].items[selectedBrands[0].id.toString()];
  const isFollowing = selectedBrand && favoriteBrandIds.includes(parseInt(selectedBrand.id, 10));

  const toggleFollow = useCallback(
    (id: string | number, name: string, authentication?: UserAuthentication | undefined | null) => {
      if (authentication) {
        dispatch(addFavorites({ authentication, id, type: 'brand' }));
      } else {
        sessionStorage.setItem('FollowAfterLoginOrCreate', name); // Set SessionStorage to make sure the follow is applied automatically after login
        window.location.assign('/member/login');
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (authentication && selectedBrand) {
      // Automatically follow if the name of the to following entity corresponds with the name in the SessionStorage
      if (sessionStorage.getItem('FollowAfterLoginOrCreate') === selectedBrand.name) {
        // don't unfollow if the user is already following
        if (!isFollowing) {
          toggleFollow(selectedBrand.id, selectedBrand.name, authentication);
        }
        sessionStorage.removeItem('FollowAfterLoginOrCreate');
      }
    }
  }, [authentication, isFollowing, selectedBrand, toggleFollow]);

  const showText = (name: string) => {
    return isFollowing
      ? translate('stopFollowing', { name })
      : translate('startFollowing', { name });
  };

  if (selectedBrands?.length !== 1) return null;

  return selectedBrand ? (
    <StyledSecondaryButton
      onClick={() => {
        toggleFollow(selectedBrand.id, selectedBrand.name, authentication);
      }}
    >
      {showText(selectedBrand.name)}
    </StyledSecondaryButton>
  ) : null;
};

export { FollowBrand }; // for testing to prevent double Provider

export default () => (
  <Provider store={store()}>
    <FollowBrand />
  </Provider>
);
