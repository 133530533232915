import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setHeaders } from '../../services/helpers';

import { UserAuthentication } from '../../types/UserTypes';
import Repo from '../../services/repository';
import { RootState } from '../../src/store';
import { Repository } from '@thenextcloset/shared-javascript';

import { User } from './userTypes';

export interface UserState {
  authentication: UserAuthentication | null | undefined;
  favoriteProductCount: number;
  currentUser: User | null;
  favorites: {
    brandIds: number[];
    sellerIds: number[];
    productIds: number[];
  };
}

const initialState: UserState = {
  currentUser: null,
  favoriteProductCount: 0,
  authentication: null,
  favorites: {
    sellerIds: [],
    productIds: [],
    brandIds: [],
  },
};

// Selectors
export const getFavoriteBrandIds = (state: RootState) => state.user.favorites.brandIds;
export const getCurrentUser = (state: RootState) => state.user.currentUser;
export const getFavoriteProductIds = (state: RootState) => state.user.favorites.productIds;
export const getFavoriteSellerIds = (state: RootState) => state.user.favorites.sellerIds;
export const getAuthentication = (state: RootState) => state.user.authentication;
export const getFavoriteProductCount = (state: RootState) => state.user.favoriteProductCount;

export const fetchCurrentUser = createAsyncThunk(
  'user/currentUser',
  async (authentication: UserAuthentication, { dispatch }) => {
    try {
      const response = await Repository.getUserDetails({
        headers: setHeaders(authentication),
      });
      dispatch(setCurrentUser(response.data));
    } catch (error) {
      console.error('fetchCurrentUser:', error);
    }
  }
);

export const fetchFavoriteIds = createAsyncThunk(
  'user/fetchFavoriteIds',
  async (authentication: UserAuthentication, { dispatch }) => {
    try {
      const response = await Repository.getFavoriteIds({
        headers: setHeaders(authentication),
      });
      dispatch(setFavoriteIds(response.data));
      const favProductCount = response.data.productIds.length;
      dispatch(setFavoriteProductCount(favProductCount ?? 0));
    } catch (error) {
      console.error('fetchFavoriteIds:', error);
    }
  }
);

const removeFavorites = createAsyncThunk(
  'products/removeFavorites',
  async (
    {
      id,
      authentication,
      type,
    }: {
      id: string | number | undefined;
      authentication: UserAuthentication;
      type: 'brand' | 'product' | 'seller';
    },
    { dispatch }
  ) => {
    const response = await Repo.removeFavorites(
      { id },
      { headers: setHeaders(authentication) },
      type
    );

    dispatch(fetchFavoriteIds(authentication));

    return response.data;
  }
);

export const addFavorites = createAsyncThunk(
  'products/addFavorites',
  async (
    {
      id,
      authentication,
      type,
    }: {
      id: string | number;
      authentication: UserAuthentication;
      type: 'brand' | 'product' | 'seller';
    },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const favorites = state.user.favorites[`${type}Ids`];

    if (favorites.includes(id)) {
      dispatch(removeFavorites({ id, authentication, type }));
      return;
    }
    const response = await Repo.addFavorites({ id }, { headers: setHeaders(authentication) }, type);
    dispatch(fetchFavoriteIds(authentication));

    return response.data;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthentication(state, action) {
      state.authentication = action.payload;
    },
    setFavoriteIds(state, action) {
      state.favorites = action.payload;
    },
    setFavoriteProductCount(state, action) {
      state.favoriteProductCount = action.payload;
      const element = document.getElementById('mobile-nav-follow-dot')
      if(element) {
        element.style.display = action.payload > 0 ? 'initial' : 'none'
      }
    },
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
  },
});

export const {
  setAuthentication,
  setFavoriteIds,
  setFavoriteProductCount,
  setCurrentUser,
} = userSlice.actions;

export default userSlice.reducer;
