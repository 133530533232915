import React, { FC, useEffect } from 'react';

import translateComponent from '../helpers/translateI18n';
import * as S from './styled/StyledProductInfo';
import { useSelector, useDispatch } from 'react-redux';

import { ProductPageImpact, EnvironmentalImpactProps } from '../../types/ProductPageTypes';
import { fetchImpact } from './productPageSlice';

import { showTooltipImpactCalculator } from './productPageTooltips';

const EnvironmentalImpact: FC<EnvironmentalImpactProps> = ({ productId }) => {
  const translate = translateComponent('EnvironmentalImpact');
  const impact = useSelector(
    (state: { productPage: { impact: ProductPageImpact } }) => state.productPage.impact
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchImpact(productId));
  }, [dispatch, productId]);

  const displayCo2Impact = () => {
    if (!impact) {
      return null;
    }

    if (impact.kgOfCo2 < 36) {
      return (
        <div>
          {impact.kgOfCo2} {translate('co2Emission')} = {impact.kilometersByCar}
          {translate('carRide')}
        </div>
      );
    }

    return (
      <div>
        {impact.kgOfCo2} {translate('co2Emission')} = {impact.kilometersByPlane}
        {translate('airplaneRide')}
      </div>
    );
  };

  const displayWaterImpact = () => {
    if (!impact) {
      return null;
    }

    if (impact.bathtubsOfWater === 0) {
      return (
        <>
          {impact.kgOfWater} {translate('litersWater')}
        </>
      );
    }

    return (
      <>
        {impact.kgOfWater} {translate('litersWater')} = {impact.bathtubsOfWater}
        {translate('bathtubs')}
      </>
    );
  };

  return (
    <>
      <S.DetailSubTitle>{translate('sustainableImpact')}</S.DetailSubTitle>
      <S.DetailText>{translate('givingItemSecondLife')}</S.DetailText>
      <S.ImpactCalculations>
        <S.CO2Wrapper>
          <S.CO2Icon />
          <S.ImpactText>{displayCo2Impact()}</S.ImpactText>
        </S.CO2Wrapper>
        <S.waterdropWrapper>
          <S.waterdropIcon />
          <S.ImpactText>{displayWaterImpact()}</S.ImpactText>
        </S.waterdropWrapper>
      </S.ImpactCalculations>
      <S.ImpactTooltip>{showTooltipImpactCalculator()}</S.ImpactTooltip>
    </>
  );
};

export default EnvironmentalImpact;
