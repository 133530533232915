import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useDispatch, useSelector } from 'react-redux';

import closeIcon from '@images/icons/close-x.svg';
import logo from '@images/tnc_logo_black.svg';

import * as S from './styled/styledMobileNavMenu';
import translateComponent from '../helpers/translateI18n';

import Search from './Search';

import { InnerMenuprops } from './NavigationOptions';
import SocialMediaContainer from '../Footer/SocialMediaContainer';
import { getProductProperties, ProductTypes, setShowZendeskButton } from '../Header/headerSlice';
import { GroupProps, isGroup, isItem, ItemProps } from './categories/NavigationOptions';
import Item from './categories/Item';
import Group from './categories/Group';

const InnerMenu: React.FC<InnerMenuprops> = ({
  open,
  setOpen,
  options
}) => {
  const translate = translateComponent('InnerMenu');
  const productData = useSelector(getProductProperties)?.types;
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    dispatch(setShowZendeskButton(true));
    document.body.style.overflow = 'unset'; // to make sure the content behind menu is shown again
  };

  const createDefaultUrl = (name: string, url: string) => {
    return (
      <a href={url} key={name}>
        {name}
      </a>
    );
  };

  return (
    <S.StyledMenu open={open}>
      <S.TreeContainer
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        <S.MainMenu>
          <S.HeaderContainer>
            <S.Icon src={closeIcon} onClick={handleClose} alt="Close button" />
            <S.Logo src={logo} alt="The Next Closet logo" />
          </S.HeaderContainer>
          <Search/>
          {
            options.map(initialEntry => {
              if(isItem(initialEntry)) {
                let entryOptions = initialEntry as ItemProps
                return <Item key={entryOptions.name} type="item" name={entryOptions.name} value={entryOptions.value} />
              } else if (isGroup(initialEntry)) {
                let entryOptions = initialEntry as GroupProps
                return <Group key={entryOptions.name} ancestry='root' type="group" name={entryOptions.name} values={entryOptions.values} newClass="" />
              }
            })
          }
        </S.MainMenu>
      </S.TreeContainer>
    </S.StyledMenu>
  );
};

export default InnerMenu;
