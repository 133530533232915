import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const Tooltip = styled(ReactTooltip)`
  max-width: 70vw;
  color: black !important;
  background-color: red;
  opacity: 1 !important;
`;

export const Icon = styled.img`
  margin-bottom: 2px;
  margin-left: 5px;
  width: 10px;
`;

export const Title = styled.p`
  font-weight: 400;
  text-decoration: underline;
  font-size: 14px;
`;

export const HoverLink = styled.a`
  margin-top: 2px;
  margin-left: 9px;

  font-weight: 400;
  text-decoration: underline;

  cursor: pointer;
`;

export const ToolTipBody = styled.div`
  width: auto;
  max-width: 375px;
  p {
    margin-bottom: 0;
    font-size: 14px;
  }
  ul {
    padding-left: 20px;
  }
  strong {
    font-weight: 400 !important;
  }
  li {
    font-size: 14px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

export const TitleIcon = styled.img`
  height: 22px;
  margin-right: 10px;
`;
