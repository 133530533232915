import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { gtmProductListSerializer } from '../Analytics/gtmProductSerializer';
import store from '../../src/store';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './style/slide_type_a.scss';
import SlideArrow from '../SlickSlider/SliderArrow';
import "./style/slide_arrow.scss";
import useWindowWidth from '../helpers/hooks/useWindowWidth';

const ProductCardTypeA = React.lazy(() => import('./ProductCardTypeA'));

function SlideTypeB({
  products,
}) {
  const responsive = {
    desktop: {
      breakpoint: { max: 10000, min: 992 },
      items: 4,
      slidesToSlide: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div className="carousel-button-group">
        <SlideArrow className={currentSlide === 0 ? 'disable' : 'slide-arrow slide-arrow-prev'} onClick={() => previous()} />
        <SlideArrow className="slide-arrow slide-arrow-next test" onClick={() => next()} />
      </div>
    );
  };
  const currentWidth = useWindowWidth();

  return (
    <div className="carousel-type-a">
      <Carousel responsive={responsive} arrows={false} renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />} infinite={true} showDots={true}>
        {products.map(product => (
          <div key={product.id}>
            <Suspense fallback={<div>Loading...</div>}>
              <ProductCardTypeA {...product} />
            </Suspense>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default (props) => (
  <Provider store={store()}>
    <SlideTypeB {...props} />
  </Provider>
);
