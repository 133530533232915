import React from 'react';
import PropTypes from 'prop-types';

import useModal from '../Modal/useModal';
import DiscountModal from './DiscountModal';

function DiscountModalTrigger(props) {
  const { isShowing, toggle } = useModal();
  const {
    currentPrice,
    discountAmounts,
    shippingFee,
    nameAndBrand,
    updatePricePath,
    id,
    icon,
    text,
    className,
    iconClassName,
    setPriceState,
    timeToLowerPriceState,
    setTimeToLowerPrice,
  } = props;

  return (
    <div>
      <div className={className} onClick={toggle}>
        <div>
          <img src={icon} alt={text} className={iconClassName} />
          {text}
        </div>
      </div>
      <DiscountModal
        isShowing={isShowing}
        hide={toggle}
        currentPrice={currentPrice}
        discountAmounts={discountAmounts}
        shippingFee={shippingFee}
        name={nameAndBrand}
        updatePricePath={updatePricePath}
        id={id}
        setPriceState={setPriceState}
        timeToLowerPriceState={timeToLowerPriceState}
        setTimeToLowerPrice={setTimeToLowerPrice}
      />
    </div>
  );
}

DiscountModalTrigger.propTypes = {
  currentPrice: PropTypes.number.isRequired,
  discountAmounts: PropTypes.arrayOf(PropTypes.number).isRequired,
  shippingFee: PropTypes.number.isRequired,
  nameAndBrand: PropTypes.string.isRequired,
  updatePricePath: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  setPriceState: PropTypes.func.isRequired,
  timeToLowerPriceState: PropTypes.bool,
  setTimeToLowerPrice: PropTypes.func,
};

DiscountModalTrigger.defaultProps = {
  className: null,
  iconClassName: null,
  timeToLowerPriceState: false,
  setTimeToLowerPrice: null,
};

export default DiscountModalTrigger;
