import React, { useState, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Item from './Item';

import * as S from './styled/styledF';

// Dropdown component
const TypesDropdown = ({ name, isMobile }) => {
  const i18n = useTranslation();
  const itemIds = useSelector(state => state.productFilter.filters[name].itemIds);
  const items = useSelector(state => state.productFilter.filters[name].items);

  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(true);

  const visibleItems = itemIds.filter(id => items[id].name.toLowerCase().indexOf(search.toLowerCase()) > -1);
  const active = itemIds.some(id => items[id].selected);
  const mainItemsArray = ['clothing', 'kleding', 'bags', 'tassen', 'shoes', 'schoenen', 'accessories', 'accessoires'];
  const mainItems = visibleItems.filter(id => {
    return mainItemsArray.includes(items[id].name.toLowerCase());
  });

  const subcategories = visibleItems.filter(id => {
    return !mainItemsArray.includes(items[id].name.toLowerCase());
  });

  const subcategoriesItems = [...new Set(subcategories)];

  const [subcategoryOpen, setSubcategoryOpen] = useState(false);

  const itemsKeys = Object.keys(items);
  const itemsValues = itemsKeys.map(k => items[k]);
  const selectedItems = itemsValues.filter(item => item.selected);

  const getListContainer = (itemsArray) => (
    <S.ListContainer>
      <S.List>
        {itemsArray.map(id => (
          <Item key={id} filterName={name} id={id} isMobile={isMobile} />
        ))}
      </S.List>
    </S.ListContainer>
  );

  const getSubcategoriesBtn = (
    <S.DropdownButton
      onClick={() => {
        setSubcategoryOpen(!subcategoryOpen);
      }}
      isExpanded={subcategoryOpen}
      style={{border: 'none'}}
    >
    {`+ ${i18n.t('select_sub_category')}`}
    </S.DropdownButton>
  );

  return (
    <S.Filter>
      <>
        <S.DropdownButton
          active={active}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          isExpanded={isOpen}
        >
          {i18n.t(`${name}`)}
          { !isOpen && 
            <S.SelectedItems>       
              { selectedItems.map(item => item.name).join(", ") }
            </S.SelectedItems>
          } 
        </S.DropdownButton>
        {isOpen && (
          getListContainer(mainItems)
        )}
        {isOpen && getSubcategoriesBtn}
        {isOpen && subcategoryOpen && (
          getListContainer(subcategoriesItems)
        )}
      </>
    </S.Filter>
   );
};

export default memo(TypesDropdown);
