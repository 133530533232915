import styled from 'styled-components';
import arrowDown from '@images/icons/arrow_down.svg';

export const StyledForm = styled.form`
  display: block;
  margin-top: 0;
  .input {
    margin-bottom: 16px;
  }
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  textarea {
    border: 1px solid var(--light-grey);
  }
`;

export const StyledFormError = styled.span`
  color: var(--brand-warning);
  display: block;
  margin-top: 6px;
`;

export const StyledFormErrorFacebook = styled.span`
  color: var(--brand-warning);
  display: block;
  margin-bottom: 12px;
  margin-top: -7px;
`;

interface StyledFieldProps {
  width?: string;
}

export const StyledField = styled.input<StyledFieldProps>`
  height: 45px;
  border-radius: 0px;
  border: 1px solid #ccc !important;
  padding: 6px 12px;
  width: ${props => props.width}
  &:focus {
    outline: none;
  }
`;

export const StyledSelect = styled.select`
  height: 45px;
  border-radius: 0px;
  border: 1px solid #ccc;
  padding: 6px 12px;
  background: white;
  /* remove rounded corners and add dropdown arrow again  */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: 98% 50%;
  background-repeat: no-repeat;
  background-image: url(${arrowDown});
  background-size: 16px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

export const StyledRadio = styled.input.attrs({ type: 'radio' })`
  height: 20px;
  width: 20px;
  margin-top: 2px;
`;
