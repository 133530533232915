import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Field, useFormikContext } from 'formik';

import FormError from '../global/FormError';
import * as S from './styled/styledBV';

import idExampleImage from '@images/kyc/IDcardvoorbeeld.png';
import driversLicenseExampleImage from '@images/kyc/Rijbewijsvoorbeeld.png';
import passportExampleImage from '@images/kyc/paspoortvoorbeeld.png';
import { BankVerificationFormValues } from './BankVerification';

interface BankVerificationFourProps {
  setFormProgress: Function;
}

const BankVerificationFour: React.FC<BankVerificationFourProps> = ({ setFormProgress }) => {
  const i18n = useTranslation();
  const { values, setFieldValue, isSubmitting, dirty } = useFormikContext<
    BankVerificationFormValues
  >();
  const documentTypeTranslation = i18n.t(values.typeOfId).toLowerCase();

  useEffect(() => {
    setFieldValue('birthDate', [values.day, values.month, values.year].toString());
  }, [values.day, values.month, values.year]);

  const getExampleIdImage = (typeOfId: string) => {
    switch (typeOfId) {
      case 'driversLicense':
        return driversLicenseExampleImage;
      case 'passport':
        return passportExampleImage;
      default:
        return idExampleImage;
    }
  };

  return (
    <>
      <S.Title>{i18n.t('BV__stepFourTitle')}</S.Title>
      <ReactMarkdown>{i18n.t('BV__stepFourText', { type: documentTypeTranslation })}</ReactMarkdown>
      <S.Container>
        <S.Wrapper>
          <S.FieldWrapper>
            <S.Bold>{i18n.t(`BV__firstnames${values.typeOfId}`)}</S.Bold>
            <Field
              as={S.Field}
              name="firstname"
              data-testid="firstNameInput"
              placeholder={i18n.t(`BV__firstNamePlaceholder${values.typeOfId}`)}
              autoComplete="new-password" // preventing auto-complete by using this
            />
            <FormError name="firstname" largeError />
          </S.FieldWrapper>

          <S.FieldWrapper>
            <S.Bold>{i18n.t(`BV__lastnames${values.typeOfId}`)}</S.Bold>
            <Field
              as={S.Field}
              name="lastname"
              data-testid="lastNameInput"
              placeholder={i18n.t(`BV__lastNamePlaceholder${values.typeOfId}`)}
              autoComplete="new-password" // preventing auto-complete by using this
            />
            <FormError name="lastname" largeError />
          </S.FieldWrapper>

          <S.FieldWrapper>
            <S.Bold>{i18n.t('BV__birthdate')}</S.Bold>
            <S.Date>
              <S.DateFieldWrapper>
                <Field as={S.SmallField} name="day" placeholder={i18n.t('BV__DD')} />
                <FormError name="day" />
              </S.DateFieldWrapper>
              <S.DateFieldWrapper>
                <Field as={S.SmallField} name="month" placeholder={i18n.t('BV__MM')} />
                <FormError name="month" />
              </S.DateFieldWrapper>
              <S.DateFieldWrapper>
                <Field as={S.SmallField} name="year" placeholder={i18n.t('BV__YYYY')} />
                <FormError name="year" />
              </S.DateFieldWrapper>
            </S.Date>
            <FormError name="birthDate" largeError />
          </S.FieldWrapper>

          <S.FieldWrapper>
            <S.Bold>IBAN</S.Bold>
            <Field as={S.Field} name="bankNumber" placeholder={i18n.t('BV__IbanPlaceholder')} />
            <FormError name="bankNumber" largeError />
          </S.FieldWrapper>

          <S.ButtonContainer marginTop="40px">
            <S.SecondaryButton type="button" onClick={() => setFormProgress('stepThree')}>
              {i18n.t('back')}
            </S.SecondaryButton>
            <S.PrimaryButton type="submit" disabled={isSubmitting && !dirty}>
              {i18n.t('submit')}
            </S.PrimaryButton>
          </S.ButtonContainer>
        </S.Wrapper>
        <S.Wrapper>
          <S.ExampleIdImage src={getExampleIdImage(values.typeOfId)} />
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default BankVerificationFour;
