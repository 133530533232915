import styled from 'styled-components';
import checkMark from '../icons/check-bold.svg';

export const StyledCheckMark = styled.img.attrs({
  src: checkMark,
  alt: 'In shopping bag',
})`
  margin-right: 5px;
`;

export const StyledTextWrapper = styled.div`
  padding-top: 10px;
  display: flex;
`;
