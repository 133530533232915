import styled from 'styled-components';
import arrowDown from '../../../../../assets/images/icons/dropdown_arrow.svg';

export const Container = styled.div`
  height: 55px;
  background-color: var(--white-color);
  position: relative;
  @media (max-width: 1460px) {
    margin-right: 10px !important;
  }
  & * {
    font-family: "Poppins";
  }
  .filter-header {
    height: inherit;
    display: flex;
    align-items: center;
    p {
      width: 100%;
      font-size: 18px;
      padding: 12px;
      margin-right: 30px;
      color: var(--black-65);
    }
  }
`;

export const HideSoldToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 40px;
  border-top: 1px solid var(--black-25);
  padding: 10px 12px;
  color: var(--black-65);
  font-family: "Poppins";
`;

export const Counter = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const SortProducts = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0;
  border: 1px solid #999999;
  border-radius: 5px;
  padding: 0 5px;
`;

export const SortByText = styled.div`
  color: var(--black-65);
  margin-right: 10px;
  font-weight: 800;
`;

export const SortByOptions = styled.select`
  border: none;
  background-color: transparent;
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance: none !important;
  background: url(${arrowDown}) right no-repeat transparent;
  padding-right: clamp(10px, 2.5vw, 60px);
  color: var(--black-65);
  font-size: 14px;
  width: 100%;
  &:focus-visible {
    outline: none !important;
  }
  @media (max-width: 1200px) {
    padding-right: 20px;
  }
`;
