document.addEventListener('DOMContentLoaded', () => {
  const toolTipTriggers = document.getElementsByClassName('js-toolTipClick');

  function positionToolTip() {
    // push the textbox inside of the canvas if it's too far to left on smaller screens
    const toolTipTexts = document.getElementsByClassName('js-toolTip__text');
    Array.from(toolTipTexts).forEach(t => {
      const text = t;
      // get the position of the textbox on the canvas
      const rect = text.getBoundingClientRect();
      const xPositionOnCanvas = rect.left;
      // if it < 0 it's outside of the canvas
      if (xPositionOnCanvas < 0) {
        // get the position of the textbox absolute of the trigger icon
        const positionOfText = text.style.right;
        // get the new position with 10px margin on the left
        const newPositionOfText = String(parseInt(positionOfText, 10) + (-10 + xPositionOnCanvas));
        // place textbox in right position
        text.style.right = newPositionOfText.concat('px');
      }
    });
  }

  // Show popup
  Array.from(toolTipTriggers).forEach(t => {
    const trigger = t;
    trigger.onclick = () => {
      const popupElements = [trigger.children[0], trigger.children[2]];
      popupElements.forEach(element => {
        const el = element;
        if (el.style.visibility === 'hidden' || el.style.visibility === '') {
          el.style.visibility = 'visible';
          el.style.height = 'auto';
          positionToolTip();
        } else {
          el.style.visibility = 'hidden';
          el.style.height = '1px';
        }
      });
    };
  });

  const toolTipHoverTriggers = document.getElementsByClassName('toolTipHover');
  Array.from(toolTipHoverTriggers).forEach(t => {
    t.addEventListener('mouseover', positionToolTip);
  });
});
