import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { popPage, toggle } from '../productFilterSlice';

const BreadCrumbItem = styled.button`
  background: #edf1ed;
  &:hover {
    background: #cedecc !important;
  }
`;

const Icon = styled.div.attrs({ className: 'glyphicon pull-right' })`
  line-height: 22px;
`;

const BreadCrumbList = ({ filterName, innerItemId, nestLevel }) => {
  const item = useSelector(state => state.productFilter.filters[filterName].items[innerItemId]);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(toggle({ filterName, id: innerItemId, active: false }))
    dispatch(popPage(nestLevel))
  }

  return (
    <>
      {item.parent && (
        <BreadCrumbList
          filterName={filterName}
          innerItemId={item.parent}
          nestLevel={nestLevel + 1}
        />
      )}

      <BreadCrumbItem
        className="list-group-item"
        type="button"
        onClick={handleClick}
      >
        {item.name}
        {<Icon className="glyphicon-remove" />}
      </BreadCrumbItem>
    </>
  );
};

BreadCrumbList.propTypes = {
  filterName: PropTypes.string.isRequired,
  innerItemId: PropTypes.string.isRequired,
  nestLevel: PropTypes.number,
};

BreadCrumbList.defaultProps = {
  nestLevel: 1,
};

export default BreadCrumbList;
