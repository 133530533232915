/* eslint-disable no-undef */
import React from 'react';
import { useSelector, useDispatch, Provider } from 'react-redux';
import { useTranslation } from 'react-i18next';
import store from '../../../src/store';
import {
  getExtraFilters,
  getTotalProductsCount,
  // toggleExtraFilter,
  setSort,
  fetchProducts,
  // setCurrentPage,
} from '../productFilterSlice';
import RemoveFiltersContainer from './RemoveFiltersContainer';
import * as S from './styled/styledHSPC';

const HideSortProductsContainer = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const extraFilters = useSelector(getExtraFilters);
  const totalProductsCount = useSelector(getTotalProductsCount);

  // check if the user searched so we can hide the sortby in this case
  const searchParams = new URLSearchParams(window.location.search).get('search');

  // const hideSoldText = () => {
  //   if (extraFilters.hideSold.active) {
  //     return i18n.t('HSPC__show_sold_items');
  //   }
  //   return i18n.t('HSPC__hide_sold_items');
  // };

  // const toggleCookieHideSold = () => {
  //   if (Cookies.get('hide_sold') === 'true') {
  //     Cookies.set('hide_sold', 'false');
  //   } else {
  //     Cookies.set('hide_sold', 'true');
  //   }
  // };

  // const handleApply = () => {
  //   dispatch(toggleExtraFilter('hideSold'));
  //   dispatch(setCurrentPage(1));
  //   toggleCookieHideSold();
  //   dispatch(fetchProducts());
  // };

  const handleSortBy = e => {
    dispatch(setSort({ sortOption: e.target.value }));
    dispatch(fetchProducts());
  };

  const showContainer =
    totalProductsCount > 0 ? (
      <S.Container className="row no-margin">

        <div className="col-md-3 filter-header">
          <p>{i18n.t('filter_by')}</p>
        </div>
        {/* <S.Counter>{`${totalProductsCount} items`}</S.Counter> */}
        <RemoveFiltersContainer />
        {/* Hide sort buttons on the just-in page */}
        {!extraFilters.justIn.active && !searchParams && (
          <S.SortProducts className="col-md-2">
            {/* <S.SortByText>{i18n.t('HSPC__sort')}</S.SortByText> */}
            <S.SortByOptions data-testid="sort-select" value={extraFilters.sortProducts.sortBy || ''} onChange={handleSortBy}>
              <option value="approved_at desc">{i18n.t('HSPC__sort')} {i18n.t('HSPC__new')}</option>
              <option value="price asc">{i18n.t('HSPC__sort')} {i18n.t('HSPC__price_desc')}</option>
              <option value="price desc">{i18n.t('HSPC__sort')} {i18n.t('HSPC__price_asc')}</option>
              <option value="favs desc">{i18n.t('HSPC__sort')} {i18n.t('HSPC__most_popular')}</option>
            </S.SortByOptions>
          </S.SortProducts>
        )}
      </S.Container>
    ) : (
      ''
    );

  return showContainer;
};
export default props => (
  <Provider store={store()}>
    <HideSortProductsContainer {...props} />
  </Provider>
);
