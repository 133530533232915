import React from 'react';

import Modal from '../Modal/Modal';
import ModalText from '../Modal/ModalText';
import translateComponent from '../helpers/translateI18n';

import closeIcon from '@images/icons/close-x.svg';
import * as S from './styled/styledUPP';

interface ConfirmExitModalProps {
  isShowing: boolean;
  hide: () => void;
}

const ConfirmExitModal: React.FC<ConfirmExitModalProps> = ({isShowing, hide}) => {
  const translate = translateComponent('UploadProduct');

  const ModalBody = (
    <>
      <S.Icon src={closeIcon} alt="close icon" onClick={() => hide()} />
      <div>Modal works! </div>
    </>
  );

  return <Modal isShowing={isShowing} hide={hide} body={ModalBody} />;
};

export default ConfirmExitModal;
