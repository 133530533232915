document.addEventListener('DOMContentLoaded', () => {
  // Move to top of the products index page
  const upButton = document.getElementById('js-btn-up');

  if (upButton) {
    upButton.addEventListener('click', () =>
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    );
  }

  const hideInfoTextProductIndexPage = document.getElementById('hideInfoTextIcon');
  // click the X icon in the product index pages of brands and types to hide the intro-text and set the cookie not to show them again.
  // The texts are mainly for SEO and this way they will show up in google indexation but will not be bothering users, especially on small screens
  if (hideInfoTextProductIndexPage) {
    hideInfoTextProductIndexPage.addEventListener('click', function hideTextAndSetCookie() {
      document.getElementById('bannerInfoText').classList.add('hidden');
      // Set cookie to hide it for one year
      const date = new Date();
      date.setMonth(date.getMonth() + 12);
      document.cookie = `hide_intro_text=true; expires=${date}`;
    });
  }
});
