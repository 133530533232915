import { screen, fireEvent} from '@testing-library/react';

import initialData from './initialFilterData';
import setup from  './initialFilterSetup';
import * as productFilterActions from '../../productFilterSlice';

test('Should hide sold items', () => {
  setup(initialData);

  const fetchProducts = jest
    .spyOn(productFilterActions, 'fetchProducts').mockReturnValue(() => {
    });

  global.Cookies = {
    get: jest.fn(),
    set: jest.fn()
  };

  // checks the text changes on the button and if new products are fetched
  const hideSoldButton = screen.getByText('Verberg verkochte items')
  expect(hideSoldButton).toBeTruthy()
  fireEvent.click(hideSoldButton)

  expect(screen.getByText('Toon verkochte items')).toBeTruthy()
  expect(screen.queryByText('Verberg verkochte items')).toBeFalsy()

  expect(fetchProducts).toHaveBeenCalledTimes(1);
})

test('Should sort on price desc', () => {
  setup(initialData);

  const fetchProducts = jest
    .spyOn(productFilterActions, 'fetchProducts').mockReturnValue(() => {
    });

  const sortNewInShop = screen.getByText('Nieuw in shop')
  const sortPriceLow = screen.getByText('Prijs laag')
  const sortPriceHigh = screen.getByText('Prijs hoog')

  expect(sortNewInShop).toBeTruthy()
  expect(sortPriceLow).toBeTruthy()
  expect(sortPriceHigh).toBeTruthy()

  const sortbyDropdown = screen.getByTestId('sort-select')
  fireEvent.change(sortbyDropdown, { target: { value: 'price desc' } })

  expect(fetchProducts).toHaveBeenCalledTimes(1);

})

