import styled from 'styled-components';
import switchOn from '@images/icons/switch-on.svg';
import switchOff from '@images/icons/switch-off.svg';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Switch = styled.div`
  width: 100%;
  padding: 10px 15px;

  color: #555;

  background-color: #fff;
  border: 1px solid #ddd;

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #f5f5f5;
  }
`;

interface SwitchIconProps {
  switchOn: boolean;
}

export const SwitchIcon = styled.div<SwitchIconProps>`
  background-image: ${props => (props.switchOn ? `url(${switchOn})` : `url(${switchOff})`)};
  background-repeat: no-repeat;
  background-position-y: 2px;
  background-size: contain;

  height: 22px;
  width: 40px;
`;

export const Subtitle = styled.div`
  font-size: 12px;
`;

export const PremiumText = styled.div`
  display: flex;
`;

export const Count = styled.div`
  color: var(--medium-grey);
  font-size: 12px;
  margin: 2px 0px 0px 5px;
`;
