import React from 'react';
import { render } from '@testing-library/react';


import CollectionCard from './CollectionCard';

const defaultProps = {
  buttonText: 'BEKIJK & SHOP',
  country: 'NL',
  description: 'Iconische gestreepte truien',
  imageLarge:
    'https://thenextcloset.com/system/uploads/product_collection/avatar/294/big_avatar_gestreepte_truien.jpg',
  imageMedium:
    'https://thenextcloset.com/system/uploads/product_collection/avatar/294/medium_avatar_gestreepte_truien.jpg',
  longDescription:
    'Een musthave voor een complete kledingkast is, vooral in de wintermaanden, een gestreepte trui.',
  shortDescription: 'ICONISCHE GESTREEPTE TRUIEN',
  subTitle: 'Items: 33',
  url: '/collecties/gestreepte-truien',
};

// TODO tests for image when we've replace lazyload


const setup = (props = {}) => {
  const setupProps = { ...defaultProps, ...props }; // props overwrites defaultProps by doing this
  return render(<CollectionCard {...setupProps} />);
};


test('component renders with all properties when they are present', () => {
  const { getByTestId, getByText } = setup();
  expect(getByTestId('CollectionCard')).toBeTruthy();

  // long description
  expect(getByText('Een musthave voor een complete kledingkast is, vooral in de wintermaanden, een gestreepte trui.')).toBeTruthy()

  // short description
  expect(getByText('ICONISCHE GESTREEPTE TRUIEN')).toBeTruthy()

  // subtitle
  expect(getByText('Items: 33')).toBeTruthy()

  // country
  expect(getByText('NL')).toBeTruthy()

});

test('button is present', () => {
  const { getByText } = setup();
  const button = getByText(/bekijk/i)
  expect(button).toBeTruthy()

});
