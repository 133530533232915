import React from 'react';
import { fireEvent, render, screen, waitFor } from '@testing-library/react';
import { I18nextProvider } from 'react-i18next';
import Repository from '../../../services/repository';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import i18n from '../../../test/i18nForTest';
import { NotificationsPanel } from '../NotificationsPanel';
import user from '../../User/userSlice';

const authenticationData = {
  authentication: {
    uid: 'someone@something.com',
    accessToken: 'randomn',
    client: 'client',
    userId: 123,
  },
};

const setup = (data: any) => {
  const store = configureStore({
    preloadedState: { user: data },
    reducer: { user },
  });

  return render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <NotificationsPanel />
      </I18nextProvider>
    </Provider>
  );
};

beforeEach(async () => {
  jest.spyOn(Repository, 'getUserNotifications').mockResolvedValue({
    data: {
      sale_alert_website: false,
      follow_user_alert_website: true,
      unfollow_specific_brand: true,
      follow_brand_alert_website: true,
      new_follower_website: true,
      new_product_follower_website: true,
      expires_in_1day_website: true,
      expires_today_website: true,
      expired_1week_ago_website: true,
    },
  });

  await waitFor(() => {
    setup(authenticationData);
  });
});

test('Should display the correct switches for a user`s notification settings ', () => {
  expect(
    screen.getByDisplayValue('Een van je favoriete items heeft nu korting!').checked
  ).toBeFalsy();
  expect(screen.getByDisplayValue('Je items zijn verlopen').checked).toBeTruthy();
});

test('Should send a toggle user notification request to Rails when clicking a switch and notications are fetched again on success', async () => {
  // Given
  jest.spyOn(Repository, 'updateUserNotifications');
  let saleAlert = screen.getByDisplayValue('Een van je favoriete items heeft nu korting!');
  expect(saleAlert.checked).toBeFalsy();

  // When
  await waitFor(() => {
    fireEvent.click(saleAlert);
  });

  // Then
  expect(Repository.updateUserNotifications).toHaveBeenCalledTimes(1);
  expect(Repository.updateUserNotifications.mock.calls[0][0]['sale_alert_website']).toBeTruthy();

  // getUserNotifications is called twice. Once after rendering the component and once after successfully updating a notification
  expect(Repository.getUserNotifications).toHaveBeenCalledTimes(2);
});
