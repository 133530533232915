import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Repository } from '@thenextcloset/shared-javascript';

import Repo from '../../services/repository';
import { RootState } from '../../src/store';

export type ProductTypes = { [key: string]: ProductTypes } | string[];

interface headerSlice {
  favCount: number;
  cartCount: number;
  productShowFavorite: null | object;
  lineItemForCart: null | object;
  productForPopUp: null | object;
  showZendeskButton: boolean;
  productProperties: {
    types: { [key: string]: ProductTypes } | string[];
    brands: string[];
    heel_height: string[];
    kleur: string[];
    materiaal: string[];
    seizoen: string[];
    sleeve_length: string[];
    conditions: string[];
    sizes: {
      clothing: {
        women: string[];
        men: string[];
        girls: string[];
        boys: string[];
      };
      shoes: {
        women: string[];
        men: string[];
        girls: string[];
        boys: string[];
      };
      jeans: {
        boys: string[];
        girls: string[];
        men: string[];
        women: string[];
      };
    };
  } | null;
}

// Initial State
const initialState: headerSlice = {
  favCount: 0,
  cartCount: 0,
  showZendeskButton: true,
  productShowFavorite: null,
  lineItemForCart: null,
  productForPopUp: null,
  productProperties: null,
};

// Selectors
export const getShowZendeskButton = (state: RootState) => state.header.showZendeskButton;
export const getProductProperties = (state: RootState) => state.header.productProperties;

// Create slice
const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setCartCount(state, action) {
      state.cartCount = action.payload;
    },
    lineItemForCart(state, action) {
      state.lineItemForCart = action.payload;
    },
    setProductForPopUp(state, action) {
      state.productForPopUp = action.payload;
    },
    setShowZendeskButton(state, action) {
      state.showZendeskButton = action.payload;
    },
    setProductProperties(state, action) {
      state.productProperties = action.payload;
    },
  },
});

// exports
export const {
  setCartCount,
  lineItemForCart,
  setProductForPopUp,
  setShowZendeskButton,
  setProductProperties,
} = headerSlice.actions;
export default headerSlice.reducer;

// Thunk actions

export const toggleInCart = (args: any[]) => async (dispatch) => {
  const csrf = document?.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
  const response = await axios({
    method: args[1],
    url: args[0],
    headers: { Accept: 'application/json', 'X-CSRF-TOKEN': csrf },
  });
  if (response.data.status !== undefined) {
    dispatch(lineItemForCart(response.data.line_item_id));
    dispatch(setCartCount(response.data.number_cart_items));
    dispatch(setProductForPopUp(args[2]));
  }
};

export const fetchProductProperties = createAsyncThunk(
  'header/fetchProductProperties',
  async (_, { dispatch }) => {
    try {
      const productProperties = (await Repository.getProductProperties()).data;
      dispatch(setProductProperties(productProperties));
    } catch (error) {
      console.error('fetchProductProperties error:', error);
    }
  }
);

export const fetchSustainImpact = createAsyncThunk('home/fetchSustainImpact', async () => {
  const response = await Repo.getSustainInfo();
  return { ...response.data };
});
