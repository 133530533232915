import styled from 'styled-components';
import { LargeBoldHeader } from '../../StyledGlobal/StyledFont';
import screen from '../../StyledGlobal/mediaQueries';
import { Theme } from '../../../services/theme';

import clothingImage from '@images/about_us/clothing.svg';
import earthImage from '@images/about_us/earth.svg';
import bathTubsImage from '@images/about_us/bath.svg';

export const Container = styled.div`
  background-color: ${Theme.colors.offWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 487px;
  ${screen.xsMax`
    height: 465px; 
  `}
  ${screen.smMax`padding: 40px 0`}
  ${screen.smMin`padding: 60px 0`}
`;

export const Title = styled(LargeBoldHeader)`
  margin: 0;
  line-height: 48px;
`;

interface wrapperProps {
  margin?: boolean;
}

export const Wrapper = styled.div<wrapperProps>`
  position: relative;
  width: 167px;
  height: 379px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 60px;
  ${screen.smMax`
    margin: 0 10px;
  `}
`;

export const co2Wrapper = styled(Wrapper)`
  width: 226px;
`;

export const ImpactNumber = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 37px;
  ${screen.smMax`
    margin-bottom: 10px;
  `}
`;

export const Text = styled.p`
  font-size: 14px;
  text-align: center;
  padding-bottom: 10px;
  line-height: 26px;
  margin-bottom: 0;
  width: 187px;
  line-height: 26px;
  ${screen.smMax`
    font-size: 16px;
    line-height: 28px;
  `}
`;

export const co2Text = styled(Text)`
  width: 226px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 85px;
  padding-bottom: 30px;
  ${screen.xsMax`
    height: auto;
    padding-top: 40px;
  `}
`;

export const co2IconContainer = styled(IconContainer)`
  padding-top: 60px;
  ${screen.xsMax`
    padding-top: 15px;
  `}
`;

export const BathTubsImg = styled.img.attrs({ src: bathTubsImage })`
  height: 80px;
`;

export const EarthImg = styled.img.attrs({ src: earthImage })`
  height: 105px;
`;

export const ClothingImg = styled.img.attrs({ src: clothingImage })`
  height: 80px;
`;

export const DesktopWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Span = styled.span`
  font-size: 14px;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;
