import styled from 'styled-components';

import { Theme } from '../../../services/theme';
import screen from '../../StyledGlobal/mediaQueries';
import logo from '../../../../assets/images/tnc_logo_black.svg';
import { StyledPrimaryLink } from '../../StyledGlobal/StyledButton';

export const ConfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 0 auto;
  border: 1px solid;
  padding: 50px 70px;
  background: ${Theme.colors.white};
  @media (max-width: 550px) {
    padding: 50px 35px;
  }

  ${screen.xsMax`width: auto`}
`;

export const Logo = styled.img.attrs({ src: logo, alt: 'The Next Closet logo' })``;

export const Title = styled.div`
  margin: 30px 0;
  font-family: ${Theme.fonts.header};
  font-size: 36px;
  font-weight: 900;
  ${screen.xsMax`font-size: 28px`}

`;

export const Label = styled.label`
  p {
    @media (min-width: 551px) {
      padding-top: 3px;
    }
    padding-left: 10px;
    color: ${Theme.colors.black};
  }
`;

export const Text = styled.div`
  margin: 15px 0 30px;
`;

export const Link = styled.a`
  text-decoration: underline;
  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
`;

export const PrimaryLink = styled(StyledPrimaryLink)`
  width: 350px;
  margin: 0 auto;
  @media (max-width: 550px) {
    width: 100%;
  }
`;
