import React from 'react';
import { ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';

import { Error as StyledError } from '../Checkout/styled/StyledAddress';
import { StyledFormError } from '../StyledGlobal/StyledForm';

interface FormErrorProps {
  name: string;
  testid?: string;
  largeError?: boolean;
  className?: string;
}

const FormError: React.FC<FormErrorProps> = ({
  name,
  testid = 'defaultError',
  largeError = false,
  className = '',
}) => {
  const i18n = useTranslation();

  const getErrorStyle = (msg: string) => {
    return largeError ? (
      <StyledFormError data-testid={testid} className={`scrollToError ${className}`}>
        {i18n.t(msg)}
      </StyledFormError>
    ) : (
      <StyledError data-testid={testid} className="scrollToError">
        {i18n.t(msg)}
      </StyledError>
    );
  };

  return <ErrorMessage name={name}>{msg => getErrorStyle(msg)}</ErrorMessage>;
};

export default FormError;
