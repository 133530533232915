import { screen } from '@testing-library/react';
import React from 'react'

import {
  DefaultProductPageProps,
  initialProductState,
  initialSellerState,
} from './initialProductPageData';
import setupTest from '../../../test/setupTest';
import { ProductPage } from '../ProductPage';



test('Should render 3 images when 3 images present', () => {
  setupTest(
    { productPage: {product: initialProductState, user: initialSellerState} },
    <ProductPage {...DefaultProductPageProps} />
  );

  expect(screen.getAllByAltText(/gucci jurk/i).length).toEqual(3); // 3 images present
});


