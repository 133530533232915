import { UserAuthentication } from '../types/UserTypes';


export const setHeaders = (authentication: UserAuthentication) =>{
  return {
    'access-token': authentication.accessToken,
    uid: authentication.uid,
    client: authentication.client,
  }
}

export const iOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
}
