import React from 'react';
import LazyLoad from 'react-lazyload';
import * as S from './styledCC';

import decodeHTML from '../../src/helpers/decodeHtml';
import { StyledPrimaryButton } from '../StyledGlobal/StyledButton';

interface CollectionCard {
  description: string;
  imageMedium: string;
  imageLarge: string;
  url: string;
  shortDescription: string;
  subTitle?: string;
  longDescription?: string;
  buttonText: string;
  country?: string;
}

const CollectionCard: React.FC<CollectionCard> = ({
  description,
  imageMedium,
  imageLarge,
  url,
  shortDescription,
  subTitle = null,
  longDescription = null,
  buttonText,
  country = null,
}) => {
  const getLongDescription = () => <S.Description>{decodeHTML(longDescription)}</S.Description>;

  const getCountry = () => <div>{country}</div>;

  return (
    <div data-testid="CollectionCard">
      <a href={url}>
        <S.ImageContainer>
          <LazyLoad debounce={false}>
            <S.Image
              src={imageMedium}
              srcSet={`${imageMedium} 1x, ${imageLarge} 2x`}
              alt={decodeHTML(description)}
            />
          </LazyLoad>
        </S.ImageContainer>
      </a>
      <S.TextWrapper>
        <S.Title className="t__f--sb">{decodeHTML(shortDescription)}</S.Title>
        <S.TitleWrapper>
          <S.Title>{subTitle && decodeHTML(subTitle)}</S.Title>
          {country && getCountry()}
        </S.TitleWrapper>
      </S.TextWrapper>
      {longDescription && getLongDescription()}
      <S.ButtonWrapper>
        <StyledPrimaryButton onClick={() => window.location.assign(url)} width={"100%"}>
          {buttonText}
        </StyledPrimaryButton>
      </S.ButtonWrapper>
    </div>
  );
};

export default CollectionCard;
