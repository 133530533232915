import { createTooltipWithIcon } from '../global/ToolTip';
import { ClothingSizeTable, ShoeSizeTable } from '../global/SizingTables';

const showTooltipBrand = () => {
  return createTooltipWithIcon('brandTipUploadPage', 'brandTitleTip', 'brandTextTip');
};

const showTooltipSize = (isClothing: boolean) => {
  const sizeChart = isClothing ? ClothingSizeTable() : ShoeSizeTable();
  return createTooltipWithIcon('sizeInfoTipUploadPage', undefined, undefined, sizeChart);
};

const showTooltipCondition = () => {
  return createTooltipWithIcon(
    'conditionInfoTipUploadPage',
    'toolTipconditiontitle',
    'toolTipconditiontext'
  );
};

const showTooltipColor = () => {
  return createTooltipWithIcon(
    'colorInfoTipUploadPage',
    'properties.colorTitleTip',
    'properties.colorTextTip'
  );
};

const showTooltipMaterialTip = () => {
  return createTooltipWithIcon('materialTipUploadPage', 'materialTitleTip', 'materialTextTip');
};

const showTooltipDescription = () => {
  return createTooltipWithIcon(
    'descriptionTipUploadPage',
    'descriptionTitleTip',
    'descriptionTextTip'
  );
};

const showTooltipPricing = () => {
  return createTooltipWithIcon('pricingTipUploadPage', 'priceTitleTip', 'priceTextTip');
};

const showTooltipShippingCountry = () => {
  return createTooltipWithIcon('shippingCountryTipUploadPage', 'countryTitleTip', 'countryTextTip');
};

export {
  showTooltipBrand,
  showTooltipSize,
  showTooltipCondition,
  showTooltipColor,
  showTooltipMaterialTip,
  showTooltipDescription,
  showTooltipPricing,
  showTooltipShippingCountry,
};
