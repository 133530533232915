// This methods takes the current filter-slice-state, and calculates the url that corresponds to that

import { FilterState } from './productFilterSlice';

declare global {
  interface Window {
    I18n: any;
  }
}

export const generateURL = (
  filters: FilterState['filters'],
  extraFilters: FilterState['extraFilters'],
  locale = window.I18n,
  updateLocale = false,
  omitSearchParams = false
) => {
  const pathname = window.location.pathname
  const currentPath = pathname.substring(1, pathname.length);

  const getOptionalLocaleForUrl = () => (locale === 'nl' ? null : locale);

  const getOptionsPart = () => {
    let optionsPart = null;
    if (extraFilters.justIn.active) {
      optionsPart = 'just-in';
    } else if (extraFilters.sale.active) {
      optionsPart = 'sale';
    } else if (extraFilters.seller.sellerId) {
      optionsPart = `member/${extraFilters.seller.sellerId}`;
    } else if (extraFilters.sustainableBrands.active) {
      optionsPart = locale === 'en' ? 'sustainable-brands' : 'duurzame-merken';
    } else if (extraFilters.TNCPremiumService.active) {
      optionsPart = 'premium-collection';
    }
    return optionsPart;
  };

  const getCategoryPart = () => {
    return (
      Object.entries(filters.types.items)
        .filter(([, item]) => item.selected)
        .map(([, item]) => item.slugs?.[locale] || item.slug)
        .join('_') || 'items'
    );
  };

  const getBrandPart = () => {
    return (
      Object.entries(filters.brands.items)
        .filter(([, item]) => item.selected)
        .map(([, item]) => item.slug)
        .join('_') || null
    );
  };

  const getCollectionPart = () => {
    let collection;
    const pathname = window.location.pathname
    if(pathname.includes('collections') || pathname.includes('collecties')) {
      if(pathname.includes('items')) {
        const tokens = pathname.split('/');
        collection = tokens[tokens.length - 2]
      } else {
        collection = pathname.split('/').pop();
      }
      const collectionsTranslation = locale === 'en' ? 'collections' : 'collecties';
      return collection && `${collectionsTranslation}/${collection}`;
    } else {
      const collections = filters.collections.items;
      const saleCollections = filters.saleCollections.items;
      const groupCollections = { ...collections, ...saleCollections };
      const allCollections =
        Object.entries(groupCollections)
          .filter(([, item]) => item.selected)
          .map(([, item]) => item.slug)
          .join('_') || null;
      const collectionsTranslation = locale === 'en' ? 'collections' : 'collecties';
      return allCollections && `${collectionsTranslation}/${allCollections}`;
    }
  };

  const getSearchParamsData = () => {
    let searchParamsData: { [key: string]: string } = {};

    [
      'clothing-sizes',
      'jeans-sizes',
      'shoe-sizes',
      'kids-clothing-sizes',
      'kids-shoe-sizes',
      'colors',
      'materials',
      'price-groups',
      'conditions',
      'heel-heights',
      'sleeve-lengths',
      'seasons',
      'gender',
      'discount-range'
    ].forEach(name => {
      const selected = Object.entries(filters[name].items).filter(([, item]) => item.selected);
      if (selected.length === 0) return;
      searchParamsData[name] = selected
        .map(([, item]) => item.slugs?.[locale] || item.slug || item.name || item.id)
        .join('_');
    });

    const premiumOnlyPart = extraFilters.premiumOnly.active;
    if (premiumOnlyPart === true) {
      const premiumOnly = { 'premium-only': true };
      searchParamsData = { ...searchParamsData, ...premiumOnly };
    }

    const sortProductsPart = extraFilters.sortProducts.sortBy;
    if (['approved_at desc', 'price asc', 'price desc', 'favs desc'].includes(sortProductsPart)) {
      const sortBy = { order: sortProductsPart };
      searchParamsData = { ...searchParamsData, ...sortBy };
    }

    const { currentPage } = extraFilters.pagination;
    if (currentPage !== 1) searchParamsData.page = currentPage;

    const search = new URLSearchParams(window.location.search).get('search');
    if (search) searchParamsData.search = search;

    if (updateLocale) searchParamsData.update_locale = 'true';
    return searchParamsData;
  };

  const searchParams = new URLSearchParams(getSearchParamsData());
  let url = `/${[
    getOptionalLocaleForUrl(),
    getOptionsPart(),
    getCollectionPart(),
    getCategoryPart(),
    getBrandPart(),
  ]
    .filter(i => i !== null)
    .join('/')}`;

  if (searchParams.toString() && !omitSearchParams) url += `?${searchParams.toString()}`;

  return url;
};

// Updates the 'change language' links, and also the alternate links
export const setLocaleURLS = (filters, extraFilters) => {
  ['nl', 'en', 'nl_be'].forEach(l => {
    const locale = l.replace('_', '-');
    document.querySelectorAll(`a[data-locale="${locale}"]`).forEach(link => {
      link.href = window.location.origin + generateURL(filters, extraFilters, l, true);
    });
    document.querySelectorAll(`link[rel="alternate"][hreflang="${locale}"]`).forEach(link => {
      link.href = window.location.origin + generateURL(filters, extraFilters, l);
    });
  });

  document.querySelectorAll(`link[rel="canonical"]`).forEach(link => {
    if(window.location.href.includes('/just-in')) {
      link.href = window.location.origin
    } else if(window.location.href.includes('/sale')) {
       link.href = window.location.href.replace('/sale', '');
    } else if(window.location.href.includes('/collections/tnc-favourites') || window.location.href.includes('/collecties/tnc-favourites')) {
      link.href = window.location.origin
    } else if(window.location.href.includes('/collections') || window.location.href.includes('/collecties')) {
      link.href = window.location.href.replace('/items', '');
    }
  });
};
