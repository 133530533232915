/* eslint-disable jest-dom/prefer-to-have-text-content */

import React from 'react';
import { render, screen, fireEvent, waitFor } from '@testing-library/react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { I18nextProvider } from 'react-i18next';
import { act } from 'react-dom/test-utils';

import { Checkout } from '../Checkout';
import checkout, * as checkoutSlice from '../checkoutSlice';
import i18n from '../../../test/i18nForTest';
import { initialData } from './initialCheckoutData';

const setup = (data) => {
  const store = configureStore({ preloadedState: data, reducer: { checkout } });
  return render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Checkout />
      </I18nextProvider>
    </Provider>
  );
};

describe('DiscountCouponCheck tests for valid and invalid coupons', () => {
  test('should show input field when user clicks on add a coupon text ', async () => {
    const { getByTestId, queryByTestId } = setup(initialData);
    const inputHidden = getByTestId('CouponCheckInputHidden');
    expect(queryByTestId('CouponCheckInputShown')).toBeFalsy();

    expect(inputHidden).toBeTruthy(); // Component is visible but input field is not
    await act(() => Promise.resolve(fireEvent.click(inputHidden)));
    expect(getByTestId('CouponCheckInputShown')).toBeTruthy(); // input field is visible
  });

  test('when coupon is valid show success message and the total price -= coupon amount ', async () => {
    // mock redux action
    const thunkActionValid = (dispatch) => {
      dispatch(checkoutSlice.setReturnValueCouponCheck({ message: 'coupon geldig', valid: true }));
      dispatch(checkoutSlice.setTotalDiscount(10));
    };
    const checkCouponValid = jest
      .spyOn(checkoutSlice, 'getDiscount')
      .mockReturnValue(thunkActionValid);
    const { getByTestId } = setup(initialData);
    const inputHidden = getByTestId('CouponCheckInputHidden');
    await act(() => Promise.resolve(fireEvent.click(inputHidden)));

    const input = screen.getByPlaceholderText('Coupon');
    const button = screen.getByText(/verreken/i);

    expect(screen.queryByText('coupon geldig')).toBeFalsy();
    expect(screen.getByTestId('total-price').textContent).toEqual('€167,00');

    // submit valid coupon
    await waitFor(() => fireEvent.change(input, { target: { value: 'good-coupon' } }));
    expect(input.value).toBe('good-coupon');

    await act(() => Promise.resolve(fireEvent.click(button)));

    expect(checkCouponValid).toHaveBeenCalledTimes(1);
    const couponMessage = screen.getByText('coupon geldig');
    expect(couponMessage).toBeTruthy();
    expect(couponMessage.style.color).toEqual('green');
    expect(screen.getByTestId('total-price').textContent).toEqual('€157,00');
  });

  test('when coupon is invalid show the failed message ', async () => {
    // mock redux action
    const thunkActionInvalid = (dispatch) => {
      dispatch(
        checkoutSlice.setReturnValueCouponCheck({ message: 'coupon ongeldig', valid: false })
      );
    };
    const checkCouponInvalid = jest
      .spyOn(checkoutSlice, 'getDiscount')
      .mockReturnValue(thunkActionInvalid);
    const { getByTestId } = setup(initialData);
    const inputHidden = getByTestId('CouponCheckInputHidden');
    await act(() => Promise.resolve(fireEvent.click(inputHidden)));

    const input = screen.getByPlaceholderText('Coupon');
    const button = screen.getByText(/verreken/i);

    expect(screen.queryByText('coupon geldig')).toBeFalsy();
    expect(screen.getByTestId('total-price').textContent).toEqual('€167,00');

    // subit invalid coupon
    await act(() => Promise.resolve(fireEvent.change(input, { target: { value: 'bad-coupon' } })));
    await act(() => Promise.resolve(fireEvent.click(button)));
    expect(checkCouponInvalid).toHaveBeenCalledTimes(1);

    const couponMessage = screen.getByText('coupon ongeldig');
    expect(couponMessage).toBeTruthy();
    expect(couponMessage.style.color).toEqual('red');
    expect(screen.queryByTestId('show-discount')).toBeFalsy();
    expect(screen.getByTestId('total-price').textContent).toEqual('€167,00');
  });
});
