import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled/styledSC';

const Card = props => {
  const {
    title,
    background1x,
    background2x,
    className,
    link,
    textClassName,
    text,
    targetBlank,
  } = props;

  const getText = textForCard => {
    return (
      textForCard && (
        <S.TextWrapper>
          <S.Text>{textForCard}</S.Text>
        </S.TextWrapper>
      )
    );
  };

  return (
    <S.Wrapper href={link} className={className} target={targetBlank ? '_blank' : '_self'}>
      {getText(text)}
      <img
        srcSet={`${background1x} 1x, ${background2x} 2x`}
        src={background1x}
        alt={title}
        className="slickcard__image"
      />
      <S.TitleWrapper className={textClassName}>
        <S.Title>{title}</S.Title>
      </S.TitleWrapper>
    </S.Wrapper>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  background1x: PropTypes.string.isRequired,
  background2x: PropTypes.string.isRequired,
  className: PropTypes.string,
  link: PropTypes.string,
  textClassName: PropTypes.string,
  text: PropTypes.string,
  targetBlank: PropTypes.bool,
};

Card.defaultProps = {
  className: null,
  title: null,
  link: null,
  textClassName: null,
  text: null,
  targetBlank: false,
};

export default Card;
