import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as S from './../styled/styledPOS';

import { getExtraFilters, toggleExtraFilter, fetchProducts, setCurrentPage } from '../../productFilterSlice';

const PremiumOnlySwitch = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const extraFilters = useSelector(getExtraFilters);
  const counter = extraFilters.premiumOnly.count
  const active = extraFilters.premiumOnly.active

  const handleApply = () => {
    dispatch(setCurrentPage(1));
    dispatch(toggleExtraFilter('premiumOnly'));
    dispatch(fetchProducts());
  };

  const premiumEnabled = (
    <S.Switch onClick={handleApply}>
      <S.Wrapper>
        <S.PremiumText>
          Premium items
          <S.Count>({counter})</S.Count>
        </S.PremiumText>
        <S.SwitchIcon switchOn={active}/>
      </S.Wrapper>
      <S.Subtitle>{i18n.t('POS__subtitle')}</S.Subtitle>
    </S.Switch>
  )

  return (
    counter > 0 ? premiumEnabled : ''
  );

};

export default PremiumOnlySwitch;
