import React, { useState } from 'react';
import { Formik, Field } from 'formik';
// import * as yup from 'yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import { userSchemaSignUp } from '../userSchema';

import * as S from '../styled/StyledLoginSignup';
import { 
  StyledFormError,
  StyledFormErrorFacebook,
  StyledField
} from '../../StyledGlobal/StyledForm';

import Spinner from '../../global/Spinner';

interface SignUpProps {
  termsAndConditionsPath: string;
  createUserPath: string;
  newSessionPath: string;
  facebookSignUpPath: string;
  gmailSignUpPath: string;
  appleSignUpPath: string;
  isAndroidWebView: boolean;
}

const intialState = {
  firstname: '',
  lastname: '',
  email: '',
  confirmEmail: '',
  password: '',
  terms_conditions_check: false,
  tncTimes: false,
  authenticity_token: document?.querySelector('meta[name="csrf-token"]')?.getAttribute('content'),
};

const SignUpForm: React.FC<SignUpProps> = ({
  createUserPath,
  termsAndConditionsPath,
  newSessionPath,
  facebookSignUpPath,
  gmailSignUpPath,
  appleSignUpPath,
  isAndroidWebView,
}) => {
  const user = intialState;
  const [emailError, setEmailError] = useState('');
  const [facebookDisabled, setFacebookDisabled] = useState(false);
  const { i18n } = useTranslation();

  const translate = (text: string) => {
    return i18n.t(`Auth.${text}`);
  };

  const showNotSupportedMessage = () => {
    setFacebookDisabled(true)
  }

  const renderFacebookBtn = (facebookSignUpPath) => {
    if(isAndroidWebView) {
      return(
        <S.FbButton onClick={showNotSupportedMessage}>
          <S.FbLogo />
          {translate('facebook_sign_up')}
        </S.FbButton>
      )
    } else {
      return(
        <S.FbButton href={facebookSignUpPath}>
          <S.FbLogo />
          {translate('facebook_sign_up')}
        </S.FbButton>
      )
    }
  }

  return (
    <>
      <Formik
        initialValues={user}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);

          axios
            .post(createUserPath, values, {
              headers: { Accept: 'application/json' },
            })
            .then(res => {
              if (res.data.redirectPath) {
                window.location.replace(res.data.redirectPath);
              } else if (res.data.errors.email) {
                actions.setSubmitting(false);
                res.data.errors.email.some((err: string) => {
                  if (err === "must use valid domain") {
                    setEmailError(translate('invalid_domain'));
                  }
                  if (err === "is already in use") {
                    setEmailError(translate('email_in_use'));
                  }
                  if (err === "is al in gebruik") {
                    setEmailError(translate('email_in_use'));
                  }
                })
              }
            });
        }}
        validationSchema={userSchemaSignUp}
      >
        {({ values, handleSubmit, isSubmitting, handleChange, errors, touched, dirty }) => (
          <S.Form onSubmit={handleSubmit}>
            { renderFacebookBtn(facebookSignUpPath) }
            {
              facebookDisabled && 
                <StyledFormErrorFacebook>{translate('facebook_login_not_supported')}</StyledFormErrorFacebook>
            }
            <S.GmailButton href={gmailSignUpPath}>
              <S.GmailLogo />
              {translate('gmail_sign_up')}
            </S.GmailButton>
            <S.AppleButton href={appleSignUpPath}>
              <S.AppleLogo />
              {translate('apple_sign_up')}
            </S.AppleButton>

            <S.Divider>{translate('or')}</S.Divider>
            <S.FieldWrapper>
              <Field
                name="firstname"
                onChange={handleChange}
                value={values.firstname}
                type="text"
                placeholder={i18n.t('firstname')}
                as={StyledField}
              />
              {errors.firstname && touched.firstname ? (
                <StyledFormError>{i18n.t(errors.firstname)}</StyledFormError>
              ) : (
                ''
              )}
            </S.FieldWrapper>
            <S.FieldWrapper>
              <Field
                name="lastname"
                onChange={handleChange}
                value={values.lastname}
                type="text"
                placeholder={i18n.t('lastname')}
                as={StyledField}
              />
              {errors.lastname && touched.lastname ? (
                <StyledFormError>{i18n.t(errors.lastname)}</StyledFormError>
              ) : (
                ''
              )}
            </S.FieldWrapper>
            <S.FieldWrapper>
              <Field
                type="email"
                placeholder={i18n.t('email')}
                onChange={handleChange}
                name="email"
                value={values.email}
                as={StyledField}
              />
              {errors.email && touched.email ? (
                <StyledFormError>{i18n.t(errors.email)}</StyledFormError>
              ) : (
                ''
              )}
              {emailError ? <StyledFormError>{emailError}</StyledFormError> : ''}
            </S.FieldWrapper>
            <S.FieldWrapper>
              <Field
                type="email"
                placeholder={i18n.t('email_confirm')}
                onChange={handleChange}
                name="confirmEmail"
                value={values.confirmEmail}
                as={StyledField}
              />
              {errors.confirmEmail && touched.confirmEmail ? (
                <StyledFormError>{translate('email_doesnt_match')}</StyledFormError>
              ) : (
                ''
              )}
            </S.FieldWrapper>
            <S.FieldWrapper>
              <Field
                type="password"
                onChange={handleChange}
                name="password"
                value={values.password}
                placeholder={i18n.t('password')}
                as={StyledField}
              />
              {errors.password && touched.password ? (
                <StyledFormError>{i18n.t(errors.password)}</StyledFormError>
              ) : (
                ''
              )}
            </S.FieldWrapper>
            <S.CheckboxWrapper>
              <Field type="checkbox" name="newsletter" id="newsletter" />
              <S.Label htmlFor="newsletter">
                <ReactMarkdown>{translate('newsletter')}</ReactMarkdown>
              </S.Label>
            </S.CheckboxWrapper>
            <S.CheckboxWrapper>
              <Field type="checkbox" name="terms_conditions_check" id="terms_conditions_check" />
              <S.Label htmlFor="terms_conditions_check">
                <p>
                  {translate('i_agree')}
                  <S.Link href={termsAndConditionsPath} target="_blank" rel="noopener  noreferrer">
                    {translate('terms')}
                  </S.Link>
                  {translate('of_tnc')}
                </p>
              </S.Label>
            </S.CheckboxWrapper>
            {errors.terms_conditions_check && touched.terms_conditions_check ? (
              <StyledFormError>{i18n.t('have_to_accept_terms')}</StyledFormError>
            ) : (
              ''
            )}
            <S.LoginLink>
              {translate('already_account')}
              <S.Link href={newSessionPath}>{translate('log_in')}.</S.Link>
            </S.LoginLink>
            <S.FButton type="submit" disabled={!dirty && isSubmitting}>
              {translate('submit').toUpperCase()}
            </S.FButton>
            {/* show spinner when submitting  */}
            {isSubmitting ? <Spinner /> : ''}
          </S.Form>
        )}
      </Formik>
    </>
  );
};

export default SignUpForm;
