import axios from 'axios';

$(() => {
  $(document).on('click', '.js-btn-talkjs', (btnEvent) => {
    btnEvent.preventDefault();
    const { href } = btnEvent.target.dataset;
    // Set up our HTTP request
    // Setup our listener to process completed requests
    axios.get(href, { headers: { Accept: 'application/json' } }).then(response => {
      // response coming from TalkjsService
      if (
        response.data.other_user.id !== undefined &&
        response.data.conversation.topicId !== undefined
      ) {
        // format of response.data
        //  response.data.other_user: {
        //     id, email, avatar, locale, name, photoUrl, configuration
        // }
        //  response.data.conversation: {
        //     topicId, subject, photoUrl
        // }

        // init Talkjs chat conversation
        // eslint-disable-next-line no-undef
        Talk.ready.then(() => {
          // eslint-disable-next-line no-undef
          const otherUser = new Talk.User(response.data.other_user);

          // eslint-disable-next-line no-undef
          const conversation = talkSession.getOrStartConversation(
            otherUser,
            response.data.conversation
          );
          if (window.popup == null) {
            // if there is no existing popup, create one
            window.popup = window.talkSession.createPopup(conversation);
            window.popup.mount({ show: true });
          } else {
            window.popup.show(); // in case popup is hidden
            window.popup.select(conversation); // select the conversation clicked on
          }
        });
      }
    });
  })
});
