import React from 'react';
import translateComponent from '../helpers/translateI18n';
import ReactMarkdown from 'react-markdown';
import { Field, useFormikContext } from 'formik';

import * as S from './styled/styledUPP';
import PreviousButton from './PreviousButton';
import { FormValues, UploadProductFiveProps } from './UploadProductPageTypes';
import MaterialSelectField from '../global/MaterialSelectField';
import { StyledPrimaryButton } from '../StyledGlobal/StyledButton';
import { showTooltipShippingCountry } from './ProductUploadToolTips';

const UploadProductFive: React.FC<UploadProductFiveProps> = ({ setFormProgress, previewImage }) => {
  const translate = translateComponent('UploadProduct');
  const { values } = useFormikContext<FormValues>();

  const SHIPPING_COUNTRIES = [
    <option value="NL" key="NL">
      {translate('Netherlands')}
    </option>,
    <option value="BE" key="BE">
      {translate('Belgium')}
    </option>,
  ];

  return (
    <S.Container>
      <h2>{translate('readyToGo')}</h2>
      <S.TitleAndToolTip>
        <p>{translate('sellingFrom')}</p>
        {showTooltipShippingCountry()}
      </S.TitleAndToolTip>

      <MaterialSelectField
        placeholderText={translate('sellingFromLabel')}
        name="country"
        selectOptions={SHIPPING_COUNTRIES}
      />

      <p>{translate('yourItem')}</p>
      <S.ItemPreviewContainer>
        <S.PreviewImage>
          <img src={previewImage} />
        </S.PreviewImage>
        <S.PreviewDescription>
          <S.PreviewBrandType>
            <h1>{values.brand}</h1>
            <p>{values.type}</p>
          </S.PreviewBrandType>
          <S.PreviewPrice>€{values.price_including_shipping}</S.PreviewPrice>
        </S.PreviewDescription>
      </S.ItemPreviewContainer>
      <S.Divider />
      <S.ButtonContainer>
        <PreviousButton previousStep="stepFour" setFormProgress={setFormProgress} />
        <StyledPrimaryButton width="48%" type="submit">
          {translate('sell')}
        </StyledPrimaryButton>
      </S.ButtonContainer>
      <S.Disclaimer>
        <ReactMarkdown>
          {translate('uploadAcceptTerms&Conditions', {
            url: translate('termsAndConditionsUrl'),
            text: translate('termsAndConditions'),
          })}
        </ReactMarkdown>
      </S.Disclaimer>
    </S.Container>
  );
};

export default UploadProductFive;
