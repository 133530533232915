import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function SliderArrow({ to, onClick, className, hidden }) {
  const cn = classNames(className, {
    hidden,
  });

  return <button type="button" onClick={onClick} aria-label={to} className={cn} />;
}

SliderArrow.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  hidden: PropTypes.bool,
  className: PropTypes.string,
};

SliderArrow.defaultProps = {
  hidden: false,
  className: '',
  onClick: () => null,
};

export default SliderArrow;
