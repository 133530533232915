export interface ItemProps {
    type: 'item'
    name: string,
    value: string,
    newClass?: string
}

export interface GroupProps {
    type: 'group',
    name: string,
    values: [GroupProps | ItemProps],
    ancestry: string,
    newClass: string
}

export function isItem(entry: any) {
    if(entry.type)
        return entry.type == 'item';

    return false;
}

export function isGroup(entry: any) {
    if(entry.type)
        return entry.type == 'group';

    return false;
}
