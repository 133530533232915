const formatCountries = countries => {
  return countries.map(d => {
    return {
      id: d[0],
      code: d[1],
      shipping_region_id: d[2],
      created_at: d[3],
      updated_at: d[4],
    };
  });
};

const formatProducts = products => {
  return products.map(p => {
    return {
      brand: p[0],
      type: p[1],
      image: `https://thenextcloset.com/system/uploads/asset/file/${p[2]}`,
      id: p[3],
      display_price: p[4],
    };
  });
};
// END helpers

// BEGIN data
const AVAILABLE_COUNTRIES = [
  [2, 'BE', 1, '2014-07-22T10:35:39.000+02:00', '2014-07-22T10:35:39.000+02:00'],
  [7, 'BG', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [8, 'CY', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [11, 'DK', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [3, 'DE', 2, '2014-07-22T10:35:43.000+02:00', '2014-07-22T10:35:43.000+02:00'],
  [12, 'EE', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [14, 'FI', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [15, 'FR', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [17, 'GR', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [19, 'HU', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [20, 'IE', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [21, 'IT', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [24, 'LV', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [22, 'LT', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [4, 'LU', 2, '2014-07-22T10:35:46.000+02:00', '2014-07-22T10:35:46.000+02:00'],
  [1, 'NL', 1, '2014-07-22T10:35:00.000+02:00', '2014-07-22T10:35:00.000+02:00'],
  [5, 'AT', 3, '2014-07-22T10:38:43.000+02:00', '2014-07-22T10:38:43.000+02:00'],
  [27, 'PL', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [28, 'PT', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [29, 'RO', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [31, 'SI', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [32, 'SK', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [13, 'ES', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [9, 'CZ', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [16, 'GB', 2, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
  [30, 'SE', 3, '2014-07-22T10:38:44.000+02:00', '2014-07-22T10:38:44.000+02:00'],
];

const AVAILABLE_PRODUCTS = [
  ['Ralph Lauren', 'Jas', '3071088/thumb_322553-ralph-lauren-coats-100.jpg', 322553, '55.0'],
  ['Xandres', 'Trui', '3071078/thumb_322574-xandres-sweaters-100.jpg', 322574, '26.0'],
  ['Missoni', 'Zonnebril', '44680/thumb_bril7.jpg', 5068, '86.0'],
];
// END data

const initialData = {
  checkout: {
    initialAddressData: {
      paymentMethod: '',
      creditcardType: '',
      itemIdsforPickup: [],
      shipping: {
        firstname: 'FirstNameShipping',
        lastname: 'LastNameShipping',
        street: 'StreetShipping',
        housenumber: '123',
        housenumberAddition: '4hoog',
        zipcode: '1234 AB',
        city: 'Amsterdam',
        country: 'NL',
        addressComplete: true,
        addressIsValidated: true,
      },
      invoice: {
        firstname: 'FirstNameShipping',
        lastname: 'LastNameShipping',
        street: 'StreetShipping',
        housenumber: '123',
        housenumberAddition: '4hoog',
        zipcode: '1234 AB',
        city: 'Amsterdam',
        country: 'NL',
        addressComplete: true,
      },
      shippingIsInvoice: true,
      createOrderPath: '/orders/',
    },
    calcShippingPath: '/carts/1234/shipping_cost/calc_shipping_redux',
    discount: 0,
    totalDiscount: 0,
    totalAmount: '167.0',
    shippingCosts: '0.0',
    couponPath: '/carts/1234/coupon/check',
    products: formatProducts(AVAILABLE_PRODUCTS),
    countries: formatCountries(AVAILABLE_COUNTRIES),
    returnValueCouponCheck: null,
    termsPath: '/algemene-voorwaarden',
    sellerCountry: ['BE', 'NL'],
  },
};

const initialDataEmptyShippingAddress = {
  checkout: {
    initialAddressData: {
      paymentMethod: '',
      creditcardType: '',
      itemIdsforPickup: [],
      shipping: {
        firstname: '',
        lastname: '',
        street: '',
        housenumber: '',
        housenumberAddition: '',
        zipcode: '',
        city: '',
        country: '',
        addressComplete: false,
      },
      invoice: {
        firstname: '',
        lastname: '',
        street: '',
        housenumber: '',
        housenumberAddition: '',
        zipcode: '',
        city: '',
        country: '',
        addressComplete: false,
      },
      shippingIsInvoice: false,
      createOrderPath: '/orders/',
    },
    calcShippingPath: '/carts/1234/shipping_cost/calc_shipping_redux',
    discount: 0,
    totalDiscount: 0,
    totalAmount: '167.0',
    shippingCosts: '0.0',
    couponPath: '/carts/1234/coupon/check',
    products: formatProducts(AVAILABLE_PRODUCTS),
    countries: formatCountries(AVAILABLE_COUNTRIES),
    returnValueCouponCheck: null,
    termsPath: '/algemene-voorwaarden',
    sellerCountry: ['BE', 'NL'],
  },
};

const initialDataEmptyInvoiceAddress = {
  checkout: {
    initialAddressData: {
      paymentMethod: '',
      creditcardType: '',
      itemIdsforPickup: [],
      shipping: {
        firstname: 'FirstNameShipping',
        lastname: 'LastNameShipping',
        street: 'StreetShipping',
        housenumber: '123',
        housenumberAddition: '',
        zipcode: '1234 AB',
        city: 'Amsterdam',
        country: 'NL',
        addressComplete: true,
      },
      invoice: {
        firstname: '',
        lastname: '',
        street: '',
        housenumber: '',
        housenumberAddition: '',
        zipcode: '',
        city: '',
        country: '',
        addressComplete: false,
      },
      shippingIsInvoice: false,
      createOrderPath: '/orders/',
    },
    calcShippingPath: '/carts/1234/shipping_cost/calc_shipping_redux',
    discount: 0,
    totalDiscount: 0,
    totalAmount: '167.0',
    shippingCosts: '0.0',
    couponPath: '/carts/1234/coupon/check',
    products: formatProducts(AVAILABLE_PRODUCTS),
    countries: formatCountries(AVAILABLE_COUNTRIES),
    returnValueCouponCheck: null,
    termsPath: '/algemene-voorwaarden',
    sellerCountry: ['BE', 'NL'],
  },
};

export { initialData, initialDataEmptyShippingAddress, initialDataEmptyInvoiceAddress };
