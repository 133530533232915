import React from "react";

import { ItemProps } from "./NavigationOptions";

const Item: React.FC<ItemProps> = ({
    name,
    value,
    newClass
}) => {
    return (
        <a href={value} className={newClass}>{name}</a>
    )
}

export default Item;