import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addFavorites, getAuthentication, getFavoriteProductIds } from '../User/userSlice';
import { UserAuthentication } from '../../types/UserTypes';

import iconNoFav from '@images/icons/favorite.svg';
import iconFav from '@images/icons/favorite-green.svg';
import heartFilledIcon from '@images/icons/heart_light-filled.svg';
import heartOutlineIcon from '@images/icons/heart_light.svg';
import { StyledFollowProductButton } from './styled/StyledFollow';

interface Props {
  iconFor: string;
  id: number;
  sellerType: string;
  productType: string;
  rootTypeEN?: string;
  brand: string;
  price: string;
  color: string;
  material: string;
  condition: string;
  sold?: boolean;
  origPrice: number;
}

const FollowProduct: React.FC<Props> = ({
  iconFor,
  id,
  sellerType,
  productType,
  brand,
  price,
  origPrice,
  color,
  material,
  condition,
  sold,
  rootTypeEN,
}) => {
  // Selectors
  const authentication = useSelector(getAuthentication);
  const favoriteProductIds = useSelector(getFavoriteProductIds);
  const isFollowing = favoriteProductIds.includes(id);

  // Hooks
  const dispatch = useDispatch();

  const toggleFollow = useCallback(
    (id: string | number, authentication?: UserAuthentication | undefined | null) => {
      if (authentication) {
        dispatch(addFavorites({ authentication, id, type: 'product' }));
      } else {
        sessionStorage.setItem('FollowAfterLoginOrCreate', String(id)); // Set SessionStorage to make sure the follow is applied automatically after login
        window.location.assign('/member/login');
      }
    },
    [brand, dispatch, isFollowing, productType, sellerType]
  );

  useEffect(() => {
    if (authentication) {
      // Automatically follow if the name of the to following entity corresponds with the name in the LocalStorage
      if (sessionStorage.getItem('FollowAfterLoginOrCreate') === String(id)) {
        // don't unfollow if the user is already following
        if (!isFollowing) {
          toggleFollow(id, authentication);
        }
        sessionStorage.removeItem('FollowAfterLoginOrCreate');
      }
    }
  }, [authentication, isFollowing, toggleFollow, id]);

  const ProductCardIcon = (
    <img src={isFollowing ? heartFilledIcon : heartOutlineIcon} data-testid="fav-icon" />
  );

  const sendAddToWishlistToGTM = (following) => {
    if(!following) {
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

      window.dataLayer.push({
        event: 'add_to_wishlist',
        ecommerce: {
          currency: 'EUR',
          value: origPrice,
          items: [
            {
              item_name: id + ' ' + brand + ' ' + productType,
              item_id: id,
              price: origPrice,
              discount: origPrice - price,
              item_category: rootTypeEN,
              item_category2: productType,
              item_category3: brand,
              item_variant: color,
              quantity: 1,
              item_seller_type: sellerType,
              item_material: material,
              item_condition: condition,
            },
          ],
        },
      });
    }
  };

  const ProductDetailPageIcon = <img src={isFollowing ? iconFav : iconNoFav} alt="Favorite icon" />;

  return (
    <StyledFollowProductButton
      onClick={(event) => {
        event.preventDefault();
        toggleFollow(id, authentication);
        sendAddToWishlistToGTM(isFollowing);
      }}
      style={{visibility: sold ? 'hidden' : 'visible'}}
    >
      {iconFor === 'ProductDetailPage' ? ProductDetailPageIcon : ProductCardIcon}
    </StyledFollowProductButton>
  );
};

export default FollowProduct;
