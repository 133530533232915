import styled from 'styled-components';

import screen from '../../StyledGlobal/mediaQueries';

import { StyledTransparentToBlackBtn, StyledDisabledButton } from '../../StyledGlobal/StyledButton';
import sustainableIcon from '@images/icons/sustainable.svg';
import co2Icon from '@images/icons/CO2.svg';
import waterIcon from '@images/icons/waterdrop.svg';
import { TreeView, TreeItem } from '@material-ui/lab';
import arrowDownIcon from '@images/icons/arrow_down.svg';

export const Wrapper = styled.div`
  background: #f9f8f4;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 25px;
  position: relative;
  ${screen.smMax`
    padding-left: 10px;
    padding-right: 10px;
    .back-button {
      display: none;
    }
  `};
`;

export const NameBrand = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
`;

export const Name = styled.a`
  color: rgba(0, 0, 0, 0.7);
  font-family: Poppins;
  font-size: 14px;
  line-height: 16px;
  &:hover {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);
  }
`;

export const Brand = styled.a`
  color: #000;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  &:hover {
    text-decoration: none;
  }
  ${screen.smMax`
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
  `};
  ${screen.xsMax`
    font-size: 25px;
  `};
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  ${screen.smMax`
    margin-top: 25px;
  `};
`;

export const CurrentPrice = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  ${screen.xsMax`
    font-size: 22px;
  `};
`;

export const OriginalPrice = styled.div`
  color: rgba(0, 0, 0, 0.31);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: line-through;
  margin-top: 6px;
  margin-left: 40px;
  ${screen.smMax`
    font-size: 16px;
    line-height: 16px;
  `};
`;

export const Flex = styled.div`
  display: flex;
`;

export const PriceText = styled.p`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.textBold};
`;

export const SizeCondition = styled.div`
  margin-top: 40px;
  ${screen.xsMax`
    margin-top: 15px;
    font-size: 14px;
  `}
`;

export const Size = styled.p`
  color: rgba(0, 0, 0, 0.7);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0px;
  margin: 0px;
`;

export const Condition = styled.p`
  color: rgba(0, 0, 0, 0.7);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0px;
  margin: 0px;
`;

export const Material = styled.p`
  color: rgba(0, 0, 0, 0.7);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0px;
  margin: 0px;
  margin-bottom: 60px;
`;

export const ButtonAndFavWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${screen.smMin`
    .pd-chat-btn {
      width: auto;
      padding: 5px 50px;
    }
  `}
  ${screen.xsMax`
    flex-direction: column;
    justify-content: space-between;
  `}
`;

export const FavWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  .pd-fav {
    display: flex;
    align-items: flex-end;
    img {
      width: 28px;
      height: 28px;
    }
    p {
      font-size: 14px;
      margin-bottom: -5px;
    }
  }
  ${screen.smMax`
    right: 5px;
    top: 5px;
    .pd-fav {
      img {
        width: 20px;
        height: 20px;
      }
      p {
        font-size: 10px;
        margin-bottom: -2px;
      }
    }
  `};
`;

export const Interactions = styled.div`
  display: flex;
  position: relative;
  border-top: 1.5px solid ${(props) => props.theme.colors.mediumGrey};
  border-bottom: 1.5px solid ${(props) => props.theme.colors.mediumGrey};
  margin-top: 10px;
  padding-top: 5px;
  padding: 6px 0;
  img {
    margin-right: 8px;
  }
`;

export const Views = styled.div`
  margin-right: 30px;
`;

export const Favorites = styled.div``;

export const Details = styled.div``;

export const BoldText = styled.div`
font-weight: 500;`;

export const Detail = styled.div``;
export const DetailSubTitle = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
`;

export const DetailText = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.857px; /* 142.857% */
  letter-spacing: 0.8px;
  margin-top: 40px;
  margin-bottom: 60px;
  border-bottom: 2px;
`;

export const SeparatorLine = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.5);
`;

export const DetailTitle = styled.p`
  margin-top: 30px;
  font-family: ${(props) => props.theme.fonts.textBold};
`;

export const SellSimilarItem = styled.div`
  background-color: ${(props) => props.theme.colors.lightGrey};
  display: flex;
  img {
    height: 80px;
    margin: 0 0 10px 20px;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 6%;
    font-size: 14px;
  }
  a {
    text-decoration: underline;
    font-weight: bold;
  }
`;

export const SoldButton = styled(StyledDisabledButton)`
  width: 300px;
  height: 50px;
`;

export const SustainableIcon = styled.img.attrs({ src: sustainableIcon, alt: 'Sustainable item' })`
  height: 37px;
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 60px;
`;

export const ImpactCalculations = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CO2Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const CO2Icon = styled.img.attrs({ src: co2Icon, alt: 'CO2 Icon' })`
  height: 15px;
  padding-right: 15px;
`;

export const ImpactText = styled.div`
  font-size: 14px;
  height: 27px;
  display: flex;
  align-items: center;
`;

// water
export const waterdropWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const waterdropIcon = styled.img.attrs({ src: waterIcon, alt: 'Waterdrop Icon' })`
  height: 15px;
  margin-left: 5px;
  padding-right: 22px;
`;

export const ImpactTooltip = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
  a {
    margin-left: 0;
  }
`;

// expandable

export const CollapsibleContainer = styled.div`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const CollapsableTitle = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: clamp(16px,1.2vw,18px);
  ${screen.smMax`
    font-size: 17px;
  `};
`;

export const TriggerComponent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
`;

export const Content = styled.div`
  padding: 16px;
  width: 100%;
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 22.857px; /* 142.857% */
  letter-spacing: 0.8px;
  padding-bottom: 30px;
`;

export const ArrowIcon = styled.span`
  justify-content: flex-end;
  right: 10px;
`;

export const ArrowDownIcon = styled.img.attrs({ src: arrowDownIcon, alt: 'arrow icon' })`
  margin-right: 30px;
  justify-content: flex-end;
  width: 18px;
`;

export const ArrowUpIcon = styled.img.attrs({ src: arrowDownIcon, alt: 'arrow icon' })`
  margin-right: 30px;
  width: 18px;
  transform: rotate(180deg);
`;

export const TransparentBtn = styled(StyledTransparentToBlackBtn)`
  width: 199px;
  height: 50px;
  margin-left: 10px;
  border-width: 1px;
  font-family: 'Poppins', sans-serif;
`;
