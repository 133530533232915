import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setMobileOpen } from '../productFilterSlice';

const FilterButton = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation()

  const handleClick = () => {
    dispatch(setMobileOpen(true));
  };

  return (
    <div className="filter-button-container toggle-filter-btn">
      <p onClick={handleClick}>{i18n.t('MFMC__filter')}</p>
    </div>
  );
};

export default FilterButton;
