import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getExtraFilters, toggleExtraFilter, fetchProducts, setCurrentPage } from '../productFilterSlice';
import { StyledPrimaryButton } from '../../StyledGlobal/StyledButton';

const PremiumOnlyButton = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation();

  const extraFilters = useSelector(getExtraFilters);

  const premiumOnlyBtnText = () => {
    if (extraFilters.premiumOnly.active) {
      return i18n.t('POB__shop_all');
    }
    return i18n.t('POB__shop_premium');
  };

  const handleApply = () => {
    dispatch(setCurrentPage(1));
    dispatch(toggleExtraFilter('premiumOnly'));
    dispatch(fetchProducts());
  };

  return (
    <StyledPrimaryButton onClick={handleApply} >
      {premiumOnlyBtnText()}
    </StyledPrimaryButton>
  );
  // TO DO:
  // = generate_tooltip(nil, t('.premium_popup'), 'icons/info.svg')
};

export default PremiumOnlyButton;
