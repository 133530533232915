import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import * as S from './styled/styledUPP';
import { NextButtonProps } from './UploadProductPageTypes';

const NextButton: React.FC<NextButtonProps> = ({ nextStep, setFormProgress }) => {
  // hooks
  const i18n = useTranslation();
  const { validateForm, setTouched } = useFormikContext();

  const handleSuccess = (goToStep: string): void => {
    setFormProgress(goToStep);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <S.NextButton
      type="button"
      onClick={() =>
        validateForm().then(
          (validation: Object) =>
            Object.keys(validation).length ? setTouched(validation) : handleSuccess(nextStep) // go to next step if validations are passed
        )
      }
    >
      {i18n.t('next')}
    </S.NextButton>
  );
};

export default NextButton;
