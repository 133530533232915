import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function DiscountInput(props) {
  const { discount, setDiscount, currentPrice, getInput, discountAmounts } = props;

  const [isHighlighted, setHighligted] = useState('');
  // make sure new sale price is at least 20 ex shipping
  function checkAmount(input) {
    const amount = currentPrice - input >= 20 ? input : currentPrice - 20;
    setDiscount(amount);
  }

  return (
    <div>
      <div className="DM__input">
        <div className="DM__input__left flex flex--cc">€</div>
        <input
          type="number"
          name="right"
          className="DM__input__right"
          value={discount}
          onChange={event => {
            checkAmount(event.target.value);
            getInput(event.target.value);
            setHighligted('');
          }}
        />
      </div>
      <div className="DM__priceButton__container">
        {discountAmounts.map(amount => {
          return (
            <div
              className={cx('DM__priceButton', { borderBlack: isHighlighted === amount })}
              onClick={() => {
                setHighligted(amount);
                setDiscount(amount);
              }}
              key={amount}
            >
              {`€ ${Math.round(amount)}`}
            </div>
          );
        })}
      </div>
    </div>
  );
}

DiscountInput.propTypes = {
  discount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setDiscount: PropTypes.func.isRequired,
  currentPrice: PropTypes.number.isRequired,
  getInput: PropTypes.func.isRequired,
  discountAmounts: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default DiscountInput;
