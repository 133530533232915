import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 50px 60px 50px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 840px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const InfoContent = styled.div`
`;

export const Title = styled.div`
  font-size: 12px;
  opacity: 1;
  text-align: left;
  font-family: Manrope-Medium, sans-serif;
`;

export const UploadsNumber = styled.div`
  font-size: 90px;
  line-height: 1.2;
  margin-top: 24px;
`;

export const Description = styled.div`
  width: 330px;
  text-align: left;
  font-family: Manrope-Light,sans-serif;
  opacity: 1;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 35px;
`;

export const WhatsNewButton = styled.a`
  display: inline-block;
  background-color: black;
  font-size: 12px;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  width: 220px;
  text-align: center;
  line-height: 40px;
  border: 1px solid transparent;
  font-family: Manrope-Light,sans-serif;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: transparent;
    color: black;
    border: 1px solid black;
    text-decoration: none;
  }
`;

export const SeoTags = styled.div`
  max-width: 820px;

  @media (max-width: 840px) {
    margin-top: 20px;
  }
`;

export const SeoTag = styled.div`
  background: #ede9e166 0% 0% no-repeat padding-box;
  border: 1px solid #ede9e1;
  border-radius: 25px;
  opacity: 1;
  float: right;
  margin: 8px;
  text-align: center;
  padding: 10px;

  @media (max-width: 840px) {
    float: left;
  }
`;

export const SeoTagLink = styled.a`
  font-family: Cormorant, sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  opacity: 0.9;
  cursor: pointer;
`;
