document.addEventListener('DOMContentLoaded', () => {
  const btnOne = document.querySelector('.js-ourStoryImpact__toggle_1');
  const btnTwo = document.querySelector('.js-ourStoryImpact__toggle_2');
  const imageOne = document.querySelector('.js-ourStoryElement__image_1');
  const imageTwo = document.querySelector('.js-ourStoryElement__image_2');

  const scrollToPartnersBtn = document.getElementById('js-OurStoryPartners');

  if (btnOne) {
    btnOne.addEventListener('click', e => {
      e.preventDefault();
      btnOne.classList.add('underline-3');
      btnTwo.classList.remove('underline-3');
      imageOne.classList.remove('hidden');
      imageTwo.classList.add('hidden');
    });

    btnTwo.addEventListener('click', e => {
      e.preventDefault();
      btnTwo.classList.add('underline-3');
      btnOne.classList.remove('underline-3');
      imageTwo.classList.remove('hidden');
      imageOne.classList.add('hidden');
    });
  }

  if (scrollToPartnersBtn) {
    scrollToPartnersBtn.addEventListener('click', e => {
      e.preventDefault();
      document
        .getElementById('ourStoryPartners')
        .scrollIntoView({ block: 'start', behavior: 'smooth' });
    });
  }
});
