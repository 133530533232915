const initializeFilters = function() {
  $('#user_clothing_size').select2();
  return $('#user_shoe_size_id').select2();
};

$(function() {
  let hideLogin;
  $('.account-page .unfollow').click(function(e) {
    e.preventDefault();

    return $.ajax({
      url: $(this).attr('href'),
      context: this,
    }).done(function(res) {
      return $(this)
        .parents('tr')
        .remove();
    });
  });

  initializeFilters();

  $('.switch').click(function(e) {
    if ($(this).hasClass('on')) {
      return $(this).removeClass('on');
    } else {
      return $(this).addClass('on');
    }
  });
});
