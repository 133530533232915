import React from 'react';
import { Provider, useSelector } from 'react-redux';
import styled from 'styled-components';
import { trackWindowScroll } from 'react-lazy-load-image-component';

import store from '../../src/store';
import { getItemIds } from './productListSlice';
import ProductCard from './ProductCard';

const StyledProductList = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin: 0 50px;
`;

const ProductList = trackWindowScroll(({ scrollPosition }) => {
  const itemIds = useSelector(getItemIds);

  return (
    <StyledProductList>
      {itemIds.map((id) => (
        <ProductCard key={id} id={id} scrollPosition={scrollPosition} />
      ))}
    </StyledProductList>
  );
});

export default () => (
  <Provider store={store()}>
    <ProductList />
  </Provider>
);
