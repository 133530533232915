import styled from 'styled-components';
import screen from './mediaQueries';
import { Theme } from '../../services/theme';

const basicStyle = `
  font-family: 'Overpass', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 0;
  letter-spacing: 1px;
  text-align: center;
  font-size: 13px;
  display: inline-block;
  height: max-content;
  &:hover {
    cursor: pointer;
  }
`;

const primaryStyle = `
  ${basicStyle}
  border: none;
  background-color: var(--light-green);
  padding: 12px 20px;
  &:hover {
    text-decoration: none;
    color: black;
    ${screen.smMin`transition: all 0.2s ease 0s; background-color: var(--light-grey);`}
  }
`;

const primaryStyleBlack = `
  ${basicStyle}
  font-family: 'Poppins', sans-serif;
  border: none;
  background-color: #000000;
  color: #FFFCFC;
  padding: 12px 20px;
  border: 2px solid #000000;
  &:hover {
    text-decoration: none;
    color: #000000;
    ${screen.smMin`transition: all 0.3s ease 0s; background-color: #FFFCFC;`}
  }
`;

const secondaryStyle = `
${basicStyle}
border: 2px solid #000000;
color: #000000;
padding: 10px 17px;
background-color: transparent;
&:hover {
  text-decoration: none;
  ${screen.smMin`border: 2px solid ${Theme.colors.green}
    color: black;
    transition: all 0.2s ease 0s;
    background-color: ${Theme.colors.green};`}
`;

const displayBlockStyle = `
  display: block;
  width: 100%;
`;

interface StyledButtonProps {
  width?: string;
}

export const StyledPrimaryButton = styled.button<StyledButtonProps>`
  ${primaryStyle}
  width: ${(props) => (props.width ? props.width : 'inherit')};
`;

export const StyledPrimaryButtonBlack = styled.button<StyledButtonProps>`
  ${primaryStyleBlack}
  width: ${(props) => (props.width ? props.width : 'inherit')};
`;

export const StyledTransparentToBlackBtn = styled.button<StyledButtonProps>`
  width: 49%;
  text-align: center;
  background-color: transparent;
  color: #000000;
  padding: 5px 0;
  border: 2px solid #000000;
  text-transform: none;
  font-size: 20px;
  &:hover {
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
    ${screen.smMin`transition: all 0.3s ease 0s; background-color: #000000;`}
  }
`;

export const StyledPrimaryLink = styled.a`
  ${primaryStyle}
`;

export const StyledSecondaryButton = styled.button<StyledButtonProps>`
  ${secondaryStyle}
  width: ${(props) => (props.width ? props.width : 'inherit')};
`;

export const StyledSecondaryLink = styled.a`
  ${secondaryStyle}
`;

export const StyledDisabledButton = styled.button.attrs({ disabled: true })`
  ${basicStyle}
  color: ${Theme.colors.mediumGrey};
  border: 2px solid ${Theme.colors.mediumGrey};
  pointer-events: none;
  padding: 9px 17px;
  background-color: transparent;
`;

export const StyledMobileFilterButton = styled.button`
  ${primaryStyle}
  ${displayBlockStyle}
  position: fixed;
  bottom: 0;
  padding: 14px;

  font-weight: normal;
  font-family: inherit;
  font-size: 16px;
`;
