/* eslint-disable jest-dom/prefer-checked */

import React from 'react';
import { render, screen, fireEvent, act } from '@testing-library/react';
import { I18nextProvider } from 'react-i18next';
import { goToStepThree } from './utils';

import i18n from '../../../test/i18nForTest';
import BankVerification from '../BankVerification';

const setup = () => {
  return render(
    <I18nextProvider i18n={i18n}>
      <BankVerification updateBankPath="mockPath" />
    </I18nextProvider>
  );
};

test('Should throw error if no two documents are uploaded for drivers license and ID', async () => {
  setup();
  await goToStepThree(/rijbewijs/i);
  const stepThreeNext = screen.getByTestId('stepThreeNext');
  await act(() => Promise.resolve(fireEvent.click(stepThreeNext)));
  const fileUploadErrors = screen.getAllByText(/Een foto of scan is verplicht/i);
  expect(fileUploadErrors).toHaveLength(2);
});

test('Should throw error if no document is uploaded for passport', async () => {
  setup();
  await goToStepThree(/paspoort/i);
  const stepThreeNext = screen.getByTestId('stepThreeNext');
  await act(() => Promise.resolve(fireEvent.click(stepThreeNext)));
  const fileUploadErrors = screen.getAllByText(/Een foto of scan is verplicht/i);
  expect(fileUploadErrors).toHaveLength(1);
});
