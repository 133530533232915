import React from 'react';
import { render, screen, fireEvent, waitFor } from '@testing-library/react';
import Cookies from 'js-cookie';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../../test/i18nForTest';

import CookieConsentBanner from '../CookieConsentBanner';

const setup = () => {
  return render(
    <I18nextProvider i18n={i18n}>
      <CookieConsentBanner />
    </I18nextProvider>
  );
};

beforeEach(() => {
  Cookies.remove('functionalCookieConsent');
  Cookies.remove('analyticalCookieConsent');
});

test('should render the CookieConsentBanner if no functionalCookieConsent cookie present', () => {
  setup();
  expect(screen.getByTestId('cookieConsentBanner')).toBeTruthy();
});

test('should render the CookieConsentBanner if functionalCookieConsent cookie is set to FALSE', () => {
  Object.defineProperty(window.document, 'cookie', {
    writable: true,
    value: 'functionalCookieConsent=false',
  });
  setup();
  expect(screen.getByTestId('cookieConsentBanner')).toBeTruthy();
});

test('should NOT render the CookieConsentBanner if functionalCookieConsent cookie is set to TRUE', () => {
  Object.defineProperty(window.document, 'cookie', {
    writable: true,
    value: 'functionalCookieConsent=true',
  });
  setup();
  expect(screen.queryByTestId('cookieConsentBanner')).toBeFalsy();
});

test('should set analyticalCookieConsent to TRUE  if accept all is clicked and hide banner', async () => {
  setup();
  const acceptAllButton = screen.getByText('Accepteer cookies');
  fireEvent.click(acceptAllButton);
  await waitFor(() => expect(Cookies.get('analyticalCookieConsent')).toBe('true'));
  // banner is hidden automatically if functionalCookieConsent is true
  expect(screen.queryByTestId('cookieConsentBanner')).toBeFalsy();
});

test('should set analyticalCookieConsent to FALSE and hide banner if click manage and turn off analytical', async () => {
  setup();
  const manageCookiesButton = screen.getByText('Beheer cookies');
  fireEvent.click(manageCookiesButton);

  const toggleAnalyticalCookies = screen.getByRole('checkbox', { name: /deze cookies verzamelen informatie over jouw gebruik van de website/i })
  // is checked by default
  expect(toggleAnalyticalCookies.checked).toBeTruthy();

  // uncheck
  fireEvent.click(toggleAnalyticalCookies);
  await waitFor(() => expect(toggleAnalyticalCookies.checked).toBeFalsy());
  const acceptAllButton = screen.getByText('Cookie-instellingen opslaan');

  // accept without accepting analytical cookies
  fireEvent.click(acceptAllButton);
  await waitFor(() => expect(Cookies.get('analyticalCookieConsent')).toBe('false'));
  expect(screen.queryByTestId('cookieConsentBanner')).toBeFalsy();
});
