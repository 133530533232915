import { Box, Grid, GridSize, List, ListItem } from "@material-ui/core";
import React from "react"
import { useTranslation } from "react-i18next";
import useWindowWidth from "../helpers/hooks/useWindowWidth";

interface link {
  label: string,
  url: string,
}

// const linksEn: Array<link> = [
//   { label: 'Chanel Handbags', url: '/handbags/chanel'},
//   { label: 'Louis Vuitton Handbags', url: '/handbags/louis-vuitton'},
//   { label: 'Gucci Handbags', url: '/handbags/gucci'},
//   { label: 'Prada Handbags', url: '/handbags/prada'},
//   { label: 'Givenchy Handbags', url: '/handbags/givenchy'},
//   { label: 'Prada Shoes', url: '/shoes/prada'},
//   { label: 'Gucci Shoes', url: '/shoes/gucci'},
//   { label: 'Isabel Marant Shoes', url: '/shoes/isabel-marant'},
//   { label: 'Chanel Shoes', url: '/shoes/chanel'},
//   { label: 'Balenciaga Shoes', url: '/shoes/balenciaga'},
//   { label: 'Isabel Marant Sneakers', url: '/sneakers/isabel-marant'},
//   { label: 'Gucci Sneakers', url: '/sneakers/gucci'},
//   { label: 'Balenciaga Sneakers', url: '/sneakers/balenciaga'},
//   { label: 'Louis Vuitton Shoulder Bags', url: '/shoulderbags/louis-vuitton'},
//   { label: 'Chanel Shoulder Bags', url: '/shoulderbags/chanel'},
//   { label: 'Gucci Shoulder Bags', url: '/shoulderbags/gucci'},
//   { label: 'Chanel Clutches', url: '/clutches/chanel'},
//   { label: 'Louis Vuitton Clutches', url: '/clutches/louis-vuitton'},
//   { label: 'Gucci Clutches', url: '/clutches/gucci'},
//   { label: 'Gucci Sunglasses', url: '/sunglasses/gucci'},
//   { label: 'Christian Dior Sunglasses', url: '/sunglasses/christian-dior'},
//   { label: 'Chanel Sunglasses', url: '/sunglasses/chanel'},
//   { label: 'Prada Boots', url: '/boots/prada'},
//   { label: 'Gucci Boots', url: '/boots/gucci'},
//   { label: 'Isabel Marant Boots', url: '/boots/isabel-marant'},
// ]

// const linksNl: Array<link> = [
//   { label: 'Chanel Handtassen', url: '/handtassen/chanel'},
//   { label: 'Louis Vuitton Handtassen', url: '/handtassen/louis-vuitton'},
//   { label: 'Gucci Handtassen', url: '/handtassen/gucci'},
//   { label: 'Prada Handtassen', url: '/handtassen/prada'},
//   { label: 'Givenchy Handtassen', url: '/handtassen/givenchy'},
//   { label: 'Prada Schoenen', url: '/schoenen/prada'},
//   { label: 'Gucci Schoenen', url: '/schoenen/gucci'},
//   { label: 'Isabel Marant Schoenen', url: '/schoenen/isabel-marant'},
//   { label: 'Chanel Schoenen', url: '/schoenen/chanel'},
//   { label: 'Balenciaga Schoenen', url: '/schoenen/balenciaga'},
//   { label: 'Isabel Marant Sneakers', url: '/sneakers/isabel-marant'},
//   { label: 'Gucci Sneakers', url: '/sneakers/gucci'},
//   { label: 'Balenciaga Sneakers', url: '/sneakers/balenciaga'},
//   { label: 'Louis Vuitton Schoudertassen', url: '/schoudertassen/louis-vuitton'},
//   { label: 'Chanel Schoudertassen', url: '/schoudertassen/chanel'},
//   { label: 'Gucci Schoudertassen', url: '/schoudertassen/gucci'},
//   { label: 'Chanel Clutches', url: '/clutches/chanel'},
//   { label: 'Louis Vuitton Clutches', url: '/clutches/louis-vuitton'},
//   { label: 'Gucci Clutches', url: '/clutches/gucci'},
//   { label: 'Gucci Zonnebrillen', url: '/zonnebrillen/gucci'},
//   { label: 'Christian Dior Zonnebrillen', url: '/zonnebrillen/christian-dior'},
//   { label: 'Chanel Zonnebrillen', url: '/zonnebrillen/chanel'},
//   { label: 'Prada Laarzen', url: '/laarzen/prada'},
//   { label: 'Gucci Laarzen', url: '/laarzen/gucci'},
//   { label: 'Isabel Marant Laarzen', url: '/laarzen/isabel-marant'},
// ]

const popularLinks: Array<link> = [
  { label: 'Gucci', url: '/items/gucci'},
  { label: 'Prada', url: '/items/prada'},
  { label: 'Chanel', url: '/items/chanel'},
  { label: 'Louis Vuitton', url: '/items/louis-vuitton'},
  { label: 'Balenciaga', url: '/items/balenciaga'},
  { label: 'Christian Dior', url: '/items/christian-dior'},
  { label: 'Celine', url: '/items/celine'},
  { label: 'Givenchy', url: '/items/givenchy'},
]

const PopularSearches: React.FC = () => {
  const i18n = useTranslation();
  // const width = useWindowWidth();

  const language = i18n.i18n.language

  // const links = language === "en" ? linksEn : linksNl

  // const getListColumns = () => {
  //   if (width <= 480) {
  //     return 1
  //   }
  //   if (width > 480 && width <= 768) {
  //     return 2
  //   }
  //   if (width > 768 && width <= 992) {
  //     return 3
  //   }
  //   if (width > 992 && width <= 1200) {
  //     return 4
  //   }
  //   return 5
  // }

  return (
    <div className="popular__searches_links">
      {popularLinks.length > 0 && (
        <Grid item xs={12} sm={12 as GridSize}>
          <Box px={2}>
            <h3>{i18n.t('popularSearches')}</h3>
            <List dense style={{ columns: 1 }}>
              {popularLinks.map(({ label, url }) => (
                <ListItem key={url} component="li" dense disableGutters>
                  <ListItem component="a" href={language === "en" ? "/en" + url : url}>{label}</ListItem>
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      )}
    </div>
  )
}

export default PopularSearches;