import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductPageUspsProps } from '../../types/ProductPageTypes';

import shippingLogo from '@images/icons/delivery_truck.svg';
import returnsRelistLogo from '@images/icons/relist.svg';
import athenticityLogo from '@images/icons/auth_check.svg';
import locationIcon from '@images/icons/location.svg';

import calculateDeliveryDay from '../helpers/calculateDeliveryDay';

import * as S from './styled/StyledPPU';
import isHoliday from '../helpers/isHoliday';

import {
  showTooltipDelivery,
  showTooltipRelistReturn,
  showTooltipAuthenticity,
} from './productPageTooltips';

const ProductPageUsps: FC<ProductPageUspsProps> = ({ isPremium, sellerCountry }) => {
  // hooks
  const i18n = useTranslation();

  const deliveryText = (isPremium: boolean) => {
    const text = isPremium ? i18n.t(calculateDeliveryDay(new Date())) : 'USP_delivery_diy';
    return <S.UspText>{i18n.t(text)}</S.UspText>;
  };

  const returnRelistText = (isPremium: boolean) => {
    const text = isPremium ? 'USP_return_possible' : 'USP_relisting_possible';
    return <S.UspText>{i18n.t(text)}</S.UspText>;
  };

  const showUspDelivery = (todayIsHoliday: boolean) => {
    return (
      !todayIsHoliday && (
        <S.UspWrapper>
          <S.ImageWrapper>
            <S.UspImage src={shippingLogo}></S.UspImage>
          </S.ImageWrapper>
          {deliveryText(isPremium)}
          {showTooltipDelivery(isPremium)}
        </S.UspWrapper>
      )
    );
  };

  return (
    <S.Wrapper>
      <S.UspLeft>
      {showUspDelivery(isHoliday(new Date()))}
        <S.UspWrapper>
          <S.ImageWrapper>
            <S.UspImage src={returnsRelistLogo}></S.UspImage>
          </S.ImageWrapper>
          {returnRelistText(isPremium)}
          {showTooltipRelistReturn(isPremium)}
        </S.UspWrapper>
      </S.UspLeft>
      <S.UspRight>
        <S.UspWrapper>
          <S.ImageWrapper>
            <S.UspImage src={locationIcon}></S.UspImage>
          </S.ImageWrapper>
          <S.UspText>{sellerCountry}</S.UspText>
        </S.UspWrapper>
        {/* uncomment when authentication service is live */}
        {/* <S.UspWrapper>
          <S.ImageWrapper>
            <S.UspImage src={athenticityLogo}></S.UspImage>
          </S.ImageWrapper>
          <S.UspText>{i18n.t('USP_authenticity_service')}</S.UspText>
          {showTooltipAuthenticity()}
        </S.UspWrapper> */}
      </S.UspRight>
    </S.Wrapper>
  );
};

export default ProductPageUsps;
