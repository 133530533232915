import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkDown from 'react-markdown';
import { Field } from 'formik';

import * as S from './styled/styledBV';
import FormError from '../global/FormError';
import NextButton from './NextButton';

interface BankVerificationOneProps {
  setFormProgress: Function;
}

const BankVerificationOne: React.FC<BankVerificationOneProps> = ({ setFormProgress }) => {
  // hooks
  const i18n = useTranslation();

  return (
    <>
      <S.Title>{i18n.t('BV__stepOneTitle')}</S.Title>
      <S.Paragraph>{i18n.t('BV__stepOneTextParagraphOne')}</S.Paragraph>
      <S.Paragraph>
        <ReactMarkDown>{i18n.t('BV__stepOneTextParagraphTwo')}</ReactMarkDown>
      </S.Paragraph>
      <S.Paragraph>
        <S.List>
          <li>{i18n.t('BV__stepOneListOne')}</li>
          <li>{i18n.t('BV__stepOneListTwo')}</li>
          <li>{i18n.t('BV__stepOneListThree')}</li>
          <li>{i18n.t('BV__stepOneListFour')}</li>
        </S.List>
      </S.Paragraph>
      <S.Paragraph>
        <ReactMarkDown>{i18n.t('BV__stepOneTextParagraphFour')}</ReactMarkDown>
      </S.Paragraph>
      <S.Paragraph flex>
        <ReactMarkDown>
          {i18n.t('BV__stepOneTextParagraphFive', {
            url: i18n.t('BV__linkMangoPayURL'),
            text: i18n.t('BV__linkMangoPayText'),
          })}
        </ReactMarkDown>
      </S.Paragraph>
      <S.Paragraph>{i18n.t('BV__stepOneTextParagraphSix')}</S.Paragraph>
      <S.TermsContainer>
        <Field as="input" type="checkbox" name="acceptedTermsKYC" id="acceptedTermsKYC" />
        <label htmlFor="acceptedTermsKYC">
          <ReactMarkDown>{i18n.t('BV__stepOneCheckBoxText')}</ReactMarkDown>
        </label>
        <FormError name="acceptedTermsKYC" largeError />
      </S.TermsContainer>
      <NextButton nextStep="stepTwo" setFormProgress={setFormProgress} testid="stepOneNext" />
    </>
  );
};

export default BankVerificationOne;
