import styled from 'styled-components';
import screen from '../../StyledGlobal/mediaQueries';

import topSellerIcon from '@images/icons/top_seller.svg';
import sustainableIcon from '@images/icons/sustainable.svg';
import locationIcon from '@images/icons/location.svg';


export const Wrapper = styled.div`
  font-family: 'Poppins', sans-serif;
  position: relative;
  background-color: white;
  margin-bottom: 30px;
  margin-top: 10px;
  box-shadow: 1px 3px 5px 2px rgba(0, 0, 0, 0.1);
  ${screen.xsMax`margin-bottom: 10px;
    padding: 2px 0 0;
    position: relative;
    box-shadow: 0px 4px 5px 2px rgba(0,0,0,0.1);
    > a {
      display:block;
      margin: auto;
    }
  `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 20px;
  margin-top: 5px;
  ${screen.xsMax`
    margin: auto;
  `}
`;

export const InfoWrapper = styled.div`
  position: relative;
  padding: 10px;
  z-index: 1;
  ${screen.xsMax`
    padding: 0 10px;
    bottom: 0;
    width: 100%;
  `}
`;

export const FlexSpaced = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Brand = styled.p`
  margin: 0;
  font-size: clamp(14px, 1.25vw, 18px);
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${screen.xsMax`
    font-weight: 500;
  `}
`;

export const Flex = styled.div`
  display: flex;
`;

export const Type = styled.p`
  margin: 0 5px 0 0;
  font-size: clamp(10px, 0.88vw, 12px);
  margin-bottom: 15px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.7);
  ${screen.xsMax`
    font-weight: 400;
    margin-bottom: 0;
  `}
`;

interface PriceProps {
  red: boolean;
}

export const Price = styled.span<PriceProps>`
  display: inline-block;
  font-size: clamp(12px, 1.12vw, 16px);
  font-weight: 600;
  height: 18px;
  color: ${props => (props.red ? 'var(--brand-warning)' : 'black')};
  ${screen.xsMax`
    font-weight: 500;
  `}
`;

export const Size = styled.p`
  font-size: clamp(12px, 1.12vw, 16px);
  font-weight: 600;
  ${screen.xsMax`
    font-weight: 500;
  `}
`;

export const PriceOrig = styled.span`
  margin-left: 7px;
  text-decoration: line-through;
  font-size: clamp(10px, 0.97vw, 14px);
  color: var(--medium-grey);
  ${screen.xsMax`
    font-weight: 600;
  `}
`;

const Badge = styled.div`
  font-weight: 600;
  position: absolute;
  left: 0;
  height: 31px;
  z-index: 1;
  padding-left: 20px;
  &:after {
    height: 100%;
    display: flex;
    align-items: center;
  }
  ${screen.xsMax`
    padding-left: 8px;
    height: 17px;
    left: 8px;
  `}
`;

interface PremiumProps {
  text: string;
}

export const Premium = styled(Badge)<PremiumProps>`
  position: relative;
  font-size: 10px;
  font-weight: 600;
  background: var(--sand-color);
  width: 100px;
  height: 19px;
  &:after {
    content: '${(props) => props.text}';
  }
  ${screen.xsMax`
    width: 75px;
    height: 14px;
    font-size: 8px;
  `}
`;

export const Sale = styled(Badge)`
  background: var(--brand-warning);
  top: 53px;
  width: 58px;
  color: white;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Leaf = styled.img.attrs({ src: sustainableIcon })`
position: absolute;
  right: 5px;
  top: 30px;
  z-index: 1;
  display: inline-block;
  height: 25px;
  ${screen.xsMax`
    height: 17px;
  `}
`;


export const TopSeller = styled.img.attrs({
  src: topSellerIcon,
  alt: 'TNC Top Seller',
})`
  height: 40px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
`;

export const Sold = styled.div`
  font-size: 2.5em;
  z-index: 2;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  ${screen.xsMax`font-size: 1.8em;`}
  @media (max-width: 600px) {
    font-size: 1.5em;
  }
  p {
    font-size: 20px;
    line-height: 40px;
    text-align: center;
  }
  p:first-child {
    font-size: 1em;
    margin: 25px 0;
  }
`;

export const SoldHover = styled(Sold)`
  display: flex;
  flex-direction: column;
`;

export const Country = styled.span`
  display: flex;
  font-size: 10px;
  align-items: baseline;
  margin-top: 10px;
  height: 20px;
  ${screen.xsMax`
    font-size: 8px;
    margin-top: 0;
  `}
`;

export const Location = styled.img.attrs({ src: locationIcon })`
  margin-right: 3px;
  ${screen.xsMax`
    height: 5px;
  `}
`;
