import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import store from '../../src/store';
import { fetchProductProperties } from '../Header/headerSlice';

// Used for fetching data that is used in multiple places for all users

const PopulateSharedData: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProductProperties());
  }, [dispatch]);

  return null;
};

export default () => (
  <Provider store={store()}>
    <PopulateSharedData />
  </Provider>
);
