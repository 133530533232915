import React from 'react';

import * as S from './styledU';

interface Props {
  photosAndLabels: { label: string; image: string }[];
}

const PhotoTips: React.FC<Props> = ({ photosAndLabels }) => {
  return (
    <S.PhotoTips>
      {photosAndLabels.map(p => {
        return (
          <S.PhotoTipsWrapper key={p.image}>
            <S.PhotoTipImage src={p.image} alt={p.label} />
            {p.label ? <S.PhotoTipLabel>{p.label}</S.PhotoTipLabel> : ''}
          </S.PhotoTipsWrapper>
        );
      })}
    </S.PhotoTips>
  );
};

export default PhotoTips;
