// hiding and showing header
document.addEventListener('DOMContentLoaded', () => {
  const header = document.querySelector('.header');
  const sortFilterContainerMobile = document.querySelector('#filter-and-sort-container-mobile');
  const dropdownMobile = document.querySelector('.filter-button-container');
  const thisIsBrandsIndexPage = document.getElementById('js-detect-brands-page');
  const uspBanner = document.getElementById('slogan-header');
  const fixedContent = document.querySelector('.fixed-content');

  function handleResize() {
    var headerHeight = header.getBoundingClientRect().height;
    fixedContent.style.marginTop = headerHeight + 'px';
  }
  handleResize();
  window.addEventListener("resize", handleResize);
  let lastScrollTop = 0;

  function debounce(func, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  function hideSlogan() {
    const currScrollTop = Math.round(window.scrollY);
    header.style.top = uspBanner == null || currScrollTop > 30 ? '0' : `${30 - currScrollTop}px`;
  }

  function onScroll() {
    const currScrollTop = Math.round(window.scrollY);
    const isScrollingDown = currScrollTop > lastScrollTop;
    const isHeaderVisible = currScrollTop < header.offsetHeight;

    header.classList.toggle('is-hidden', isScrollingDown && !isHeaderVisible);
    if (sortFilterContainerMobile)
      sortFilterContainerMobile.classList.toggle('is-hidden', isScrollingDown && !isHeaderVisible);
    if (dropdownMobile && dropdownMobile.classList.contains('open')) document.body.click();
    lastScrollTop = currScrollTop;
  }
  if (header) {
    // dont hide navbar on brand index page to prevent conflicted sticky elements
    if (!thisIsBrandsIndexPage) {
      window.addEventListener('scroll', debounce(onScroll, 20));
    }
    window.addEventListener('scroll', hideSlogan);
  }
});
