import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import DiscountCalculation from './DiscountCalculation';
import DiscountInput from './DiscountInput';

import Modal from '../Modal/Modal';
import ModalTitle from '../Modal/ModalTitle';
import ModalText from '../Modal/ModalText';

import { StyledPrimaryButton, StyledSecondaryButton } from '../StyledGlobal/StyledButton';
import './style/DiscountModal.scss';
import decodeHTML from '../../src/helpers/decodeHtml';

function DiscountModal(props) {
  const {
    isShowing,
    hide,
    currentPrice,
    discountAmounts,
    shippingFee,
    name,
    updatePricePath,
    id,
    setPriceState,
    timeToLowerPriceState,
    setTimeToLowerPrice,
  } = props;

  const [discount, setDiscount] = useState('');
  const [showMaxWarning, setShowMaxWarning] = useState(false);
  const { i18n } = useTranslation();

  // show minimum lower 5 euros
  const lowerToAlert = <div className="DM__notice--tip">{i18n.t('disMod_MinAmountForAlert')}</div>;

  // warn if discount too high
  function getInput(input) {
    return currentPrice - input < 20 ? setShowMaxWarning(true) : setShowMaxWarning(false);
  }
  const maximumDiscountAlert = (
    <div className="DM__notice--alert">{i18n.t('disMod_MaxDiscountForAlert')}</div>
  );

  const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  function lowerPrice() {
    axios
      .patch(
        updatePricePath,
        { price_sale: currentPrice - discount, product_id: id },
        { headers: { Accept: 'application/json', 'X-CSRF-TOKEN': csrf } }
      )
      .then(res => {
        setPriceState(res.data.price + shippingFee);
        if (timeToLowerPriceState) {
          setTimeToLowerPrice(false);
        }
        hide();
      });
  }

  const modalBody = (
    <div>
      <ModalTitle title={i18n.t('disMod_title')} isShowing={isShowing} hide={hide} />
      <ModalText text={decodeHTML(i18n.t('disMod_text', { name, price: currentPrice }))} />
      {discount < 5 && currentPrice >= 25 ? lowerToAlert : ''}
      {showMaxWarning ? maximumDiscountAlert : ''}
      <DiscountInput
        discount={discount}
        setDiscount={setDiscount}
        currentPrice={currentPrice}
        getInput={getInput}
        discountAmounts={discountAmounts}
      />
      <DiscountCalculation
        currentPrice={currentPrice}
        discount={discount}
        shippingFee={shippingFee}
      />
      <div className="DM__buttons__bottom">
        <StyledSecondaryButton onClick={hide}>
          {i18n.t('cancel')}
        </StyledSecondaryButton>

        <StyledPrimaryButton onClick={lowerPrice}>
          {i18n.t('submit')}
        </StyledPrimaryButton>
      </div>
    </div>
  );

  return <Modal isShowing={isShowing} hide={hide} body={modalBody} id={id} />;
}

DiscountModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  currentPrice: PropTypes.number.isRequired,
  discountAmounts: PropTypes.arrayOf(PropTypes.number).isRequired,
  shippingFee: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  updatePricePath: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  setPriceState: PropTypes.func.isRequired,
  setTimeToLowerPrice: PropTypes.func,
  timeToLowerPriceState: PropTypes.bool,
};

DiscountModal.defaultProps = {
  setTimeToLowerPrice: null,
  timeToLowerPriceState: false,
};

export default DiscountModal;
