import { configureStore } from '@reduxjs/toolkit';

import home from '../components/Home/homeSlice';
import checkout from '../components/Checkout/checkoutSlice';
import header from '../components/Header/headerSlice';
import productFilter from '../components/Filters/productFilterSlice';
import productList from '../components/ProductList/productListSlice';
import myItems from '../components/MyItems/myItemsSlice';
import productPage from '../components/ProductPage/productPageSlice';
import user from '../components/User/userSlice';

const createStore = () =>
  configureStore({
    // @ts-ignore
    reducer: { header, myItems, checkout, productFilter, productList, home, productPage, user },
    preloadedState: global.gon && global.gon.redux_state,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable state check
      immutableCheck: false, // Disable immutability check
    }),
  });

const store = createStore();

export default () => {
  if (typeof window !== 'undefined') {
    // return the global store on the client
    return store;
  }
  // return a new store every time for SSR
  return createStore();
};

export type RootState = ReturnType<typeof store.getState>;
