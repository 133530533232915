import React from 'react';
import { TextField } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

interface MaterialTextFieldProps {
  name: string;
  label?: string;
  type?: string;
  autoComplete?: string;
  disabled?: boolean;
  hidden?: boolean;
  placeholderText?: string;
  multiline?: boolean;
  rows?: number; // only use for multiline
  size?: string;
  onKeyUp?: () => void;
}

const SmallTextField: React.FC<MaterialTextFieldProps> = ({
  name,
  label = null,
  type = 'text',
  autoComplete = undefined,
  disabled = false,
  hidden = false,
  placeholderText = undefined,
  multiline = false,
  rows = 1,
  onKeyUp = undefined,
}): JSX.Element => {
  const i18n = useTranslation();
  return (
    <span hidden={hidden} data-testid={`${name}.wrapper`}>
      <Field name={name}>
        {({ field: { value, onChange, onBlur }, meta: { error, touched } }: FieldProps) => (
          <TextField
            error={!!error && touched}
            variant="outlined"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            label={label}
            type={type}
            autoComplete={autoComplete}
            helperText={error && touched ? i18n.t(error) : null}
            disabled={disabled}
            InputProps={{
              style: { fontSize: '16px', borderRadius: '0', border: 1, textAlign: 'center' },
            }}
            InputLabelProps={{
              style: {
                color: "0, 0, 0, 0.7",
                
                fontFamily: 'Poppins',
                fontSize: 10,

                fontWeight: 500,
              },
            }}
            FormHelperTextProps={{ style: { fontSize: '12px' } }}
            fullWidth
            data-testid={name}
            placeholder={placeholderText}
            multiline={multiline}
            rows={rows}
            onKeyUp={onKeyUp}
            size={'small'}
          />
        )}
      </Field>
    </span>
  );
};

export default SmallTextField;
