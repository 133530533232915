import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PostNLImage from '../../../assets/images/postnl.jpg';
import DPDImage from '@images/logos/dpd.svg';
import { RootState } from '../../src/store';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-top: 8px;
  }
`;

const StyledPostNL = styled.img`
  margin: 0 5px 0 10px;
  height: 25px;
`;

const StyledDPD = styled.img`
  margin-left: 15px;
  height: 20px;
`

const ShowShippingProvider = React.memo(function ShowShippingProvider() {
  const i18n = useTranslation();
  const ShippingProvider = useSelector((state: RootState) => state.checkout.sellerCountry);

  const PostNL = <StyledPostNL src={PostNLImage} alt="PostNL" />;
  const DPD = <StyledDPD src={DPDImage} alt="DPD" />;

  return (
    <StyledWrapper data-testid="ShowShippingProvider">
      <p>{i18n.t('shipping_provider')}</p>
      {ShippingProvider.includes('NL') ? PostNL : ''}
      {ShippingProvider.length == 2 ? ' & ' : ''}
      {ShippingProvider.includes('BE') ? DPD : ''}
    </StyledWrapper>
  );
});

export default ShowShippingProvider;
