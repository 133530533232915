import { createAsyncThunk, createSlice, Dispatch, PayloadAction, current } from '@reduxjs/toolkit';
import axios from 'axios';
import dropRight from 'lodash/dropRight';

import { generateURL, setLocaleURLS } from './utils';
import { setItems } from '../ProductList/productListSlice';

declare global {
  interface Window {
    ReactRailsUJS: any;
  }
}

export interface SelectedFilter {
  filterName: 'types' | 'brands';
  id: number;
}

export type FilterState = {
  selectedFilters: null | SelectedFilter[];
  mobileFilter: {
    open: boolean;
    pages: { filterName: string }[];
  };
  filters: {
    [key: string]: {
      label: string;
      open: boolean;
      itemIds: string[];
      items: {
        [key: string]: {
          id: string;
          name: string;
          children?: string[];
          parent?: string;
          selected: boolean;
          slug?: string;
          slugs?: { [key: string]: string };
          grandparent?: string;
          count: number;
          sustainable?: boolean;
        };
      };
    };
  };
  extraFilters: {
    TNCPremiumService: { active: boolean };
    hideSold: { active: boolean };
    justIn: { active: boolean };
    pagination: { currentPage: number; totalPages: number };
    premiumOnly: { active: boolean; count: number };
    sale: { active: boolean };
    seller: { sellerId: string | null };
    sortProducts: { sortBy: string | null };
    sustainableBrands: { active: boolean };
  };
  totalProductsCount: number;
  loading: boolean;
};

// Initial State
const initialState: FilterState = {
  selectedFilters: null,
  mobileFilter: {
    open: false,
    pages: [{ filterName: 'main' }],
  },
  filters: {},
  totalProductsCount: 0,
  extraFilters: {
    TNCPremiumService: { active: false },
    hideSold: { active: false },
    justIn: { active: false },
    pagination: { currentPage: 0, totalPages: 0 },
    premiumOnly: { active: false, count: 0 },
    sale: { active: false },
    seller: { sellerId: null },
    sortProducts: { sortBy: null },
    sustainableBrands: { active: false },
  },
  loading: false,
};

// Thunk actions
export const fetchProducts = createAsyncThunk(
  'productFilter/fetchProductsNew',
  async (category: Nullable<string>, { getState, dispatch }) => {
    const state = getState() as { productFilter: FilterState };
    const filters = getFilters(state);
    const extraFilters = getExtraFilters(state);

    let url = generateURL(filters, extraFilters);

    if (category) {
      url = `${url}&category=${category}`
    }

    // `filterUsed` indicates that when a user presses the back/forward browser button
    // the page should refresh, to reflect the filter changes
    window.history.replaceState({ filterUsed: true }, ''); // change url in browser
    window.history.pushState({ filterUsed: true }, '', url); // change url in browser

    // Set localized urls
    setLocaleURLS(filters, extraFilters);

    // Hide banners
    Object.values(document.getElementsByClassName('js-banner')).forEach((e) => e.remove());

    // navigate mobile menu back to page 1
    dispatch(popPage(state.productFilter.mobileFilter.pages.length - 1));

    // Fetch the products
    return axios.get(url, { headers: { Accept: 'application/json' } }).then((res) => {
      // Change the filter-data
      dispatch(setItems(res.data.productList));
      dispatch(setFilterData(res.data.reduxFilterData));
      dispatch(setExtraFilterData(res.data.reduxExtraFilterData));
      dispatch(setTotalProductsCountData(res.data.reduxTotalProductsCount));
    })
  }
);

// Create slice
const productFilterSlice = createSlice({
  name: 'productFilter',
  initialState,
  reducers: {
    toggle: (state, { payload: { filterName, id, active } }) => {
      const { items } = state.filters[filterName];
      const item = items[id];

      if (typeof active !== 'undefined') {
        item.selected = active; // Set selected to true or false
      } else {
        item.selected = !item.selected; // Toggle selected state
      }

      // Deselect all children and grandchildren
      if (item.children) {
        item.children.forEach((i) => {
          items[i].selected = false;
          const children = items[i].children;
          if (children) {
            children.forEach((grandchild) => {
              items[grandchild].selected = false;
            });
          }
        });
      }
      if (item.parent) {
        // Deselect the parent
        items[item.parent].selected = false;
      }
      if (item.grandparent) {
        // Deselect the grandparent
        items[item.grandparent].selected = false;
      }
    },
    setOpen: (state, { payload: { filterName, open } }) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterName]: {
            ...state.filters[filterName],
            open: open
          }
        }
      };

      // Close all other filters
      // Object.keys(state.filters).forEach((name) => {
      //   if (name !== filterName) state.filters[name].open = false;
      // });
    },
    setMobileOpen: (state, { payload }) => {
      state.mobileFilter.open = payload;
      const overflow = payload ? 'hidden' : 'auto';
      document.body.style.overflow = overflow;
    },

    toggleExtraFilter: (
      state,
      {
        payload,
      }: PayloadAction<
        'TNCPremiumService' | 'hideSold' | 'justIn' | 'premiumOnly' | 'sale' | 'sustainableBrands'
      >
    ) => {
      const extraFilter = state.extraFilters[payload];
      state.extraFilters[payload].active = !state.extraFilters[payload].active;
    },

    setSort: (state, { payload: { sortOption } }) => {
      state.extraFilters.sortProducts.sortBy = sortOption;
    },
    pushPage: (state, { payload }) => {
      state.mobileFilter.pages.push(payload);
    },
    popPage: (state, { payload }) => {
      state.mobileFilter.pages = dropRight(state.mobileFilter.pages, payload);
    },
    setFilterData: (state, { payload }) => {
      state.filters = payload;
    },
    setBrandsFilterData: (state, { payload: { data, append } }) => {
      var filters = state.filters;

      if(append) {
        filters['brands'] = {
          itemIds: [...current(state).filters['brands'].itemIds, ...data.item_ids],
          items: {...current(state).filters['brands'].items, ...data.items},
          label: 'brands',
          open: false
        }
      } else {
        filters['brands'] = {
          itemIds: data.item_ids,
          items: data.items,
          label: 'brands',
          open: false
        }
      }

      state.filters = filters;
    },
    setExtraFilterData: (state, { payload }) => {
      state.extraFilters = payload;
    },
    setCurrentPage: (state, { payload }) => {
      state.extraFilters.pagination.currentPage = payload;
    },
    setSelectedFilters: (state, { payload }) => {
      state.selectedFilters = payload;
    },
    setTotalProductsCountData: (state, { payload }) => {
      state.totalProductsCount = payload;
    },
    reset: (state) => {
      Object.entries(state.filters).forEach(([, { items }]) => {
        Object.entries(items).forEach(([, draft]) => {
          draft.selected = false;
        });
      });
      state.extraFilters.premiumOnly.active = false;
      state.extraFilters.sortProducts.sortBy = '';
      state.extraFilters.justIn.active = false;
      state.extraFilters.sale.active = false;
      state.extraFilters.justIn.active = false;
      state.extraFilters.seller.sellerId = null;
      state.extraFilters.TNCPremiumService.active = false;
      state.extraFilters.sustainableBrands.active = false;
      state.extraFilters.pagination.currentPage = 1;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchProducts.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchProducts.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(fetchProducts.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

// The actions and the reducers have the same name.
export const {
  toggle,
  setOpen,
  setMobileOpen,
  toggleExtraFilter,
  setSort,
  pushPage,
  popPage,
  setFilterData,
  setBrandsFilterData,
  setExtraFilterData,
  setCurrentPage,
  setTotalProductsCountData,
  setSelectedFilters,
  reset,
} = productFilterSlice.actions; // -->  These actions are automaticaly generated.
export default productFilterSlice.reducer; // --> This are the reducers that are defined in the code above.

// Selectors
export const getFilters = (state: { productFilter: FilterState }) => state.productFilter.filters;
export const getExtraFilters = (state: { productFilter: FilterState }) =>
  state.productFilter.extraFilters;
export const getTotalProductsCount = (state: { productFilter: FilterState }) =>
  state.productFilter.totalProductsCount;
export const getSelectedFilters = (state: { productFilter: FilterState }) =>
  state.productFilter.selectedFilters;

type Nullable<T> = T | null;
