const getProductRootType = (type: string) => {
  const typeLowerCase = type.toLocaleLowerCase();
  if (['kleding', 'clothing'].includes(typeLowerCase)) {
    return 'clothing';
  } else if (['schoenen', 'shoes'].includes(typeLowerCase)) {
    return 'shoes';
  } else if (['tassen', 'bags'].includes(typeLowerCase)) {
    return 'bags';
  } else if (['jeans'].includes(typeLowerCase)) {
    return 'jeans'
  } else {
    return 'accessories';
  }
};

export { getProductRootType };
