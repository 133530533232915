import React, { useState, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useUpdateEffect from '../helpers/hooks/useUpdateEffect'; // custom hook to run useEffect not on initial render

import MaterialTextField from '../global/MaterialTextField';
import FormError from '../global/FormError';
import * as S from './styled/StyledAddress';
import CountrySelect from './CountrySelect';
import AutoCompleteAddressField from './helpers/AutoCompleteAddressField';
import {
  setShippingIsInvoice,
  setInvoiceAddressComplete,
  setAddressIsValidated,
} from './checkoutSlice';
import { zipCodeApiCall } from './helpers/getAddress';
import { Address, InitialAddress } from './CheckoutTypes';
import { RootState } from '../../src/store';

const ShippingAddress = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<InitialAddress>();
  // hooks
  interface RetrievedAddres extends Address {
    valid: boolean;
    setNumberAndZip?: boolean | undefined;
    housenumberAdditionsArray?: string[] | undefined;
    errorMessage?: boolean | string;
  }
  const [formEnabled, setFormEnabled] = useState(!values.shipping.addressIsValidated);
  const [retrievedAddress, setRetrievedAddress] = useState<RetrievedAddres | null | undefined>(
    null
  );
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [validationNeeded, setValidationNeeded] = useState(
    false
  );
  const [useAutoComplete, setUseAutoComplete] = useState(
    ['BE', 'LU', 'DE'].includes(values.shipping.country)
  );
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const shippingIsInvoice = useSelector(
    (state: RootState) => state.checkout.initialAddressData.shippingIsInvoice
  );
  const zipcodeApiPath = useSelector((state: RootState) => state.checkout.zipcodeApiPath);

  const noInitialCountry = values.shipping.country === '';

  // for these countries we use validation of the address either zipcode check or autocomplete address
  const afterUpdateCountry = () => {
    setValidationNeeded(false);
    setUseAutoComplete(['BE', 'LU', 'DE'].includes(values.shipping.country));
  };

  useUpdateEffect(afterUpdateCountry, [values.shipping.country]);

  const setStreetAndCity = () => {
    setFieldValue('shipping.city', retrievedAddress?.city);
    setFieldValue('shipping.street', retrievedAddress?.street);
  };

  const setHousenumberAndZipcode = () => {
    setFieldValue('shipping.housenumber', retrievedAddress?.housenumber);
    setFieldValue('shipping.housenumberAddition', retrievedAddress?.housenumberAddition || '');
    setFieldValue('shipping.zipcode', retrievedAddress?.zipcode);
  };

  useEffect(() => {
    if (retrievedAddress?.valid) {
      dispatch(setAddressIsValidated(true)); // address is validated and this will be saved on the shipping address in the db so the user doesn't have to check this address again
      setStreetAndCity();
      setFieldsDisabled(['BE', 'LU', 'DE', 'NL'].includes(values.shipping.country));
    }
    if (retrievedAddress?.setNumberAndZip) {
      setHousenumberAndZipcode(); // only for autocomplete address, zipcode check already has these fields filled
    }
  }, [retrievedAddress]);

  const enterManually = (
    <S.Error
      link
      largeFont
      black={values.shipping.country !== 'NL'} // Display it in black for DE, BE, LUX since it's always there and not an error
      onClick={() => {
        dispatch(setAddressIsValidated(false)); // this shipping address of user is no longer validated
        setValidationNeeded(false);
        setRetrievedAddress(null);
        setUseAutoComplete(false); // to show the correct fields for the autocomplete countries
        setFieldsDisabled(false);
      }}
    >
      {i18n.t('SA__enterAddressManually')}{' '}
    </S.Error>
  );

  const zipcodeToCheckFalse = () => {
    // make fields editable
    setFieldsDisabled(false);
  };

  const makeFormEditable = () => {
    retrievedAddress && setRetrievedAddress({ ...retrievedAddress, valid: false });
    zipcodeToCheckFalse();
  };

  // call the zipcode API (NL)
  const getAddress = async () => {
    try {
      const res = await zipCodeApiCall(
        zipcodeApiPath,
        values.shipping.zipcode,
        values.shipping.housenumber,
        values.shipping.housenumberAddition
      );
      if (res.data.exception) {
        setRetrievedAddress({ valid: false, errorMessage: res.data.exception });
      } else {
        setRetrievedAddress({
          valid: true,
          street: res.data.street,
          city: res.data.city,
          zipcode: res.data.postcode,
          housenumber: res.data.houseNumber,
          housenumberAddition: res.data.houseNumberAddition,
          housenumberAdditionsArray: res.data.houseNumberAdditions,
        });
      }
    } catch (e) {
      setRetrievedAddress({ valid: false, errorMessage: true });
    }
  };

  const clearFields = () => {
    setRetrievedAddress(null);
    setFieldValue('shipping.zipcode', '');
    setFieldValue('shipping.housenumber', '');
    setFieldValue('shipping.housenumberAddition', '');
  };

  const zipcodeCheck =
    /* show check zip button if not yet checked, otherwise show reset button */
    retrievedAddress?.valid ? (
      <S.UnderlinedButton
        onClick={() => {
          makeFormEditable();
        }}
      >
        {i18n.t('SA__changeAddress')}
      </S.UnderlinedButton>
    ) : (
      <S.Button
        type="button"
        onClick={(e) => {
          if (values.shipping.zipcode === '' || values.shipping.housenumber === '') return;
          getAddress();
        }}
      >
        {i18n.t('SA__checkAddress').toUpperCase()}
      </S.Button>
    );

  // show possible housenumberAddition if present and if user hasn't selected one yet
  const showHousenumberAdditions = () => (
    <div>
      <S.Additions>
        {i18n.t('SA__chooseAddition')}
        {retrievedAddress?.housenumberAdditionsArray?.map((addition) => {
          return (
            <S.Addition
              data-testid="chooseHouseNumberAddition"
              key={addition}
              onClick={() => {
                setRetrievedAddress({ ...retrievedAddress, housenumberAddition: addition });
                setFieldValue('shipping.housenumberAddition', addition);
              }}
            >
              {addition}
            </S.Addition>
          );
        })}
      </S.Additions>
    </div>
  );

  // address retrieved from postcode api

  const showRetrievedAddress = retrievedAddress?.valid ? (
    <>
      <S.Flex>
        {/* show the addition options if they are available and the user didn't select one yet. Only for zipcode check not for autocomplete */}
        {retrievedAddress.housenumberAdditionsArray &&
        retrievedAddress.housenumberAdditionsArray !== [''] &&
        !retrievedAddress.housenumberAddition
          ? showHousenumberAdditions()
          : ''}
      </S.Flex>
      <S.RetrievedAddress data-testid="retrievedAddressDisplayed">
        <S.Flex>
          <div>
            <div>
              {retrievedAddress.street} {retrievedAddress.housenumber}
              {retrievedAddress.housenumberAddition
                ? `-${retrievedAddress.housenumberAddition}`
                : ''}
            </div>
            <div>
              {retrievedAddress.zipcode} {retrievedAddress.city}
            </div>
          </div>
          <S.UnderlinedButton
            onClick={() => {
              clearFields();
              zipcodeToCheckFalse();
            }}
          >
            {i18n.t('SA__reset')}
          </S.UnderlinedButton>
        </S.Flex>
      </S.RetrievedAddress>
    </>
  ) : (
    ''
  );
  const handleToggle = () => {
    // toggle shipping is equal to invoice and show editable invoice address if user sets it too false
    dispatch(setShippingIsInvoice(!shippingIsInvoice));
    // shippingIsInvoice still has the old value here therefore we run on true
    shippingIsInvoice ? dispatch(setInvoiceAddressComplete(false)) : '';
  };
  return (
    <div data-testid="ShippingAddress">
      {formEnabled ? (
        <>
          <S.Title>{i18n.t('shippingAddress')}</S.Title>
          <S.Flex>
            <MaterialTextField
              name="shipping.firstname"
              label={i18n.t('firstname')}
              autoComplete="given-name"
            />
            <S.Spacer />
            <MaterialTextField
              label={i18n.t('lastname')}
              name="shipping.lastname"
              autoComplete="family-name"
            />
          </S.Flex>
          <CountrySelect
            fieldName="shipping.country"
            calcShipping
            makeFormEditable={makeFormEditable}
            fieldsDisabled={fieldsDisabled}
          />
          <FormError name="shipping.country" />

          {/* Show AutoCompleteAddressField whe useAutoComplete (for BE, DE, LU)  */}
          {useAutoComplete ? (
            <>
              <AutoCompleteAddressField
                setRetrievedAddress={setRetrievedAddress}
                isDisabled={fieldsDisabled}
                country={values.shipping.country}
              />
              {enterManually}
            </>
          ) : (
            ''
          )}
          {/* hide the zipcode and housenumber fields when useautocomplete. The fields are still used when submitting the form but not shown to user. */}
          <S.Flex
            hidden={useAutoComplete || noInitialCountry}
            data-testid="shipping.numberZipcodeWrapper"
          >
            <MaterialTextField
              label={i18n.t('zipcode')}
              name="shipping.zipcode"
              autoComplete="postal-code"
              disabled={fieldsDisabled}
            />
            <S.Spacer />
            <MaterialTextField
              name="shipping.housenumber"
              label={i18n.t('housenumber')}
              type="number"
              disabled={fieldsDisabled}
              autoComplete="new-password"
            />
            <S.Spacer />
            <MaterialTextField
              name="shipping.housenumberAddition"
              label={i18n.t('housenumberAddition')}
              disabled={fieldsDisabled}
              autoComplete="new-password"
            />
          </S.Flex>
          <S.Flex marginTop="15px" hidden={useAutoComplete || noInitialCountry}>
            <MaterialTextField
              label={i18n.t('street')}
              hidden={validationNeeded}
              name="shipping.street"
              disabled={fieldsDisabled}
              autoComplete="new-password"
            />
            <S.Spacer />
            <MaterialTextField
              label={i18n.t('city')}
              name="shipping.city"
              hidden={validationNeeded}
              disabled={fieldsDisabled}
            />
          </S.Flex>
          {values.shipping.country === 'NL' && validationNeeded ? zipcodeCheck : ''}
          {/* have hidden checkbox to check if the user has an approved  address  to run the validation */}
          {showRetrievedAddress}
          {retrievedAddress?.errorMessage ? (
            <>
              <S.Error largeFont>{i18n.t('SA__addressNotFound')} </S.Error>
              {enterManually}
            </>
          ) : (
            ''
          )}
        </>
      ) : (
        <S.PrintedWrapper data-testid="printedShippingAddress">
          <S.Flex>
            <S.Title>{i18n.t('shippingAddress')}</S.Title>
            <S.EditButton
              type="button"
              onClick={() => {
                setFormEnabled(true);
                dispatch(setAddressIsValidated(false));
              }}
            >
              {i18n.t('editAddress')}
            </S.EditButton>
          </S.Flex>
          <div>
            <p>
              {values.shipping.firstname} {values.shipping.lastname}
            </p>
            <p>
              {values.shipping.street} {values.shipping.housenumber}
              {values.shipping.housenumberAddition ? `-${values.shipping.housenumberAddition}` : ''}
            </p>
            <p>
              {values.shipping.zipcode} {values.shipping.city}
            </p>
            <p>{i18n.t(values.shipping.country)}</p>
          </div>
        </S.PrintedWrapper>
      )}
      <S.CheckBoxWrapper>
        <Field
          type="checkbox"
          name="shippingIsInvoice"
          id="shippingIsInvoice"
          onChange={() => handleToggle()}
          checked={shippingIsInvoice}
          data-testid="shippingIsInvoice"
        />
        <label htmlFor="shippingIsInvoice">{i18n.t('checkoutShippingIsInovice')}</label>
      </S.CheckBoxWrapper>
      {values.shipping.housenumberAddition.length > 3 &&
        <S.ErrorMessage>
           {i18n.t('editYourAddress')}
        </S.ErrorMessage>
      }
    </div>
  );
};
export default ShippingAddress;
