import styled, { keyframes } from 'styled-components';
import screen from '../../StyledGlobal/mediaQueries';

// icons
import checkMark from '@images/icons/check.svg';
import poundIcon from '@images/icons/pound--grey.svg';
import viewsIcon from '@images/icons/views.svg';
import viewsGrey from '@images/icons/views--grey.svg';
import heartIcon from '@images/icons/heart_light.svg';
import heartGrey from '@images/icons/heart_light--grey.svg';

export const Wrapper = styled.div`
  padding: 10px 0;
  border-bottom: 2px solid var(--light-grey);
  ${screen.mdMax`padding: 20px 0;`}
  ${screen.xsMax`font-size:14px`}
`;

export const Info = styled.div`
  display: flex;
  ${screen.mdMin`left: 0;
    width: 100%;`}
  ${screen.mdMax`justify-content: space-between;`}
  @media (max-width: 450px) {
    justify-content: flex-start;
  }
`;

export const Grey = styled.div`
  color: var(--medium-grey);
`;

export const InfoSmall = styled.div`
  ${screen.lgMin`display:none`}
  display: flex;
  justify-content: space-between;
  > div {
    padding: 0 5px;
    display: inline-flex;
  }
  img {
    margin-right: 5px;
  }
`;

export const ImageWrapper = styled.div`
  ${screen.xsMax`width:100px`}
  ${screen.mdMax`heigth:auto`}
  height: 100px;
  width: 115px;
`;

export const Image = styled.img`
  width: 90px;
  margin-top: 5px;
  ${screen.mdMax`margin: 0 0 10px 0;`}
`;

export const BrandType = styled.div`
  ${screen.mdMax`width:auto`}
  width: 160px;
`;

export const Truncate = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Type = styled(Truncate)`
  ${screen.smMax`display:none`}
  margin-top: 5px;
`;

export const Flex = styled.div`
  display: flex;
`;

export const StateSmall = styled.div`
  display: flex;
  ${screen.mdMin`display:none`}
`;

export const StateLarge = styled.div`
  display: flex;
  width: 170px;
  ${screen.smMax`display:none`};
`;

const handleColorType = state => {
  switch (state) {
    case 'Green':
      return '#00ce98;';
    case 'Red':
      return `var(--brand-warning)`;
    case 'Orange':
      return '#fa9006';
    default:
      return '#fff';
  }
};

export const Dot = styled.div`
  height: 12px;
  width: 12px;
  margin-top: 5px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${props => handleColorType(props.state)};
`;

export const Sold = styled.div`
  background-image: url(${checkMark});
  height: 20px;
  width: 19px;
  border-radius: 0;
  background-repeat: no-repeat;
  margin: 2px 10px 0 0;
`;

export const showSmall = styled.div`
  ${screen.mdMin`display:none`}
`;

export const hideSmall = styled.div`
  ${screen.smMax`display:none`}
`;

export const InfoLarge = styled.div`
  width: 70px;
  ${screen.mdMax`display:none`}
`;

const growElement = keyframes`
0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
`;

const Price = styled.div`
  animation-name: ${growElement};
  animation-duration: ${props => (props.priceChange ? '.8s' : '0')};
`;

export const PriceLarge = styled(Price)`
  ${screen.mdMax`display: none`}
  width: 70px;
`;

export const PriceSmall = styled(Price)`
  ${screen.lgMin`display: none`}
  width: auto;
  color: var(--medium-grey);
`;

export const Message = styled.div`
  ${screen.smMin(`width: 500px; margin-left: -125px; min-width: 250px; left: 50%; top: 145px;`)}
  ${screen.xsMax(
    `width: auto; margin-right: 6px; margin-left: -10px; top: 50px`
  )}
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
  color: var(--medium-grey);
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  border-radius: 8px;
`;

export const ViewsFavs = styled.div`
  ${screen.mdMax(`display:none`)}
  width: 130px;
  display: flex;
`;

export const Views = styled.div`
  display: flex;
  align-items: flex-start;
  ${screen.mdMax`color: var(--medium-grey)`}
`;

export const ViewsImage = styled.div`
  background-image: url(${props => (props.isSmall ? viewsGrey : viewsIcon)});
  width: 23px;
  height: 15px;
  background-repeat: no-repeat;
  margin: 3px 6px 0 0;
`;

export const heartImage = styled.div`
  background-image: url(${props => (props.isSmall ? heartGrey : heartIcon)});
  width: 18px;
  height: 17px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 3px 6px 0 0;
`;

export const Favs = styled(Views)`
  margin-left: 10px;
`;

export const Pound = styled.img.attrs({
  src: poundIcon,
  alt: '',
})`
  height: 15px;
  margin-top: 3px;
`;
