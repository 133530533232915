import { createTooltipWithIcon, createTooltipWithText } from '../global/ToolTip';
import { ClothingSizeTable, ShoeSizeTable } from '../global/SizingTables';

const showTooltipShipping = () => {
  return createTooltipWithIcon(
    'shippingInfoProductPage',
    undefined,
    'toolTipShippingOnCheckout'
  );
};

const showTooltipSize = (typeId: number) => {
  const sizeChart = typeId === 3 ? ClothingSizeTable() : ShoeSizeTable();
  return createTooltipWithIcon('sizeInfoProductPage', undefined, undefined, sizeChart);
};

const showTooltipCondition = () => {
  return createTooltipWithIcon(
    'conditionInfoProductPage',
    'toolTipconditiontitle',
    'toolTipconditiontext'
  );
};

const showTooltipDelivery = (premium: boolean) => {
  const text = premium
    ? { title: 'toolTipDeliveryTitlePremium', body: 'toolTipDeliveryTextPremium' }
    : { title: 'toolTipDeliveryTitleDiy', body: 'toolTipDeliveryTextDiy' };
  return createTooltipWithIcon('deliveryInfoProductPage', text.title, text.body);
};

const showTooltipRelistReturn = (premium: boolean) => {
  const text = premium
    ? { title: 'toolTipReturnTitle', body: 'toolTipReturnText' }
    : { title: 'toolTipRelistTitle', body: 'toolTipRelistText' };
  return createTooltipWithIcon('ReturnRelistInfoProductPage', text.title, text.body);
};

const showTooltipAuthenticity = () => {
  return createTooltipWithIcon(
    'authenticityInfoProductPage',
    'toolTipAuthenticityTitle',
    'toolTipAuthenticityText'
  );
};

const showTooltipMaterialTip = (material: string) => {
  return createTooltipWithIcon(
    'materialTipProductPage',
    'toolTipMaterial.title',
    `toolTipMaterial.${material
      .toLowerCase()
      .split(' ')
      .join('_')}`,
    undefined,
    true
  );
};

const showTooltipVerifiedSeller = (premium: boolean) => {
  const toolTipText = premium ? 'tooltipVerifiedSellerTextPremium' : 'tooltipVerifiedSellerText';
  return createTooltipWithIcon('toolTipVerifiedSeller', 'tooltipVerifiedSellerTitle', toolTipText);
};

const showTooltipImpactCalculator = () => {
  return createTooltipWithText(
    'impactCalculatorProductPage',
    'howDoWeCalculate',
    'tooltipImpactTitle',
    'tooltipImpactText'
  );
};

export {
  showTooltipShipping,
  showTooltipSize,
  showTooltipDelivery,
  showTooltipRelistReturn,
  showTooltipAuthenticity,
  showTooltipMaterialTip,
  showTooltipCondition,
  showTooltipVerifiedSeller,
  showTooltipImpactCalculator,
};
