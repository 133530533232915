import React, { useState, useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Item from './Item';
import { debounce } from 'lodash';

import * as S from './styled/styledF';

// Filter component
const Filter = ({ name, searchEnabled, isMobile, searchBrandsFilter, fetchBrandsForFilter }) => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const listInnerRef = useRef();
  const sellerType = name === 'seller-type';
  const label = sellerType ? 'seller-type' : useSelector(state => state.productFilter.filters[name].label);
  const itemIds = sellerType ? [] : useSelector(state => state.productFilter.filters[name].itemIds);
  const items = sellerType ? {} : useSelector(state => state.productFilter.filters[name].items);

  const [search, setSearch] = useState('');
  const [nextPage, setNextPage] = useState(2);

  const visibleItems = itemIds.filter(id => items[id].name.toLowerCase().indexOf(search.toLowerCase()) > -1);
  const active = itemIds.some(id => items[id].selected);

  const itemsKeys = Object.keys(items);
  const itemsValues = itemsKeys.map(k => items[k]);
  const selectedItems = itemsValues.filter(item => item.selected);

  const [isOpen, setIsOpen] = useState(name == 'brands' || name == 'gender' ? true : false);

  // for now it is only Premium, if not selected then show all
  const sellerTypes = ['Premium sellers'];

  const handleFetchMoreBrands = debounce(val => {
    fetchBrandsForFilter(val)
    setNextPage(nextPage + 1);
  }, 500);

  const handleBrandsFilterSearch = debounce(query => {
    searchBrandsFilter(query)
    setNextPage(2);
  }, 500);

  const onScroll = () => {
    if(name == 'brands') {
      if (listInnerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
        const isNearBottom = scrollTop + clientHeight >= scrollHeight - 40;
        if (isNearBottom && search.length == 0) {
          handleFetchMoreBrands(nextPage)
        }
      }
    }
  };

  const getListContainer = (
    !sellerType ? (
      <S.ListContainer>
        {searchEnabled && (
          <S.SearchField
            placeholder={i18n.t('SF__search') + ' ' + i18n.t(`${label}`) + 's'}
            onChange={e => { handleBrandsFilterSearch(e.target.value); setSearch(e.target.value) }}
          />
        )}
        <S.List ref={listInnerRef} onScroll={onScroll}>
          {visibleItems.map(id => (
            <Item key={id} filterName={name} id={id} isMobile={isMobile} />
          ))}
        </S.List>
      </S.ListContainer>
    ) : (
      <S.ListContainer>
        <S.List>
          {sellerTypes.map(name => (
            <Item key={name} filterName={name} id={name} isMobile={isMobile} />
          ))}
        </S.List>
      </S.ListContainer>
    )
  );

  return (
    <S.Filter>
      <>
        <S.DropdownButton
          active={active}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          isExpanded={isOpen}
        >
          {i18n.t(`${name}`)}
          { !isOpen && 
            <S.SelectedItems>       
              { selectedItems.map(item => item.name).join(", ") }
            </S.SelectedItems>
          } 
        </S.DropdownButton>
        {isOpen && (
          getListContainer
        )}
      </>
    </S.Filter>
   );   
};

Filter.propTypes = {
  name: PropTypes.string.isRequired,
  searchEnabled: PropTypes.bool,
};

Filter.defaultProps = {
  searchEnabled: true,
};

export default memo(Filter);
