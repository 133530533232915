import styled from 'styled-components';
import { Theme } from '../../../services/theme';
import screen from '../../StyledGlobal/mediaQueries';


export const StyledBreadcrumbsWrapper = styled.nav`
  ${screen.xsMax`
  padding: 0 15px 0;`};

  display: flex;
  a,
  p {
    color: ${Theme.colors.mediumGrey};
    font-size: 14px;
  }
  padding: 10px 0 0px 50px;
  height: 30px;
`;

export const StyledBreadcrumb = styled.a``;
export const StyledBreadcrumbWrapper = styled.div`
  display: flex;
  p {
    padding: 0 5px;
  }
`;
