import React from 'react';
import { fireEvent, screen, waitFor } from '@testing-library/react';

import setupTest from '../../../test/setupTest';
import { FollowBrand } from '../FollowBrand';
import { UserState } from '../../User/userSlice';
import initialState from '../../Filters/DesktopFilterContainer/tests/initialFilterData';
import { Repository } from '@thenextcloset/shared-javascript';
import Repo from '../../../services/repository';
import set from 'lodash/set';

const setup = (data: UserState) => {
  setupTest(
    {
      user: data,
      productFilter: initialState,
    },
    <FollowBrand />
  );
};

const initialData: UserState = {
  authentication: {
    uid: 'someone@something.com',
    accessToken: 'randomn',
    client: 'client',
    userId: 123,
  },
  favoriteProductCount: 0,
  favorites: {
    sellerIds: [],
    productIds: [],
    brandIds: [],
  },
};

const mockGetFavoriteBrands = (isFavorite: boolean) => {
  jest
    .spyOn(Repository, 'getFavoriteIds')
    // default resolve value
    .mockResolvedValue({ data: { brandIds: [] } })
    // initially the user is following the brand when isFollowing true
    .mockResolvedValueOnce({
      data: isFavorite ? { brandIds: [] } : { brandIds: [15] },
    })
    // the second call the user is not following the brand when isFollowing true or following when isFollwing false
    .mockResolvedValueOnce({
      data: isFavorite ? { brandIds: [15] } : { brandIds: [] },
    });
};

describe('when not following a brand', () => {
  test('should render a follow button when NOT following the brand and follow when pressing the follow button', async () => {
    jest.spyOn(Repo, 'addFavorites').mockResolvedValue({ data: true });
    mockGetFavoriteBrands(false);
    await setup(initialData);
    const followButton = screen.getByRole('button', { name: 'Volg items van Gucci' });
    // unfollow button is there
    fireEvent.click(followButton);
    await waitFor(() => {
      // after pressing the button the follow button is there
      expect(screen.getByRole('button', { name: 'Ontvolg items van Gucci' })).toBeTruthy();
    });
  });
});

describe('when following a brand', () => {
  jest.spyOn(Repo, 'removeFavorites').mockResolvedValue({ data: true });

  test('should unfollow when pressing the unfollow button', async () => {
    mockGetFavoriteBrands(true);
    await setup(set(initialData, 'favorites.brandIds', [15]));

    const unFollowButton = await waitFor(() =>
      screen.getByRole('button', { name: 'Ontvolg items van Gucci' })
    ); // unfollow button is there
    fireEvent.click(unFollowButton);
    await waitFor(() => {
      // after pressing the button the follow button is there
      expect(screen.getByRole('button', { name: 'Volg items van Gucci' })).toBeTruthy();
    });
  });
});
