import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as S from './styled/StyledProductInfo';
import ShoppingCartButton from '../ShoppingCartModal/ShoppingCartButton';
import ProductPageUsps from './ProductPageUsps';
import { gtmProductSerializer } from '../Analytics/gtmProductSerializer';
import {
  showTooltipShipping,
  showTooltipSize,
  showTooltipMaterialTip,
  showTooltipCondition,
} from './productPageTooltips';
import { ProductPageProduct } from '../../types/ProductPageTypes';
import Collapsible from 'react-collapsible';
import _ from 'lodash';
import backArrow from '@images/icons/back_arrow.svg';
import giftBoxIcon from '@images/icons/simple_present.svg';

interface Props {
  uploadProductPath: string;
  productIsInCart: boolean;
  productForCart: Object;
  categoryPath: string;
  brandPath: string;
  gtmEvent?: Record<string, unknown>;
  sellerCountry: string | undefined;
  askQuestionPath: string;
  userLoggedIn: boolean;
  logInPath: string;
  pdpBanner: string;
  chatDisabled: boolean;
}

const ProductInfo: FC<Props> = ({
  uploadProductPath,
  productIsInCart,
  productForCart,
  categoryPath,
  brandPath,
  sellerCountry,
  askQuestionPath,
  userLoggedIn,
  logInPath,
  pdpBanner,
  chatDisabled
}) => {
  // hooks
  const {
    display_price,
    price_original,
    type,
    type_en,
    rootType_en,
    brand,
    size = null,
    condition,
    condition_en,
    premium,
    views,
    favs,
    description,
    id,
    product_properties_en,
    sustainable,
    type_id,
    sold_or_sold_invisible,
    backend_state,
    assets,
    product_path,
  } = useSelector(
    (state: { productPage: { product: ProductPageProduct } }) => state.productPage.product
  );
  const i18n = useTranslation();
  const chatButton = (
    userLoggedIn: boolean,
    askQuestionPath: string,
    logInPath: string
  ) => {
    return userLoggedIn ? (
      <S.TransparentBtn data-href={askQuestionPath} className="js-btn-talkjs pd-chat-btn">
        {i18n.t('ask_a_question')}
      </S.TransparentBtn>
    ) : (
      <S.TransparentBtn href={logInPath} className="pd-chat-btn">{i18n.t('ask_a_question')}</S.TransparentBtn>
    );
  };
  const showShoppingCartButton = (isSoldOrSoldInvisible: boolean, backendState: string) => {
    if (backendState === 'preview') return <S.SoldButton>{i18n.t('preview')}</S.SoldButton>; // in preview mode we don't want the item to be able to be sold

    return isSoldOrSoldInvisible ? (
      <S.SoldButton>{i18n.t('sold_button')}</S.SoldButton>
    ) : (
      <ShoppingCartButton
        id={'trk-add-to-shopping-bag'}
        buttonFor={'ProductShowPage'}
        isInCart={productIsInCart}
        product={productForCart}
      />
    );
  };

  const CollapsableRow = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleCollapsible = () => {
      setIsOpen(!isOpen);
    };

    const trigger = (
      <S.TriggerComponent>
        <S.CollapsableTitle>{i18n.t(title)}</S.CollapsableTitle>
        {isOpen ? <S.ArrowUpIcon /> : <S.ArrowDownIcon />}
      </S.TriggerComponent>
    );
    return (
      <S.CollapsibleContainer>
        <Collapsible
          trigger={trigger}
          triggerStyle={{ width: '100%' }}
          onOpen={toggleCollapsible}
          onClose={toggleCollapsible}
        >
          <S.Content>{content}</S.Content>
        </Collapsible>
      </S.CollapsibleContainer>
    );
  };

  // GTM
  const itemColor = product_properties_en?.find((property) => property.name === 'kleur')?.value;
  const itemMaterial = product_properties_en?.find(
    (property) => property.name === 'materiaal'
  )?.value;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: {
      currency: 'EUR',
      value: display_price,
      items: [gtmProductSerializer()],
      items_google_ads: [{
        item_id: id,
        google_business_vertical: 'retail'
      }]
    },
  });

  // Klaviyo
  var _learnq = window._learnq || [];
  var item = {
   "ProductName": name,
   "ProductID": id,
   "Categories": [rootType_en, type_en],
   "ImageURL": assets[0],
   "URL": product_path,
   "Brand": brand,
   "Price": display_price,
   "CompareAtPrice": price_original
  };
  _learnq.push(["track", "Viewed Product", item]);
  _learnq.push(["track", "Viewed Brand", { "Brand": brand }]);

  return (
    <S.Wrapper data-testid="productInfo">
      <div className="back-button" onClick={() => window.history.back()}><img src={backArrow} /><p>Back</p></div>
      <S.NameBrand>
        <S.Flex>
          <S.Brand href={brandPath} data-testid="brandLink">
            {brand.toUpperCase()}
          </S.Brand>
        </S.Flex>
        <S.Name href={categoryPath} data-testid="typeLink">
          {`${type}, ${itemColor}, ${itemMaterial} `}
        </S.Name>
      </S.NameBrand>
      <S.PriceWrapper>
        <S.Flex>
          <S.CurrentPrice id="current-price">€{display_price}</S.CurrentPrice>
          {/* {showTooltipShipping()} */}
          {price_original > 0 && <S.OriginalPrice>€{price_original}</S.OriginalPrice>}
        </S.Flex>
      </S.PriceWrapper>
      <S.SizeCondition>
        {size && (
          <S.Flex>
            <S.Size>
              {i18n.t('size')}: {size}
            </S.Size>
            {showTooltipSize(type_id)}
          </S.Flex>
        )}
        <S.Flex>
          <S.Condition>
            {i18n.t('condition')}: {condition}
          </S.Condition>
          {showTooltipCondition()}
        </S.Flex>
        <S.Flex>
          <S.Material>
            {_.startCase(i18n.t('material').toLowerCase())}: {itemMaterial}
          </S.Material>
        </S.Flex>
      </S.SizeCondition>
      <div className='pdp-btn-gift-wrapper'>
        <S.ButtonAndFavWrapper>
          <div className="cart-button">{showShoppingCartButton(sold_or_sold_invisible, backend_state)}</div>
          {!chatDisabled && chatButton(userLoggedIn, askQuestionPath, logInPath)}
        </S.ButtonAndFavWrapper>
        {
          pdpBanner != null && pdpBanner.trim().length != 0 && (
            <div className='pdp-gift-wrapper'>
              <img src={giftBoxIcon} alt="gift_box" />
              <p>{ pdpBanner }</p>
            </div>
          )
        }
      </div>
      <ProductPageUsps isPremium={premium} sellerCountry={sellerCountry} />
      <S.Details>
        <CollapsableRow title={'description'} content={description} />
        <CollapsableRow
          title={'details'}
          content={
            <div>
              <p>
                {i18n.t('color')}: {itemColor}
              </p>
              <p>
                {i18n.t('material')}: {itemMaterial}
              </p>
              {size && (
                <p>
                {i18n.t('size')}: {size}
              </p>
              )}
              <p>
                {i18n.t('condition')}: {condition}
              </p>
              <p>
                {i18n.t('item_nr')}: {id}
              </p>
              {sellerCountry !== (null) ? (<p>{i18n.t('seller_country')}: {sellerCountry}</p>) : (null)}
            </div>
          }
        />
        <CollapsableRow
          title={'payment'}
          content={
            <p>
              {i18n.t('payment_details')}: {id}
            </p>
          }
        />
        <CollapsableRow
          title={'delivery_returns'}
          content={
            <div>
              <S.BoldText>{i18n.t('delivery')}</S.BoldText>
              <p>{i18n.t('delivery_details')}</p>
              <S.BoldText>{i18n.t('returns')}</S.BoldText>
              <p>{i18n.t('toolTipRelistText')}</p>
            </div>
          }
        />
      </S.Details>
      <S.SeparatorLine />
    </S.Wrapper>
  );
};

export default ProductInfo;
