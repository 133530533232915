import React from 'react';
import PropTypes from 'prop-types';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ShoppingCartModal from './ShoppingCartModal';
import store from '../../src/store';
import { toggleInCart } from '../Header/headerSlice';
import { StyledProductShowButton, StyledIndexAddToCartButtonBlack } from '../global/styled/StyledTCB';
import { StyledCheckMark, StyledTextWrapper } from './styled/styledSCB';
import Repository from '../../services/repository';
import { setHeaders } from '../../services/helpers';
import { getAuthentication } from '../User/userSlice';

function ShoppingCartButton(props) {
  const {
    product: {
      linkToCart,
      addToBagLink,
      ProductPageUrl,
      id,
      type,
      typeEN,
      rootTypeEN,
      priceWithoutCurrency,
      origPrice,
      brand,
      condition,
      color,
      material,
      sellerType,
      image
    },
    product,
    isInCart,
    buttonFor,
    slideInView,
    setInCart,
  } = props;

  const i18n = useTranslation();
  const dispatch = useDispatch();
  // check if product has a lineitemnumber and therefore is in cart
  const lineItemForCart = useSelector((state) => state.header.lineItemForCart) || false;
  const addedProduct = useSelector((state) => state.header.productForPopUp) || false;
  const authentication = useSelector(getAuthentication);

  // add product to cart
  function handleCart() {
    dispatch(toggleInCart([addToBagLink, 'post', product]));
    if (setInCart) {
      setInCart(true);
    }
  }

  function handleToCart() {
    window.location = linkToCart;
  }

  const sendAddToCartEventToGTM = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });

    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'EUR',
        value: parseFloat(origPrice),
        items: [
          {
            item_name: id + ' ' + brand + ' ' + typeEN,
            item_id: id,
            price: parseInt(origPrice),
            discount: parseInt(origPrice) - parseInt(priceWithoutCurrency),
            item_category: rootTypeEN,
            item_category2: typeEN,
            item_category3: brand,
            item_variant: color,
            quantity: 1,
            item_seller_type: sellerType,
            item_material: material,
            item_condition: condition,
          },
        ],
        items_google_ads: [
          { 
            item_id: id,
            google_business_vertical: 'retail'
          }
        ]
      },
    });
  };

  const sendAddToCartEventToKlaviyo = () => {
    var _learnq = window._learnq || [];
    var item = {
      "AddedItemProductName": brand + ' ' + typeEN,
      "AddedItemProductID": id,
      "AddedItemCategories": [rootTypeEN, typeEN],
      "AddedItemImageURL": image,
      "AddedItemURL": ProductPageUrl,
      "AddedItemPrice": parseInt(origPrice),
      "AddedItemQuantity": 1
    }

    _learnq.push(["track", "Added to Cart", item]);
  }

  const addToHelloRetailCart = () => {
    if(typeof ADDWISH_PARTNER_NS  !== "undefined") {
      Repository.getCartProductsUrl({ headers: setHeaders(authentication) }).then((res) => {
        var data = res.data
        var email = Object.keys(data)[0]
        var items = data[Object.keys(data)[0]]
        var cartLink = data[Object.keys(data)[1]]

        ADDWISH_PARTNER_NS.api.cart.setCart({
          url: cartLink,
          urls: items,
          email: email
        }, 
        function() {
          console.log('Cart has been tracked');
        });
      });
    }
  }

  // If there is a  LineItemForCart or if isInCart ==  true the product is already in the cart
  const showPageButton =
    lineItemForCart || isInCart ? (
      <>
        <StyledProductShowButton onClick={handleToCart} id="trk-add-to-shopping-bag">
          {i18n.t('complete_order').toUpperCase()}
        </StyledProductShowButton>
        <StyledTextWrapper>
          <StyledCheckMark />
          {i18n.t('item_is_in_shoppingbag')}
        </StyledTextWrapper>
      </>
    ) : (
      <StyledProductShowButton
        onClick={() => {
          handleCart();
          sendAddToCartEventToGTM();
          sendAddToCartEventToKlaviyo();
          addToHelloRetailCart();
        }}
      >
        {i18n.t('buy_button')}
      </StyledProductShowButton>
    );

  const indexPageButton = isInCart ? (
    <StyledIndexAddToCartButtonBlack
      slideInView={slideInView}
      onClick={() => {
        handleToCart();
      }}
      data-testid="addToCart-isInCart"
      className="cart-btn"
    >
      {i18n.t('Go to cart').toUpperCase()}
    </StyledIndexAddToCartButtonBlack>
  ) : (
    <StyledIndexAddToCartButtonBlack
      slideInView={slideInView}
      onClick={() => {
        handleCart();
        sendAddToCartEventToGTM();
        sendAddToCartEventToKlaviyo();
        addToHelloRetailCart();
      }}
      data-testid="addToCart-notInCart"
      className="cart-btn"
    >
      {i18n.t('Add to shoppingbag').toUpperCase()}
    </StyledIndexAddToCartButtonBlack>
  );

  const selectButton = buttonFor === 'ProductShowPage' ? showPageButton : indexPageButton;

  return (
    <>
      {selectButton}
      {addedProduct.id === id ? <ShoppingCartModal /> : ''}
    </>
  );
}

ShoppingCartButton.propTypes = {
  product: PropTypes.object.isRequired,
  isInCart: PropTypes.bool.isRequired,
  buttonFor: PropTypes.string.isRequired,
  slideInView: PropTypes.bool,
  setInCart: PropTypes.func,
};

ShoppingCartButton.defaultProps = {
  slideInView: false,
  setInCart: null,
};
// HOC for redux
export default (props) => (
  <Provider store={store()}>
    <ShoppingCartButton {...props} />
  </Provider>
);
