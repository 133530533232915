document.addEventListener('DOMContentLoaded', () => {
  function showFullDescription(button, trunc, full) {
    button.addEventListener('click', e => {
      e.preventDefault();
      trunc.classList.add('hidden');
      full.classList.remove('hidden');
    });
  }

  // show full description text for types
  const truncBut = document.getElementById('js-show-entire-description');
  const truncText = document.getElementById('js-truncated-description');
  const fullText = document.getElementById('js-full-description');

  //   const truncButB = document.getElementById('js-show-entire-description-brand');
  //   const truncTextB = document.getElementById('js-truncated-description-brand');
  //   const fullTextB = document.getElementById('js-full-description-brand');

  if (truncBut) {
    showFullDescription(truncBut, truncText, fullText);
  }

  //   if (truncButB) {
  //     showFullDescription(truncButB, truncTextB, fullTextB);
  //   }
});
