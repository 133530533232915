import styled from 'styled-components';
import { StyledMobileFilterButton } from '../../../StyledGlobal/StyledButton';

export const MobileFilterContainer = styled.div`
  padding-left: 12px;
  padding-right: 12px;
`;

export const Wrapper = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-right: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const FilterText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins";
  opacity: 0.65;
`;

export const Container = styled.div`
  margin-bottom: 70px;
`;

export const ViewProductsButton  = styled(StyledMobileFilterButton)``;

export const Reset = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: none;
  padding-left: 15px;
  &:focus {
    outline: none;
  }
  font-size: 14px;
  font-weight: 300;
`;

export const ResetButtonContainer = styled.div`
  padding: 10px 10px 0px 10px;
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--black-25);
`;

export const ResetButton = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  border: 1px solid #999999;
  background-color: white;
  font-family: "Poppins";
  font-size: 13px;
  line-height:
`;

