import styled from 'styled-components';
import { MenuItem, Menu } from '@material-ui/core';

export const MaterialMenuItem = styled(MenuItem)`
  font-size: 16px;
  background-color: transparent !important;
  img {
    margin-right: 10px;
  }
  &:hover {
    text-decoration: underline;
    background-color: var(--light-grey) !important;
  }
`;

export const MaterialMenu = styled(Menu)`
  .Muilist-padding {
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  @media (max-width: 450px) {
    margin-left: auto;
  }
  .glyphicon {
    display: none !important;
  }
  img {
    height: 20px !important;
    width: 20px !important;
    padding: 0 !important;
    margin-right: 5px;
  }
`;
