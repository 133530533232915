import styled from 'styled-components';
import screen from '../../StyledGlobal/mediaQueries';
import { StyledPrimaryButton } from '../../StyledGlobal/StyledButton';

import { Theme } from '../../../services/theme';

export const Container = styled.div`
  margin-top: -94px;
  ${screen.smMax`
 margin-top: -50px;
 `}
  ${screen.xsMax`
    margin-top: -45px;
    `};
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 0 10%;
  margin-bottom: 100px;
  ${screen.mdMax`
 padding: 0;
 `}
  ${screen.xsMax`
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    `};
`;

interface SubWrapperProps {
  marginBottom?: boolean;
}

export const SubWrapper = styled.div<SubWrapperProps>`
  padding: 0 20px;
  margin-bottom: ${(props) => (props.marginBottom ? '25px' : '')};
  ${screen.smMax`padding: 0 10px`}
`;

export const SubWrapperBorder = styled(SubWrapper)`
  border-left: solid 3px var(--light-grey);
  border-width: 3px;
  ${screen.xsMax`border: none`}
`;

export const Title = styled.h1`
  font-size: 36px;
  text-align: center;
  padding: 0 0 20px;
  ${screen.xsMax`
  font-size: 22px;
  padding: 20px 0 0;
  `}
`;

export const Header = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
`;

export const Button = styled(StyledPrimaryButton)`
  width: 100%;
  height: 50px;
  margin-top: 15px;
  margin-bottom: 50px;
  background-color: ${Theme.colors.green};
  color: ${Theme.colors.white};
  &:hover {
    background-color: ${Theme.colors.activeGreen};
    color: ${Theme.colors.white};
  }
`;

export const Terms = styled.p``;

export const Link = styled.a`
  text-decoration: underline;
`;
