/* eslint-disable jest-dom/prefer-checked */

import React from 'react';
import { render, screen, fireEvent, act } from '@testing-library/react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { I18nextProvider } from 'react-i18next';
import { Formik } from 'formik';
import set from 'lodash/set';

import { Checkout } from '../Checkout';
import checkout from '../checkoutSlice';
import i18n from '../../../test/i18nForTest';
import { initialData } from './initialCheckoutData';

const setup = (data) => {
  const store = configureStore({ preloadedState: data, reducer: { checkout } });

  return render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Formik>
          <Checkout />
        </Formik>
      </I18nextProvider>
    </Provider>
  );
};

function getDomNodes() {
  return {
    ideal: screen.getByRole('radio', { name: 'iDEAL' }),
    bancontact: screen.getByRole('radio', { name: 'Bancontact' }),
    creditcard: screen.getByRole('radio', { name: /creditcard type/i }),
    submit: screen.getByTestId('submit'),
    creditCardTypeDropDown: screen.getByTestId('creditcardType'),
  };
}

test('Shows four payment methods and non is selected when no previous payment method is known for user', async () => {
  await act(async () => {
    // to prevent `act` error because of useEffect in the component
    setup(initialData);
  });

  expect(screen.getByTestId('PaymentMethod')).toBeTruthy();

  const { ideal, creditcard, bancontact } = getDomNodes();

  expect(ideal.checked).toBeFalsy();
  expect(bancontact.checked).toBeFalsy();
  expect(creditcard.checked).toBeFalsy();

  await act(() => Promise.resolve(fireEvent.click(ideal)));

  expect(ideal.checked).toBeTruthy();
  expect(bancontact.checked).toBeFalsy();
  expect(creditcard.checked).toBeFalsy();
});

test('Selects IDEAL if this is the previous used payment method for a user and it"s possible to change', async () => {
  await act(async () => {
    // to prevent `act` error because of useEffect in the component
    setup(set(initialData, 'checkout.initialAddressData.paymentMethod', 'ideal'));
  });

  const { ideal, creditcard, bancontact } = getDomNodes();

  expect(ideal.checked).toBeTruthy();
  expect(bancontact.checked).toBeFalsy();
  expect(creditcard.checked).toBeFalsy();

  await act(() => Promise.resolve(fireEvent.click(bancontact)));

  expect(ideal.checked).toBeFalsy();
  expect(bancontact.checked).toBeTruthy();
});

test('Selects BANCONTACT if this is the previous used payment method for a user', async () => {
  await act(async () => {
    // to prevent `act` error because of useEffect in the component
    setup(set(initialData, 'checkout.initialAddressData.paymentMethod', 'bancontact'));
  });

  const { ideal, bancontact, creditcard } = getDomNodes();

  expect(ideal.checked).toBeFalsy();
  expect(bancontact.checked).toBeTruthy();
  expect(creditcard.checked).toBeFalsy();
});

test('Selects CREDIT CARD if this is the previous used payment method for a user', async () => {
  await act(async () => {
    setup(set(initialData, 'checkout.initialAddressData.paymentMethod', 'creditcard'));
  });
  const { ideal, creditcard, bancontact } = getDomNodes();

  expect(ideal.checked).toBeFalsy();
  expect(bancontact.checked).toBeFalsy();
  expect(creditcard.checked).toBeTruthy();
});


test('automatically select credit card as a payment method if a credit card type is selected from dropdown', async () => {
  await act(async () => {
    // to prevent `act` error because of useEffect in the component
    setup(set(initialData, 'checkout.initialAddressData.paymentMethod', ''));
  });
  const { creditcard, creditCardTypeDropDown } = getDomNodes();

  expect(creditCardTypeDropDown.value).toBe('');
  expect(creditcard.checked).toBeFalsy();

  // select creditcard as payment method already when clicking on dropdown
  await act(() => Promise.resolve(fireEvent.click(creditCardTypeDropDown)));
  expect(creditcard.checked).toBeTruthy();

  await act(() =>
    Promise.resolve(fireEvent.change(creditCardTypeDropDown, { target: { value: 'visa' } }))
  );
  expect(creditCardTypeDropDown.value).toBe('visa');
});

test('cant submit form without selecting a PaymentMethod', async () => {
  window.scrollTo = jest.fn();

  await act(async () => {
    setup(set(initialData, 'checkout.initialAddressData.paymentMethod', ''));
  });
  const { submit, ideal, creditcard, bancontact } = getDomNodes();

  expect(screen.queryByTestId('PaymentError')).toBeFalsy();
  await act(() => Promise.resolve(fireEvent.click(submit)));
  expect(ideal.checked).toBeFalsy();
  expect(bancontact.checked).toBeFalsy();
  expect(creditcard.checked).toBeFalsy();
  expect(screen.getByTestId('PaymentError')).toBeTruthy();
});

test('If you select credit card as payment method you have to specify a type to be able to submit ', async () => {
  await act(async () => {
    setup(set(initialData, 'checkout.initialAddressData.paymentMethod', ''));
  });
  const { submit, creditcard, creditCardTypeDropDown } = getDomNodes();

  expect(screen.queryByTestId('SpecifyCreditCardError')).toBeFalsy();
  fireEvent.click(creditcard);
  await act(() => Promise.resolve(fireEvent.click(submit)));
  expect(screen.getByTestId('SpecifyCreditCardError')).toBeTruthy();

  await act(() =>
    Promise.resolve(fireEvent.change(creditCardTypeDropDown, { target: { value: 'visa' } }))
  );
  expect(creditCardTypeDropDown.value).toBe('visa');
  expect(screen.queryByTestId('SpecifyCreditCardError')).toBeFalsy();
});
