/* eslint-disable jest-dom/prefer-checked */

import React from 'react';
import { render, screen } from '@testing-library/react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { I18nextProvider } from 'react-i18next';
import set from 'lodash/set';
import { act } from 'react-dom/test-utils';

import { Checkout } from '../Checkout';
import checkout from '../checkoutSlice';
import i18n from '../../../test/i18nForTest';
import { initialData } from './initialCheckoutData';

const setup = (data) => {
  const store = configureStore({ preloadedState: data, reducer: { checkout } });
  return render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Checkout />
      </I18nextProvider>
    </Provider>
  );
};

test('it renders all child components when ShippingAddres is NOT equal to InvoiceAddress', async () => {
  await act(() =>
    Promise.resolve(setup(set(initialData, 'checkout.initialAddressData.shippingIsInvoice', false)))
  );

  expect(screen.getByTestId('CheckoutHeader')).toBeTruthy();
  expect(screen.getByTestId('ShippingAddress')).toBeTruthy();
  expect(screen.getByTestId('InvoiceAddress')).toBeTruthy();
  expect(screen.getByTestId('PaymentMethod')).toBeTruthy();
  expect(screen.getByTestId('Products')).toBeTruthy();
  expect(screen.getByTestId('CouponCheckInputHidden')).toBeTruthy(); // Component is visible but input field is not
  expect(screen.getByTestId('PriceBreakdown')).toBeTruthy();
  expect(screen.getByTestId('ShowShippingProvider')).toBeTruthy();
  expect(screen.getByTestId('submit')).toBeTruthy();
});

test('it renders all child components EXCEPT InvoiceAddress when ShippingAddres is NOT equal to InvoiceAddress', async () => {
  await act(() =>
    Promise.resolve(setup(set(initialData, 'checkout.initialAddressData.shippingIsInvoice', true)))
  );

  expect(screen.getByTestId('CheckoutHeader')).toBeTruthy();
  expect(screen.queryByTestId('InvoiceAddress')).toBeFalsy();
  expect(screen.getByTestId('ShippingAddress')).toBeTruthy();
  expect(screen.getByTestId('PaymentMethod')).toBeTruthy();
  expect(screen.getByTestId('Products')).toBeTruthy();
  expect(screen.getByTestId('CouponCheckInputHidden')).toBeTruthy(); // Component is visible but input field is not
  expect(screen.getByTestId('PriceBreakdown')).toBeTruthy();
  expect(screen.getByTestId('ShowShippingProvider')).toBeTruthy();
  expect(screen.getByTestId('submit')).toBeTruthy();
});
