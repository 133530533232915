import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Repository from '../../services/repository';

interface StyledBannerProps {
  backgroundColor: string;
}

interface MessageObject {
  textEn: string;
  textNl: string;
  backgroundColor?: string;
}

const StyledBanner = styled.div<StyledBannerProps>`
  font-size: 14px;
  background: ${props => props.backgroundColor};
  color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const DegradationMessage: React.FC = () => {
  const locale = window.I18n;
  const [message, setMessage] = useState<string | MessageObject>('null');

  const fetchDegradationMessage = () => {
    Repository.getDegradationMessage()
      .then(res => {
        setMessage(res.data);
      })
      .catch((e: any) => {
        console.error('DegradationMessage.tsx', e);
      });
  };

  useEffect(() => {
    fetchDegradationMessage();
  }, []);

  const showText = (message: MessageObject) => {
    return locale === 'en' ? message.textEn : message.textNl;
  };

  return typeof message === 'object' && message !== null ? (
    <StyledBanner backgroundColor={message.backgroundColor || 'black'}>
      {showText(message)}
    </StyledBanner>
  ) : null;
};

export default DegradationMessage;
