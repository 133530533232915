import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Provider, useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { StylesProvider } from '@material-ui/core';

import { setFilteredProducts } from './myItemsSlice';
import store from '../../src/store';
import MyItemCard from './MyItemsCard';
import SearchField from '../global/SearchField';
import Pagination from '../global/Pagination';

function MyItemCardContainer({ filterPath, stateQuery }) {
  const i18n = useTranslation();
  const dispatch = useDispatch();

  // Hooks
  const { products, isSearching, currentPage, productCount } = useSelector(state => state.myItems);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const isFirstRun = useRef(true);

  const handleFilter = () =>
    dispatch(
      setFilteredProducts(
        `${filterPath}?filter=${searchQuery}${
          stateQuery.length > 1 ? `&state=${stateQuery}` : ''
        }&page=${page}`
      )
    );

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    handleFilter();
  }, [searchQuery, page]);

  // pageCount for pagination
  const pageCount = Math.ceil(productCount / 60);

  // `debounce` used to not send too many requests to server
  const handleSearch = debounce(query => {
    // reset page to 1 to make sure user ends up on first page when searching
    setPage(1);
    setSearchQuery(query);
  }, 300);

  const searchInProgress = <div>{i18n.t('search_in_progress')}</div>;

  return (
    <>
      <SearchField
        onChangeAction={e => handleSearch(e.target.value)}
        placeholder={i18n.t('MICC__search_placeholder')}
        inputStyle={{ width: '100%' }}
        containerStyle={{ width: '100%' }}
      />
      {isSearching ? searchInProgress : null}
      <div>
        {products.map(product => (
          <MyItemCard key={product.id} {...product} />
        ))}
      </div>

      <Pagination currentPage={currentPage} pageCount={pageCount} setPage={setPage} />
    </>
  );
}

MyItemCardContainer.propTypes = {
  filterPath: PropTypes.string.isRequired,
  stateQuery: PropTypes.string,
};

MyItemCardContainer.defaultProps = {
  stateQuery: '',
};

export default props => (
  <Provider store={store()}>
    <StylesProvider injectFirst>
      <MyItemCardContainer {...props} />
    </StylesProvider>
  </Provider>
);
