import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getItems } from '../productListSlice';
import 'react-lazy-load-image-component/src/effects/blur.css';

const StyledProductCard = styled.div`
  /* display: flex;
  flex-direction: column; */
  padding: 30px 15px;
`;
const ImageContainer = styled.div`
  position: relative;

  margin-bottom: 20px;
`;
const Image = styled(LazyLoadImage)`
  display: block;
  width: 100%;
`;

const DetailsContainer = styled.div``;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CartButton = styled.div`
  position: absolute;
  right: -15px;
  bottom: 0;
  left: -15px;

  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => (props.visible ? '45px' : 0)};
  overflow: hidden;

  font-size: 16px;
  text-align: center;
  text-transform: uppercase;

  background: #cedecc;
  cursor: pointer;

  transition: 0.3s ease;
`;

const ProductCard = ({ id }) => {
  const product = useSelector(state => getItems(state)[id]);

  const [hover, setHover] = useState(false);
  const image = hover ? product.backImage : product.image;

  return (
    <StyledProductCard onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <ImageContainer>
        <Image width="360" height="360" src={image} placeholderSrc={product.placeholder} effect="blur" />
        <CartButton visible={hover}>+ Winkelwagen</CartButton>
      </ImageContainer>
      <DetailsContainer>
        <DetailRow>
          <div>{product.brand}</div>
          <div>∂</div>
        </DetailRow>
        <DetailRow>
          <div>{product.type}</div>
        </DetailRow>
        <DetailRow>
          <div>{product.price}</div>
          <div>{product.size}</div>
        </DetailRow>
      </DetailsContainer>
    </StyledProductCard>
  );
};

ProductCard.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ProductCard;
