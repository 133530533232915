import times from 'lodash/times';
import translateComponent from '../helpers/translateI18n';

const images = require.context('../../images/photoTips', true);
const translate = translateComponent('photoTipsAndLabels');

const getImage = (number: number, type: string) => {
  try {
    return images(`./photo_tip_${type}_${number}.jpg`);
  } catch {
    return '';
  }
};

const photosAndLabels = (type: string) => {
  const numberOfTimes = type === 'shoes' || type === 'clothing' ? 4 : 6;

  const tipsAndLabels: { label: string; image: string }[] = [];
  times(numberOfTimes, n =>
    tipsAndLabels.push({
      label: translate(`${type}PhotoTip_${n + 1}`),
      image: getImage(n + 1, type),
    })
  );
  return tipsAndLabels;
};

export { photosAndLabels };
