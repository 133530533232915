/* eslint-disable jest-dom/prefer-checked */

import React from 'react';
import { render, screen, fireEvent, act } from '@testing-library/react';
import { I18nextProvider } from 'react-i18next';

import i18n from '../../../test/i18nForTest';
import BankVerification from '../BankVerification';


const setup = () => {
  return render(
    <I18nextProvider i18n={i18n}>
      <BankVerification updateBankPath="mockPath"  />
    </I18nextProvider>
  );
};

test('It should only let you submit the form if you filled out all fields correctly', async () => {
  const a = setup();

  // step 1 is show initially
  expect(screen.getByText(/Stap 1: waarom verificatie?/i)).toBeTruthy();
  const stepOneNext = screen.getByTestId('stepOneNext');
  // accept terms and conditions
  const termsCheckbox = screen.getByText(/Ik heb bovenstaande gelezen en ga akkoord./i);
  await act(() => Promise.resolve(fireEvent.click(termsCheckbox)));
  await act(() => Promise.resolve(fireEvent.click(stepOneNext)));

  // step 2 is shown
  expect(screen.getByText(/STAP 2: UPLOAD DOCUMENT/i)).toBeTruthy();
  const stepTwoNext = screen.getByTestId('stepTwoNext');
  const radioButtonDriversLicense = screen.getByText(/Rijbewijs/);
  await act(() => Promise.resolve(fireEvent.click(radioButtonDriversLicense)));
  await act(() => Promise.resolve(fireEvent.click(stepTwoNext)));

  // For the user this is still Step 2, for us it's step 3
  expect(
    screen.getByText(/De volgende bestandstypen worden geaccepteerd voor de documenten/i)
  ).toBeTruthy();



  // Simulate file upload
  const file = new File(['(⌐□_□)'], 'chucknorris.png', { type: 'image/png' });
  const uploadFront = screen.getByTestId('fileFront');
  const uploadBack = screen.getByTestId('fileBack');
  await act(() => Promise.resolve(fireEvent.change(uploadFront, { target: { files: [file] } })));
  await act(() => Promise.resolve(fireEvent.change(uploadBack, { target: { files: [file] } })));
  // Now you can proceed to next step
  const stepThreeNext = screen.getByTestId('stepThreeNext');
  await act(() => Promise.resolve(fireEvent.click(stepThreeNext)));

  // Step 4 (step 3 for user)
  expect(screen.getByText(/STAP 3: BANKGEGEVENS/i)).toBeTruthy();

  // fill input fields
  const firstNameInput = screen.getByTestId('firstNameInput');
  const lastNameInput = screen.getByTestId('lastNameInput');
  const dayInput = screen.getByPlaceholderText('DD');
  const monthInput = screen.getByPlaceholderText('MM');
  const yearInput = screen.getByPlaceholderText('Jaar');
  const ibanInput = screen.getByPlaceholderText(/Bankrekening voor uitbetaling/i);
  const submit = screen.getByText(/bevestig/i)

  fireEvent.change(firstNameInput, { target: { value: 'firstName' } });
  fireEvent.change(lastNameInput, { target: { value: 'lastName' } });
  fireEvent.change(dayInput, { target: { value: 10 } });
  fireEvent.change(monthInput, { target: { value: 10 } });
  fireEvent.change(yearInput, { target: { value: 2000 } });
  fireEvent.change(ibanInput, { target: { value: 'NL91ABNA0417164300' } });

  await act(() => Promise.resolve(fireEvent.click(submit)));
  // no errors when submit
  expect(screen.queryByTestId('defaultError')).toBeFalsy()

});


