import styled from 'styled-components';
import { Autocomplete } from '@material-ui/lab';
import { FormControl, NativeSelect } from '@material-ui/core';

const baseStyle = `
  & .MuiInput-underline:before {
    border: none;
    content: none;
  }

  & .MuiInput-underline:after {
    border: none;
  }

  & .MuiFormControl-root {
    font-weight: 700;
    margin-bottom: 20px;
    p {
      font-weight: 700;
    }
  }
  & .MuiInputBase-input {
    font-size: 16px;
    color: #555555;
    line-height: initial;
  }

  & .MuiNativeSelect-outlined.MuiNativeSelect-outlined {
    margin-top: 10px;
    height: 45px;
    border: 1px solid #b6b6b6;
    padding: 6px 12px;
    border-radius: 0px;
  }
`;

interface Props {
  disabled: boolean;
  error?: boolean;
}

export const StyledAutoCompleteField = styled(Autocomplete)<Props>`
  ${baseStyle}
  & .MuiSvgIcon-root {
    top: -7px;
    font-size: 3.5rem;
    position: absolute;
    right: 1px;
  }
  border: ${props => (props.disabled ? '3px solid black' : '1px solid var(--medium-grey)')};
  border-bottom: ${props => props.error && '2px solid red'};
  background-color: ${props => (props.disabled ? 'var(--light-grey)' : 'transparent')};
  height: 59px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  & :hover {
    border-bottom: ${props => (props.disabled ? '3px solid black' : '2px solid black')};
  }
`;

export const StyledSelectField = styled(NativeSelect)<Props>`
  ${baseStyle}

  select {
    border: ${props =>
      props.disabled ? '3px solid black !important' : '1px solid var(--medium-grey)'};
    background-color: ${props => (props.disabled ? 'var(--light-grey)' : 'transparent')};
  }
  & .MuiSvgIcon-root {
    top: 21px;
    font-size: 3.5rem;
    right: 10px;
  }
`;

export const StyledFormControl = styled(FormControl)`
  margin-bottom: 30px !important;
`;
