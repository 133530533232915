import React from 'react';

import useModal from '../Modal/useModal';
import ConfirmModal from './ConfirmModal';

interface Props {
  confirmLink?: string;
  modalText: string;
  id?: number;
  icon?: string;
  text?: string;
  className?: string;
  iconClassName?: string;
  setItemState?: Function;
  useAxios?: boolean;
  dataMethod?: string;
  gtmEvent?: Record<string, unknown>;
  helloRetailEvent?: Record<string, unknown>;
}

const ConfirmModalTrigger: React.FC<Props> = ({
  confirmLink = undefined,
  modalText,
  id = null,
  className = '',
  icon = '',
  text = '',
  iconClassName = '',
  setItemState = null,
  useAxios = null,
  dataMethod = null,
  gtmEvent,
  helloRetailEvent,
}) => {
  const { isShowing, toggle } = useModal();

  return (
    <div>
      <div className={className} onClick={toggle}>
        {!!icon && <img src={icon} alt={text} className={iconClassName} />}
        {text}
      </div>
      <ConfirmModal
        isShowing={isShowing}
        hide={toggle}
        id={id}
        confirmLink={confirmLink}
        setItemState={setItemState}
        modalText={modalText}
        useAxios={useAxios}
        dataMethod={dataMethod}
        gtmEvent={gtmEvent}
        helloRetailEvent={helloRetailEvent}
      />
    </div>
  );
};

export default ConfirmModalTrigger;
