import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import LazyLoad from 'react-lazyload';

import SlickCard from './SlickCard';
import SliderArrow from './SliderArrow';
import './style/SlickSlider.scss';

function SlickSlider({ cards, fallbackImage, reverseOrder }) {
  const [leftArrowHidden, setLeftArrowHidden] = useState(true);

  const cardsForSlider = reverseOrder ? cards.reverse() : cards

  const getImage1x = card => (card.background1x ? card.background1x : fallbackImage);
  const getImage2x = card => (card.background2x ? card.background2x : fallbackImage);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 2,
    initialSlide: 0,
    swipeToSlide: false,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
    ],
    prevArrow: (
      <SliderArrow to="vorige" className="slick-arow slick-prev test" hidden={leftArrowHidden} />
    ),
    nextArrow: <SliderArrow to="volgende" className="slick-arow slick-next test" />,
    beforeChange: (oldIndex, newIndex) => {
      setLeftArrowHidden(newIndex === 0);
    },
  };

  return (
    <div>
      <LazyLoad debounce={false} offsetVertical={250}>
        <Slider {...settings}>
          <div />
          {cardsForSlider.map(card => (
            <div key={card.id}>
              <SlickCard
                className="slider__card"
                title={card.name}
                background1x={getImage1x(card)}
                background2x={getImage2x(card)}
                link={card.link}
                textClassName="slider__card__text"
                text={card.text}
                targetBlank={card.targetBlank}
              />
            </div>
          ))}
        </Slider>
      </LazyLoad>
    </div>
  );
}

SlickSlider.propTypes = {
  cards: PropTypes.array.isRequired,
  fallbackImage: PropTypes.string,
  textClassName: PropTypes.string,
  reverseOrder: PropTypes.bool
};

SlickSlider.defaultProps = {
  fallbackImage: null,
  textClassName: null,
  reverseOrder: false
};

export default SlickSlider;
