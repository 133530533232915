import React, { useState } from 'react';
import { Provider, useDispatch } from 'react-redux';

import menuIcon from '@images/icons/menu.svg';

import * as S from './styled/styledMobileNavMenu';
import InnerMenu from './InnerMenu';
import { NavigationProps } from './NavigationOptions';
import ExternalSearchIcon from './ExternalSearchIcon';
import useWindowWidth from '../helpers/hooks/useWindowWidth';
import store from '../../src/store';
import { setShowZendeskButton } from '../Header/headerSlice';

const Navigation: React.FC<NavigationProps> = ({
  options,
  currentUser,
  highlightedCollectionDescription
}) => {
  const width = useWindowWidth();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
    dispatch(setShowZendeskButton(false));
    document.body.style.overflow = 'hidden'; // hide content behind menu to prevent scroll body
  };

  return width < 992 ? (
    <S.MenuIconContainer>
      <InnerMenu
          open={open}
          setOpen={setOpen}
          options={options}
          currentUser={currentUser}
          highlightedCollectionDescription={highlightedCollectionDescription}
        />
      <div className="mobile-navigation-options-holder">
        <S.StyledBurger
          onClick={handleClick}
          aria-label="toggle menu"
          aria-controls="simple-menu"
          aria-haspopup="true"
        >
          <img src={menuIcon} alt="burger icon" />
        </S.StyledBurger>
        <ExternalSearchIcon
          setOpen={setOpen}
        />
      </div>
    </S.MenuIconContainer>
  ) : null;
};

export default (props: JSX.IntrinsicAttributes & NavigationProps) => (
  <Provider store={store()}>

    <Navigation {...props} />
  </Provider>
);
