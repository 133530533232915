import { screen, fireEvent} from '@testing-library/react';

import initialData from './initialFilterData';
import setup from  './initialFilterSetup';
import * as productFilterActions from '../../productFilterSlice';

test('should filter on only premium items', () => {
  setup(initialData);

  const fetchProducts = jest
    .spyOn(productFilterActions, 'fetchProducts').mockReturnValue(() => {
    });

  // checks the text changes on the button and if new products are fetched
  const premiumButton = screen.getByText('Shop premium')
  expect(premiumButton).toBeTruthy()
  fireEvent.click(premiumButton)

  expect(screen.getByText('Shop alles')).toBeTruthy()
  expect(screen.queryByText('Shop premium')).toBeFalsy()

  expect(fetchProducts).toHaveBeenCalledTimes(1);
})
