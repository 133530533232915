import styled from 'styled-components';

export const CounterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 20px;
  flex-direction: column;
`;

export const Counter = styled.div`
font-size: 14px;`;

export const HideSoldToggle = styled.div`
  margin-top: 6px;
`;

export const NoProductsTextWrapper = styled.div`
  padding: 50px 15px 0;
  width: 75%;
  @media (max-width: 1399px) {
    padding: 50px 50px 0;
  }
`;

export const NoProductsTitle = styled.div`
  font-size: 48px;
`;

export const RemoveFiltersContainerMobile = styled.div`
  padding-left: 6px;
  padding-right: 6px;
`;
