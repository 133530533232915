import React from "react";
import { useForm } from "react-hook-form";
import mobileSearchIcon from '@images/icons/mobile_search.svg';
import { useTranslation } from 'react-i18next';
import PopularSearches from "../PopularSearches";
import closeIcon from '@images/icons/close-x.svg';

interface SearchFormInput {
  search: string;
}

const Search : React.FC = () => {
  const { register, handleSubmit } = useForm<SearchFormInput>();

  const onSubmit = (form: SearchFormInput) =>
    document.location.href = '/items?search=' + form.search;
  const { t, i18n } = useTranslation();
  const [isHiden, setIsHiden] = React.useState(false);

  return (
    <div id="mobile-search-navbar" className="">
      <form onSubmit={handleSubmit(onSubmit)} className="navbar-form">
        <div className="input-group" id="mobile-search-bar-nav">
          <div className="input-group-btn">
            <div className="form-group">
              <button name="button" type="submit" className="search-button">
                <img src={mobileSearchIcon} alt="Search" id="search-icon" />
              </button>
            </div>
          </div>
          <div className="form-group">
            <input {...register("search")} onFocus={() => setIsHiden(false)} type="text" autoComplete="off" name="search" id="search" className="searchinput" placeholder={i18n.t('MICC__search_placeholder')}>
            </input>
          </div>
        </div>
        {!isHiden &&
        <div className="sugestions">
          <PopularSearches />
          <img src={closeIcon} onClick={() => setIsHiden(true)} alt="Close button" className="close-popular" />
        </div>}
      </form>
    </div>
  )
}

export default Search;
