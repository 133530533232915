import isHoliday from './isHoliday';

export default function workingHours() {
  const now = new Date();
  const hours = now.getHours();
  const day = now.getDay();

  // returns boolean if at this moment we live during working hours
  return day >= 1 && day <= 5 && hours >= 9 && hours < 17 && !isHoliday(now);
}

workingHours();
