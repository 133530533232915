import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import Spinner from '../../global/Spinner';

import { userSchemaLogIn } from '../userSchema';
import * as S from '../styled/StyledLoginSignup';
import { 
  StyledFormError,
  StyledFormErrorFacebook,
  StyledField
} from '../../StyledGlobal/StyledForm';
interface LogInProps {
  googleLogInPath: string;
  facebookLogInPath: string;
  appleLogInPath: string;
  loginPath: string;
  newAccountPath: string;
  newPasswordPath: string;
  isAndroidWebView: boolean;
}

const intialState = {
  email: '',
  password: '',
  remember_me: '1',
};

const authToken = document?.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

const LogInForm: React.FC<LogInProps> = ({
  googleLogInPath,
  facebookLogInPath,
  appleLogInPath,
  loginPath,
  newAccountPath,
  newPasswordPath,
  isAndroidWebView,
}) => {
  const [user, setUser] = useState(intialState);
  const [logInError, setLogInError] = useState(false);
  const [facebookDisabled, setFacebookDisabled] = useState(false);
  const { i18n } = useTranslation();

  const translate = (text: string) => {
    return i18n.t(`Auth.${text}`);
  };

  const showNotSupportedMessage = () => {
    setFacebookDisabled(true)
  }

  const renderFacebookBtn = (facebookLogInPath, translate) => {
    if(isAndroidWebView) {
      return(
        <S.FbButton onClick={() => showNotSupportedMessage(translate)}>
          <S.FbLogo />
          {translate('facebook_log_in')}
        </S.FbButton>
      )
    } else {
      return(
        <S.FbButton href={facebookLogInPath}>
          <S.FbLogo />
          {translate('facebook_log_in')}
        </S.FbButton>
      )
    }
  }

  return (
    <>
      <Formik
        initialValues={user}
        onSubmit={(values, actions) => {
          const userData = {
            user: values,
            authenticity_token: authToken,
          };
          actions.setSubmitting(true);
          setUser(values);

          axios
            .post(loginPath, userData, {
              headers: { Accept: 'application/json' },
            })
            .then(res => {
              if (res !== undefined && res.data.redirectPath) {
                window.location.replace(res.data.redirectPath);
              }
            })
            .catch(error => {
              actions.setSubmitting(false);
              setLogInError(error.response.data.error);
            });
        }}
        validationSchema={userSchemaLogIn}
      >
        {({ values, handleSubmit, isSubmitting, handleChange, errors, touched, dirty }) => (
          <S.Form onSubmit={handleSubmit}>
            { renderFacebookBtn(facebookLogInPath, translate) }
            {
              facebookDisabled && 
                <StyledFormErrorFacebook>{translate('facebook_login_not_supported')}</StyledFormErrorFacebook>
            }
            <S.GmailButton href={googleLogInPath}>
              <S.GmailLogo />
              {translate('gmail_log_in')}
            </S.GmailButton>

            <S.AppleButton href={appleLogInPath}>
              <S.AppleLogo />
              {translate('apple_log_in')}
            </S.AppleButton>

            <S.Divider>{translate('or')}</S.Divider>

            <S.FieldWrapper>
              <Field
                type="email"
                placeholder={i18n.t('email')}
                onChange={handleChange}
                name="email"
                value={values.email}
                as={StyledField}
              />
              {errors.email && touched.email ? (
                <StyledFormError>{i18n.t(errors.email)}</StyledFormError>
              ) : (
                ''
              )}
            </S.FieldWrapper>

            <S.FieldWrapper>
              <Field
                type="password"
                onChange={handleChange}
                name="password"
                value={values.password}
                placeholder={i18n.t('password')}
                as={StyledField}
              />
              {errors.password && touched.password ? (
                <StyledFormError>{i18n.t(errors.password)}</StyledFormError>
              ) : (
                ''
              )}
            </S.FieldWrapper>
            {logInError ? <StyledFormError>{logInError} </StyledFormError> : ''}
            <S.Link href={newPasswordPath}>{translate('forgot_password')}</S.Link>

            <S.LoginLink>
              {translate('no_account_yet')}
              <S.Link href={newAccountPath}>{translate('sign_up')}</S.Link>
            </S.LoginLink>

            <S.FButton type="submit" disabled={!dirty && isSubmitting}>
              {translate('log_in').toUpperCase()}
            </S.FButton>

            {isSubmitting ? <Spinner /> : ''}
          </S.Form>
        )}
      </Formik>
    </>
  );
};

export default LogInForm;
