import React from 'react';

import ExpandMore from '@material-ui/icons/ExpandMore';
import { Field, FieldProps } from 'formik';
import FormError from './FormError';
import { StyledSelectField, StyledFormControl } from './styled/styledMaterial';
import useWindowWidth from '../helpers/hooks/useWindowWidth';

interface MaterialSelectFieldProps {
  name: string;
  disabled?: boolean;
  hidden?: boolean;
  selectOptions: JSX.Element[];
  labelText?: string;
  placeholderText: string;
  onClick?: () => void | null;
  toolTip?: JSX.Element | null;
  defaultValue?: string | null;
}

const MaterialSelectField: React.FC<MaterialSelectFieldProps> = ({
  name,
  selectOptions,
  disabled = false,
  hidden = false,
  labelText,
  placeholderText,
  onClick = null,
  toolTip = null,
  defaultValue = null,
}): JSX.Element => {
  const width = useWindowWidth();
  const SMALL_SCREEN = width < 768;

  return (
    <span hidden={hidden} data-testid={`${name}.wrapper`}>
      <StyledFormControl fullWidth>
        <div id={`${name}-label`}>
          {labelText}
          {SMALL_SCREEN && toolTip}
        </div>
        <Field name={name}>
          {({ field: { value, onChange, onBlur }, meta: { error, touched } }: FieldProps) => (
            <StyledSelectField
              IconComponent={ExpandMore}
              error={!!error && touched}
              variant="outlined"
              value={defaultValue || value}
              onChange={onChange}
              onBlur={onBlur}
              onClick={onClick}
              name={name}
              disabled={disabled}
              fullWidth
              data-testid={name}
            >
              <option value="" disabled>
                {placeholderText}
              </option>
              {selectOptions}
            </StyledSelectField>
          )}
        </Field>
        <FormError name={name} largeError />
      </StyledFormControl>
    </span>
  );
};

export default MaterialSelectField;
