import React from 'react';
import * as S from '../styled/styledPCtypeC';

// show sale badge if original price, is on sale and sale price is minimal 40% lower than original and not sold
function getSaleBadge(originalPrice: number, currentPrice: string , checkIfSold: boolean): JSX.Element | void {
  const curPriceInteger = parseInt(currentPrice.replace(/\D/g, ''), 10);
  const discountPercentage = Math.round((1 - curPriceInteger / originalPrice) * 100);
  if (discountPercentage >= 70 && !checkIfSold) {
    return <S.Sale data-testid="sale-percentage">- {discountPercentage}%</S.Sale>;
  }

}

function getPremiumBadge(checkIfPremium: boolean, text: string): JSX.Element | void {
  if (checkIfPremium) {
    return <S.Premium data-testid="premium" text={text}/>;
  }
}

function getSustainableIcon(checkIfSustainable: boolean) : JSX.Element | void {
  if (checkIfSustainable) {
    return <S.Leaf data-testid="sustainable" />;
  }
}

function getOriginalPrice(originalPrice: number) {
  if (originalPrice > 0) {
    return (
      <S.PriceOrig data-testid="original-price">{`€${originalPrice}`}</S.PriceOrig>
    );
  }
}

export { getSaleBadge, getPremiumBadge, getSustainableIcon, getOriginalPrice };
