import React from 'react';

import facebookIcon from '@images/icons/facebook.svg';
import instagramIcon from '@images/icons/instagram.svg';
import tiktokIcon from '@images/icons/tiktok.svg';
import youtubeIcon from '@images/icons/youtube.svg';

import * as S from './styled/styledFooter';

const SocialMediaContainer = () => {
  return (
    <S.IconContainer>
      <a href="https://www.facebook.com/thenextcloset" target="_blank" rel="noreferrer" >
        <img src={facebookIcon} alt="Facebook logo" />
      </a>
      <a href="https://instagram.com/thenextcloset" target="_blank" rel="noreferrer">
        <img src={instagramIcon} alt="Instagram logo" />
      </a>
      <a href="https://www.tiktok.com/tag/thenextcloset" target="_blank" rel="noreferrer">
        <img src={tiktokIcon} alt="TikTok logo" />
      </a>
      <a href="https://www.youtube.com/channel/UCk3b7IA_cUY4090btW8419A/videos" target="_blank" rel="noreferrer">
        <img src={youtubeIcon} alt="Youtube logo" />
      </a>
    </S.IconContainer>
  );
};

export default SocialMediaContainer;
