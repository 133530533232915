import React from 'react';

import './style/Modal.scss';
import { StyledModal } from './styled/styledModal';

interface ModalProps {
  isShowing: boolean;
  hide: Function;
  body: Object;
  id?: string;
  maxWidth?: string;
  changePadding?: string;
  changeHeight?: string;
  changeWidth?: string;
}

const Modal: React.FC<ModalProps> = ({
  isShowing,
  hide,
  body,
  id,
  maxWidth = null,
  changePadding = null,
  changeHeight = null,
  changeWidth = null,
}) => {
  StyledModal.setAppElement('body');

  return (
    <StyledModal
      isOpen={isShowing}
      onRequestClose={() => hide()}
      key={id}
      maxWidth={maxWidth}
      changeHeight={changeHeight}
      changeWidth={changeWidth}
      changePadding={changePadding}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      {body}
    </StyledModal>
  );
};

export default Modal;
