import styled from 'styled-components';
import { LargeBoldHeader } from '../../StyledGlobal/StyledFont';
import screen from '../../StyledGlobal/mediaQueries';
import { Theme } from '../../../services/theme';

import clothingImage from '@images/about_us/impact_designer_fashion.svg';
import earthImage from '@images/about_us/impact_earth.svg';
import bathTubsImage from '@images/about_us/impact_bathtub.svg';

export const Subtitle = styled.div`
  margin: 17px 0 48px 0;
  width: 557px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  ${screen.smMax`width: 100%`}
`;

export const CardHeader = styled.div`
  width: 421px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 24px;
  ${screen.smMax`
    width: 100%;
  `}
`;

export const Container = styled.div`
  align-items: center;
  width: fit-content;
  ${screen.xsMax`
    height: 465px; 
    width: 370px;
  `}
`;

export const BorderRight = styled.div`
  border-right: 1px solid rgba(50, 50, 50, 0.05);
`;

export const SectionContainer = styled.div`
  display: flex;
  background-color: ${Theme.colors.offWhite};
`;

export const Title = styled(LargeBoldHeader)`
  margin: 0;
  ${screen.xsMax`
    line-height: 48px;
  `}
`;

export const Wrapper = styled.div`
  position: relative;
  padding: 40px 50px;
  height: 418px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  ${screen.smMax`
    margin: 0 10px;
    padding: 10px 20px;
    height: 383px;
  `}
`;

export const ImpactNumber = styled.div`
  font-size: 36px;
  font-weight: 600;
  line-height: 55px;
  padding-bottom: 8px;
`;

export const Text = styled.p`
  font-size: 14px;
  text-align: left;
  padding-bottom: 10px;
  line-height: 21px;
  margin-bottom: 0;
  width: 187px;
  ${screen.smMax`
    font-size: 16px;
    line-height: 28px;
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 25px;
  padding-top: 44px;
  ${screen.xsMax`
    height: auto;
    padding-top: 40px;
  `}
`;

export const co2IconContainer = styled(IconContainer)`
  padding-top: 24px;
  ${screen.xsMax`
    padding-top: 15px;
  `}
`;

export const BathTubsImg = styled.img.attrs({ src: bathTubsImage })`
  height: 80px;
`;

export const EarthImg = styled.img.attrs({ src: earthImage })`
  height: 98px;
`;

export const ClothingImg = styled.img.attrs({ src: clothingImage })`
  height: 80px;
`;

export const Span = styled.span`
  font-size: 14px;
  position: absolute;
  bottom: 103px;
  padding-left: 7px;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${Theme.colors.offWhite};
`;

export const RoundButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  padding: 2% 4%;
  font-size: 14px;
  border: 1px solid black;
  border-radius: 50px;
  margin: 36px 0;

  &:hover {
    background-color: white;
    cursor: pointer;
    opacity: 0.7;
  }
  &:focus {
    color: black;
  }
`;

export const CardTitle = styled.span`
  font-size: 16px;
  padding-top: 40px;
  line-height: 25px;
`;
