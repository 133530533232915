import React, { FunctionComponent, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { iOS } from '../../services/helpers';

import * as S from './styled/styledPCtypeC';


import {
  getSaleBadge,
  getPremiumBadge,
  getSustainableIcon,
  getOriginalPrice,
} from './helpers/helpersTypeC';
import ImageLoader from '../ImageLoader';
import ShoppingCartButton from '../ShoppingCartModal/ShoppingCartButton';
import FollowProduct from '../Follow/FollowProduct';

export interface ProductCardProps {
  image: string;
  imageLarge: string;
  isPremium: boolean;
  rootTypeEN?: string;
  type: string;
  typeEN: string;
  price: [boolean, string];
  priceWithoutCurrency: number;
  origPrice: number;
  tncPrice: number;
  size?: string;
  backImage: string;
  imageAlt: string;
  ProductPageUrl: string;
  sold: boolean;
  levelRecommendation: string;
  addToBagLink: string;
  brand: string;
  isInShoppingbag: boolean;
  linkToCart: string;
  isSustainable: boolean;
  id: string;
  linkToOrder?: string;
  topSeller: boolean;
  displayName: string;
  gtmEvent?: Record<string, unknown>;
  color: string;
  material: string;
  sellerType: string;
  condition: string;
  seller_country: undefined;
  isTrustedSeller: boolean;
}

const ProductCardTypeC: FunctionComponent<ProductCardProps> = ({
  image,
  imageLarge,
  isPremium,
  rootTypeEN,
  type,
  typeEN,
  price,
  priceWithoutCurrency,
  origPrice,
  tncPrice,
  size,
  backImage,
  imageAlt,
  ProductPageUrl,
  sold,
  levelRecommendation,
  addToBagLink,
  brand,
  isInShoppingbag,
  linkToCart,
  isSustainable,
  id,
  topSeller,
  displayName,
  color,
  material,
  sellerType,
  condition,
  seller_country,
  isTrustedSeller
}) => {
  const { i18n } = useTranslation();

  const isMobile = window.innerWidth < 768;

  const [imageShown, setImage] = useState(image);
  const [hovered, setHovered] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [inCart, setInCart] = useState(isInShoppingbag);

  const showAddToShoppingBag = (
    <ShoppingCartButton
      buttonFor="IndexPage"
      slideInView={showButton}
      setInCart={setInCart}
      isInCart={inCart}
      product={{
        brand,
        rootTypeEN,
        type,
        typeEN,
        price,
        priceWithoutCurrency,
        origPrice,
        tncPrice,
        image,
        imageAlt,
        ProductPageUrl,
        size,
        isPremium,
        addToBagLink,
        id,
        linkToCart,
        color,
        material,
        sellerType,
        condition,
      }}
    />
  );

  const getSold = hovered ? (
    <S.SoldHover data-testid="sold-hover">
      <p className="h__f--l--b">{i18n.t('soldBackTitle')}</p>
      <ReactMarkdown>{i18n.t('soldBackText')}</ReactMarkdown>
    </S.SoldHover>
  ) : (
    <S.Sold className="h__f--l--b" data-testid="sold">
      {i18n.t('sold').toUpperCase()}
    </S.Sold>
  );

  const addSoldParameterToProductPageUrl = () => {
    if (sold === true) {
      return ProductPageUrl + '#sold';
    } else {
      return ProductPageUrl;
    }
  };

  const getSize = size ? <S.Size data-testid="size">{size}</S.Size> : '';

  // Change the background image on hover
  function handleMouseEnter() {
    setImage(backImage);
    setShowButton(!showButton);
  }

  function handleMouseLeave() {
    setImage(image);
    setShowButton(!showButton);
  }

  const toggleHover = () => (!isMobile ? setHovered(!hovered) : '');

  const resolveBadge = () => {
    if (isTrustedSeller) {
      return getPremiumBadge(isTrustedSeller, 'Trusted Seller');
    } else if (isPremium) {
      return getPremiumBadge(isPremium, 'Premium');
    } else {
      return <div></div>;
    }
  };

  const renderContent = () => {
    return(
      <>
        {/*  check if topseller, if not check ik sustainable */}
        {topSeller ? (
          <S.TopSeller className="growthTopSeller hidden" />
        ) : (
          getSustainableIcon(isSustainable)
        )}
        <S.FlexSpaced className="badges-wrapper">
          {resolveBadge()}
          {!sold ? (
            <FollowProduct
              iconFor="ProductCard"
              id={parseInt(id, 10)}
              sellerType={isPremium ? 'premium' : 'diy'}
              productType={typeEN}
              brand={brand}
              price={priceWithoutCurrency}
              color={color}
              material={material}
              condition={condition}
            />
          ) : (
            ''
          )}
        </S.FlexSpaced>
        <a href={addSoldParameterToProductPageUrl()} data-gtm_item_id={id}>
          {sold ? getSold : ''}
          {/* {getPremiumBadge(isPremium)} */}
          <S.ImageWrapper data-testid="lazyload">
            <LazyLoad debounce={undefined} offset={250}>
              <ImageLoader src={imageShown} altSrc={imageLarge} alt={imageAlt} />
            </LazyLoad>
          </S.ImageWrapper>
        </a>
        <S.InfoWrapper>
          {!sold && !isMobile ? showAddToShoppingBag : ''}
          <a href={addSoldParameterToProductPageUrl()} data-gtm_item_id={id}>
            <S.FlexSpaced>
              <S.Brand>{brand.toUpperCase()}</S.Brand>
            </S.FlexSpaced>
            <S.Flex>
              <S.Type>{displayName}</S.Type>
            </S.Flex>
          </a>
          <S.FlexSpaced>
            <div>
              <S.Price red={price[0]}>{price[1]}</S.Price>
              {getOriginalPrice(price[0] ? tncPrice : origPrice)}
            </div>
            <S.Price red={false}>{getSize}</S.Price>
          </S.FlexSpaced>
        </S.InfoWrapper>
      </>
    )
  }

  return (
    <>
      { iOS() ?
        <S.Wrapper
          data-recommendation={levelRecommendation}
          data-testid="product-card-wrapper"
        >
          { renderContent() }
        </S.Wrapper> :
        <S.Wrapper
          onMouseEnter={sold ? toggleHover : handleMouseEnter}
          onMouseLeave={sold ? toggleHover : handleMouseLeave}
          data-recommendation={levelRecommendation}
          data-testid="product-card-wrapper"
        >
          { renderContent() }
        </S.Wrapper>
      }
    </>
  );
};

export default ProductCardTypeC;
