/* eslint-disable import/no-unresolved */
/* eslint-disable import/first */
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import {} from 'jquery-ujs';
import 'select2';

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

import 'src/analytics';
import 'src/countDown';
import 'src/diy';
import 'src/helpers/toolTip';
import 'src/header';
import 'src/home';
import 'src/I18n';
import 'src/myItems';
import 'src/navbar';
import 'src/ourStory';
// import 'src/popUp'; moved back to asset pipe because of jquery loading
// productsJS is the new file, all new code here. The JQ is the old Jquery file
import 'src/productsJQ';
import 'src/productsJS';
import 'src/sentry';
import 'src/talkjs';
import 'src/truncate';
import 'src/userData';
import 'src/usersJQ';
import 'src/usersJS';

