/* eslint-disable jest-dom/prefer-checked */

import React from 'react';
import { render, screen, fireEvent, act } from '@testing-library/react';
import { I18nextProvider } from 'react-i18next';
import { goToStepFour } from './utils';

import i18n from '../../../test/i18nForTest';
import BankVerification from '../BankVerification';

const setup = () => {
  return render(
    <I18nextProvider i18n={i18n}>
      <BankVerification updateBankPath="mockPath" />
    </I18nextProvider>
  );
};

const getSubmit = () => {
  return screen.getByText(/bevestig/i);
};

test('Should throw six errors if no fields are filled out before submit', async () => {
  setup();
  await goToStepFour();
  const submit = getSubmit();
  await act(() => Promise.resolve(fireEvent.click(submit)));
  expect(screen.getAllByTestId('defaultError')).toHaveLength(6);
});

test('Should throw error for invalid IBAN and remove error when valid iban is provided', async () => {
  setup();
  await goToStepFour();
  const submit = getSubmit();
  const ibanInput = screen.getByPlaceholderText(/Bankrekening voor uitbetaling/i);
  fireEvent.change(ibanInput, { target: { value: 'NL91ABNA041710' } });
  await act(() => Promise.resolve(fireEvent.click(submit)));

  fireEvent.change(ibanInput, { target: { value: 'NL91ABNA0417164300' } });
  await act(() => Promise.resolve(fireEvent.click(submit)));
  expect(screen.queryByText(/iban is ongeldig/i)).toBeFalsy();
});

test('Should throw error for invalid IBAN and remove error when valid iban is provided', async () => {
  setup();
  await goToStepFour();
  const submit = getSubmit();
  const ibanInput = screen.getByPlaceholderText(/Bankrekening voor uitbetaling/i);

  fireEvent.change(ibanInput, { target: { value: 'NL91ABNA041710' } });
  await act(() => Promise.resolve(fireEvent.click(submit)));
  expect(screen.getByText(/iban is ongeldig/i)).toBeTruthy();

  fireEvent.change(ibanInput, { target: { value: 'NL91ABNA0417164300' } });
  await act(() => Promise.resolve(fireEvent.click(submit)));
  expect(screen.queryByText(/iban is ongeldig/i)).toBeFalsy();
});

test('Should throw error because user is too young and remove error when user old enough', async () => {
  setup();
  await goToStepFour();
  const submit = getSubmit();
  const dayInput = screen.getByPlaceholderText('DD');
  const monthInput = screen.getByPlaceholderText('MM');
  const yearInput = screen.getByPlaceholderText('Jaar');

  fireEvent.change(dayInput, { target: { value: 1 } });
  fireEvent.change(monthInput, { target: { value: 1 } });
  fireEvent.change(yearInput, { target: { value: 2010 } });
  await act(() => Promise.resolve(fireEvent.click(submit)));
  expect(screen.getByText(/minimumleeftijd is 18 jaar/i)).toBeTruthy();

  fireEvent.change(yearInput, { target: { value: 2000 } });
  await act(() => Promise.resolve(fireEvent.click(submit)));
  expect(screen.queryByText(/minimumleeftijd is 18 jaar/i)).toBeFalsy();
});
