import _ from "lodash";
import React from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next";
import * as S from "./styled/ListingPageDescription"

interface categoryData {
  topLevelCategory: { name: string, items: number },
  secondLevelCategory: { name: string, items: number } | null,
  subCategories: string[] | null,
  averagePrice: { value: string, items: number },
  condition: { value: string, items: number }[],
  designers: string[] | null,
  designerLevelCategory: { name: string, items: number } | null,
  sale: { value: string, items: number },
  category: string,
}

interface props {
  productsInfo: {
    dataTop: categoryData,
  };
}

const ListingPageDescription: React.FC<props> = ({ productsInfo }) => {
  const data = productsInfo.dataTop

  const displayStringArray = (arr: Array<string>) => {
    let designersText = ""
    arr.forEach((item, index) => {
      if (arr.length - 2 === index) {
        designersText += ` ${item} and`
      } else if (arr.length - 1 === index) {
        designersText += ` ${item}`
      } else {
        designersText += ` ${item}, `
      }
    })
    return designersText
  }
  const displayConditionText = (condition: string) => {
    switch (condition) {
      case "new-with-price-tag":
        return "new with a price tag";
      case "as-good-as-new":
        return "as good as new";
      default:
        return "";
    }
  }
  const displayAverageValue = (value: string) => {
    return Math.round(parseFloat(value)).toFixed(0)
  }
  const getCondition = (conditionArray: categoryData["condition"], conditionValue: string) => {
    return conditionArray.find((c) => c.value === conditionValue) || { items: 0, value: conditionValue }
  }
  const displayDescription = () => {
    switch (data?.category) {
      case "top-level":
        return `The Next Closet houses the top branded authentic ${data.topLevelCategory.name} by 
        designers such as${displayStringArray(Array.isArray(data.designers) ? data.designers : [])}. 
        With a selection of ${data.topLevelCategory.items} second hand ${data.topLevelCategory.name} 
        including${displayStringArray(data.subCategories || [])} to choose from, the offering caters 
        for anyone's pocket - choose from ${data.averagePrice.items} items under €${displayAverageValue(data.averagePrice.value)}. 
        Our inventory includes ${getCondition(data.condition, "new-with-price-tag").items} ${data.topLevelCategory.name} that are 
        ${displayConditionText(getCondition(data.condition, "new-with-price-tag").value)} available, 
        of which there are ${data.sale.items} designer items with a ${data.sale.value} discount applied. 
        At The Next Closet, we offer sustainable fashion for the customer who enjoys vintage goods.`;
      case "second-level":
        return `The Next Closet houses the top branded authentic ${data.secondLevelCategory?.name} by designers 
        such as${displayStringArray(Array.isArray(data.designers) ? data.designers : [])}. Available to you is a 
        selection of ${data.secondLevelCategory?.items} second hand ${data.secondLevelCategory?.name} to choose from 
        with ${data.averagePrice.items} items priced under €${displayAverageValue(data.averagePrice.value)}. Our inventory includes 
        ${getCondition(data.condition, "new-with-price-tag").items} ${data.secondLevelCategory?.name} that are 
        ${displayConditionText(getCondition(data.condition, "new-with-price-tag").value)}, of which there are 
        ${data.sale.items} designer items with a ${data.sale.value} discount applied. At The Next Closet, we offer sustainable 
        fashion for the customer who enjoys vintage goods.`;
      case "category-designer":
        return `The Next Closet is famously known to bring authentic, well-known designer pieces in a variety of items, 
        such as ${data.designerLevelCategory?.name}, to consumers who enjoy a vintage piece or two. Currently, there is a 
        selection of ${data.designerLevelCategory?.items} pairs of ${data.designers} ${data.designerLevelCategory?.name}, 
        ${data.averagePrice.items} of the total pairs are under €${displayAverageValue(data.averagePrice.value)} and 
        ${getCondition(data.condition, "as-good-as-new").items} are ${displayConditionText(getCondition(data.condition, "as-good-as-new").value)}. 
        These pieces are as unique as they are vintage - ${data.sale.items} pairs are designer items with ${data.sale.value} 
        discount. Sustainable fashion has become a term most fashion-conscious individuals are relating more to and the 
        second-hand ${data.designers} ${data.designerLevelCategory?.name} we have for sale have never been more enticing.`;
      default:
        return ""
    }
  }
  const i18n = useTranslation();
  const [truncatedDescription, setTruncatedDescription] 
    = useState(
      displayDescription().length > 230 ?
       `${displayDescription().substring(0, 230)}... ` 
       : displayDescription()
      )
  const [readMoreActive, setReadMoreActive] = useState(displayDescription().length > 230)
  const truncate = (event: React.MouseEvent) => {
    event.preventDefault()
    setReadMoreActive(false)
    setTruncatedDescription(displayDescription())
  }
  return (
    <S.StyledListingPageDescription>
      {truncatedDescription}
      {readMoreActive &&
        <a href="#" className="link--underline" onClick={(e) => truncate(e)}>
          {i18n.t('brandDescriptionReadMore')}
        </a>
      }
    </S.StyledListingPageDescription>
  )
}

export default ListingPageDescription;