import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setSort, fetchProducts } from '../productFilterSlice';

const SortButton = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation();

  const handleSortBy = (sortOption: string) => {
    dispatch(setSort({ sortOption }));
    dispatch(fetchProducts());
  };

  // check if the user searched so we can hide the sortby in this case
  const searchParams = new URLSearchParams(window.location.search).get('search');

  // TODO: rewrite to styled components

  const sortEnabled = (
    <div className="filter-button-container dropdown">
      <a className="dropdown-toggle sort-button flex id_sort_links" data-toggle="dropdown">
        <p>{i18n.t('MFMC__sort')}</p>
      </a>

      <ul id="mobile-sort-links" className="dropdown-menu sort-links">
        <li>
          <a className="sort-link" onClick={() => handleSortBy('approved_at desc')}>
            {i18n.t('HSPC__new')}
          </a>
        </li>
        <li>
          <a className="sort-link" onClick={() => handleSortBy('price asc')}>
            {i18n.t('HSPC__price_desc')}
          </a>
        </li>
        <li>
          <a className="sort-link" onClick={() => handleSortBy('price desc')}>
            {i18n.t('HSPC__price_asc')}
          </a>
        </li>
        <li>
          <a className="sort-link" onClick={() => handleSortBy('favs desc')}>
            {i18n.t('HSPC__most_popular')}
          </a>
        </li>
      </ul>
    </div>
  );

  const sortDisabled = (
    <div className="filter-button-container">
      <p className="light-grey">{i18n.t('MFMC__sort')}</p>
    </div>
  );

  return searchParams ? sortDisabled : sortEnabled;
};

export default SortButton;
