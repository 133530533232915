import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled/styledUPP';
import { PreviousButtonProps } from './UploadProductPageTypes';

const PreviousButton: React.FC<PreviousButtonProps> = ({ previousStep, setFormProgress }) => {
  // hooks
  const i18n = useTranslation();

  const handleClick = (goToStep: string): void => {
    setFormProgress(goToStep);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <S.PreviousButton
      type="button"
      onClick={() => {
        handleClick(previousStep);
      }}
    >
      {i18n.t('previous')}
    </S.PreviousButton>
  );
};

export default PreviousButton;
