import i18next from 'i18next';
import React from 'react';

import * as S from './styled/StyledSizingTable';

interface ShoeSize {
  eu: number;
  us: number;
  uk: number;
}

const womenShoeSizes = [
  { eu: 35, us: 4, uk: 2 },
  { eu: 36, us: 5, uk: 3 },
  { eu: 37, us: 6, uk: 4 },
  { eu: 38, us: 7, uk: 5 },
  { eu: 39, us: 8, uk: 6 },
  { eu: 40, us: 9, uk: 7 },
  { eu: 41, us: 10, uk: 8 },
  { eu: 42, us: 11, uk: 9 },
];

const menShoeSizes = [
  { eu: 41, us: 8.5, uk: 7.5 },
  { eu: 42, us: 9, uk: 8 },
  { eu: 43, us: 10, uk: 9 },
  { eu: 44, us: 10.5, uk: 9.5 },
  { eu: 45, us: 11.5, uk: 10 },
  { eu: 46, us: 12, uk: 11 },
  { eu: 47, us: 13, uk: 12 },
];

const buildShoeTable = (shoeSizes: ShoeSize[]) => {
  return shoeSizes.map((sizeItem) => {
    return (
      <tr>
        <S.Td>{sizeItem.eu}</S.Td>
        <S.Td>{sizeItem.us}</S.Td>
        <S.Td>{sizeItem.uk}</S.Td>
      </tr>
    );
  });
};

const ClothingSizeTable = () => (
  <>
    <S.SizeTableTitle>{i18next.t('toolTipSizeTableTitle')}</S.SizeTableTitle>
    <S.Table>
      <tbody>
        <S.TrTitle>
          <S.ThBig>S/M/L</S.ThBig>
          <S.Th>EU</S.Th>
          <S.Th>FR</S.Th>
          <S.Th>IT</S.Th>
          <S.Th>UK</S.Th>
          <S.Th>US</S.Th>
          <S.Th>Jeans</S.Th>
        </S.TrTitle>

        <tr>
          <S.Td>XXS</S.Td>
          <S.Td>32</S.Td>
          <S.Td>34</S.Td>
          <S.Td>38</S.Td>
          <S.Td>4</S.Td>
          <S.Td>2</S.Td>
          <S.Td>24-25</S.Td>
        </tr>

        <tr>
          <S.Td>XS</S.Td>
          <S.Td>34</S.Td>
          <S.Td>36</S.Td>
          <S.Td>40</S.Td>
          <S.Td>6</S.Td>
          <S.Td>4</S.Td>
          <S.Td>26</S.Td>
        </tr>

        <tr>
          <S.Td>S</S.Td>
          <S.Td>36</S.Td>
          <S.Td>38</S.Td>
          <S.Td>42</S.Td>
          <S.Td>8</S.Td>
          <S.Td>6</S.Td>
          <S.Td>27/28</S.Td>
        </tr>

        <tr>
          <S.Td>M</S.Td>
          <S.Td>38</S.Td>
          <S.Td>40</S.Td>
          <S.Td>44</S.Td>
          <S.Td>10</S.Td>
          <S.Td>8</S.Td>
          <S.Td>29/30</S.Td>
        </tr>

        <tr>
          <S.Td>L</S.Td>
          <S.Td>40</S.Td>
          <S.Td>42</S.Td>
          <S.Td>46</S.Td>
          <S.Td>12</S.Td>
          <S.Td>10</S.Td>
          <S.Td>31</S.Td>
        </tr>

        <tr>
          <S.Td>XL</S.Td>
          <S.Td>42</S.Td>
          <S.Td>44</S.Td>
          <S.Td>48</S.Td>
          <S.Td>14</S.Td>
          <S.Td>12</S.Td>
          <S.Td>32</S.Td>
        </tr>

        <tr>
          <S.Td>XXL</S.Td>
          <S.Td>44</S.Td>
          <S.Td>46</S.Td>
          <S.Td>50</S.Td>
          <S.Td>16</S.Td>
          <S.Td>14</S.Td>
          <S.Td>33</S.Td>
        </tr>

        <tr>
          <S.Td>3XL</S.Td>
          <S.Td>46</S.Td>
          <S.Td>48</S.Td>
          <S.Td>52</S.Td>
          <S.Td>18</S.Td>
          <S.Td>16</S.Td>
          <S.Td>34</S.Td>
        </tr>

        <tr>
          <S.Td>4XL</S.Td>
          <S.Td>48</S.Td>
          <S.Td>50</S.Td>
          <S.Td>54</S.Td>
          <S.Td>20</S.Td>
          <S.Td>18</S.Td>
          <S.Td>35/36</S.Td>
        </tr>

        <tr>
          <S.Td>5XL</S.Td>
          <S.Td>50</S.Td>
          <S.Td>52</S.Td>
          <S.Td>56</S.Td>
          <S.Td>22</S.Td>
          <S.Td>20</S.Td>
          <S.Td>37</S.Td>
        </tr>
        <tr>
          <S.Td>6XL</S.Td>
          <S.Td>52</S.Td>
          <S.Td>54</S.Td>
          <S.Td>58</S.Td>
          <S.Td>24</S.Td>
          <S.Td>22</S.Td>
          <S.Td>38/39</S.Td>
        </tr>

        <tr>
          <S.Td>7XL</S.Td>
          <S.Td>54</S.Td>
          <S.Td>56</S.Td>
          <S.Td>60</S.Td>
          <S.Td>26</S.Td>
          <S.Td>24</S.Td>
          <S.Td>40</S.Td>
        </tr>
      </tbody>
    </S.Table>
  </>
);

const ShoeSizeTable = () => (
  <>
    <S.SizeTableTitle>{i18next.t('toolTipSizeTableTitle')}</S.SizeTableTitle>

    <p>{i18next.t('toolTipSizeTableWomenSubtitle')}</p>
    <S.Table>
      <tbody>
        <S.TrTitle>
          <S.ThBiggest>EU</S.ThBiggest>
          <S.ThBiggest>US</S.ThBiggest>
          <S.ThBiggest>UK</S.ThBiggest>
        </S.TrTitle>

        <>{buildShoeTable(womenShoeSizes)}</>
      </tbody>
    </S.Table>
    <br />
    <p>{i18next.t('toolTipSizeTableMenSubtitle')}</p>
    <S.Table>
      <tbody>
        <S.TrTitle>
          <S.ThBiggest>EU</S.ThBiggest>
          <S.ThBiggest>US</S.ThBiggest>
          <S.ThBiggest>UK</S.ThBiggest>
        </S.TrTitle>

        <>{buildShoeTable(menShoeSizes)}</>
      </tbody>
    </S.Table>
  </>
);

export { ClothingSizeTable, ShoeSizeTable };
