import { ProductCardProps } from '../ProductCard';

export const array: ProductCardProps[] = Array(10).fill([
  {
    brand: 'Gucci',
    type: 'Dress',
    image: 'image',
    isPremium: false,
    price: [true, '55', '33'],
    origPrice: 77,
    tncPrice: 75,
    size: 'size',
    backImage: 'back_image',
    imageAlt: 'image alt',
    ProductPageUrl: 'product_page_url',
    sold: false,
    levelRecommendation: 'lr',
    addToBagLink: 'add_to_bag_link',
    isInShoppingbag: false,
    linkToCart: 'to_cart_link',
    isSustainable: false,
    id: '1',
    linkToOrder: 'link_to_order',
    topSeller: false,
    typeEN: 'asdf',
    priceWithoutCurrency: 12,
    condition: 'condition',
    material: 'material',
    color: 'color',
    displayName: ' displayName',
    sellerType: 'sellerType',
  },
]);
