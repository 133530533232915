import styled from 'styled-components';

import { Theme } from '../../../services/theme';
import { StyledPrimaryButtonBlack } from '../../StyledGlobal/StyledButton';

import screen from '../../StyledGlobal/mediaQueries';
import SmallTextField from '../../global/SmallTextField';
import MaterialTextField from '../../global/MaterialTextField';

interface StyledNewsletterProps {
  justifyContent: string | null;
}

export const FormWrapper = styled.div<StyledNewsletterProps>`
  display: flex;
  justify-content: ${(props) => (props.justifyContent ? 'justifyContent' : 'space-between')};
  flex-wrap: wrap;
  & .MuiInputBase-root {
    background-color: white;
  }
  span:first-of-type {
    width: 60%;
    ${screen.xsMax`
      width: 100%;
      margin-bottom: 20px;
    `};
    ${screen.smMax`
      width: 70%;
      margin: 0 auto;
    `};
    @media (max-width: 480px) {
      width: 90%;
    }
  }
`;

export const FooterSuccessHeader = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

export const FooterTopText = styled.div`
  justify-content: center;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (min-width:1301px){
    text-align: left;
  }
`;

export const Form = styled.form`
  width: 100%;
  margin: 20px 0;
  ${screen.xsMax`
      flex-wrap: wrap;
  `};
`;

export const SmallField = styled(SmallTextField)`
  width: 70%;
  margin-right: 20px;
  font-size: 14px;
  display: none;
  ${screen.xsMax`
    width: 100%;
    margin-bottom: 33px;
    margin-right: 0;
    height:40px;
  `};
  ${screen.smMax`
    width: 70%;
    height:40px;
  `};
`;

export const Field = styled(MaterialTextField)`
  width: 70%;
  margin-right: 20px;
  font-size: 14px;

  ${screen.xsMax`
      width: 100%;
      margin-bottom: 33px;
      margin-right: 0;
      height:40px;
      background-color: red;
  `};
  ${screen.smMax`
    width: 70%;
    height:40px;
  `};
`;

export const Button = styled(StyledPrimaryButtonBlack)`
  width: 40%;
  text-align: center;
  height: 56px;
  font-size: 16px;
  ${screen.xsMax`
      width: 100%;
  `};
  ${screen.smMax`
    width: 70%;
    font-size: 14px;
    margin: 0 auto;
    height:40px;
  `};
  @media (max-width: 480px) {
    width: 90%;
  }
`;
