import React from 'react';
import Scroll from 'react-scroll';
import { useTranslation } from 'react-i18next';

import * as S from './styled/styledBLC';
import sustainableIcon from '@images/icons/sustainable.svg';

export interface Brand {
  name: string;
  link: string;
  desirable: boolean;
  sustainable: boolean;
}

interface BrandLetterContainerProps {
  brandsPerLetter: Brand[];
  letter: string;
  showOnlySustainable: boolean;
  searchTerm?: string;
  showOnlyPremium: boolean;
}

const BrandLetterContainer: React.FC<BrandLetterContainerProps> = ({
  brandsPerLetter,
  letter,
  searchTerm = '',
  showOnlySustainable,
  showOnlyPremium,
}) => {
  const i18n = useTranslation();
  const { Element } = Scroll;

  function getIcon(isSustainable: boolean) {
    return isSustainable ? <S.Icon src={sustainableIcon} /> : '';
  }

  function getBrand(brand: Brand) {
    return brand.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
  }

  // using searchTerm in brandcontainer to filter the right brands
  const filteredBrandsPerLetter = brandsPerLetter.filter(brand => {
    // if show only sustainable and
    if (showOnlySustainable && !brand.sustainable) {
      return null;
    }
    if (showOnlyPremium && !brand.desirable) {
      return null;
    }
    return getBrand(brand);
  });

  if (filteredBrandsPerLetter.length < 1) return null;

  const getBrandLink = (brandLink: string) => {
    if(!brandLink) return null
    const brandLinkSplit = brandLink.split('/')
    if (brandLinkSplit.length > 3) {
      brandLinkSplit.shift()
    }
    const language = i18n.i18n.language
    brandLinkSplit[0] = language === "nl" ? "" : language
    let newLink = ""
    brandLinkSplit.forEach(item => {
      if (item) {
        newLink += `/${item}`
      }
    });
    return newLink
  }

  return (
    <Element name={letter}>
      <S.BrandLetterWrapper>
        <S.BrandLetter> {letter.toUpperCase()}</S.BrandLetter>
        <S.LetterContainer>
          {filteredBrandsPerLetter.map(brand => {
            return (
              <a
                href={getBrandLink(brand.link)}
                className={`${brand.desirable ? 'bold' : ''} ${brand.sustainable ? 'green' : ''}`}
                key={brand.name}
              >
                {brand.name}
                {getIcon(brand.sustainable)}
              </a>
            );
          })}
        </S.LetterContainer>
      </S.BrandLetterWrapper>
    </Element>
  );
};

export default BrandLetterContainer;

// array positions:
// :name, :slug, :desirable, :sustainable
