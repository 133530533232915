import React, { memo } from 'react';
import { Provider, useSelector } from 'react-redux';

import store from '../../src/store';

import * as S from './styled/styledCounters';

import heartIcon from './icons/nav-heart.svg';
import { getFavoriteProductCount } from '../User/userSlice';

interface NavbarCounterProps {
  link: string;
}

const NavbarCounter: React.FC<NavbarCounterProps> = memo(({ link }) => {
  const count = useSelector(getFavoriteProductCount);


  return (
    <a href={link} className='hidden-xs hidden-sm'>
      <S.HeartIcon src={heartIcon} alt="Heart icon" />
      <S.HeartCounter>{count < 99 ? count : 99}</S.HeartCounter>
    </a>
  );
});

// HOC for redux
export default (props: NavbarCounterProps) => (
  <Provider store={store()}>
    <NavbarCounter {...props} />
  </Provider>
);
