import React, { useEffect, useState } from 'react';

interface TileProps {
  dataImage: string;
  dataScale: string;
}

const Tile: React.FC<TileProps> = ({ dataImage, dataScale }) => {
  const [transformOrigin, setTransformOrigin] = useState('');

  const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (e) => {
    const target = e.target as HTMLElement;
    setTransformOrigin((e.pageX - target.offsetLeft) / target.offsetWidth * 100 + '% ' + (e.pageY - target.offsetTop) / target.offsetHeight * 100 +'%');
  };  

  const handleMouseOver = () => {
    setTransformOrigin('scale(' + dataScale + ')');
  };

  const handleMouseOut = () => {
    setTransformOrigin('scale(1)');
  };

  useEffect(() => {
    const handleMouseMoveWrapper = (e: MouseEvent) => handleMouseMove(e as unknown as React.MouseEvent<HTMLDivElement>);
    const handleMouseOverWrapper = () => handleMouseOver();
    const handleMouseOutWrapper = () => handleMouseOut();
  
    // document.addEventListener('mousemove', handleMouseMoveWrapper);
    document.addEventListener('mouseover', handleMouseOverWrapper);
    document.addEventListener('mouseout', handleMouseOutWrapper);
  
    return () => {
      // document.removeEventListener('mousemove', handleMouseMoveWrapper);
      document.removeEventListener('mouseover', handleMouseOverWrapper);
      document.removeEventListener('mouseout', handleMouseOutWrapper);
    };
  }, [dataScale]);
  

  return (
    <div className="tile" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onMouseMove={handleMouseMove}>
      <div className="photo" style={{ backgroundImage: `url(${dataImage})`, transformOrigin: transformOrigin }} />
    </div>
  );
};

export default Tile;
