import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import toEuro from '../helpers/toEuro';

import * as S from './styled/StyledPBD';
import { RootState } from '../../src/store';

import infoIcon from '../../../assets/images/icons/info.svg';

const PriceBreakDown = () => {
  const i18n = useTranslation();
  const { totalAmount, totalDiscount, shippingCosts, transactionFee } = useSelector(
    (state: RootState) => state.checkout
  );

  const showDiscount =
    totalDiscount > 0 ? (
      <S.LineWrapper>
        <S.Text>{i18n.t('couponDiscount')}</S.Text>
        <S.Price>{`- ${toEuro(totalDiscount)}`}</S.Price>
      </S.LineWrapper>
    ) : (
      ''
    );

  const generateToolTipForTransactionFee = (
    <>
      <div className='toolTipHover toolTip'>
        <div className='toolTip__hover__container'>
        </div>
        <div className='toolTip__triangle'>
        </div>
        <img src={infoIcon} className="toolTip__icon" alt="" />
        <span className="js-toolTip__text toolTip__text">
          <div>
            <div className='t__f--sb underline'>Processing Fee:</div>
            <div>{i18n.t('transactionFeeDescription')}</div>
          </div>
        </span>
      </div>
    </>
  );

  const showShipping = (
    <S.LineWrapper>
      <S.Text>{i18n.t('shippingCosts')}</S.Text>
      <S.Price>{shippingCosts && toEuro(shippingCosts)}</S.Price>
    </S.LineWrapper>
  );

  const showTransactionFee = (
      <S.LineWrapper>
        <S.Text className="flex">
          Processing fee
          {generateToolTipForTransactionFee}
        </S.Text>
        <S.Price>{toEuro(transactionFee)}</S.Price>
      </S.LineWrapper>
  )

  const calculateTotal = () => {
    return toEuro(Number(totalAmount) - Number(totalDiscount) + Number(shippingCosts) + Number(transactionFee));
  };

  const showTotal = (
    <S.LineWrapper>
      <S.Text className="bold">{i18n.t('totalPrice')}</S.Text>
      <S.Price data-testid="total-price">{calculateTotal()}</S.Price>
    </S.LineWrapper>
  );

  return (
    <div data-testid="PriceBreakdown">
      {showDiscount}
      {showShipping}
      {showTransactionFee}
      {showTotal} 
    </div>
  );
};

export default PriceBreakDown;
