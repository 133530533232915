import React from 'react';
import { render, cleanup, fireEvent, waitFor } from '@testing-library/react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import { resizeWindowWidth } from '../../../test/testUtils';
import i18n from '../../../test/i18nForTest';

import ProductCard from '../ProductCard';
import store from '../../../src/store';

const defaultProps = {
  brand: 'Gucci',
  type: 'Dress',
  image: 'image',
  isPremium: false,
  price: [true, '55'],
  origPrice: '77',
  size: null,
  currentFavorite: false,
  favoriteLink: 'fav_link',
  backImage: 'back_image',
  imageAlt: 'image alt',
  ProductPageUrl: 'product_page_url',
  sold: false,
  levelRecommendation: null,
  addToBagLink: 'add_to_bag_link',
  isInShoppingbag: false,
  linkToCart: 'to_cart_link',
  isSustainable: false,
  dataAttribute: null,
  id: 1234,
  linkToOrder: 'link_to_order',
  topSeller: false,
};

afterEach(() => {
  cleanup;
  resizeWindowWidth(1024); // reset width to standard
});

const setup = (props = {}) => {
  const setupProps = { ...defaultProps, ...props }; // props overwrites defaultProps by doing this
  return render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store()}>
        <ProductCard {...setupProps} />
      </Provider>
    </I18nextProvider>
  );
};

///////////////ALL DEVICES////////////////
test('If it`s not sold there is a favorite icon and NO sold layover', () => {
  const { getByTestId, queryByTestId } = setup({ sold: false });
  // expect(getByTestId('fav-icon')).toBeTruthy(); // getByTestId will break if the element doesn't exist
  expect(queryByTestId('sold')).toBeNull(); //  queryByTestId will NOT break if the element doens't exist
});

test('If it`s sold there is NO favorite icon but a sold layover', () => {
  const { queryByTestId } = setup({ sold: true });
  expect(queryByTestId('fav-icon')).toBeNull();
  expect(queryByTestId('sold')).toBeTruthy();
});

test('should show sustainable icon if the item is sustainable', () => {
  const { getByTestId } = setup({
    isSustainable: true,
  });
  expect(getByTestId('sustainable')).toBeTruthy();
});

test('should NOT show sustainable icon if the item is NOT sustainable', () => {
  const { queryByTestId } = setup({
    isSustainable: false,
  });
  expect(queryByTestId('sustainable')).toBeNull();
});

test('should show premium badge if premium item', () => {
  const { getByTestId } = setup({
    isPremium: true,
  });
  expect(getByTestId('premium')).toBeTruthy();
});

test('should NOT show premium badge  if the item is NOT premium', () => {
  const { queryByTestId } = setup({
    isPremium: false,
  });
  expect(queryByTestId('premium')).toBeNull();
});

test('should  have sale percentage badge if the discount is more than 70% of original price and NOT sold', () => {
  const { getByTestId } = setup({
    origPrice: '100',
    price: [true, '29'],
    sold: false,
  });
  expect(getByTestId('sale-percentage')).toBeTruthy();
});

test('should NOT have sale percentage badge if the discount is more than 70% when it`s sold', () => {
  const { queryByTestId } = setup({
    origPrice: '100',
    price: [true, '29'],
    sold: true,
  });
  expect(queryByTestId('sale-percentage')).toBeNull();
});

test('should NOT have sale percentage badge if the discount is less than 70%', () => {
  const { queryByTestId } = setup({
    origPrice: '100',
    price: [true, '31'],
    sold: true,
  });
  expect(queryByTestId('sale-percentage')).toBeNull();
});

test('should have an LazyLoad component', async () => {
  const { container, getByTestId, debug } = setup();
  expect(getByTestId('lazyload')).toBeTruthy();

  // Improve to check if the lazy load component actually loads an image

  // fireEvent.scroll(container, { scrollY: 250 });
  // // console.log(window.pageYOffset);
  // await waitFor(() => {
  //   // fireEvent.scroll(container, { scrollY: 250 });
  //   console.log(container);

  //   window.scroll(0, 500);
  //   // fireEvent.scroll(container, { target: { scrollY: -1000 } });
  // });
  // console.log(window.pageYOffset);

  // debug();
});

test('should have size element if size is present', () => {
  const { getByTestId } = setup({ size: 'm' });
  expect(getByTestId('size')).toBeTruthy();
});

test('should have NO size element if size is NOT present', () => {
  const { queryByTestId } = setup({ size: '' });
  expect(queryByTestId('size')).toBeFalsy();
});

test('should have an original price if the product has an original price', () => {
  const { getByTestId } = setup({ origPrice: '100' });
  expect(getByTestId('original-price')).toBeTruthy();
});

test('should NOT have an original price if the product doesn`t have an original price', () => {
  const { queryByTestId } = setup({ origPrice: '' });
  expect(queryByTestId('original-price')).toBeFalsy();
});

/////////////// DESKTOP //////////////////

test('should show hover version of sold when sold and hovering the card on desktop', () => {
  const { getByTestId } = setup({
    sold: true,
  });
  const wrapper = getByTestId('product-card-wrapper');
  fireEvent.mouseEnter(wrapper);
  expect(getByTestId('sold-hover')).toBeTruthy();
});

test('should have `go to cart` button when it`s in cart', () => {
  const { getByTestId } = setup({ isInShoppingbag: true });
  expect(getByTestId('addToCart-isInCart')).toBeTruthy();
});

test('should have `add to cart` button when it`s NOT in cart', () => {
  const { getByTestId } = setup({ isInShoppingbag: false });
  expect(getByTestId('addToCart-notInCart')).toBeTruthy();
});

// needs to be added when figured out how to check if the function is triggered

// test('should call mouseEnter function when hovering', () => {
//   const handleMouseEnter = jest.fn();
//   const { getByTestId } = setup();

//   const wrapper = getByTestId('product-card-wrapper');
//   fireEvent.mouseEnter(wrapper);
//   console.log(wrapper);
//   expect(handleMouseEnter).toHaveBeenCalledTimes(1);
// });

////////////////// MOBILE ////////////////////

test('should NOT show sold-hover when sold and hovering the card on mobile', () => {
  resizeWindowWidth(500); // make window mobile size
  const { queryByTestId } = setup({ sold: true });
  const wrapper = queryByTestId('product-card-wrapper');
  fireEvent.mouseEnter(wrapper);
  expect(queryByTestId('sold-hover')).toBeFalsy();
});

test('should have NOT `go to cart` button when it`s in cart on mobile', () => {
  resizeWindowWidth(500);
  const { queryByTestId } = setup({ isInShoppingbag: true });
  expect(queryByTestId('addToCart-isInCart')).toBeFalsy();
});

test('should have NOT `add to cart` button when it`s NOT in cart on mobile', () => {
  resizeWindowWidth(500);
  const { queryByTestId } = setup({ isInShoppingbag: false });
  expect(queryByTestId('addToCart-notInCart')).toBeFalsy();
});
