import styled from 'styled-components';

export const Wrapper = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover {
    text-decoration: none;
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
  padding: 10px 5px 5px;
  width: 100%;
`;

export const Title = styled.p`
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: clamp(14px, 1.2vw, 18px);
  overflow: hidden;
  @media (max-width: 480px){
    padding: 0;
    font-size: 12px;
  }
`;
