const photoUpload = document.getElementById('user_avatar');

if (photoUpload) {
  photoUpload.onchange = function() {
    document.getElementById('user_edit_form').submit();
  };

  const noAtSignInput = document.querySelectorAll('.js-restricted_input');

  noAtSignInput.forEach(input => {
    input.addEventListener('keypress', e => {
      const inputField = input;
      if (e.keyCode === 64) {
        // add error message when typing @ symbol
        const errorMessage = inputField.parentElement.parentElement.nextElementSibling;
        errorMessage.classList.remove('hidden');
        // remove error message after 3 seconds
        setTimeout(() => {
          errorMessage.classList.add('hidden');
        }, 3000);
        e.preventDefault();
        return false;
      }
    });
  });

  noAtSignInput.forEach(input => {
    const inputField = input;
    // prevent pasting in the fields so field cant paste @ symbol
    inputField.onpaste = e => {
      e.preventDefault();
      return false;
    };
  });
}
