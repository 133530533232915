import React from 'react';
import { useFormikContext } from 'formik';

function scrollToError() {
  const { isValidating } = useFormikContext();
  React.useEffect(() => {
    if (isValidating) {

      setTimeout(() => {
        // get first error
        const printedError = document.querySelectorAll('.scrollToError')[0];
        if (printedError) {
        const headerOffset = printedError.classList.contains('scrollHigher') ? 250 : 85;
          const printedErrorPosition = printedError.offsetTop;
          const offsetPosition = printedErrorPosition - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }, 0);
    }
  }, [isValidating]);
  return null;
};

export default scrollToError;
