import React, { useEffect, useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch, ThemeProvider } from '@material-ui/core';
import ReactMarkDown from 'react-markdown';

import store, { RootState } from '../../src/store';
import { setHeaders } from '../../services/helpers';
import Repository from '../../../javascript/services/repository';
import { muiTheme } from '../../services/muiTheme';
import * as S from './styled/StyledNotificationsPanel';
import { getAuthentication } from '../User/userSlice';

interface NotificationState {
  sale_alert_website: boolean;
  follow_user_alert_website: boolean;
  follow_brand_alert_website: boolean;
  new_follower_website: boolean;
  new_product_follower_website: boolean;
  expires_in_1day_website: boolean;
  expires_today_website: boolean;
  expired_1week_ago_website: boolean;
}

const NotificationsPanel: React.FC = () => {
  const i18n = useTranslation();
  const translate = (text: string) => {
    return i18n.t(`userNotifications.${text}`);
  };

  const userAuthentication = useSelector(getAuthentication);

  const [userNotifications, setUserNotifications] = useState<NotificationState | undefined>(
    undefined
  );

  const fetchUserNotifications = () => {
    userAuthentication
      ? (async () => {
          try {
            setUserNotifications(
              (
                await Repository.getUserNotifications({
                  headers: setHeaders(userAuthentication),
                })
              ).data
            );
          } catch (err) {
            console.error('err', err);
          }
        })()
      : null;
  };

  useEffect(() => {
    fetchUserNotifications();
  }, [userAuthentication]);

  const handleChange = (notificationName: keyof NotificationState) => {
    userAuthentication && userNotifications
      ? Repository.updateUserNotifications(
          { [notificationName]: !userNotifications[notificationName] },
          {
            headers: setHeaders(userAuthentication),
          }
        )
          .then(() => {
            fetchUserNotifications();
          })
          .catch((e) => console.error('error', e))
      : null;
  };

  interface NoticationProps {
    text: string | React.ReactElement;
    notificationName: keyof NotificationState;
  }

  const Notification: React.FC<NoticationProps> = ({ text, notificationName }) => {
    return userNotifications ? (
      <S.Notification>
        <S.Label
          label={text}
          labelPlacement="start"
          control={
            <Switch
              inputProps={{ role: 'switch' }}
              checked={userNotifications[notificationName]}
              onClick={() => handleChange(notificationName)}
              color="secondary"
              value={text}
            />
          }
        />
      </S.Notification>
    ) : null;
  };

  return (
    <S.Container>
      <Notification text={translate('sale_alert')} notificationName="sale_alert_website" />
      <Notification
        text={
          <ReactMarkDown>
            {i18n.t('userNotifications.unfollow_specific_seller', {
              url: window.location.href.replace('notifications', 'alerts'),
              text: translate('here'),
            })}
          </ReactMarkDown>
        }
        notificationName="follow_user_alert_website"
      />
      <Notification
        text={
          <ReactMarkDown>
            {i18n.t('userNotifications.unfollow_specific_brand', {
              url: window.location.href.replace('notifications', 'alerts'),
              text: translate('here'),
            })}
          </ReactMarkDown>
        }
        notificationName="follow_brand_alert_website"
      />
      <Notification text={translate('new_follower')} notificationName="new_follower_website" />
      <Notification
        text={translate('new_product_follower')}
        notificationName="new_product_follower_website"
      />
      <Notification text={translate('expire_soon')} notificationName="expires_in_1day_website" />
      <Notification text={translate('expire_today')} notificationName="expires_today_website" />
      <Notification text={translate('expired')} notificationName="expired_1week_ago_website" />
    </S.Container>
  );
};

export { NotificationsPanel };

const WrappedNotificationsPanel = (): JSX.Element => (
  <Provider store={store()}>
    <ThemeProvider theme={muiTheme}>
      <NotificationsPanel />
    </ThemeProvider>
  </Provider>
);

export default WrappedNotificationsPanel;
