import React from 'react';
import { render } from '@testing-library/react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { I18nextProvider } from 'react-i18next';

import home from '../components/Home/homeSlice';
import checkout from '../components/Checkout/checkoutSlice';
import header from '../components/Header/headerSlice';
import productFilter from '../components/Filters/productFilterSlice';
import productList from '../components/ProductList/productListSlice';
import myItems from '../components/MyItems/myItemsSlice';
import productPage from '../components/ProductPage/productPageSlice';
import user from '../components/User/userSlice';

import i18n from '../test/i18nForTest';
import { ThemeProvider } from 'styled-components';
import { Theme } from '../services/theme';

const setupTest = (preloadedStateData: Object, component: React.ReactChild) => {
  const store = configureStore({
    preloadedState: preloadedStateData,
    reducer: { header, myItems, checkout, productFilter, productList, home, productPage, user },
  });

  return render(
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <I18nextProvider i18n={i18n}>{component}</I18nextProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default setupTest;


