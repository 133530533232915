import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import NewsletterSignUpForm from './NewsletterSignUpForm';
import * as S from './styled/styledNewsletter';

const NewsletterSignUp: React.FC = () => {
  const [signupSuccess, setSignupSuccess] = useState(false);

  const i18n = useTranslation();

  const translate = (text: string) => {
    return i18n.t(`newsletterSignUp.${text}`);
  };

  const showCoupon = () => {
    return (
      <>
        <S.FooterSuccessHeader>{translate('subscribed')}</S.FooterSuccessHeader>
        <div>{translate('newsletter_signup_success')}</div>
      </>
    );
  };

  return (
    <>
      {signupSuccess ? (
        showCoupon()
      ) : (
        <>
           <S.FooterTopText>{translate('newsletter_signup_short')}</S.FooterTopText>
          <NewsletterSignUpForm setSignupSuccess={setSignupSuccess} justifyContent="unset" />
        </>
      )}
    </>
  );
};

export default NewsletterSignUp;
