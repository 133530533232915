const memberSince = (date: string): { month: string; year: string } => {
  const MONTHS = [
    'january',
    'februari',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];
  const month = MONTHS[Number(date.substring(5, 7))];
  const year = date.substring(0, 4);
  return { month, year };
};

export default memberSince;
