import { FilterState } from '../../productFilterSlice';

const initialState: FilterState = {
  mobileFilter: {
    open: false,
    pages: [{ filterName: 'main' }],
  },
  filters: {
    types: {
      label: 'type',
      itemIds: ['1', '2', '3'],
      open: false,
      items: {
        '1': {
          count: 15,
          name: 'Dresses',
          id: '1',
          slug: 'dresses',
          selected: false,
        },
        '2': {
          count: 152,
          name: 'Shoes',
          id: '2',
          slug: 'shoes',
          selected: true,
        },
        '3': {
          count: 24,
          name: 'Bags',
          id: '3',
          slug: 'bags',
          selected: false,
        },
      },
    },
    'clothing-sizes': {
      label: 'clothing-sizes',
      itemIds: [],
      open: false,
      items: {},
    },
    'shoe-sizes': {
      label: 'shoe-sizes',
      itemIds: [],
      open: false,
      items: {},
    },
    brands: {
      label: 'Brand',
      itemIds: ['15', '16', '17'],
      open: false,
      items: {
        '15': {
          count: 15,
          name: 'Gucci',
          id: '15',
          slug: 'gucci',
          selected: false,
        },
        '16': {
          count: 152,
          name: 'Guess',
          id: '16',
          slug: 'guess',
          selected: false,
        },
        '17': {
          count: 24,
          name: 'Tommy Hilfiger',
          id: '17',
          slug: 'tommy-hilfiger',
          selected: false,
        },
      },
    },
    materials: {
      label: 'materials',
      itemIds: [],
      open: false,
      items: {},
    },
    colors: {
      label: 'colors',
      itemIds: [],
      open: false,
      items: {},
    },
    'price-groups': {
      label: 'price-groups',
      itemIds: [],
      open: false,
      items: {},
    },
    conditions: {
      label: 'conditions',
      itemIds: [],
      open: false,
      items: {},
    },
    'heel-heights': {
      label: 'heel-heights',
      itemIds: [],
      open: false,
      items: {},
    },
    'sleeve-lengths': {
      label: 'sleeve-lengths',
      itemIds: [],
      open: false,
      items: {},
    },
    'jeans-sizes': {
      label: 'jeans-sizes',
      itemIds: [],
      open: false,
      items: {}
    },
    seasons: {
      label: 'seasons',
      itemIds: [],
      open: false,
      items: {},
    },
    saleCollections: {
      label: 'saleCollections',
      itemIds: [],
      open: false,
      items: {},
    },
    collections: {
      label: 'collections',
      itemIds: [],
      open: false,
      items: {},
    },
  },
  selectedFilters: [{ filterName: 'brands', id: 15 }],
  totalProductsCount: 10,
  extraFilters: {
    TNCPremiumService: { active: false },
    hideSold: { active: false },
    justIn: { active: false },
    pagination: { currentPage: 0, totalPages: 0 },
    premiumOnly: { active: false, count: 0 },
    sale: { active: false },
    seller: { sellerId: null },
    sortProducts: { sortBy: '' },
    sustainableBrands: { active: false },
  },
};

export default initialState;
