import styled from 'styled-components';
import closeImage from '@images/icons/close-x-gray.svg';


export const Icon = styled.div.attrs({ className: 'glyphicon pull-right' })`
  line-height: 22px;
`;

export const StyledItem = styled.button`
  display: flex;
  width: 100%;
  margin-bottom: -1px;
  padding: 10px 15px;

  color: #555;

  background-color: #fff;
  border: 1px solid #ddd;

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const Left = styled.div`
  flex: 1;

  text-align: left;
`;

export const Subtitle = styled.div`
  color: var(--medium-grey);
  font-size: 12px;
`;

export const Count = styled.span`
  color: var(--medium-grey);
  font-size: 13px;
`;

export const CloseX = styled.div`
  width: 12px;
  height: 12px;

  background-image: url(${closeImage});
  background-repeat: no-repeat;
  background-size: 12px;
`;