import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

import * as S from './styled/styledTooltip';

import verifiedIcon from '@images/icons/check.svg';
import infoIcon from '@images/icons/info.svg';
import sustainableIcon from '@images/icons/sustainable.svg';

type TooltipBodyProps = {
  id: string;
  bodyTitle?: string;
  bodyText?: string;
  bodyElement?: JSX.Element | null;
  hasIcon?: boolean;
};

const TooltipBody: FC<TooltipBodyProps> = ({ id, bodyTitle, bodyText, bodyElement, hasIcon }) => {
  const i18n = useTranslation();
  return (
    <S.Tooltip id={id} place="bottom" backgroundColor="#edf1ed">
      <S.ToolTipBody>
        <S.TitleWrapper>
          {hasIcon && <S.TitleIcon src={sustainableIcon} />}
          {bodyTitle && <S.Title> {i18n.t(bodyTitle)}</S.Title>}
        </S.TitleWrapper>
        {bodyText && <ReactMarkdown>{i18n.t(bodyText)}</ReactMarkdown>}
        {bodyElement}
      </S.ToolTipBody>
    </S.Tooltip>
  );
};

const createTooltipWithIcon = (
  id: string,
  bodyTitle?: string,
  bodyText?: string,
  bodyElement?: JSX.Element | null,
  hasIcon?: boolean
) => {
  const icon = id === 'toolTipVerifiedSeller' ? verifiedIcon : infoIcon;
  return (
    <>
      <a data-tip data-for={id}>
        <S.Icon src={icon} />
      </a>
      <TooltipBody
        id={id}
        bodyTitle={bodyTitle}
        bodyText={bodyText}
        bodyElement={bodyElement}
        hasIcon={hasIcon}
      />
    </>
  );
};

const createTooltipWithText = (
  id: string,
  hoverText: string,
  bodyTitle?: string,
  bodyText?: string,
  bodyElement?: JSX.Element | null
) => {
  const i18n = useTranslation();

  return (
    <>
      <S.HoverLink data-tip data-for={id}>
        {i18n.t(hoverText)}
      </S.HoverLink>
      <TooltipBody id={id} bodyTitle={bodyTitle} bodyText={bodyText} bodyElement={bodyElement} />
    </>
  );
};

export { createTooltipWithIcon, createTooltipWithText };
