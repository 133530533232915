import React from "react";

import * as S from '../styled/styledMobileNavMenu';

import { SubgroupProps } from "./NavigationOptions";

import Item from "./Item";

const Subgroup: React.FC<SubgroupProps> = ({
    name,
    values,
    newClass
}) => {
    return (
        <S.NavItem nodeId={name} label={name} className={newClass}>
            {
                values.map(item => {
                    return <Item type="item" name={item.name} value={item.value} newClass="subgroup-item" />
                })
            }
        </S.NavItem>
    )
}

export default Subgroup;