import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled/StyledCH';

interface CheckoutHeaderProps {
  windowWidth: number;
}

const CheckoutHeader: React.FC<CheckoutHeaderProps> = ({ windowWidth }) => {
  const i18n = useTranslation();

  const securedPayment = (
    <S.SecuredCheckout>
      <S.Lock />
      {i18n.t('CheckoutHeader.secured_checkout')}
    </S.SecuredCheckout>
  );

  return (
    <div data-testid="CheckoutHeader">
      <S.Slogan>{windowWidth <= 768 ? securedPayment : i18n.t('TNC_slogan')}</S.Slogan>
      <S.Wrapper>
        <a href="/">
          <S.Logo />
        </a>
        {windowWidth > 768 ? securedPayment : ''}
      </S.Wrapper>
    </div>
  );
};
export default CheckoutHeader;
