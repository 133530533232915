import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import questionIcon from '@images/icons/question-mark-circle.png';
import * as S from './styled/styledZL';
import { Provider, useSelector } from 'react-redux';
import store from '../../src/store';
import { getShowZendeskButton } from '../Header/headerSlice';

const ZendeskLauncher: React.FC = () => {
  const i18n = useTranslation();
  const getZendeskButtonSelector = useSelector(getShowZendeskButton);
  const [zendeskButton, setZendeskButton] = useState(true);
  const [isLoadingZendesk, setLoadingZendesk] = useState(false);

  useEffect(() => {
    if (getZendeskButtonSelector !== undefined) {
      setZendeskButton(getZendeskButtonSelector);
    }
  }, [getZendeskButtonSelector]);

  useEffect(() => {
    if (isLoadingZendesk) {
      const script = document.createElement('script');
      document.head.appendChild(script);
      script.src =
        'https://static.zdassets.com/ekr/snippet.js?key=ca29d039-94a2-4edd-9633-ef84abb64a51';
      script.defer = true;
      script.id = 'ze-snippet';

      // set up the mutation observer to see if the real launcher exists
      // when it exists, simulate click to launch zendesk
      const observer = new MutationObserver(function(mutations, me) {
        const launcher = document.getElementById('launcher');
        if (launcher) {
          $zopim.livechat.window.show();

          me.disconnect(); // stop observing
          return;
        }
      });

      // start observing
      observer.observe(document, {
        childList: true,
        subtree: true,
      });
    }
  }, [isLoadingZendesk]);

  return !isLoadingZendesk && zendeskButton ? (
    <S.Button type="button" onClick={() => setLoadingZendesk(true)} id="js-zendeskLauncher">
      <S.Icon src={questionIcon} />
      <S.Text>{i18n.t('ZendeskLauncher.help')}</S.Text>
    </S.Button>
  ) : null;
};

export default () => (
  <Provider store={store()}>
    <ZendeskLauncher />
  </Provider>
);
