import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Repository } from '@thenextcloset/shared-javascript';

type HomeSlice = {
  impact: {
    bathtubs?: number;
    co2_month?: number;
    kg_of_clothing?: number;
    kgOfWaterLastDay?: number;
    kilometersByPlaneLastMonth?: number;
    roundWorldTrips?: number;
  };
};

const initialState: HomeSlice = {
  impact: {
    bathtubs: undefined,
    co2_month: undefined,
    kg_of_clothing: undefined,
    kgOfWaterLastDay: undefined,
    kilometersByPlaneLastMonth: undefined,
    roundWorldTrips: undefined,
  },
};

export const fetchSustainableImpact = createAsyncThunk('home/fetchSustainableImpact', async () => {
  const response = await Repository.getSustainInfo();
  return { ...response.data };
});

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSustainableImpact.fulfilled, (state, { payload }) => {
      const {
        bathtubs,
        co2_month,
        kg_of_clothing,
        kgOfWaterLastDay,
        kilometersByPlaneLastMonth,
        roundWorldTrips,
      } = payload;
      state.impact = {
        bathtubs,
        co2_month,
        kg_of_clothing,
        kgOfWaterLastDay,
        kilometersByPlaneLastMonth,
        roundWorldTrips,
      };
    });
  },
});

export const {} = homeSlice.actions;

export default homeSlice.reducer;
