import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const zipCodeApiCall = (
  path: string,
  zipCode: string,
  houseNumber: string,
  houseNumberAddition: string
) => {
  return axios.get(path, {
    params: {
      zipCode: zipCode.replace(/ /g, ''), // stripped of whitespace
      houseNumber: String(houseNumber).replace(/ /g, ''),
      houseNumberAddition: String(houseNumberAddition).replace(/ /g, ''),
    },
  });
};
