import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import * as S from './styled/styledUPP';
import NextButton from './NextButton';
import { FormValues, UploadProductOneProps } from './UploadProductPageTypes';
import { getProductProperties } from '../Header/headerSlice';
import MaterialSelectField from '../global/MaterialSelectField';
import SelectType from './SelectType';
import translateComponent from '../helpers/translateI18n';
import Repository from '../../services/repository';
import { category } from './Category'

import {
  showTooltipBrand,
  showTooltipSize,
  showTooltipCondition,
  showTooltipColor,
  showTooltipMaterialTip,
} from './ProductUploadToolTips';

export interface ProductTypeObject {
  id: number;
  name: string;
  children?: ProductTypeObject[];
}

const UploadProductOne: React.FC<UploadProductOneProps> = ({
  setFormProgress,
  rootType,
  isShoes,
  isClothing,
  productToEdit,
  setStep,
}) => {
  const translate = translateComponent('UploadProduct');

  const productProperties = useSelector(getProductProperties);
  const {
    brands,
    sizes,
    heel_height,
    sleeve_length,
    seizoen,
    conditions,
    kleur,
    materiaal,
  } = productProperties || {};

  const prodTypesInitialState: ProductTypeObject[] = [];

  const [productTypes, setProductTypes] = useState(prodTypesInitialState);

  const fetchProductTypes = () => {
    Repository.getProductTypes().then((res) => {
      setProductTypes(res.data);
    });
  };

  let sizeOptions = null;  

  const { setFieldValue, values } = useFormikContext<FormValues>();

  if(isShoes) {
    sizeOptions = sizes?.shoes[category]
  }
  else if (values.typeId == 13) {
    sizeOptions = sizes?.jeans[category]
  } else {
    sizeOptions = sizes?.clothing[category]
  }

  const inRewiewOrChangeRequired =
    productToEdit && ['in_review', 'change_required'].includes(productToEdit.backend_state);

  useEffect(() => {
    // A size is only used for shoes and clothing. needSize is also used for yup validations
    setFieldValue('needSize', isShoes || isClothing);
    // heel_height is only used for some types of shoes. needHeelHeight is also used for yup validations
    setFieldValue(
      'needHeelHeight',
      isShoes && values.typeId != null && ![16, 18, 29].includes(values.typeId)
    );
    // jeansSize is only used for some types of clothing. needJeansSize is also used for yup validations 
    setFieldValue(
      'needJeansSize',
      isClothing && values.typeId != null && [13].includes(values.typeId)
    );
    // sleeveLength is only used for some types of clothing. needHeelHeight is also used for yup validations
    setFieldValue(
      'needSleeveLength',
      isClothing && values.typeId != null && [45, 8, 48, 44].includes(values.typeId)
    );
    // idem for season
    setFieldValue(
      'needSeason',
      isClothing && values.typeId != null && [61, 60, 59, 31].includes(values.typeId)
    );
  }, [isShoes, isClothing, values.type, setFieldValue]);

  useEffect(() => {
    fetchProductTypes();
  }, []);

  useEffect(() => {
    splitAndSetTypeFields();
  }, [values.typeName]);

  const splitAndSetTypeFields = () => {
    if (values.typeName?.indexOf('|') === -1) {
      return;
    }

    const [typeId, type] = (values.typeName || '').split('|');
    setFieldValue('typeId', Number(typeId));
    setFieldValue('type', type);
  };

  const createSelect = (
    options: any,
    name: string,
    labelText: string,
    placeholderText: string,
    toolTip?: JSX.Element
  ) => {
    return (
      <MaterialSelectField
        placeholderText={translate(placeholderText)}
        labelText={translate(labelText)}
        name={name}
        onClick={() => setStep(name)}
        toolTip={toolTip}
        selectOptions={options.map((value: string) => {
          return (
            <option value={value} key={value}>
              {value}
            </option>
          );
        })}
      />
    );
  };

  const showSizes = (sizeOptions: string[]) => {
    return createSelect(sizeOptions, 'size', 'whatSize', 'selectSize', showTooltipSize(isClothing));
  };

  return productProperties && productTypes ? (
    <S.Container>
      <h2>
        {translate('moreInfo')}
        {translate(`this${values.rootType}`)}
      </h2>
      {values.rootType && (
        <SelectType
          types={productTypes}
          disabled={!!productToEdit && !inRewiewOrChangeRequired}
          setStep={setStep}
          rootType={values.rootType}
        />
      )}
      {values.needHeelHeight &&
        heel_height &&
        createSelect(heel_height, 'properties.heel_height', 'whatHeelHeight', 'selectHeelHeight')}
      {values.needSleeveLength &&
        sleeve_length &&
        createSelect(
          sleeve_length,
          'properties.sleeve_length',
          'whatSleeveLength',
          'selectSleeveLength'
        )}
      {brands && (
        <S.AutoCompleteField
          name="brand"
          options={brands}
          labelText={translate('whatBrand')}
          placeholderText={translate('selectBrand')}
          disabled={!!productToEdit && !inRewiewOrChangeRequired}
          handleOpen={() => setStep('brand')}
          toolTip={showTooltipBrand()}
        />
      )}

      {sizeOptions && (isShoes || isClothing) ? showSizes(sizeOptions) : null}
      {values.needSeason &&
        seizoen &&
        createSelect(seizoen, 'properties.season', 'whatSeason', 'selectSeason')}
      {createSelect(
        conditions,
        'condition',
        'whatCondition',
        'selectCondition',
        showTooltipCondition()
      )}
      {createSelect(kleur, 'properties.color', 'whichColor', 'selectColor', showTooltipColor())}
      {createSelect(
        materiaal,
        'properties.material',
        'whatMaterial',
        'selectMaterial',
        showTooltipMaterialTip()
      )}
      <S.ButtonContainerStepOne>
        <NextButton nextStep="stepTwo" setFormProgress={setFormProgress} />
      </S.ButtonContainerStepOne>
    </S.Container>
  ) : null;
};

export default UploadProductOne;
