import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import axios from 'axios';

import Spinner from '../global/Spinner';
import FlashMessage from '../FlashMessage/FlashMessage';

import BankVerificationOne from './BankVerificationOne';
import BankVerificationTwo from './BankVerificationTwo';
import BankVerificationThree from './BankVerificationThree';
import BankVerificationFour from './BankVerificationFour';
import { bankVerificationValidationSchema } from './yupBankVerificationSchema';

interface BankVerifcationProps {
  bankPath: string; // same path for show and update but update is patch
}

export interface BankVerificationFormValues {
  fileFront: Object | string;
  fileBack: Object | string;
  firstname: string;
  lastname: string;
  bankNumber: string;
  typeOfId: string;
  acceptedTermsKYC: boolean;
  day: string;
  month: string;
  year: string;
  birthDate: string;
}

const BankVerification: React.FC<BankVerifcationProps> = ({ bankPath }) => {
  // hooks
  const [formProgress, setFormProgress] = useState('stepOne');
  const [documentType, setDocumentType] = useState('');
  const [submittingError, setSubmittingError] = useState(false);

  const initialValues: BankVerificationFormValues = {
    firstname: '',
    lastname: '',
    fileFront: '',
    fileBack: '',
    bankNumber: '',
    typeOfId: '',
    acceptedTermsKYC: false,
    day: '',
    month: '',
    year: '',
    birthDate: '',
  };

  const buildBankVerificationForRails = (values: any): typeof formData => {
    const formData = new FormData();
    formData.append('kyc_document[file_front]', values.fileFront);
    formData.append('kyc_document[file_back]', values.fileBack);
    formData.append('kyc_document[document_type]', values.typeOfId);
    formData.append('bank[firstname]', values.firstname);
    formData.append('bank[lastname]', values.lastname);
    formData.append('bank[number]', values.bankNumber);
    formData.append('user[birth_date]', values.birthDate);
    return formData;
  };

  const showStep = (progress: string): JSX.Element => {
    switch (progress) {
      case 'stepTwo':
        return (
          <BankVerificationTwo
            setFormProgress={setFormProgress}
            setDocumentType={setDocumentType}
          />
        );
      case 'stepThree':
        return <BankVerificationThree setFormProgress={setFormProgress} />;
      case 'stepFour':
        return <BankVerificationFour setFormProgress={setFormProgress} />;
      default:
        return <BankVerificationOne setFormProgress={setFormProgress} />;
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={bankVerificationValidationSchema(formProgress, documentType)}
        onSubmit={(values, actions) => {
          const paramsForRails = buildBankVerificationForRails(values);
          actions.setSubmitting(true);
          const csrf = document?.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
          axios
            .patch(bankPath, paramsForRails, {
              headers: {
                Accept: 'application/json',
                'X-CSRF-TOKEN': csrf,
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(res => {
              if (res.status === 200) {
                window.location.assign(bankPath);
              }
            })
            .catch(() => {
              actions.setSubmitting(false);
              setSubmittingError(true);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            {submittingError ? <FlashMessage text="Er is iets misgegaan" /> : ''}
            {showStep(formProgress)}
            {isSubmitting ? <Spinner data-testid="spinners" /> : ''}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BankVerification;
