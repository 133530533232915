import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchProducts, setMobileOpen, setCurrentPage } from '../../productFilterSlice';

import * as S from './../styled/styledAB';

const ApplyButton = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();

  const handleApply = () => {
    dispatch(setCurrentPage(1));
    dispatch(fetchProducts());
    dispatch(setMobileOpen(false));
  };

  return (
    <S.ApplyButton onClick={handleApply}>
      {i18n.t('SF__apply')}
    </S.ApplyButton>
  )
};

export default ApplyButton;
