import React, { useEffect } from 'react';

import useModal from '../Modal/useModal';
import ConfirmItemReceivedModal from './ConfirmItemReceivedModal';

interface Props {
  id: number;
  lineItemState: 'in_progress' | 'at_pickup_point' | 'delivered' | 'buyer_confirmed_payout';
}

const TriggerEmailModal: React.FC<Props> = ({ id, lineItemState }) => {
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    toggle();
  }, [toggle]);

  return (
    <>
      <ConfirmItemReceivedModal
        isShowing={isShowing}
        hide={toggle}
        id={id.toString()}
        lineItemState={lineItemState}
      />
    </>
  );
};

export default TriggerEmailModal;
