import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { gtmProductListSerializer } from '../Analytics/gtmProductSerializer';
import store from '../../src/store';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './style/slide_type_a.scss';
import SlideArrow from '../SlickSlider/SliderArrow';
import "./style/slide_arrow.scss";

const ProductCardTypeC = React.lazy(() => import('./ProductCardTypeC'));

function SlideTypeC({
  products,
  num_of_items = 6,
}) {
  useEffect(() => {
    // GTM Tracking
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        currency: 'EUR',
        items: products && Object.entries(products).map(gtmProductListSerializer),
        items_google_ads: products && Object.entries(products).map((product) => {
          return {
            item_id: product[1].id,
            google_business_vertical: 'retail'
          }
        })
      },
    });
  }, [products, num_of_items]);
  const responsive = {
    desktop: {
      breakpoint: { max: 10000, min: 992 },
      items: num_of_items,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 991, min: 0 },
      items: 2,
      slidesToSlide: 2,
    }
  };
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div className="carousel-button-group">
        <SlideArrow className={currentSlide === 0 ? 'disable' : 'slide-arrow slide-arrow-prev'} onClick={() => previous()} />
        <SlideArrow className="slide-arrow slide-arrow-next test" onClick={() => next()} />
        {/* <ButtonThree onClick={() => goToSlide(currentSlide + 1)}> Go to any slide </ButtonThree> */}
      </div>
    );
  };
  
  return (
    <div className="carousel-type-c">
      <Carousel responsive={responsive} arrows={false} renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />} infinite={true} showDots={true}>
        {products.map(product => (
          <div key={product.id}>
            <Suspense fallback={<div>Loading...</div>}>
              <ProductCardTypeC {...product} />
            </Suspense>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default (props) => (
  <Provider store={store()}>
    <SlideTypeC {...props} />
  </Provider>
);
