import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { gtmProductListSerializer } from '../Analytics/gtmProductSerializer';
import useWindowWidth from '../helpers/hooks/useWindowWidth';
import store from '../../src/store';
import Slider from 'react-slick';
import SliderArrow from '../SlickSlider/SliderArrow';
import './style/slider_type_c.scss';


const ProductCardTypeC = React.lazy(() => import('./ProductCardTypeC'));

function SlideProductsTypeC({
  products,
}) {
  const currentWidth = useWindowWidth();

  useEffect(() => {
    // GTM Tracking
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        currency: 'EUR',
        items: products && Object.entries(products).map(gtmProductListSerializer),
        items_google_ads: products && Object.entries(products).map((product) => {
          return {
            item_id: product[1].id,
            google_business_vertical: 'retail'
          }
        })
      },
    });
  }, [products]);
  const [leftArrowHidden, setLeftArrowHidden] = useState(true);
  const settings = {
    dots: false,
    speed: 300,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerMode: false, /* set centerMode to false to show completeon mobile swap */
          slidesToScroll: 1,
          slidesToShow: 2,
          swipeToSlide: true,
        },
      },
    ],
    prevArrow: (
      <SliderArrow to="vorige" className="slick-arow slick-prev test" />
    ),
    nextArrow: <SliderArrow to="volgende" className="slick-arow slick-next test" />,
    beforeChange: (oldIndex, newIndex) => {
      setLeftArrowHidden(newIndex === 0);
    },
  };

  return (
    <div data-testid="productcard-container-responsive" className='carousel-type-c'>
      <Slider {...settings}>
        <div />
        {products.map(product => (
          <div key={product.id}>
            <Suspense fallback={<div>Loading...</div>}>
              <ProductCardTypeC {...product} />
            </Suspense>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default (props) => (
  <Provider store={store()}>
    <SlideProductsTypeC {...props} />
  </Provider>
);
