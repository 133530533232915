import React, { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import multicolour from '@images/icons/multicolour.svg';
import animalPrint from '@images/icons/animal-print.svg';
import blackWhite from '@images/icons/black_white.svg';
import tartanPattern from '@images/icons/tartan-pattern.svg';
import floralPrint from '@images/icons/floral-print.svg';
import otherColour from '@images/icons/other-colour.svg';
import metallic from '@images/icons/metallic.svg';
import polkadot from '@images/icons/polkadot.svg';
import * as S from './styled/styledI'
import { useTranslation } from 'react-i18next';

import { toggle, fetchProducts, setSelectedFilters, getFilters, setCurrentPage, toggleExtraFilter, getExtraFilters  } from '../productFilterSlice';
import { getItemIds, getItems } from '../../ProductList/productListSlice';

const Item = ({ filterName, id, isMobile }) => {
  const sellerType = filterName.includes('seller');
  const item = sellerType ? filterName : useSelector(state => state.productFilter.filters[filterName].items[id]);
  const dispatch = useDispatch();
  const filters = useSelector(getFilters);
  const itemIds = useSelector(getItemIds);
  const items = useSelector(getItems);
  const extraFilters = useSelector(getExtraFilters);

  const apply = () => {
    dispatch(toggle({ filterName, id }));
    if(!isMobile) {
      dispatch(fetchProducts());
    }
  };
  const [isChecked, setIsChecked] = useState(item.selected || false);
  const handleChecked = (event) => {
    setIsChecked(event.target.checked);
  };

  const iconsColors = [
    multicolour,
    animalPrint,
    blackWhite,
    tartanPattern,
    floralPrint,
    otherColour,
    metallic, 
    polkadot,
  ];

  const handleColor = (color) => {
    let col = color == 'black-white' ? 'black_white' : color
    let iconColor = iconsColors.find(a => a.includes(`/${col}-`));
    if (iconColor) {
      return iconColor;
    } else {
      return color;
    }
  };

  
  const [sellerTypeSelected, setSellerTypeSelected] = useState(extraFilters.premiumOnly.active || false);
  const handleSellersType = () => {
    dispatch(setCurrentPage(1));
    dispatch(toggleExtraFilter('premiumOnly'));
    dispatch(fetchProducts());
  };

  const getCheckbox = (
    <S.Checkbox
      type="checkbox"
      checked={sellerTypeSelected}
      name="checkItem"
      id={sellerType ? `${filterName}-${id}` : `${item.name}-${id}`}
      onClick={sellerType ? handleSellersType : apply}
      onChange={!sellerType ? handleChecked : (event) => setSellerTypeSelected(!sellerTypeSelected)}
    />
  );
  const i18n = useTranslation();
  const language = i18n.i18n.language

  if (filterName.toLowerCase().includes('size')) {
    return (
      <S.SizeItem root={!item.parent && item.children} selected={item.selected} >
        {getCheckbox}
          <span>{filterName.includes('kids') ? item.name.replace(/\s\(\d+-\d+m\)/g, "") : item.name.split(" / ")[0]}</span>
      </S.SizeItem>
    );
  } else if (filterName.toLowerCase().includes('color')) {
    return (
      <S.ColorItem root={!item.parent && item.children} selected={item.selected} color={handleColor(item.slugs.en)} title={language === "en" ? item.slugs.en : item.slugs.nl}>
        {getCheckbox}
      </S.ColorItem>
    );
  } else if (sellerType) {
    return (
      <S.Item >
        <S.Label>
          {getCheckbox}
          {filterName}
          <S.StyledCheckbox selected={extraFilters.premiumOnly.active}/>
        </S.Label>
      </S.Item>
    );
  } else {
    return (
      <S.Item root={!item.parent && item.children} selected={item.selected} >
        <S.Label>
          {getCheckbox}
          {i18n.t(item.name.replace(/[()]/g, ''))}
          {/* {item.name} {item.count && `(${item.count})`} */}
          <S.StyledCheckbox selected={item.selected}/>
        </S.Label>
      </S.Item>
    );
  }
};

Item.propTypes = {
  filterName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default memo(Item);
