import styled from 'styled-components';
import { StyledTransparentToBlackBtn, StyledSecondaryButton } from '../../StyledGlobal/StyledButton';
import screen from '../../StyledGlobal/mediaQueries'

export const Wrapper = styled.div`
  font-family: ${props => props.theme.fonts.poppins};
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 15px;
  background-color: ${(props) => props.theme.colors.sand};
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
  ${screen.smMax`
    padding-left: 10px;
    padding-right: 10px;
  `};
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 50%;
  ${screen.smMax`
    width: 65%;
  `};
`;
export const AvatarWrapper = styled.div`
  width: 50%;
  text-align: center;
  ${screen.smMax`
    width: 35%;
  `};
  ${screen.smMin`
    padding-bottom: 10px;
  `};
`;

export const Avatar = styled.img`
  border-radius: 50%;
  ${screen.smMax`
    width: 90px;
    height: 90px;
  `};
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SemiBoldLink = styled.a`
  position: relative;
  padding-bottom: 1px;
  width: fit-content;
  text-decoration: underline;
  font-size: clamp(16px,1.2vw,18px);
  font-weight: 400;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 4px;
    left: 0;
    background-color: ${(props) => props.theme.colors.black};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  &:hover {
    text-decoration: none !important;
    &:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
  ${screen.smMax`
    font-size: 20px;
  `};
`;

export const Medium = styled.p`
  font-size: clamp(16px,1.2vw,18px);
  font-weight: 400;
  margin-bottom: 20px;
  ${screen.smMax`
    font-size: 16px;
    margin-bottom: 10px;
  `};
`;


export const MemberSince = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  ${screen.smMax`
    font-size: 12px;
  `};
`;

export const Stats = styled.div`
  margin-bottom: 15px;
`;

export const Stat = styled.div`
  display: flex;
  margin-bottom: 10px;
  p {
    font-size: 14px;
    font-weight: 400;
    ${screen.smMax`
      font-size: 16px;
    `};
  }
`;

interface statImageProps {
  height?: string;
  marginLeft?: string;
}

export const StatImage = styled.img<statImageProps>`
  width: 17px;
  display: flex;
  justify-content: center;
  height: 17px;
  @media (max-width: 767px) {
    width: 14px;
    height: 14px;
  }
`;

export const StatImageWrapper = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
`;

export const StatText = styled.p`
  margin: 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  ${screen.xsMax`
    margin: 0 5px 30px;
    width: 100%;
  `};
`;

export const TransparentLink = styled(StyledTransparentToBlackBtn)`
  ${screen.xsMax`
  padding: 10px 10px;`}
`;

export const SecondaryButton = styled(StyledSecondaryButton)`
  width: 45%;
`;

export const SeparatorLine = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.5);
`;
