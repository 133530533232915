import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// Initial State
const initialState = {
  products: [],
  productCount: 0,
  currentPage: 1,
  isSearching: false,
};

// Create slice
const myItemsSlice = createSlice({
  name: 'myItems',
  initialState,
  reducers: {
    setProducts(state, action) {
      state.products = action.payload;
    },
    setProductCount(state, action) {
      state.productCount = action.payload;
    },
    setPage(state, action) {
      state.currentPage = action.payload;
    },
    setIsSearching(state, action) {
      state.isSearching = action.payload;
    },
  },
});

// exports
export const { setProducts, setIsSearching, setPage, setProductCount } = myItemsSlice.actions;
export default myItemsSlice.reducer;

// Thunk actions
export const setFilteredProducts = (linkAndQuery: string) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  dispatch(setIsSearching(true));
  const response = await axios.get(linkAndQuery, { headers: { Accept: 'application/json' } });
  if (response.status === 200) {
    dispatch(setProducts(response.data.products));
    dispatch(setIsSearching(false));
    dispatch(setPage(Number(response.data.currentPage)));
    dispatch(setProductCount(response.data.productCount));
  }
};
