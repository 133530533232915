import styled, { css } from 'styled-components';
import { ButtonBack, ButtonNext } from 'pure-react-carousel';

import trees4AllImage from '@images/about_us/trees_for_all.png';
import dress4SuccessImage from '@images/about_us/dress_for_success.png';
import circleEconomyImage from '@images/about_us/circle_economy.png';
import reshareImage from '@images/about_us/reshare.png';
import leftArrow from '@images/left.svg';
import rightArrow from '@images/right.svg';

export const Trees4AllImg = styled.img.attrs({ src: trees4AllImage, alt: 'Trees For All logo' })`
  height: 60px;
`;
export const Dress4SuccessImg = styled.img.attrs({
  src: dress4SuccessImage,
  alt: 'Dress For Success logo',
})`
  height: 42px;
  margin: -10px 0 9px;
`;
export const CircleEconomyImg = styled.img.attrs({
  src: circleEconomyImage,
  alt: 'Circle Economy logo',
})`
  height: 60px;
`;
export const ReshareImg = styled.img.attrs({ src: reshareImage, alt: 'Reshare Store logo' })`
  height: 48px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  height: auto;
  @media (min-width: 768px) {
    padding: 40px 0;
  }
`;

export const CarouselWrapper = styled.div`
  justify-content: space-around;
  padding: 0;
  width: 90%;
`;

export const CarouselCard = styled.div`
  display: flex;
  width: 300px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
`;

export const IconContainer = styled.div`
  padding-bottom: 10px;
`;

export const Nav = styled.nav`
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: 100%;
  left: 0;
  justify-content: space-between;
  position: absolute;
`;

const sharedButtonStyles = css`
  background: transparent;
  border: none;
  background-size: cover;
  height: 35px;
  width: 35px;
`;

export const BackButton = styled(ButtonBack)`
  ${sharedButtonStyles};
  left: 10px;
  background: url(${leftArrow});
`;

export const NextButton = styled(ButtonNext)`
  ${sharedButtonStyles};
  right: 10px;
  background: url(${rightArrow});
`;
