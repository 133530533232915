import React from 'react';

import translateComponent from '../helpers/translateI18n';
import Modal from '../Modal/Modal';

import * as S from './styled/styledUPP';
import { PrimaryButton, SecondaryButton } from '../BankVerification/styled/styledBV';

interface ConfirmExitModalProps {
  isShowing: boolean;
  hide: () => void;
}

const ConfirmExitModal: React.FC<ConfirmExitModalProps> = ({ isShowing, hide, title, text }) => {
  const translate = translateComponent('UploadProduct');

  const redirectToHome = () => {
    window.location = '/';
  };

  const ModalBody = (
    <S.ModalContainer>
      <S.ModTitle title={translate('progress')} hide={hide} />
      <S.ModText text={translate('confirmQuit')} />
      <S.ButtonContainer>
        <S.SecondaryButtonModal onClick={redirectToHome}>
          {translate('exit')}
        </S.SecondaryButtonModal>
        <PrimaryButton onClick={hide}>{translate('continue')}</PrimaryButton>
      </S.ButtonContainer>
    </S.ModalContainer>
  );

  return <Modal isShowing={isShowing} hide={hide} body={ModalBody} />;
};

export default ConfirmExitModal;
