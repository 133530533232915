import React, { useState } from 'react';
import Message from 'react-flash-message';

import './style/FlashMessage.scss';
import closeIconWhite from '@images/icons/close-x-white.svg';

interface Props {
  containerClassName?: string;
  text: string;
  className?: string;
  duration?: number;
}

const FlashMessage: React.FC<Props> = ({
  text,
  className = 'flash--black',
  duration = 7000,
  containerClassName = '',
}) => {
  const [showMessage, setShowMessage] = useState(true);

  return (
    <div className={`FM__container ${containerClassName} ${showMessage ? '' : 'hidden'}`}>
      <Message duration={duration}>
        <div className={`FM__message ${className}`}>
          {text}
          <div onClick={() => setShowMessage(false)}>
            <img src={closeIconWhite} alt="Close message" className="FM__close" />
          </div>
        </div>
      </Message>
    </div>
  );
};

export default FlashMessage;
