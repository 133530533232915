import React from 'react';
import styled from 'styled-components';

const StyledOffCanvas = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 15;

  width: 100%;
  height: 100%;
  overflow: hidden auto;

  background: white;
`;

type Props = {
  children: React.ReactNode;
  open: boolean;
};

const OffCanvas = ({ children, open }: Props): JSX.Element => (
  <>{open && <StyledOffCanvas>{children}</StyledOffCanvas>}</>
);

export default OffCanvas;
