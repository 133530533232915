import React, { FC, useEffect, useState } from 'react';

import translateComponent from './../helpers/translateI18n';
import * as S from './styled/StyledImpact';
import Repository from '../../services/repository';

interface ImpactArguments {
  productIds: number[];
  firstName: string;
  pageType: Paths;
}

enum Paths {
  ordersPage = 1,
  orderDetailPage = 2,
  showCartPage = 3,
}

const initialState = {
  kgOfCo2: 0,
  kgOfWater: 0,
};

const Impact: FC<ImpactArguments> = ({ productIds, firstName, pageType }) => {
  const translate = translateComponent('Impact');

  const [impact, setImpact] = useState(initialState);

  const fetchImpact = () => {
    Repository.getProductsImpact({ product_ids: productIds })
      .then((res) => {
        setImpact(res.data);
      })
      .catch((err: unknown) => {
        console.error('Fetch orders impact', err);
      });
  };

  useEffect(() => {
    fetchImpact();
  }, []);

  return (
    <S.Container>
      <S.Title>
        {firstName ? translate('goodJobName', { name: firstName }) : translate('goodJob')}
      </S.Title>

      <S.MaterialSaved>
        {pageType === Paths.ordersPage ? translate('saveInTotal') : translate('withThisYouSave')}
      </S.MaterialSaved>
      <S.CO2Wrapper>
        <S.CO2Icon />
        <S.ImpactText>{impact.kgOfCo2}</S.ImpactText>
        <S.ImpactText>kg</S.ImpactText>
        <S.MaterialSaved>CO2</S.MaterialSaved>
      </S.CO2Wrapper>
      <S.WaterdropWrapper>
        <S.WaterdropIcon />
        <S.ImpactText>{impact.kgOfWater}</S.ImpactText>
        <S.ImpactText>{translate('liters')}</S.ImpactText>
        <S.MaterialSaved>{translate('water')}</S.MaterialSaved>
      </S.WaterdropWrapper>
    </S.Container>
  );
};

export default Impact;
