import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { PrimaryButton as StyledButton } from './styled/styledBV';

interface NextButtonProps {
  nextStep: string;
  testid: string;
  setFormProgress: Function;
}

const NextButton: React.FC<NextButtonProps> = ({ nextStep, testid, setFormProgress }) => {
  // hooks
  const i18n = useTranslation();
  const { validateForm, setTouched } = useFormikContext();

  const handleSuccess = (goToStep: string): void => {
    setFormProgress(goToStep);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <StyledButton
      type="button"
      onClick={() =>
        validateForm().then(
          (validation: Object) =>
            Object.keys(validation).length ? setTouched(validation) : handleSuccess(nextStep) // go to next step if validations are passed
        )
      }
      data-testid={testid}
    >
      {i18n.t('next')}
    </StyledButton>
  );
};

export default NextButton;
