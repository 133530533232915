import React, { FC, memo } from 'react';
import { useSelector, Provider } from 'react-redux';
import BreadCrumb from './BreadCrumb';
import { getSelectedFilters, SelectedFilter } from '../productFilterSlice';
import store from '../../../src/store';
import {
  StyledBreadcrumbsWrapper,
} from '../../global/styled/styledGlobalBreadCrumb';
import GlobalBreadcrumb from '../../global/GlobalBreadCrumb';

const BreadcrumbsContainer: FC = memo(() => {
  const selectedFilters = useSelector(getSelectedFilters);

  let typeBreadcrumbs: SelectedFilter[] = [];
  let brandBreadcrumbs: SelectedFilter[] = [];
  function configureBreadcrumbs(object: SelectedFilter) {
    if (object.filterName === 'types') {
      typeBreadcrumbs.push(object);
    }
    if (object.filterName === 'brands') {
      brandBreadcrumbs.push(object);
    }
  }

  const categorizeFilters = () => {
    selectedFilters &&
      selectedFilters.forEach((obj: SelectedFilter) => {
        configureBreadcrumbs(obj);
      });
  };

  const getBreadcrumbs = () => {
    categorizeFilters();
    if (typeBreadcrumbs.length === 1 || brandBreadcrumbs.length === 1) {
      return (
        <StyledBreadcrumbsWrapper aria-label="breadcrumb">
          <GlobalBreadcrumb link="/" name="Home" firstBreadcrumb />
          {typeBreadcrumbs.length === 1 && (
            <BreadCrumb
              filterName={typeBreadcrumbs[0].filterName}
              id={String(typeBreadcrumbs[0].id)}
            />
          )}
          {/* only show brand breadcrumb if one type is selected */}
          {brandBreadcrumbs.length === 1 && (
            <BreadCrumb
              brandParentName={typeBreadcrumbs.length === 1 ? typeBreadcrumbs[0].id : undefined}
              filterName={brandBreadcrumbs[0].filterName}
              id={String(brandBreadcrumbs[0].id)}
            />
          )}
        </StyledBreadcrumbsWrapper>
      );
    }
  };

  return <>{getBreadcrumbs()}</>;
});

export default () => (
  <Provider store={store()}>
    <BreadcrumbsContainer />
  </Provider>
);
