import { configureStore } from '@reduxjs/toolkit';
import { render } from '@testing-library/react';
import { Formik } from 'formik';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import i18n from '../../../test/i18nForTest';
import checkout from '../checkoutSlice';
import Products from '../Products';
import { initialData } from './initialCheckoutData';

const setup = (data) => {
  const store = configureStore({ preloadedState: data, reducer: { checkout } });
  return render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Formik>
          <Products store={store} />
        </Formik>
      </I18nextProvider>
    </Provider>
  );
};

test('Shows all known products (initialData contains three products)', () => {
  const { queryByTestId } = setup(initialData);
  const products = queryByTestId('Products');
  const images = products.getElementsByTagName('img');
  expect(images.length).toBe(3);
});
