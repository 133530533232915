import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Provider, useSelector } from 'react-redux';
import axios from 'axios';
import { ThemeProvider } from 'styled-components';

import CheckoutHeader from './CheckoutHeader';
import ShippingAddress from './ShippingAddress';
import InvoiceAddress from './InvoiceAddress';
import PaymentMethod from './PaymentMethod';
import Products from './Products';
import DiscountCouponCheck from './DiscountCouponCheck';
import PriceBreakdown from './PriceBreakdown';
import ShowShippingProvider from './ShowShippingProvider';
import ScrollToError from './helpers/ScrollToError';

import LaunchZenDesk from './helpers/LaunchZendesk';
import Spinner from '../global/Spinner';

import useWindowWidth from '../helpers/hooks/useWindowWidth';

import store, { RootState } from '../../src/store';

// yup
import { checkoutSchema } from './yupCheckoutSchema';

import * as S from './styled/StyledCheckout';
import { Theme } from '../../services/theme';
import { Address, InitialAddress } from './CheckoutTypes';
import ErrorBoundary from '../global/ErrorBoundary';
import Repository from '../../services/repository';
import { setHeaders } from '../../services/helpers';
import { getAuthentication } from '../User/userSlice';

const Checkout = () => {
  // hacky way to make sure the user sees the correct data when they press back from mangopay to checkout
  if (window.performance.navigation?.type === 2) {
    window.location.reload();
  }

  // Hooks
  const i18n = useTranslation();
  const width = useWindowWidth();

  const initialData = useSelector((state: RootState) => state.checkout.initialAddressData);
  const termsPath = useSelector((state: RootState) => state.checkout.termsPath);
  const products = useSelector((state: RootState) => state.checkout.products);
  const { returnValueCouponCheck } = useSelector((state: RootState) => state.checkout);
  const authentication = useSelector(getAuthentication);

  // GTM Tracking
  const productData = () => {
    return (
      products &&
      products.map((product) => ({
        item_name: product.id + ' ' + product.brand + ' ' + product.typeEN,
        item_id: product.id,
        price: parseInt(product.original_price),
        discount: parseInt(product.original_price) - parseInt(product.display_price),
        item_category: product.rootType_en,
        item_category2: product.typeEN,
        item_category3: product.brand,
        item_variant: product.color,
        quantity: 1,
        item_seller_type: product.seller_type,
        item_material: product.material,
        item_condition: product.condition,
      }))
    );
  };

  const productsDataKlaviyo = () => {
    return (
      products &&
      products.map((p) => ({
        "ProductID": p.id,
        "ProductName": p.brand + ' ' + p.type,
        "Quantity": 1,
        "ItemPrice": parseFloat(p.display_price),
        "RowTotal": parseFloat(p.display_price),
        "ProductURL": "https://thenextcloset.com" + p.product_path,
        "CheckoutURL": "https://thenextcloset.com" + p.link_to_order,
        "ImageURL": p.image,
        "ProductCategories": [p.rootType_en, p.type_en]
      }))
    );
  }

  useEffect(() => {
    const value = products.reduce((acc, obj) => acc + parseFloat(obj.display_price), 0);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        currency: 'EUR',
        items: productData(),
      },
    });

    var _learnq = window._learnq || [];
    _learnq.push(["track", "Started Checkout", {
      "$event_id": String((new Date).getTime()),
      "$value": value,
      "ItemNames": products.map((p) => p.brand + ' ' + p.type),
      "Categories": products.map((p) => p.typeEN),
      "Items": productsDataKlaviyo(),
    }]);
  }, []);

  const trackAddPaymenInfo = ({
    creditcardType,
    coupon,
    paymentMethod,
  }) => {
    if(returnValueCouponCheck && !returnValueCouponCheck.valid) {
      coupon = null;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'add_payment_info',
      ecommerce: {
        currency: 'EUR',
        value: products.reduce((acc, obj) => acc + parseFloat(obj.display_price), 0),
        coupon: coupon,
        payment_type: paymentMethod,
        items: productData(),
      },
    });
  } 

  const termsLink = <S.Link href={termsPath}>{i18n.t('checkoutTerms')}</S.Link>;

  const buildOrderForRails = ({
    creditcardType,
    coupon,
    paymentMethod,
    shipping,
    invoice,
  }: {
    creditcardType: string;
    coupon: string;
    paymentMethod: string;
    shipping: Address;
    invoice: Address;
  }) => ({
    shipping_addr_is_invoice_addr: initialData.shippingIsInvoice,
    coupon_code: coupon ? coupon : null,
    payment_method: paymentMethod,
    creditcard_type: creditcardType,
    item_ids_for_pickup: initialData.itemIdsforPickup,
    order: {
      user_attributes: {
        shipping_address_attributes: {
          firstname: shipping.firstname,
          lastname: shipping.lastname,
          street: shipping.street,
          housenumber: shipping.housenumber,
          housenumber_addition: shipping.housenumberAddition,
          city: shipping.city,
          country: shipping.country,
          zipcode: shipping.zipcode,
          address_is_validated: initialData.shipping.addressIsValidated,
        },
        invoice_address_attributes: {
          firstname: invoice.firstname,
          lastname: invoice.lastname,
          street: invoice.street,
          housenumber: invoice.housenumber,
          housenumber_addition: invoice.housenumberAddition,
          city: invoice.city,
          country: invoice.country,
          zipcode: invoice.zipcode,
        },
      },
    },
  });

  const initialValues: InitialAddress = initialData;

  return (
    <S.Container>
      <CheckoutHeader windowWidth={width} />
      <Formik
        initialValues={initialValues}
        validationSchema={checkoutSchema(initialData.shippingIsInvoice)}
        onSubmit={(values, actions) => {
          const paramsForRails = buildOrderForRails(values);
          actions.setSubmitting(true);
          const csrf = document?.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

          axios
            .post(initialData.createOrderPath, paramsForRails, {
              headers: { Accept: 'application/json', 'X-CSRF-TOKEN': csrf },
            })
            .then((res) => {
              if (res !== undefined && res.data.redirectPath) {
                window.location.assign(res.data.redirectPath);

                if(typeof ADDWISH_PARTNER_NS  !== "undefined") {
                  Repository.getOrderProductUrls({ headers: setHeaders(authentication) }).then((res) => {
                    var data = res.data
                    var total = data["total"]
                    var orderNumber = data["orderNumber"]
                    var email = data["email"]
                    var urls = data["urls"]

                    _awev=(window._awev||[]);_awev.push(["bind_once", "context_ready", function() {
                      ADDWISH_PARTNER_NS.api.conversion.track_sale({
                        total: total,
                        orderNumber: orderNumber,
                        email: email,
                        urls: urls
                      });
                    }]);
                  });

                  ADDWISH_PARTNER_NS.api.cart.clearCart(function() {
                    console.log('Cart has been cleared');
                  });
                }
              }
            })
            .catch(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            {/* scroll to first error on smaller screens */}
            {width < 1025 ? <ScrollToError /> : ''}
            <LaunchZenDesk />
            <S.Title className="h__f--l--b">{i18n.t('checkoutTitle')}</S.Title>
            <S.Wrapper>
              <S.SubWrapper>
                <S.Header className="bold">{i18n.t('checkoutStep1')}</S.Header>
                <ShippingAddress />
              </S.SubWrapper>
              <S.SubWrapperBorder marginBottom>
                <S.Header className="bold">{i18n.t('checkoutStep2')}</S.Header>
                <PaymentMethod />
                {/* only show InvoiceAddress if it's not equal to ShippingAddress */}
                {initialData.shippingIsInvoice ? '' : <InvoiceAddress />}
              </S.SubWrapperBorder>
              <S.SubWrapperBorder>
                <S.Header className="bold">{i18n.t('checkoutStep3')}</S.Header>
                <Products />
                <DiscountCouponCheck />
                <PriceBreakdown />
                <ShowShippingProvider />
                <S.Button
                  type="submit"
                  disabled={isSubmitting && !dirty}
                  data-testid="submit"
                  id="trk-order-submit-button"
                >
                  {i18n.t('CheckoutCompleteOrder').toUpperCase()}
                </S.Button>
                <S.Terms>
                  {i18n.t('checkoutAgreeWithTerms')}
                  {termsLink}
                  {i18n.t('checkoutTNC')}{' '}
                </S.Terms>
              </S.SubWrapperBorder>
            </S.Wrapper>
            {/* show spinner when submitting  */}
            {isSubmitting ? <Spinner data-testid="spinners" /> : ''}
          </Form>
        )}
      </Formik>
    </S.Container>
  );
};

export { Checkout };

export default (props: JSX.IntrinsicAttributes) => (
  <Provider store={store()}>
    <ThemeProvider theme={Theme}>
      <ErrorBoundary>
        <Checkout {...props} />
      </ErrorBoundary>
    </ThemeProvider>
  </Provider>
);
