import React from 'react';

import useModal from '../Modal/useModal';
import ConfirmItemReceivedModal from './ConfirmItemReceivedModal';
import * as S from './styled/styledRelistReturn';
import translateComponent from '../helpers/translateI18n';

interface Props {
  id: number;
  lineItemState: 'in_progress' | 'at_pickup_point' | 'delivered';
  sellerType: 'diy' | 'boutique' | 'premium' | 'premium_visible';
}

const TriggerConfirmItemReceivedModal: React.FC<Props> = ({ id, lineItemState, sellerType }) => {
  const translate = translateComponent('RelistReturn');
  const { isShowing, toggle } = useModal();

  const ButtonText = () => {
    return lineItemState === 'at_pickup_point'
      ? translate('itemPickedUp')
      : translate('itemReceived');
  };

  return (
    <>
      <S.SecondaryButton onClick={toggle}>{ButtonText()}</S.SecondaryButton>
      <ConfirmItemReceivedModal
        isShowing={isShowing}
        hide={toggle}
        id={id.toString()}
        lineItemState={lineItemState}
        sellerType={sellerType}
      />
    </>
  );
};

export default TriggerConfirmItemReceivedModal;
