import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import workingHours from '../../helpers/workingHours';

export default function LaunchZenDesk() {
  // launch the zendesk widget during working hours after 3 times trying to submit and failing
  const { submitCount } = useFormikContext();
  useEffect(() => {
    if (submitCount === 3 && workingHours()) {
      // eslint-disable-next-line no-undef
      document?.getElementById('js-zendeskLauncher')?.click();
    }
  }, [submitCount]);
  return null;
}
