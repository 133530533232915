import React from 'react';
import { StyledBreadcrumbWrapper, StyledBreadcrumb } from './styled/styledGlobalBreadCrumb';

interface GlobalBreadcrumbProps {
  name: string;
  link: string;
  dataTestId?: string;
  firstBreadcrumb?: boolean; // has no slash to the left
}

const GlobalBreadcrumb: React.FC<GlobalBreadcrumbProps> = ({
  name,
  link,
  dataTestId = '',
  firstBreadcrumb = false,
}) => {
  return (
    <StyledBreadcrumbWrapper>
      {firstBreadcrumb ? null : <p>/</p>}
      <StyledBreadcrumb href={link} data-testid={dataTestId}>
        {name}
      </StyledBreadcrumb>
    </StyledBreadcrumbWrapper>
  );
};

export default GlobalBreadcrumb;
