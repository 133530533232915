import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';

Sentry.onLoad(async () => {
  Sentry.init({
    dsn: 'https://0287576375914f1a926c8194c1c00434@o441440.ingest.sentry.io/5411624',
    release: process.env.SENTRY_RELEASE,
    // An array of strings or regexps that'll be used to ignore specific errors based on their type/message
    ignoreErrors: [
      // setImmediate$0 happens in Facebook browser on Iphones in 3rd party (probably facebook) code. Doesn't affect user.
      'setImmediate$0',
      // offsetWidth error happens to Andriod mobile on products#show. Doesn't affect user. This error can be removed as soon as we get rid of ui-kit
      'offsetWidth',
      // ignore error in facebook JS code happening in FB browser on Iphone
      'JSON Parse error: Unexpected identifier "object"',
      // ignore yet another error in facebook JS code happening in FB browser on Iphone
      'Not enough arguments',
    ],
    integrations: [new CaptureConsole({ levels: ['error'] })],
    environment: process.env.NODE_ENV,
  });
});
