import React from 'react';

interface Button {
  className: string;
  link?: string;
  text: string;
  onClick?: MouseEvent;
  id?: string;
  dataMethod?: string;
}

const Button: React.FC<Button> = ({
  className,
  link = null,
  text = '',
  onClick = null,
  id = '',
  dataMethod = '',
}) => {
  return (
    <a href={link} className={className} onClick={onClick} id={id} data-method={dataMethod}>
      {text}
    </a>
  );
};

export default Button;
