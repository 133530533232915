$(() => {
  $('.topnav .main a[data-target]').click(function topnavMain(e) {
    e.preventDefault();
    const $this = $(this);
    if ($('.topnav .active').is($this)) {
      $this.removeClass('active');
      $this.blur();
      $('.topnav').removeClass('open');
    } else {
      $('.topnav').addClass('open');
      $('.topnav .active').removeClass('active');
      $this.addClass('active');
    }

    const $new = $('.topnav').find($(this).data('target'));
    $new.toggle();
    return $new.siblings('.sub').hide();
  });

  $('.topnav .main .locale').click(function topnavLocale(e) {
    e.preventDefault();
    const $this = $(this);
    return $this.toggleClass('open');
  });

  $(".topnav .main .dropdown").on('mouseenter', function (event) {
    event.stopPropagation();
    $(this).addClass("open");
    $(this).find('.dropdown-toggle').attr("aria-expanded","true");
  });

  $(".topnav .main .dropdown").on('mouseleave', function () {
    $(this).find('.dropdown-toggle').attr("aria-expanded","false");
    $(this).removeClass("open");
  });

  document.addEventListener('DOMContentLoaded', function() {
    var dropdownToggle = document.querySelector('.dropdown-toggle');
   
    dropdownToggle.addEventListener('click', function(event) {
      if (this.getAttribute('aria-expanded') === 'false') {
        // Allow the default action if the dropdown is closed
        return;
      } else {
        // Prevent the default action if the dropdown is open
        event.preventDefault();
      }
    });
  });
   
  return $('.topnav .main .locale a').click(function topnavLocaleA(e) {
    let locale;
    if (locale = $(this).data('locale')) {
      Cookies.set('lang', locale);
      return e.stopPropagation();
    }
  });
});
