import React, { useState, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Item from './Item';

import * as S from './styled/styledF';

// Dropdown component
const GroupDropdown = ({clothingSizeOpen, setClothingSizeOpen, sizeFilters, isMobile }) => {
  const i18n = useTranslation();
  const [search, setSearch] = useState('');

  const filterStates = sizeFilters.map(name => {
    const items = useSelector(state => state.productFilter.filters[name].items);
    const itemIds = useSelector(state => state.productFilter.filters[name].itemIds);
    const isOpen = useSelector(state => state.productFilter.filters[name].open);

    return { name, items, itemIds, isOpen };
   });

   const visibleItems = useMemo(() => filterStates.map(({ name, items, itemIds }) => itemIds.filter(id => items[id].name.toLowerCase().indexOf(search.toLowerCase()) > -1)), [filterStates, search]);
   const active = useMemo(() => filterStates.some(({ itemIds, items }) => itemIds.some(id => items[id].selected)), [filterStates]);


  const [moreOpen, setMoreOpen] = useState(false);
  const [kidsSizesOpen, setKidsSizesOpen] = useState(false);
  const [isMoreSizesBtnRendered, setIsMoreSizesBtnRendered] = useState(false);
  const [isMoreKidsSizesBtnRendered, setIsMoreKidsSizesBtnRendered] = useState(false);

  const styledTitle = {
    border: 'none',
    padding: '0 12px',
    fontSize: '14px',
  };

  const getVisibleItems = (name) => {
    return visibleItems[sizeFilters.indexOf(name)];
  }

  const getListContainer = (name) => {
    const specificVisibleItems = getVisibleItems(name);
    return (
      <S.ListContainer>
        <S.List>
          {specificVisibleItems.map(id => (
            <Item key={id} filterName={name} id={id} isMobile={isMobile} />
          ))}
        </S.List>
      </S.ListContainer>
    );
  };

  const getMoreSizesBtn = (
    <S.DropdownButton
      onClick={() => {
        setMoreOpen(!moreOpen);
      }}
      isExpanded={moreOpen}
      style={{border: 'none'}}
    >
    {`+ ${i18n.t('more_sizes')}`}
    </S.DropdownButton>
  );

  const getKidsSizesBtn = (
    <S.DropdownButton
      onClick={() => {
        setKidsSizesOpen(!kidsSizesOpen);
      }}
      isExpanded={kidsSizesOpen}
      style={{border: 'none'}}
    >
    {`+ ${i18n.t('show_kids_sizes')}`}
    </S.DropdownButton>
  );

  const ClothingSizes = ({name}) => {
    return (
      <>
        <S.DropdownButton
          withoutArrow={true}
          style={styledTitle}
        >
        {i18n.t('clothing')}
        </S.DropdownButton>
        {getListContainer(name)}
      </>
    );
  };

  const ShoeSizes = ({name}) => {
    return (
      <>
        {moreOpen && (
          <>
            <S.DropdownButton
              withoutArrow={true}
              style={styledTitle}
            >
            {i18n.t('shoes')}
            </S.DropdownButton>
            {getListContainer(name)}
          </>
        )}
      </>
    );
  };

  const KidsClothingSizes = ({name}) => {
    return (
      <>
        {kidsSizesOpen && (
          <>
            <S.DropdownButton
              withoutArrow={true}
              style={styledTitle}
            >
            {i18n.t('kids_clothing')}
            </S.DropdownButton>
            {getListContainer(name)}
          </>
        )}
      </>
    );
  };

  const KidsShoeSizes = ({name}) => {
    return (
      <>
        {kidsSizesOpen && (
          <>
            <S.DropdownButton
              withoutArrow={true}
              style={styledTitle}
            >
            {i18n.t('kids_shoes')}
            </S.DropdownButton>
            {getListContainer(name)}
          </>
        )}
      </>
    );
  };

  const JeansSizes = ({name}) => {
    return (
      <>
        {moreOpen && (
          <>
            <S.DropdownButton
              withoutArrow={true}
              style={styledTitle}
            >
            {i18n.t('jeans')}
            </S.DropdownButton>
            {getListContainer(name)}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <S.DropdownButton
        onClick={() => setClothingSizeOpen(!clothingSizeOpen)}
        isExpanded={clothingSizeOpen}
      >
        {i18n.t(`${'size'}`)}
      </S.DropdownButton>
      {clothingSizeOpen && (
        <>
          {sizeFilters.map(filterName => {
            if (filterName === 'clothing-sizes' && getVisibleItems(filterName).length > 0) {
              return <ClothingSizes key={filterName} name={filterName}/>;
            }
          })}
          {isMoreSizesBtnRendered && getMoreSizesBtn}
          {sizeFilters.map(filterName => {
            if (filterName === 'jeans-sizes' && getVisibleItems(filterName).length > 0) {
              if (!isMoreSizesBtnRendered) {
                setIsMoreSizesBtnRendered(true);
              }
              return <JeansSizes key={filterName} name={filterName}/>;
            } else if (filterName === 'shoe-sizes' && getVisibleItems('shoe-sizes').length > 0) {
              if (!isMoreSizesBtnRendered) {
                setIsMoreSizesBtnRendered(true);
              }
              return <ShoeSizes key={filterName} name={filterName}/>;
            }
          })}
          {isMoreKidsSizesBtnRendered && getKidsSizesBtn}
          {sizeFilters.map(kidsFilterName => {
            if (kidsFilterName === 'kids-clothing-sizes' && getVisibleItems(kidsFilterName).length > 0) {
              if (!isMoreKidsSizesBtnRendered) {
                setIsMoreKidsSizesBtnRendered(true);
              }
              return <KidsClothingSizes key={kidsFilterName} name={kidsFilterName}/>;
            } else if (kidsFilterName === 'kids-shoe-sizes' && getVisibleItems(kidsFilterName).length > 0) {
              if (!isMoreKidsSizesBtnRendered) {
                setIsMoreKidsSizesBtnRendered(true);
              }
              return <KidsShoeSizes key={kidsFilterName} name={kidsFilterName}/>;
            }
          })}
        </>
      )}
    </>
   );
};

export default memo(GroupDropdown);
