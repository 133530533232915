import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import * as S from '../styled/StyledConfirmOmniAuth';

interface OmniAuthProps {
  name: string;
  termsLink: string;
  registerLink: string;
}

const ConfirmOmniAuth: React.FC<OmniAuthProps> = ({ name, termsLink, registerLink }) => {
  const [checked, setChecked] = useState(false);
  const handleClick = () => setChecked(!checked);
  const { i18n } = useTranslation();

  const translate = (text: string) => {
    return i18n.t(`Auth.${text}`);
  };

  const getLinkWithNewsLetter = `${registerLink}?newsletter=${checked}`;

  return (
    <S.ConfirmWrapper>
      <S.Logo />
      <S.Title>{`Almost there, ${name}!`}</S.Title>
      <input onClick={handleClick} type="checkbox" id="newsletter" />
      <S.Label htmlFor="newsletter">
        <ReactMarkdown>{translate('newsletter')}</ReactMarkdown>
      </S.Label>
      <S.Text>
        {translate('accept')}
        <S.Link href={termsLink}>{translate('terms_and_conditions')}</S.Link>
      </S.Text>
      <S.PrimaryLink href={getLinkWithNewsLetter}>{i18n.t('confirm').toUpperCase()}</S.PrimaryLink>
    </S.ConfirmWrapper>
  );
};

export default ConfirmOmniAuth;
