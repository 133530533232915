import React from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import store from '../../../src/store';
import MainPage from './MainPage';
import OffCanvas from './OffCanvas';
import { setMobileOpen, fetchProducts } from '../productFilterSlice';
import Spinner from '../../global/Spinner';

const MobileFilterContainer = () => {
  const pages = useSelector(state => state.productFilter.mobileFilter.pages);
  const isOpen = useSelector(state => state.productFilter.mobileFilter.open);
  const dispatch = useDispatch();

  const { filterName, id } = pages[pages.length - 1];

  const loading = useSelector((state) => state.productFilter.loading);

  const handleClose = () => {
    dispatch(fetchProducts());
    dispatch(setMobileOpen(false));
  }

  return (
    <>
      {loading && <Spinner />}
      <OffCanvas open={isOpen}>
        {(filterName === 'main' && (
          <MainPage handleClose={handleClose} />
        ))}
      </OffCanvas>
    </>
  );
};

MobileFilterContainer.propTypes = {};

export default () => (
  <Provider store={store()}>
    <MobileFilterContainer />
  </Provider>
);
