import React from 'react';
import { screen, fireEvent, render } from '@testing-library/react';
import { I18nextProvider } from 'react-i18next';

import i18n from '../../../test/i18nForTest';
import BrandsContainer from '../BrandsContainer';

const setup = props => {
  return render(
    <I18nextProvider i18n={i18n}>
      <BrandsContainer {...props} />
    </I18nextProvider>
  );
};

const brandData = {
  brands: {
    g: [
      {
        name: 'Gucci',
        slug: '/gucci-slug',
        desirable: true,
        sustainable: false,
      },
      {
        name: 'Givenchy',
        slug: '/givenchy-slug',
        desirable: false,
        sustainable: true,
      },
    ],
  },
};

describe('filtering by type of brand', () => {
  beforeEach(() => {
    setup(brandData);
  })

  test('show only premium brands when checkbox is checked',  () => {
    // Given
    const premiumCheckbox = screen.getByRole('checkbox', {name: 'Bekijk premium merken' });
    expect(premiumCheckbox).toBeTruthy();
    expect(premiumCheckbox.checked).toBeFalsy(); 
    expect (screen.queryByText('Gucci')).toBeTruthy();
    expect (screen.queryByText('Givenchy')).toBeTruthy();

    // When
    fireEvent.click(premiumCheckbox);

    // Then
    expect (premiumCheckbox.checked).toBeTruthy();
    expect (screen.queryByText('Gucci')).toBeTruthy(); 
    expect (screen.queryByText('Givenchy')).toBeFalsy();
  })

  test('show only sustainable brands when checkbox is checked',  () => {
    // Given
    const sustainableCheckbox = screen.getByRole('checkbox', {name: 'Bekijk duurzame merken' });
    expect(sustainableCheckbox).toBeTruthy();
    expect(sustainableCheckbox.checked).toBeFalsy();
    expect(screen.queryByText('Gucci')).toBeTruthy();
    expect(screen.queryByText('Givenchy')).toBeTruthy();

    // When
    fireEvent.click(sustainableCheckbox);

    // Then
    expect (sustainableCheckbox.checked).toBeTruthy();
    expect (screen.queryByText('Gucci')).toBeFalsy(); 
    expect (screen.queryByText('Givenchy')).toBeTruthy();
  })
});


describe('search by brand', () => {
  let searchBar;
  let searchQuery;

  beforeEach(() => {
    setup(brandData); 
    searchBar = screen.getByRole('textbox', { type: 'text'});
    searchQuery = screen.getByPlaceholderText('Zoek een merk...');

    // Given
    expect(searchBar).toBeTruthy(); 
    expect(searchQuery).toBeTruthy();
    expect (screen.queryByText('Gucci')).toBeTruthy(); 
    expect (screen.queryByText('Givenchy')).toBeTruthy();

  })
  
  test('search query does not match any brands', () => {
    // When 
    fireEvent.change(searchQuery, { target: { value: '@asefadf7A!' } });
    
    // Then
    expect (screen.queryByText('Gucci')).toBeFalsy(); 
    expect (screen.queryByText('Givenchy')).toBeFalsy();
  })

  test('search brand by letter', () => {
     // When 
     fireEvent.change(searchQuery, { target: { value: 'G' } });
     
     // Then
     expect (screen.queryByText('Gucci')).toBeTruthy();
     expect (screen.queryByText('Givenchy')).toBeTruthy();
  })

  test('brands shown match search query', () => {
    // When 
    fireEvent.change(searchQuery, { target: { value: 'Givenchy' } });
     
    // Then
     expect (screen.queryByText('Gucci')).toBeFalsy();
     expect (screen.queryByText('Givenchy')).toBeTruthy();
  })
});
