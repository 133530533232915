import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { gtmProductListSerializer } from '../Analytics/gtmProductSerializer';
import useWindowWidth from '../helpers/hooks/useWindowWidth';
import store from '../../src/store';

const ProductCardTypeA = React.lazy(() => import('./ProductCardTypeA'));

function ProductCardContainerResponsive({
  products,
  numberOfCardsMobile,
  numberOfCardsTablet,
  numberOfCardsDesktop,
}) {
  const currentWidth = useWindowWidth();

  useEffect(() => {
    // GTM Tracking
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        currency: 'EUR',
        items: products && Object.entries(products).map(gtmProductListSerializer),
        items_google_ads: items && Object.entries(items).map((product) => {
          return {
            item_id: product[1].id,
            google_business_vertical: 'retail'
          }
        })
      },
    });
  }, [products]);

  function getNumberOfCards(width) {
    let numberOfCards;
    if (width < 768) {
      numberOfCards = numberOfCardsMobile;
    } else if (width < 1200 && width > 767) {
      numberOfCards = numberOfCardsTablet;
    } else {
      numberOfCards = numberOfCardsDesktop;
    }
    return numberOfCards;
  }

  return (
    <div data-testid="productcard-container-responsive">
      {products.slice(0, getNumberOfCards(currentWidth)).map((product) => (
        <div className="col-xs-6 col-md-4 col-lg-3" key={product.id} data-testid="productCard">
          <Suspense fallback={<div>Loading...</div>}>
            <ProductCardTypeA {...product} />
          </Suspense>
        </div>
      ))}
    </div>
  );
}

ProductCardContainerResponsive.propTypes = {
  numberOfCardsMobile: PropTypes.number.isRequired,
  numberOfCardsDesktop: PropTypes.number.isRequired,
  numberOfCardsTablet: PropTypes.number.isRequired,
};

export default (props) => (
  <Provider store={store()}>
    <ProductCardContainerResponsive {...props} />
  </Provider>
);
