import styled from 'styled-components';

export const LargeBoldHeader = styled.p`
  font-family: 'Playfair Display', serif;
  font-size: 36px;
  font-weight: 900;
  color: black;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;
