import React from 'react';
import { render, cleanup, act, waitFor } from '@testing-library/react';
import { I18nextProvider } from 'react-i18next';

import { resizeWindowWidth } from '../../../test/testUtils';
import ProductCardContainerResponsive from '../ProductCardContainerResponsive';
import ProductCard from '../ProductCard';
import i18n from '../../../test/i18nForTest';

const products = require('./testProducts');

const defaultProps = {
  products: products.array,
  numberOfCardsMobile: 4,
  numberOfCardsTablet: 6,
  numberOfCardsDesktop: 8,
};

afterEach(() => {
  resizeWindowWidth(1024); // reset width to standard
});

const setup = (props = {}) => {
  const setupProps = { ...defaultProps, ...props };
  return render(
    <I18nextProvider i18n={i18n}>
      <ProductCardContainerResponsive {...setupProps} />
    </I18nextProvider>
  );
};

// TODO: replace these tests with tests that use the redux store
xtest('should render an empty container when there are no products', () => {
  const { queryByTestId, getByTestId } = setup({ products: [] });
  expect(getByTestId('productcard-container-responsive')).toBeTruthy();
  expect(queryByTestId('product-card-wrapper')).toBeFalsy();
});

xtest('should render 8 products when passed 10 products on large screen', async () => {
  act(() => resizeWindowWidth(1280));
  const { getAllByTestId } = setup();
  await waitFor(() => expect(getAllByTestId('productCard')).toHaveLength(8));
});

xtest('should render 6 products when passed 10 products on Ipad screen', async () => {
  act(() => {
    resizeWindowWidth(1000);
  });
  const { getAllByTestId } = setup();
  await waitFor(() => expect(getAllByTestId('productCard')).toHaveLength(6));
});

xtest('should render 4 products when passed 10 products on mobile screen', async () => {
  act(() => {
    resizeWindowWidth(375);
  });
  const { getAllByTestId } = setup();
  await waitFor(() => expect(getAllByTestId('productCard')).toHaveLength(4));
});
