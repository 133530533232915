import styled from 'styled-components';
import screen from '../StyledGlobal/mediaQueries';

export const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
  justify-content: space-between;
  justify-items: stretch;
  padding: 15px;
  width: 75%;
`;

export const Message = styled.div`
  justify-content: space-between;
  min-height: 40px;
  padding: 10px 15px 0 15px;

  color: ${(props) => (props.green ? 'green' : 'black')};

  border-radius: 0px;
  opacity: 0.8;
`;

export const PhotoTips = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const PhotoTipsWrapper = styled.div`
  position: relative;
  margin-top: 10px;
`;

export const PhotoTipImage = styled.img`
  width: 170px;
  height: 170px;
  border: 5px solid var(--light-green);
  margin: 8px;
  @media (max-width: 450px) {
    width: 150px;
    height: 150px;
  }
  @media (max-width: 360px) {
    width: 130px;
    height: 130px;
  }
`;

export const PhotoTipLabel = styled.p`
  position: absolute;
  bottom: 20px;
  left: 8px;
  background: var(--light-green);
  padding: 3px 6px;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: ${(props) => (props.spaceBetween ? 'space-between' : 'start')};
`;

export const Error = styled.div`
  color: var(--brand-warning);
  height: 20px;
  padding-bottom: 10px;
`;
