import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  toggle,
  getFilters,
  fetchProducts,
  setCurrentPage,
  FilterState,
  getExtraFilters,
  toggleExtraFilter,
} from '../productFilterSlice';

import * as S from './styled/styledRF';

type Props = {
  filterName: string;
  id: string | number;
};

const RemoveFilter: FC<Props> = ({ filterName, id }) => {
  const extraFilters = useSelector(getExtraFilters);
  const item = filterName != 'premium' ? useSelector(
    (state: { productFilter: FilterState }) => getFilters(state)[filterName].items[id]
  ) : null;
  const dispatch = useDispatch();
  const [showed, setShowed] = useState(false);
  const i18n = useTranslation();
  useEffect(() => {
    // console.log('Item changed in removefilter:', filterName, id);
  }, [filterName, id, extraFilters]);

  const handleApply = () => {
    dispatch(toggle({ filterName, id }));
    dispatch(setCurrentPage(1));
    dispatch(fetchProducts());
  };

  const handleFilterName = () => {
    if (filterName.toLowerCase().includes('size')) {
      const sizeName = item ? item.name.split(" / ")[0] : '';
      return `${i18n.t('size')}: ${i18n.t(sizeName.toString())}`;
    } else {
      return item && i18n.t(item.name);
    }
  };

  const handleSellersType = () => {
    dispatch(setCurrentPage(1));
    dispatch(toggleExtraFilter('premiumOnly'));
    dispatch(fetchProducts());
  };

  return item && item.selected ? (

    <S.RemoveFilter onClick={() => handleApply()} >
      <p className='truncate' title={handleFilterName() || ''}>
        {handleFilterName()}
      </p>
      <S.CloseX onClick={()=> setShowed(!showed)}/>
    </S.RemoveFilter>
  ) : extraFilters && extraFilters.premiumOnly.active ? (
    <S.RemoveFilter onClick={() => handleSellersType()} >
      <p className='truncate' title={'Premium'}>
        {'Premium'}
      </p>
      <S.CloseX onClick={()=> setShowed(!showed)}/>
    </S.RemoveFilter>
   ) : null;
};

export default RemoveFilter;
