import React, { useEffect, useState } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch, ThemeProvider } from '@material-ui/core';
import { muiTheme } from '../../services/muiTheme';

import { setTotalDiscount, getLineItemsForPickUp } from './checkoutSlice';

import * as S from './styled/styledProducts';
import toEuro from '../helpers/toEuro';
import { RootState } from '../../src/store';
import store from '../../src/store';
import { CheckoutProduct } from './CheckoutTypes';

const Products = () => {
  const products = useSelector((state: RootState) => state.checkout.products);
  const itemsForPickUp: number[] = useSelector(
    (state: RootState) => state.checkout.initialAddressData.itemIdsforPickup
  );
  let coupon = useSelector((state: RootState) => state.checkout.discount);

  const [displayClickCollectBanner, setClickCollectBanner] = useState(false);

  const dispatch = useDispatch();
  const i18n = useTranslation();

  useEffect(() => {
    handleDiscount();
  }, [itemsForPickUp, coupon]);

  const updateItemsForPickUp = (productId: number) => {
    dispatch(getLineItemsForPickUp({ itemsForPickUp, productId }));
  };

  const handleDiscount = () => {
    const discountPerItem = 7;
    const pickupDiscount = itemsForPickUp.length * discountPerItem;

    if (!coupon) {
      coupon = 0;
    }

    dispatch(setTotalDiscount(coupon + pickupDiscount));
  };

  const isEligibleForPickup = (product: CheckoutProduct): boolean => {
    const isPremium: boolean =
      product.seller_type === 'premium' || product.seller_type === 'premium_visible';

    if (isPremium && displayClickCollectBanner === false) {
      setClickCollectBanner(true);
    }

    return isPremium;
  };

  const displayProducts =
    products &&
    products.map((product) => (
      <S.Wrapper key={product.id}>
        <S.Image src={product.image} alt={`${product.brand} ${product.type}`} />
        <S.SubWrapper>
          <S.ProductInfoContainer pickup={isEligibleForPickup(product)}>
            <div>
              <div>{product.brand}</div>
              <div>{product.type}</div>
            </div>
            <div>{toEuro(product.display_price)}</div>
          </S.ProductInfoContainer>
          {false && (
            <S.ToggleContainer>
              <div>{i18n.t('checkoutClickCollectSelectPickUp')}</div>
              <Switch
                checked={itemsForPickUp.includes(product.id)}
                inputProps={{ role: 'switch' }}
                onClick={() => {
                  updateItemsForPickUp(product.id);
                }}
                color="secondary"
              />
            </S.ToggleContainer>
          )}
        </S.SubWrapper>
      </S.Wrapper>
    ));

  return (
    <>
      {false ? (
        <div>
          <S.ClickCollectWrapper>
            <S.ClickCollectBannerText>
              <S.Title>{i18n.t('checkoutClickCollect')}</S.Title>
              <S.Text>{i18n.t('checkoutClickCollectDiscount')}</S.Text>
            </S.ClickCollectBannerText>
            <S.ClickCollectBannerImage />
          </S.ClickCollectWrapper>
          <S.CollectionAddress>
            <S.Link
              href="https://www.google.com/maps/place/The+Next+Closet+B.V./@52.36934,4.8682,17z/data=!3m2!4b1!5s0x47c609dfbea685b7:0xdafb04516ebe377e!4m5!3m4!1s0x47c609e97b4335ed:0xc307f087bb2fc6a6!8m2!3d52.36934!4d4.870394"
              target="_blank"
              rel="noreferrer"
            >
              <S.AddressLocationPinIcon />
              <S.Address>{i18n.t('checkoutClickCollectAddress')}</S.Address>
            </S.Link>
          </S.CollectionAddress>
        </div>
      ) : null}

      <div data-testid="Products">{displayProducts}</div>
    </>
  );
};

export default (props = {}) => (
  <Provider store={props?.store ?? store()}>
    <ThemeProvider theme={muiTheme}>
      <Products />
    </ThemeProvider>
  </Provider>
);
