import React from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next";

interface Props {
  brandDescription: string;
}

const BrandDescription: React.FC<Props> = ({ brandDescription }) => {
  const i18n = useTranslation();
  const [truncatedBrandDescription, setTruncatedBrandDescription] 
    = useState(
      brandDescription.length > 230 ?
       `${brandDescription.substring(0, 230)}... ` 
       : brandDescription
      )
  const [readMoreActive, setReadMoreActive] = useState(brandDescription.length > 230)
  const truncate = (event: React.MouseEvent) => {
    event.preventDefault()
    setReadMoreActive(false)
    setTruncatedBrandDescription(brandDescription)
  }
  return (
    <p>
      {truncatedBrandDescription}
      {readMoreActive &&
        <a href="#" className="link--underline" onClick={(e) => truncate(e)}>
          {i18n.t('brandDescriptionReadMore')}
        </a>
      }
    </p>
  )
}

export default BrandDescription;
