import styled from 'styled-components';
import { StyledPrimaryButton } from '../../StyledGlobal/StyledButton';
import screen from '../../StyledGlobal/mediaQueries';
import { Theme } from '../../../services/theme';

export const StyledProductShowButton = styled(StyledPrimaryButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 300px;
  background-color: ${Theme.colors.black};
  color: ${Theme.colors.white};
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  border: 1px solid ${Theme.colors.black};
  text-transform: none;
  :hover {
    color: ${Theme.colors.black};
    transition: all 0.2s ease 0s;
    background-color: transparent;
  }
  @media (max-width: 991) {
    width: 250px;
  }

  ${screen.xsMax`left: 0;
    width: 100%;
    font-size: 14px;
    `}
`;

export const StyledIndexAddToCartButton = styled.a`
  height: ${(props) => (props.slideInView ? '45px' : '0')};
  top: ${(props) => (props.slideInView ? '-45px' : '0')};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  left: -15px;
  background: ${Theme.colors.green};
  color: ${Theme.colors.white};
  right: -15px;
  overflow: hidden;
  transition: 0.3s ease;
  cursor: pointer;
  :hover {
    color: ${Theme.colors.white};
  }
  ${screen.xsMax`left: 0;
    right: 0;
    width: 100%;
    font-size: 14px;
    props.slideInView ? height:35px : 0`}
`;

export const StyledIndexAddToCartButtonBlack = styled.a`
  height: ${(props) => (props.slideInView ? '33px' : '0')};
  top: ${(props) => (props.slideInView ? '-33px' : '0')};
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  left: 0;
  background: ${Theme.colors.black};
  color: ${Theme.colors.white};
  right: 0;
  overflow: hidden;
  transition: 0.3s ease;
  cursor: pointer;
  :active {
    background: ${Theme.colors.white};
    color: ${Theme.colors.black};
    border: 1px solid ${Theme.colors.black};
  }
  :hover {
    color: ${Theme.colors.white};
  }
  ${screen.xsMax`left: 0;
    right: 0;
    width: 100%;
    props.slideInView ? height:33px : 0`}
`;
