import React, { useRef, useEffect, memo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import '../style/AutoCompleteAddressField.scss';

declare global {
  interface Window {
    PostcodeNl: any;
  }
}
window.PostcodeNl = window.PostcodeNl || {};

const AutoCompleteAddressField = ({
  setRetrievedAddress,
  isDisabled,
  country,
}: {
  setRetrievedAddress: Function;
  isDisabled: boolean;
  country: string;
}) => {
  // Hooks
  const autocompleteRef = useRef(null);
  const i18n = useTranslation();

  const getCountryCode = () => {
    // country codes used by autocomplete API
    switch (country) {
      case 'BE':
        return 'bel';
      case 'DE':
        return 'deu';
      default:
        return 'lux';
    }
  };

  useEffect(() => {
    const session = uuidv4(); // create a random session-id
    // initialize the auto-complete code from https://api.postcode.nl/documentation/international/javascript
    const postcodeNL = new window.PostcodeNl.AutocompleteAddress(autocompleteRef.current, {
      autocompleteUrl: `/call_postcode_autocomplete_api/${session}`,
      addressDetailsUrl: `/call_postcode_details_api/${session}`,
      context: getCountryCode(),
    });

    // Callback for when an address has been picked
    if (autocompleteRef && autocompleteRef.current) {
      autocompleteRef.current.addEventListener(
        'autocomplete-select',
        (e: { detail: { precision: string; context: any } }) => {
          if (e.detail.precision === 'Address') {
            postcodeNL.getDetails(
              e.detail.context,
              (res: {
                address: {
                  exception: any;
                  street: string;
                  locality: string;
                  postcode: string;
                  buildingNumber: string;
                  buildingNumberAddition: string;
                };
              }) => {
                return res.address.exception
                  ? setRetrievedAddress({ valid: false, errorMessage: true })
                  : setRetrievedAddress({
                      valid: true,
                      setNumberAndZip: true, // only for autocomplete we fill out number, addition and zip
                      street: res.address.street,
                      city: res.address.locality,
                      zipcode: res.address.postcode,
                      housenumber: res.address.buildingNumber,
                      housenumberAddition: res.address.buildingNumberAddition,
                    });
              }
            );
          }
        }
      );
    }

    return () => {
      postcodeNL.destroy();
    };
  });

  return (
    <div id="autoCompleteInput">
      <input
        ref={autocompleteRef}
        placeholder={i18n.t('addressAutocompletePlaceholder')}
        disabled={isDisabled}
      />
    </div>
  );
};

AutoCompleteAddressField.propTypes = {
  setRetrievedAddress: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  country: PropTypes.string.isRequired,
};

AutoCompleteAddressField.defaultProps = {
  isDisabled: false,
};

export default memo(AutoCompleteAddressField);
