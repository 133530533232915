import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { showTooltipVerifiedSeller } from './productPageTooltips';
import useWindowWidth from '../helpers/hooks/useWindowWidth';

import { ProductPageUser } from '../../types/ProductPageTypes';

import * as S from './styled/StyledSellerInfo';
import { fetchUser } from './productPageSlice';
import memberSince from '../helpers/memberSince';

import dressIcon from '@images/icons/dress.svg';
import soldIcon from '@images/icons/sold.svg';
import personIcon from '@images/icons/TNC_avatar.svg';
import FollowSeller from '../Follow/FollowSeller';

interface Props {
  userId: string;
  sellerPath: string;
  askQuestionPath: string;
  isPremium: boolean;
  userLoggedIn: boolean;
  logInPath: string;
}

const SellerInfo: FC<Props> = ({
  userId,
  sellerPath,
  askQuestionPath,
  isPremium,
  userLoggedIn,
  logInPath,
  chatDisabled
}) => {
  // hooks
  const user = useSelector(
    (state: { productPage: { user: ProductPageUser } }) => state.productPage.user
  );
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useWindowWidth() < 768;
  
  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [userId]);

  if (!user) {
    // only return component when product is fetched
    return null;
  }

  const SELLER_IS_USER = userId !== '5'; // user 5 is product sold by the next closet

  const showMemberSince = (sellerIsUser: boolean, isMemberSince: string) => {
    const { month, year } = memberSince(isMemberSince);
    return (
      sellerIsUser && (
        <S.MemberSince>{i18n.t('member_since', { month: i18n.t(month), year })}</S.MemberSince>
      )
    );
  };

  const showAskQuestionButton = (
    userLoggedIn: boolean,
    askQuestionPath: string,
    logInPath: string
  ) => {
    return userLoggedIn ? (
      <S.TransparentLink data-href={askQuestionPath} className="js-btn-talkjs">
        {i18n.t('ask_a_question')}
      </S.TransparentLink>
    ) : (
      <S.TransparentLink href={logInPath}>{i18n.t('ask_a_question')}</S.TransparentLink>
    );
  };

  const showStats = (
    sellerIsUser: boolean,
    itemsForSale: number,
    soldItems: number,
    followers: number
  ) => {
    if (!sellerIsUser) return; // only show if it's not sold by TNC

    return (
      <>
        {itemsForSale > 0 && (
          <S.Stat>
            <S.StatImageWrapper>
              <S.StatImage src={dressIcon} height="20px" marginLeft="5px" />
            </S.StatImageWrapper>
            <S.StatText>{i18n.t('items_for_sale', { number: itemsForSale })}</S.StatText>
          </S.Stat>
        )}
        {soldItems > 0 && (
          <S.Stat>
            <S.StatImageWrapper>
              <S.StatImage src={soldIcon} height="20px"/>
            </S.StatImageWrapper>
            <S.StatText>{i18n.t('items_sold', { number: soldItems })}</S.StatText>
          </S.Stat>
        )}
        {followers > 0 && (
          <S.Stat>
            <S.StatImageWrapper>
              <S.StatImage src={personIcon} height="20px" marginLeft="2px" />
            </S.StatImageWrapper>
            <S.StatText>{i18n.t('followers', { number: followers })}</S.StatText>
          </S.Stat>
        )}
      </>
    );
  };
  
  if (isMobile) {
    return (
      <S.Wrapper data-testid="sellerInfo">
        <S.LeftWrapper>
          
          <S.Name>
            <S.Medium>The Closet Of</S.Medium>
            <div className="seller-fullname">
              <S.SemiBoldLink href={sellerPath}>
                {`${user.firstname} ${user.lastname}`.toUpperCase()}
              </S.SemiBoldLink>
              {user.verified ? showTooltipVerifiedSeller(isPremium) : null}
            </div>
            {showMemberSince(SELLER_IS_USER, user.member_since)}
          </S.Name>
          <S.Stats>
            {showStats(SELLER_IS_USER, user.items_for_sale, user.items_sold, user.followers)}
          </S.Stats>
          
        </S.LeftWrapper>
        <S.AvatarWrapper>
          <S.Avatar src={`https://www.thenextcloset.com${user.avatar_small}`} />
        </S.AvatarWrapper>
        <S.ButtonWrapper className="seller-info-btn">
          <FollowSeller id={parseInt(userId,10)} buttonType="transparent"/>
          {!chatDisabled && showAskQuestionButton(userLoggedIn, askQuestionPath, logInPath)}
        </S.ButtonWrapper>
        <S.SeparatorLine />
      </S.Wrapper>
    );
  } else {
    return (
      <S.Wrapper data-testid="sellerInfo">
        <S.LeftWrapper>
          
          <S.Name>
            <S.Medium>The Closet Of</S.Medium>
            <div className="seller-fullname">
              <S.SemiBoldLink href={sellerPath}>
                {`${user.firstname} ${user.lastname}`.toUpperCase()}
              </S.SemiBoldLink>
              {user.verified ? showTooltipVerifiedSeller(isPremium) : null}
            </div>
            {showMemberSince(SELLER_IS_USER, user.member_since)}
          </S.Name>
          <S.Stats>
            {showStats(SELLER_IS_USER, user.items_for_sale, user.items_sold, user.followers)}
          </S.Stats>
          
        </S.LeftWrapper>
        <S.AvatarWrapper>
          <S.Avatar src={`https://www.thenextcloset.com${user.avatar_small}`} />
          <S.ButtonWrapper className="seller-info-btn">
            <FollowSeller id={parseInt(userId,10)} buttonType="transparent"/>
            {!chatDisabled && showAskQuestionButton(userLoggedIn, askQuestionPath, logInPath)}
          </S.ButtonWrapper>
        </S.AvatarWrapper>
        <S.SeparatorLine />
      </S.Wrapper>
    );
  }
};

export default SellerInfo;
