import styled from 'styled-components';

import { Theme } from '../../../services/theme';
import { StyledPrimaryButton } from '../../StyledGlobal/StyledButton';
import { StyledForm } from '../../StyledGlobal/StyledForm';
import facebookLogo from '../../../../assets/images/icons/facebook-white.svg';
import googleLogo from '../../../../assets/images/icons/gmail.svg';
import appleLogo from '../../../../assets/images/icons/apple.svg';

const LoginButton = styled.a`
  height: 50px;
  margin-bottom: 20px;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  &:hover {
    text-decoration: none;
  }
`;

export const Form = styled(StyledForm)`
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  textarea {
    width: 100%;
  }
`;

export const FbButton = styled(LoginButton)`
  background: #4568b2;
  color: ${Theme.colors.white};
  cursor: pointer;
  &:hover {
    background: #305399;
    color: ${Theme.colors.white};
  }
`;

export const FbLogo = styled.img.attrs({
  src: facebookLogo,
  alt: 'Facebook logo',
})`
  height: 20px;
  margin-right: 10px;
`;

export const GmailButton = styled(LoginButton)`
  color: ${Theme.colors.black};
  border: 2px solid ${Theme.colors.black};
  &:hover {
    border: 2px solid rgba(0, 0, 0, 0.8);
    color: black;
    opacity: 0.8;
  }
`;

export const GmailLogo = styled.img.attrs({
  src: googleLogo,
  alt: 'Gmail logo',
})`
  height: 15px;
  margin-right: 10px;
`;

export const AppleButton = styled(LoginButton)`
  color: ${Theme.colors.black};
  border: 2px solid ${Theme.colors.black};
  &:hover {
    border: 2px solid rgba(0, 0, 0, 0.8);
    color: black;
    opacity: 0.8;
  }
`;

export const AppleLogo = styled.img.attrs({
  src: appleLogo,
  alt: 'Apple logo',
})`
  margin-right: 10px;
`;

export const Divider = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0 25px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 15px;
`;

export const Link = styled.a`
  text-decoration: underline;
  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
`;

export const LoginLink = styled.span`
  padding-top: 20px;
  border-top: 2px solid ${Theme.colors.lightGrey};
  display: block;
  margin-top: 30px;
`;

export const FButton = styled(StyledPrimaryButton)`
  width: 100%;
  margin: 30px 0 60px;
`;

export const Label = styled.label`
  p {
    margin-left: 5px;
    color: ${Theme.colors.black};
  }
`;
