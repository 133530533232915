import React, { useEffect } from 'react';
import { useSelector, Provider, useDispatch } from 'react-redux';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import store from '../../src/store';
import { fetchSustainableImpact } from './homeSlice';
import useWindowWidth from '../helpers/hooks/useWindowWidth';
import translateComponent from '../helpers/translateI18n';

import * as S from './styled/StyledImpact';
import leftArrow from '@images/left.svg';
import rightArrow from '@images/right.svg';

const Impact: React.FC = () => {
  const dispatch = useDispatch();
  const sustainableImpact = useSelector((state: any) => state.home.impact);
  const width = useWindowWidth();

  const translate = translateComponent('Impact');

  useEffect(() => {
    if (!sustainableImpact.bathtubs) {
      dispatch(fetchSustainableImpact());
    }
  }, []);

  const sliderStyle = {
    width: '100%',
    height: '240px',
  };

  const innerSlideStyle = {
    paddingBottom: '220px',
  };

  const buttonStyle = {
    background: 'transparent',
    border: 'none',
    position: 'absolute',
    top: '80px',
    backgroundSize: 'cover',
    height: '35px',
    width: '35px',
  };

  const backButtonStyle = {
    ...buttonStyle,
    left: '10px',
    background: `url(${leftArrow})`,
  };

  const forwardButtonStyle = {
    ...buttonStyle,
    right: '10px',
    background: `url(${rightArrow})`,
  };

  const clothing = () => {
    return (
      <S.Wrapper>
        <S.IconContainer>
          <S.ClothingImg />
        </S.IconContainer>
        <S.ImpactNumber>
          {sustainableImpact.kg_of_clothing}
          <S.Span> Kg</S.Span>
        </S.ImpactNumber>
        <S.Text>{translate('clothing')}</S.Text>
      </S.Wrapper>
    );
  };

  const emissions = () => {
    if (sustainableImpact.roundWorldTrips >= 1) {
      return (
        <S.co2Wrapper>
          <S.IconContainer>
            <S.EarthImg />
          </S.IconContainer>
          <S.ImpactNumber>
            {sustainableImpact.co2_month}
            <S.Span> Kg</S.Span>
          </S.ImpactNumber>
          <S.co2Text>
            {translate('co2RoundWorldTrip', { numbersFlights: sustainableImpact.roundWorldTrips })}
          </S.co2Text>
        </S.co2Wrapper>
      );
    }

    return (
      <S.co2Wrapper>
        <S.co2IconContainer>
          <S.EarthImg />
        </S.co2IconContainer>
        <S.ImpactNumber>
          {sustainableImpact.co2_month}
          <S.Span> Kg</S.Span>
        </S.ImpactNumber>
        <S.co2Text>
          {translate('co2KilometersByPlane', {
            kilometerPlane: sustainableImpact.kilometersByPlaneLastMonth,
          })}
        </S.co2Text>
      </S.co2Wrapper>
    );
  };

  const bathTubs = () => {
    return (
      <S.Wrapper>
        <S.IconContainer>
          <S.BathTubsImg />
        </S.IconContainer>
        <S.ImpactNumber>{sustainableImpact.bathtubs}</S.ImpactNumber>
        <S.Text>{translate('bathtubs')}</S.Text>
      </S.Wrapper>
    );
  };

  const smallScreenInfo = () => {
    return (
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={3}
        style={sliderStyle}
      >
        <Slider>
          <Slide style={innerSlideStyle} index={0}>
            <S.FlexCenter>{clothing()}</S.FlexCenter>
          </Slide>
          <Slide style={innerSlideStyle} index={1}>
            <S.FlexCenter>{emissions()}</S.FlexCenter>
          </Slide>
          <Slide style={innerSlideStyle} index={2}>
            <S.FlexCenter>{bathTubs()}</S.FlexCenter>
          </Slide>
        </Slider>
        <ButtonBack style={backButtonStyle} />
        <ButtonNext style={forwardButtonStyle} />
      </CarouselProvider>
    );
  };

  const largeScreenInfo = () => {
    return (
      <S.DesktopWrapper>
        {clothing()}
        {emissions()}
        {bathTubs(true)}
      </S.DesktopWrapper>
    );
  };

  const showImpactInfo = (width: number) => {
    return width < 768 ? smallScreenInfo() : largeScreenInfo();
  };

  return (
    <S.Container>
      <S.Title>{translate('title')}</S.Title>
      {showImpactInfo(width)}
    </S.Container>
  );
};

export default () => (
  <Provider store={store()}>
    <Impact />
  </Provider>
);
