import styled from 'styled-components';
import screen from '../../StyledGlobal/mediaQueries';
import { FormControlLabel } from '@material-ui/core';
import { StyledPrimaryButton } from '../../StyledGlobal/StyledButton';

export const Container = styled.div`
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999999999;
  background: white;
  padding: 70px;
  display: flex;
  justify-content: space-between;
  ${screen.smMax`
  padding: 20px;
  flex-direction: column;
  `}
`;

export const Overlay = styled.div`
  z-index: 99999999998;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: grey;
  opacity: 0.5;
  position: fixed;
`;

export const Text = styled.div``;

export const ButtonWrapper = styled.div`
  padding-left: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  ${screen.smMax`
  padding: 20px 0 0 0;
  align-items: center;
  margin-bottom: 50px
  `}
`;

export const AcceptCookieButton = styled(StyledPrimaryButton)`
  ${screen.smMax`
    margin-bottom: 30px;
  `}
`;

export const Link = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
`;

export const Label = styled(FormControlLabel)`
  & .MuiTypography-body1 {
    font-size: 16px;
    color: black;
  }
  & .MuiFormControlLabel-label.Mui-disabled {
    color: black;
  }
`;

export const Title = styled.p`
  font-weight: bold;
  margin-left: 48px;
  margin-bottom: 4px;
`;

export const ManageCookiesWrapper = styled.div``;

interface SwitchWrapperProps {
  marginBottom?: boolean;
}

export const SwitchWrapper = styled.div<SwitchWrapperProps>`
  margin-bottom: ${props => (props.marginBottom ? '30px' : 'inherit')};
`;
