import React from 'react';
import { render, cleanup } from '@testing-library/react';
import { I18nextProvider } from 'react-i18next';

import ProductCardContainer from '../ProductCardContainer';
import i18n from '../../../test/i18nForTest';

const products = require('./testProducts');
const defaultProps = {
  products: products.array,
};

afterEach(() => {
  cleanup();
});

const setup = (props = {}) => {
  const setupProps = { ...defaultProps, ...props }; // props overwrites defaultProps by doing this
  return render(
    <I18nextProvider i18n={i18n}>
      <ProductCardContainer {...setupProps} />
    </I18nextProvider>
  );
};

// TODO: replace these tests with tests that use the redux store
xtest('should render an empty container when there are no products', () => {
  const { queryByTestId, getByTestId } = setup({ products: [] });
  expect(getByTestId('productcard-container')).toBeTruthy();
  expect(queryByTestId('product-card-wrapper')).toBeFalsy();
});

xtest('should render 10 products when passed 10 products', () => {
  const { getAllByTestId } = setup();
  expect(getAllByTestId('product-card-wrapper')).toHaveLength(10);
});
