import styled from 'styled-components';
import { Form } from 'formik';

import MaterialSelectField from '../../global/MaterialSelectField';
import MaterialAutoComplete from '../../global/MaterialAutoComplete';
import { StyledPrimaryButton, StyledSecondaryButton } from '../../StyledGlobal/StyledButton';
import ModalTitle from '../../Modal/ModalTitle';
import ModalText from '../../Modal/ModalText';

import screen from '../../StyledGlobal/mediaQueries';

import { Theme } from '../../../services/theme';
import logo from '@images/tnc_logo_black.svg';

export const UploadPage = styled.div`
  height: 100%;
  width: 100%;
  margin-top: -94px;
  ${screen.smMax`
    margin-top: -50px;
`}
  ${screen.xsMax`
    margin-top: -45px;
  `};
`;

export const NavBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  position: relative;
  top: 0;
  left: 0;
  ${screen.xsMax`
    margin-top: -45px;
  `};
  ${screen.smMax`
    margin-top: -50px;
  `};
  ${screen.mdMin`
    margin-top: -94px;
  `};
`;

export const NavText = styled.p`
  font-size: 18px;
  padding-left: 22px;
  margin: 0;
  & :first-of-type {
    ${screen.xsMax`
    display: none;
  `}
  }
`;

export const ProgressBarContainer = styled.div`
  height: 4px;
  width: 100%;
  background-color: #e4e4e4;
`;

export const Hide = styled.div<{ hide: boolean }>`
  display: ${props => (props.hide ? 'none' : 'block')};
`;

export const CloseButton = styled(NavText)`
  position: absolute;
  right: 35px;
  ${screen.xsMax`
    right: 10px;
  `}
`;

export const LogoContainer = styled.div`
  z-index: 2;
  border-right: 2px solid #e4e4e4;
  height: 100%;
  display: flex;
  align-items: center;
  ${screen.xsMax`
    border-right: none;
  `}
`;

export const Logo = styled.img.attrs({
  src: logo,
  alt: 'Logo',
})`
  width: 170px;
  height: 15px;
  margin: 20px;
`;

export const ModalContainer = styled.div`
  padding: 40px;
  ${screen.xsMax`
    padding: 15px;
  `};
`;

export const ModTitle = styled(ModalTitle)`
  margin-bottom: 20px;
`;

export const ModText = styled(ModalText)`
  margin-bottom: 40px;
`;

export const StepContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const FormContainer = styled(Form)`
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export const TipsContainer = styled.div`
  flex: 1;
  background-color: ${Theme.colors.lightGrey};
  margin-bottom: -150px;
  ${screen.xsMax`
    display: none;
  `}
`;

export const TipsBox = styled.div`
  padding: 25px;
  border-radius: 0px;
  border: 1px solid ${Theme.colors.mediumGrey};
  background-color: white;
  margin-top: 50px;
  margin-left: 30px;
  width: 83%;
`;

export const TipsHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  h4 {
    font-weight: 800;
  }

  img {
    margin-right: 7px;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 80%;
  @media (max-width: 450px) {
    width: 90%;
  }
  margin: auto;
  h2 {
    margin-bottom: 50px;
  }
`;

export const SelectField = styled(MaterialSelectField)``;

export const CurrencySymbol = styled.div`
  height: 56px;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #555555;
  background-color: #eeeeee;
  border: 1px solid #ccc;
  border-radius: 0px;
`;

export const PriceTitle = styled.div`
  margin: 0 0 10px 3px;
`;

export const PriceContainer = styled.div`
  display: inline-flex;
  width: 100%;

  span {
    width: 100%;
  }
  .PrivateNotchedOutline-root-1 {
    border-left: none;
    top: -6px;
  }

  .MuiFormControl-root {
    margin-bottom: 0;
  }
`;

export const AutoCompleteField = styled(MaterialAutoComplete)`
  border: 1px solid ${Theme.colors.mediumGrey};
`;

export const ButtonContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 80px;
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainerStepOne = styled(ButtonContainer)`
  justify-content: flex-end;
`;

export const PreviousButton = styled(StyledSecondaryButton)`
  width: 48%;
`;

export const NextButton = styled(StyledPrimaryButton)`
  width: 48%;
  right: 0;
`;

export const SecondaryButtonModal = styled(StyledSecondaryButton)`
  width: 115px;
  ${screen.xsMax`
    margin-right: 15px;
  `}
`;

export const RevenueContainer = styled.div`
  background-color: ${Theme.colors.lightGreen};
  display: flex;
  height: 40px;
  color: black;
  justify-content: space-between;
  padding: 10px 15px 0 15px;
  border-radius: 0px;
  margin-top: 5px;
`;

export const PriceBreakdown = styled.div`
  margin-left: 15px;
`;

export const PriceShipping = styled.div`
  margin-top: 25px;
  padding-bottom: 10px;
  border-bottom: 2px solid black;
  margin-bottom: 20px;
`;

export const PriceShippingStep = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ConversionTip = styled.p`
  font-size: 13px;
  color: ${Theme.colors.mediumGrey};
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
`;

export const Icon = styled.img``;

export const ItemPreviewContainer = styled.div`
  height: 350px;
  border: 1px solid ${Theme.colors.lightGrey};
  border-radius: 0px;
  margin-bottom: 75px;
  -webkit-box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 20%);
  -moz-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 20%);
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const PreviewImage = styled.div`
  display: flex;
  justify-content: center;
  height: 300px;
  margin: 30px 0;
  img {
    max-height: 225px;
  }
`;

export const PreviewDescription = styled.div`
  padding: 0 15px;
  display: inline-flex;
  width: 100%;
  align-items: center;
`;

export const PreviewBrandType = styled.div`
  width: 90%;
  h1 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 800;
    margin: 0;
  }
`;

export const PreviewPrice = styled.div`
  width: 10%;
  font-size: 20px;
`;

export const Divider = styled.div`
  border: 1px solid ${Theme.colors.mediumGrey};
`;
export const Disclaimer = styled.div`
  margin-top: 20px;
`;

export const CommentsHeader = styled.p`
  margin-top: 20px;
`;

export const TitleAndToolTip = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  p {
    margin-bottom: 0;
  }
`;
