import React from 'react';
import LazyLoad from 'react-lazyload';

import * as S from './styled/styledCard';

export interface CardProps {
  className?: string;
  link?: string;
  textClassName?: string;
  id?: string;
  image: string;
  imageL: string;
  title: string;
}

const Card: React.FC<CardProps> = ({
  className = '',
  link = '',
  textClassName = '',
  id = '',
  image,
  imageL,
  title,
}) => {
  return (
    <S.Wrapper href={link}>
      <S.ImageWrapper className={className} id={id}>
        <LazyLoad debounce={false} offsetVertical={250}>
          <S.Image src={image} srcSet={`${image} 1x, ${imageL} 2x`} alt={title} />
        </LazyLoad>
      </S.ImageWrapper>
    </S.Wrapper>
  );
};


export default Card;
