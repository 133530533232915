import * as yup from 'yup';
import IBAN from 'iban';

const FILE_SIZE = 7000 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

export const bankVerificationValidationSchema = (stepNumber: string, documentType: string) => {
  switch (stepNumber) {
    case 'stepOne':
      return stepOne;
    case 'stepTwo':
      return stepTwo;
    case 'stepThree':
      return stepThree(documentType);
    case 'stepFour':
      return stepFour;
    default:
      break;
  }
};

const isToday18orMoreYearsOld = (date: String): boolean => {
  // date format is day, month, year
  const dateArray = date.split(',');
  return (
    new Date(Number(dateArray[2]) + 18, Number(dateArray[1]) - 1, Number(dateArray[0])) <= new Date()
  );
};

const stepOne = () => {
  return yup.object().shape({
    acceptedTermsKYC: yup
      .boolean()
      .oneOf([true], 'BV__acceptedTermsError'),
  });
};

const stepTwo = () => {
  return yup.object().shape({
    typeOfId: yup.string().required('BV__selectedDocumentError'),
  });
};

const stepThree = (documentType: string) => {
  const optionalBacksideValidation =
    documentType !== 'passport'
      ? yup
          .mixed()
          .required('BV__documentError')
          .test('fileSize', 'BV__fileTooLarge', value => value && value.size <= FILE_SIZE)
          .test(
            'fileFormat',
            'BV__fileTypeError',
            value => value && SUPPORTED_FORMATS.includes(value.type)
          )
      : '';

  return yup.object().shape({
    fileFront: yup
      .mixed()
      .required('BV__documentError')
      .test('fileSize', 'BV__fileTooLarge', value => value && value.size <= FILE_SIZE)
      .test(
        'fileFormat',
        'BV__fileTypeError',
        value => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    fileBack: optionalBacksideValidation,
  });
};

const stepFour = () => {
  return yup.object().shape({
    firstname: yup.string().required('BV__firstnameRequired'),
    lastname: yup.string().required('BV__lastnameRequired'),
    day: yup
      .number()
      .required('required')
      .typeError('BV__mustBeNumber')
      .min(1, 'BV__enterValidDay')
      .max(31, 'BV__enterValidDay'),
    month: yup
      .number()
      .required('required')
      .typeError('BV__mustBeNumber')
      .min(1, 'BV__enterValidMonth')
      .max(12, 'BV__enterValidMonth'),
    year: yup
      .number()
      .required('required')
      .typeError('BV__mustBeNumber')
      .min(1920, 'BV__enterValidYear')
      .max(2020, 'BV__enterValidYear'),
    birthDate: yup
      .string()
      .required('BV__birthDateRequired')
      .test(
        'overEighteen',
        'BV__minimumAgeEighteen',
        value => value && isToday18orMoreYearsOld(value)
      ),
    bankNumber: yup
      .string()
      .required('BV__banknumberRequired')
      .test('validIban', 'BV__IbanInvalid', value => value && IBAN.isValid(value) && !value.startsWith('IE') && !value.startsWith('LU')),
  });
};
