import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import * as S from './styled/StyledSpinner';

function Spinner() {
  return (
    <S.SpinnerContainer>
      <ClipLoader size={150} color="black" loading data-testid="spinner" />
    </S.SpinnerContainer>
  );
}

export default Spinner;
