import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import * as S from './styled/styledUPP';
import translateComponent from '../helpers/translateI18n';
import Repository from '../../services/repository';

import NextButton from './NextButton';
import PreviousButton from './PreviousButton';
import { FormValues, UploadProductFourProps } from './UploadProductPageTypes';
import MaterialTextField from '../global/MaterialTextField';
import { StyledPrimaryButton } from '../StyledGlobal/StyledButton';
import { showTooltipPricing } from './ProductUploadToolTips';
import { getAuthentication } from '../User/userSlice';
import { setHeaders } from '../../services/helpers';

const UploadProductFour: React.FC<UploadProductFourProps> = ({
  setFormProgress,
  productMargin,
}) => {
  const translate = translateComponent('UploadProduct');
  const authentication = useSelector(getAuthentication);

  const { values, setFieldValue } = useFormikContext<FormValues>();
  const [margin, setMargin] = useState(20);

  const BASE_SHIPPING_FEE = 7;

  const fetchMargin = () => {
    if (!authentication) {
      return;
    }

    Repository.getProductMargin(
      { type_id: values.typeId },
      {
        headers: setHeaders(authentication),
      }
    ).then((res) => {
      setMargin(res.data.margin);
    });
  };

  useEffect(() => {
    setFieldValue('price_including_shipping', parseInt(values.price, 10) + BASE_SHIPPING_FEE);
  }, [setFieldValue, values.price, margin]);

  useEffect(() => {
    fetchMargin();
  }, []);

  return (
    <S.Container>
      <h2>{translate('bestStep')}</h2>

      <S.PriceTitle>{translate('originalPrice')}</S.PriceTitle>
      <S.PriceContainer>
        <S.CurrencySymbol>€</S.CurrencySymbol>
        <MaterialTextField name="price_original" autoComplete="new-password" type="number" />
      </S.PriceContainer>
      <S.ConversionTip>{translate('conversionTip')}</S.ConversionTip>

      <S.TitleAndToolTip>
        <p>{translate('targetPrice')}</p>
        {showTooltipPricing()}
      </S.TitleAndToolTip>

      <S.PriceContainer>
        <S.CurrencySymbol>€</S.CurrencySymbol>
        <MaterialTextField name="price" autoComplete="new-password" type="number" />
      </S.PriceContainer>
      {values.price && (
        <S.RevenueContainer>
          <span>{translate('revenue', { margin: 100 - (productMargin ?? margin) })}</span>

          <span>
            € {(((100 - (productMargin ?? margin)) / 100) * Number(values.price)).toFixed(2)}
          </span>
        </S.RevenueContainer>
      )}

      <S.PriceBreakdown>
        <S.PriceShipping>
          <S.PriceShippingStep>
            <p>{translate('yourPrice')}</p>
            {values.price ? <p>€ {values.price}</p> : null}
          </S.PriceShippingStep>
          <S.PriceShippingStep>
            <p>{translate('shipping')}</p>
            {BASE_SHIPPING_FEE ? <p>€ {BASE_SHIPPING_FEE}</p> : null}
          </S.PriceShippingStep>
        </S.PriceShipping>

        <S.PriceShippingStep>
          <span>{translate('priceOnTNC')}</span>
          {values.price && values.price_including_shipping ? (
            <span>€ {values.price && values.price_including_shipping}</span>
          ) : null}
        </S.PriceShippingStep>
      </S.PriceBreakdown>

      <S.ButtonContainer>
        <PreviousButton previousStep="stepThree" setFormProgress={setFormProgress} />
        {/* for editing a product this is the last step (if a productMargin exists it means we're in edit mode) */}
        {productMargin ? (
          <StyledPrimaryButton width="48%" type="submit">
            {translate('submit')}
          </StyledPrimaryButton>
        ) : (
          <NextButton nextStep="stepFive" setFormProgress={setFormProgress} />
        )}
      </S.ButtonContainer>
    </S.Container>
  );
};

export default UploadProductFour;
