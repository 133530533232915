import React from 'react';
import { TextField } from '@material-ui/core';
import { Field, FieldProps, useFormikContext } from 'formik';
import FormError from './FormError';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { StyledAutoCompleteField, StyledFormControl } from './styled/styledMaterial';
import useWindowWidth from '../helpers/hooks/useWindowWidth';

interface MaterialAutoCompleteProps {
  name: string;
  disabled?: boolean;
  options: string[];
  labelText: string;
  placeholderText?: string;
  handleOpen: () => void | undefined;
  toolTip?: JSX.Element | null;
}

const MaterialAutoComplete: React.FC<MaterialAutoCompleteProps> = ({
  name,
  options,
  disabled = false,
  labelText,
  placeholderText,
  handleOpen,
  toolTip = null,
}): JSX.Element => {
  const width = useWindowWidth();
  const SMALL_SCREEN = width < 768;
  const { setFieldValue } = useFormikContext();
  return (
    <span data-testid={`${name}.wrapper`}>
      <StyledFormControl fullWidth>
        <div id={`${name}-label`}>
          {labelText}
          {SMALL_SCREEN && toolTip}
        </div>
        <Field name={name}>
          {({ field: { value, onBlur }, meta: { error, touched } }: FieldProps) => (
            <div>
              <StyledAutoCompleteField
                popupIcon={<ExpandMore />}
                options={options}
                renderInput={(params) => <TextField {...params} placeholder={placeholderText} />}
                value={value}
                onChange={(_, value) => setFieldValue(name, value)}
                onBlur={onBlur}
                disabled={disabled}
                fullWidth
                data-testid={name}
                placeholder={placeholderText}
                onOpen={handleOpen}
                error={error && touched ? error : ''}
              />
            </div>
          )}
        </Field>
        <FormError name={name} largeError />
      </StyledFormControl>
    </span>
  );
};

export default MaterialAutoComplete;
