import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';

import FormError from '../global/FormError';
import * as S from './styled/styledBV';
import NextButton from './NextButton';

interface BankVerificationTwoProps {
  setFormProgress: Function;
  setDocumentType: Function;
}

const BankVerificationTwo: React.FC<BankVerificationTwoProps> = ({
  setFormProgress,
  setDocumentType,
}) => {
  // hooks
  const i18n = useTranslation();
  const { values } = useFormikContext();
  useEffect(() => {
    setDocumentType(values.typeOfId);
  }, [values.typeOfId]);

  const chooseDocument = () => {
    return (
      <S.Paragraph>
        <S.Paragraph>{i18n.t('BV__stepTwoText')}</S.Paragraph>
        <S.RadioButtons>
          <S.Label htmlFor="passport" key="passport">
            <Field as={S.Radio} type="radio" name="typeOfId" id="passport" value="passport" />
            {i18n.t('passport')}
          </S.Label>
          <S.Label htmlFor="IdCard" key="IdCard">
            <Field as={S.Radio} type="radio" name="typeOfId" id="IdCard" value="IdCard" />
            {i18n.t('idCard')}
          </S.Label>
          <S.Label htmlFor="driversLicense" key="driversLicense">
            <Field
              as={S.Radio}
              type="radio"
              name="typeOfId"
              id="driversLicense"
              value="driversLicense"
            />
            {i18n.t('driversLicenseNL')}
          </S.Label>
          <FormError name="typeOfId" largeError />
        </S.RadioButtons>
      </S.Paragraph>
    );
  };

  return (
    <>
      <S.Title>{i18n.t('BV__stepTwoTitle')}</S.Title>
      {chooseDocument()}
      <S.ButtonContainer>
        <S.SecondaryButton type="button" onClick={() => setFormProgress('stepOne')}>
          {i18n.t('previous')}
        </S.SecondaryButton>
        <NextButton nextStep="stepThree" setFormProgress={setFormProgress} testid="stepTwoNext" />
      </S.ButtonContainer>
    </>
  );
};

export default BankVerificationTwo;
