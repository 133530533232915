const calculateDeliveryDay = (date: Date): string => {
  const WEEKDAYS = [1, 2, 3, 4, 5];
  const SUN_TIL_THURSDAY = [0, 1, 2, 3, 4];

  // Sunday = 0, Monday = 1, etc
  const today = date.getDay();
  const currentHour = date.getHours();

  //  monday -> friday (before 14)
  if (currentHour < 14 && WEEKDAYS.includes(today)) {
    return 'USP_delivery_premium_tomorrow';
    // sunday til thursday after 14
  } else if (SUN_TIL_THURSDAY.includes(today)) {
    return 'USP_delivery_premium_day_after_tomorrow';
    // saturday OR friday after 14
  } else {
    return 'USP_delivery_premium_tuesday';
  }
};

export default calculateDeliveryDay;
