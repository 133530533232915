import styled, { css } from 'styled-components';
import sustainableIcon from '@images/icons/sustainable.svg';
import minusIcon from '@images/icons/minus.svg';

export const Item = styled.div`
  // border: ${({ selected }) => (selected ? '2px solid black !important' : '1px solid #b6b6b6')};
  // font-weight: ${({ root }) => (root ? '600' : '')};
  position: relative;
  width: 100%;
`;

export const Count = styled.small.attrs({ className: 'text-muted' })``;

export const Leaf = styled.img.attrs({ src: sustainableIcon })`
  display: inline-block;
  height: 20px;
`;

export const Dash = styled.img.attrs({ src: minusIcon })`
  height: 14px;
  margin-left: 11px;
  margin-right: 4px;
`;

export const Label = styled.label`
  margin-bottom: 0.5rem;
  margin-left: 0;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  display: block;
  cursor: pointer;
  padding-left: 30px;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`;

export const StyledCheckbox = styled.span`
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #eeeeee !important;
  border-radius: 5px;
  ${({ selected }) => selected && css`
   border: 1px solid black !important;
   &::after {
    content: '';
    position: absolute;
    left: 6.5px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
   }
 `}
`;

export const SizeItem = styled.label`
  position: relative;
  width: 33px;
  height: 34px;
  border: 1px solid #eeeeee !important;
  border-radius: 5px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 12px !important;
    font-weight: 300;
    text-align: center;
  }
  ${({ selected }) => selected && css`
   border: 1px solid black !important;
    span {
      font-weight: 600;
    }
 `}
`;

export const ColorItem = styled.label`
  position: relative;
  width: 25px;
  height: 25px;
  border: 1px solid #eeeeee !important;
  border-radius: 50%;
  margin-right: 7px;
  cursor: pointer;
  ${({ color }) => color && color.includes('icons') ? css`
    background-image: url(${color});
    background-size: cover;
    ` : css`
    background-color: var(--${color});
 `}
  ${({ selected }) => selected && css`
   border: 2px solid black !important;
 `}
`;