import React, { useEffect, useCallback } from 'react';

import { Provider, useDispatch, useSelector } from 'react-redux';
import { addFavorites, getAuthentication, getFavoriteSellerIds } from '../User/userSlice';
import { UserAuthentication } from '../../types/UserTypes';
import store from '../../src/store';
import translateI18n from '../helpers/translateI18n';

import { StyledPrimaryButton, StyledSecondaryButton, StyledTransparentToBlackBtn } from '../StyledGlobal/StyledButton';

interface Props {
  id: number;
  buttonType: 'primary' | 'secondary' | 'transparent' | undefined;
}

const FollowSeller: React.FC<Props> = ({ id, buttonType = 'primary' }) => {
  const translate = translateI18n('FollowSeller');
  // Selectors
  const authentication = useSelector(getAuthentication);
  const favoriteSellerIds = useSelector(getFavoriteSellerIds);
  const isFollowing = favoriteSellerIds.includes(id);

  // Hooks
  const dispatch = useDispatch();

  const toggleFollow = useCallback(
    (id: string | number, authentication?: UserAuthentication | undefined | null) => {
      if (authentication) {
        dispatch(addFavorites({ authentication, id, type: 'seller' }));
      } else {
        sessionStorage.setItem('FollowAfterLoginOrCreate', String(id)); // Set SessionStorage to make sure the follow is applied automatically after login
        window.location.assign('/member/login');
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (authentication) {
      // Automatically follow if the name of the to following entity corresponds with the name in the LocalStorage
      if (sessionStorage.getItem('FollowAfterLoginOrCreate') === String(id)) {
        // don't unfollow if the user is already following
        if (!isFollowing) {
          toggleFollow(id, authentication);
        }
        sessionStorage.removeItem('FollowAfterLoginOrCreate');
      }
    }
  }, [authentication, isFollowing, toggleFollow, id]);

  const button = (buttonType: 'primary' | 'secondary' | 'transparent') => {
    return buttonType === 'primary' ? (
      <StyledPrimaryButton width="100%" onClick={() => toggleFollow(id, authentication)}>
        {translate(isFollowing ? 'stopFollowing' : 'startFollowing')}
      </StyledPrimaryButton>
    ) : buttonType === 'secondary' ? (
      <StyledSecondaryButton width="45%" onClick={() => toggleFollow(id, authentication)}>
        {translate(isFollowing ? 'stopFollowing' : 'startFollowing')}
      </StyledSecondaryButton>
    ) : (
      <StyledTransparentToBlackBtn onClick={() => toggleFollow(id, authentication)}>
        {translate(isFollowing ? 'stopFollowing' : 'startFollowing')}
      </StyledTransparentToBlackBtn>
    );
  };

  return button(buttonType);
};

export default (props: Props) => (
  <Provider store={store()}>
    <FollowSeller {...props} />
  </Provider>
);
