const Theme = {
  name: 'default',
  fonts: {
    text: 'Overpass-Light',
    textBold: 'Overpass-Bold',
    header: 'Playfair Display',
    poppins: 'Poppins',
  },
  colors: {
    lightGreen: '#CEDFCD',
    lightGrey: '#edf1ed',
    green: '#339966',
    activeGreen: '#27af84',
    mediumGrey: '#b6b6b6',
    darkGrey: '#423f41',
    black: '#000000',
    white: '#FFFFFF',
    offWhite: '#F9F9F9',
    error: '#F75352',
    sand: '#f9f8f4',
    darkSand: '#f5f0e9',
    blueHover: '#0D0B9D',
  },
};

export { Theme };
