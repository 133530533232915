import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SlideDown } from 'react-slidedown';
import { Provider, useSelector, useDispatch } from 'react-redux';
import store from '../../src/store';

import 'react-slidedown/lib/slidedown.css';

import './style/ShoppingCartModal.scss';
import ShoppingCartProduct from './ShoppingCartProduct';
import Button from '../global/Button';
import { setProductForPopUp } from '../Header/headerSlice';

function ShoppingCartModal() {
  const addedProduct = useSelector(state => state.header.productForPopUp);
  const dispatch = useDispatch();
  const lineItemId = useSelector(state => state.header.lineItemForCart) || 0;

  function hideThis() {
    dispatch(setProductForPopUp(false));
  }

  useEffect(() => {
    if (addedProduct) {
      setTimeout(() => {
        hideThis();
      }, 5000);
    }
  });

  const { i18n } = useTranslation();
  const isShowingShoppingCart = addedProduct
    ? ReactDOM.createPortal(
        <>
          <SlideDown className="SCM__container">
            <div className="SCM__text flex">
              <div className="SCM__dismissIcon" onClick={() => hideThis()}>
                &times;
              </div>
              <p>{i18n.t('item_added')}</p>
            </div>
            <ShoppingCartProduct product={addedProduct} lineItemId={lineItemId} />
            <div className="SCM__button_container">
              <Button
                link={addedProduct.linkToCart}
                className="btn--secondary SCM__button"
                text={i18n.t('Go to cart').toUpperCase()}
              />
              <Button
                link={addedProduct.linkToOrder}
                className="btn--primary SCM__button"
                text={i18n.t('complete_order').toUpperCase()}
              />
            </div>
          </SlideDown>
        </>,
        document.body
      )
    : null;

  return isShowingShoppingCart;
}

ShoppingCartModal.propTypes = {
  toggle: PropTypes.func,
};

export default props => (
  <Provider store={store()}>
    <ShoppingCartModal {...props} />
  </Provider>
);
