/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Provider, useSelector } from 'react-redux';

import FormError from '../global/FormError';
import repository from '../../services/repository';
import store from '../../src/store';
import { getAuthentication } from '../User/userSlice';
import * as S from './styled/StyledPM';
import { InitialAddress } from './CheckoutTypes';
import { setHeaders } from '../../services/helpers';

function PaymentMethod() {
  const i18n = useTranslation();
  const { setFieldValue, values } = useFormikContext<InitialAddress>();
  const authentication = useSelector(getAuthentication);

  const [featureFlag, setFeatureFlag] = useState(false);

  const changePaymentMethod = (value: string | number) => {
    setFieldValue('paymentMethod', String(value));
  };

  const offlinePaymentFlag = 'offline_payment';

  const checkFeatureFlag = (feature: string) => {
    if (authentication) {
      repository
        .getFeatureFlag(feature, {
          headers: setHeaders(authentication),
        })
        .then((response) => {
          setFeatureFlag(response.data);
        })
        .catch((err: unknown) => {
          console.error('Offline payments feature flag', err);
        });
    }
  };

  // set initial paymentmethod
  useEffect(() => {
    checkFeatureFlag(offlinePaymentFlag);
    changePaymentMethod(values.paymentMethod);
  }, [authentication]);

  return (
    <div data-testid="PaymentMethod">
      <S.Label htmlFor="iDEAL" key="iDEAL">
        <Field as={S.Radio} name="paymentMethod" type="radio" value="ideal" id="iDEAL" />
        <S.Ideal />
        <p>iDEAL</p>
      </S.Label>
      <S.Label htmlFor="Bancontact" key="Bancontact">
        <Field as={S.Radio} name="paymentMethod" type="radio" value="bancontact" id="Bancontact" />
        <S.Bancontact />
        <p>Bancontact</p>
      </S.Label>
      <S.Label htmlFor="creditcard" key="creditcard">
        <Field as={S.Radio} name="paymentMethod" type="radio" value="creditcard" id="creditcard" />
        <S.Mastercard />
        <S.Maestro />
        <S.Visa />
        <Field
          as={S.Select}
          name="creditcardType"
          data-testid="creditcardType"
          onClick={() => {
            changePaymentMethod('creditcard');
          }}
        >
          <option value="">{i18n.t('selectCreditCard')}</option>
          <option value="mastercard">Mastercard</option>
          <option value="visa">Visa</option>
          <option value="maestro">Maestro</option>
        </Field>
      </S.Label>
      {featureFlag === true ? (
        <S.Label key="Cash">
          <Field as={S.Radio} name="paymentMethod" type="radio" value="cash" id="Cash" />
          <p>Offline payment</p>
        </S.Label>
      ) : null}
      {/* scrollHigher is used to adjust the offset of ScrollToError */}
      <FormError name="paymentMethod" testid="PaymentError" largeError className="scrollHigher" />
      <FormError
        name="creditcardType"
        testid="SpecifyCreditCardError"
        largeError
        className="scrollHigher"
      />
    </div>
  );
}

export default () => (
  <Provider store={store()}>
    <PaymentMethod />
  </Provider>
);
