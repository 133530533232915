import React from 'react';

import BannerText from './BannerText';

interface Image {
  url: string;
}

interface BannerProps {
  className: string;
  image: {
    xs: Image;
    s: Image;
    m: Image;
    l: Image;
    xl: Image;
  };
  mobileImage: {
    xs: Image;
    s: Image;
    m: Image;
    l: Image;
  };
  link?: string;
  buttonClassName?: string;
  buttonText?: string;
  bannerHeader?: string;
  bannerText?: string;
  buttonId?: string;
  id?: string;
}

const Banner: React.FC<BannerProps> = ({
  className,
  image,
  link = null,
  buttonClassName,
  buttonText,
  bannerHeader,
  bannerText,
  mobileImage,
  buttonId,
  id,
}) => {
  const mobileExtraSmall = mobileImage.xs.url;
  const mobileSmall = mobileImage.s.url;
  const mobileMedium = mobileImage.m.url;
  const extraSmall = image.xs.url;
  const small = image.s.url;
  const medium = image.m.url;
  const large = image.l.url;
  const extraLarge = image.xl.url;

  const pictureStyle = {
    width: '100%',
  };

  const hasBannerText =
    bannerHeader || bannerText ? (
      <BannerText
        link={link}
        buttonClassName={buttonClassName}
        buttonId={buttonId}
        buttonText={buttonText}
        bannerHeader={bannerHeader}
        bannerText={bannerText}
      />
    ) : (
      ''
    );

  return (
    <a href={link} className={className} id={id}>
      <picture style={pictureStyle}>
        <source media="(max-width: 375px)" srcSet={`${mobileExtraSmall} 1x, ${mobileSmall} 2x`} />
        <source media="(max-width: 440px)" srcSet={`${mobileSmall} 1x, ${mobileMedium} 2x`} />
        <source media="(min-width: 2000px)" srcSet={extraLarge} />
        <source media="(min-width: 1680px)" srcSet={`${large} 1x, ${extraLarge} 2x`} />
        <source media="(min-width: 1200px)" srcSet={`${medium} 1x, ${large} 2x`} />
        <source media="(min-width: 800px)" srcSet={`${small} 1x, ${medium} 2x`} />
        <source media="(min-width: 441px)" srcSet={`${extraSmall} 1x, ${small} 2x`} />
        <img src={large} alt={bannerText} />
      </picture>
    </a>
  );
};

export default Banner;
