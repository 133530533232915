import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';

import { Provider, useDispatch, useSelector } from 'react-redux';
import { Repository } from '@thenextcloset/shared-javascript';
import ReactMarkdown from 'react-markdown';
import { getProductRootType } from './helpers';

import Spinner from '../global/Spinner';
import FlashMessage from '../FlashMessage/FlashMessage';
import useModal from '../Modal/useModal';
import lightbulb from '@images/icons/lamp.svg';

import * as S from './styled/styledUPP';
import translateComponent from '../helpers/translateI18n';
import UploadProductOne from './UploadProductOne';
import UploadProductTwo from './UploadProductTwo';
import UploadProductThree from './UploadProductThree';
import UploadProductFour from './UploadProductFour';
import UploadProductFive from './UploadProductFive';
import EditNavBar from './EditNavBar';
import ProgressBar from './ProgressBar';
import store from '../../src/store';

import { useTranslation } from 'react-i18next';
import { ShoeSizeTable, ClothingSizeTable } from '../global/SizingTables';
import { uploadProductSchema } from './yupUploadProductPageSchema';
import { Product, UploadProductPageProps } from './UploadProductPageTypes';
import { fetchCurrentUser, getAuthentication, getCurrentUser } from '../User/userSlice';
import { setHeaders } from '../../services/helpers';
import { UserAuthentication } from '../../types/UserTypes';
import { newFormValues, editFormValues } from './formValues';
import ConfirmExitModal from './ConfirmExitModal';

import PhotoTips from '../Uploader/PhotoTips';
import { photosAndLabels } from '../Uploader/photoTipsAndLabels';

import Submitter from './Submitter';

const UploadProductPage: React.FC<UploadProductPageProps> = ({ rootType, productIdForEdit, category }) => {
  // we pass as props rootType for a new product and productIdForEdit for editing a product

  const translate = translateComponent('UploadProduct');
  const i18n = useTranslation();
  const { isShowing, toggle } = useModal();
  // hooks
  const [formProgress, setFormProgress] = useState('stepOne');
  const [submittingError, setSubmittingError] = useState('');
  const [uploadingAssets, setUploadingAssets] = useState(0);
  const [previewImage, setPreviewImage] = useState('');
  const [productToEdit, setProductToEdit] = useState<Product | undefined | null>(null);
  const [step, setStep] = useState('type');

  const authentication = useSelector(getAuthentication);
  const userShippingCountry = useSelector(getCurrentUser)?.shipping_address?.country;

  const dispatch = useDispatch();

  const productRootType =
    (rootType || productToEdit) && getProductRootType(rootType ?? productToEdit?.root_type);

  // get to edit product if edit mode and get currentUser
  useEffect(() => {
    const fetchProductToEdit = async (productId: string, authentication: UserAuthentication) => {
      authentication &&
        setProductToEdit(
          (
            await Repository.getProduct(parseInt(productId, 10), {
              headers: setHeaders(authentication),
            })
          ).data
        );
    };

    if (authentication) {
      dispatch(fetchCurrentUser(authentication));
      if (productIdForEdit) {
        fetchProductToEdit(productIdForEdit, authentication);
      }
    }
  }, [authentication, dispatch, productIdForEdit]);

  useEffect(() => {
    // this cannot be included in the showStep function because we're not allowed to update one component while rendering another
    const selectStep = (progress: string) => {
      switch (progress) {
        case 'stepTwo':
          return 'photo';
        case 'stepThree':
          return 'description';
        case 'stepFour':
          return 'price';
        case 'stepFive':
          return 'country';
        default:
          return 'type';
      }
    };

    setStep(selectStep(formProgress));
  }, [formProgress, setStep]);

  const isShoes = productRootType === 'shoes';
  const isClothing = productRootType === 'clothing';

  // to prevent undefined while fetching data
  if (!productRootType) return null;

  const showStep = (progress: string) => {
    switch (progress) {
      case 'stepTwo':
        break;
      case 'stepThree':
        return <UploadProductThree setFormProgress={setFormProgress} />;
      case 'stepFour':
        return (
          <UploadProductFour
            setFormProgress={setFormProgress}
            productMargin={
              productToEdit?.product_margin ? 100 - productToEdit?.product_margin : undefined
            }
          />
        );
      case 'stepFive':
        return <UploadProductFive setFormProgress={setFormProgress} previewImage={previewImage} />;
      default:
        return (
          <UploadProductOne
            setFormProgress={setFormProgress}
            rootType={rootType}
            isShoes={isShoes}
            isClothing={isClothing}
            productToEdit={productToEdit}
            setStep={setStep}
          />
        );
    }
  };

  const showSizeTableOrTip = (isShoes: boolean, step: string) => {
    if (step !== ('size' || 'maat'))
      return <ReactMarkdown>{i18n.t(`${step}TextTip`)}</ReactMarkdown>;
    return isShoes ? <ShoeSizeTable /> : <ClothingSizeTable />;
  };

  return (
    <S.UploadPage>
      <S.NavBar>
        <S.LogoContainer>
          <S.Logo onClick={toggle} />
        </S.LogoContainer>
        {!productToEdit && <S.NavText>{translate(`${formProgress}Title`)}</S.NavText>}
        <S.CloseButton onClick={toggle}>{translate('exit')}</S.CloseButton>
        <ConfirmExitModal isShowing={isShowing} hide={toggle} />
      </S.NavBar>
      {!productToEdit ? <ProgressBar formProgress={formProgress} /> : null}

      {productToEdit && (
        <EditNavBar formProgress={formProgress} setFormProgress={setFormProgress} />
      )}

      <S.StepContainer>
        <Formik
          initialValues={
            productToEdit
              ? editFormValues(productToEdit)
              : newFormValues(rootType, userShippingCountry, category)
          }
          enableReinitialize
          validationSchema={uploadProductSchema(
            formProgress,
            productToEdit?.price_product ? Number(productToEdit.price_product) : null
          )}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onSubmit={() => {}}
          // onSubmit is empty because it's mandatory but we don't need it
        >
          {({ isSubmitting }) => (
            <S.FormContainer>
              <Submitter
                setSubmittingError={setSubmittingError}
                authentication={authentication}
                uploadingAssets={uploadingAssets}
                productToEdit={productToEdit ?? null}
              />
              {submittingError.length > 0 ? <FlashMessage text={submittingError} /> : ''}

              {/* Uploading of photos can continue in the background if we don't unmount this component */}
              <S.Hide hide={formProgress !== 'stepTwo'}>
                <UploadProductTwo
                  setFormProgress={setFormProgress}
                  setPreviewImage={setPreviewImage}
                  productToEdit={productToEdit}
                  productRootType={productRootType}
                  uploadingAssets={uploadingAssets}
                  setUploadingAssets={setUploadingAssets}
                />
              </S.Hide>
              {showStep(formProgress)}
              {isSubmitting ? <Spinner data-testid="spinners" /> : ''}
            </S.FormContainer>
          )}
        </Formik>
        <S.TipsContainer>
          <S.TipsBox>
            {step === 'photo' ? (
              <PhotoTips photosAndLabels={photosAndLabels(productRootType)} />
            ) : (
              <>
                <S.TipsHeader>
                  <img src={lightbulb} alt="Lightbulb" />
                  <h4>{i18n.t(`${step}TitleTip`)}</h4>
                </S.TipsHeader>
                {showSizeTableOrTip(isShoes, step)}
              </>
            )}
          </S.TipsBox>
        </S.TipsContainer>
      </S.StepContainer>
    </S.UploadPage>
  );
};

export default (props: Props) => (
  <Provider store={store()}>
    <UploadProductPage {...props} />
  </Provider>
);
