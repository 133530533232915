import styled from 'styled-components';
import screen from '../../StyledGlobal/mediaQueries';
import logo from '@images/tnc_logo_black.svg';
import lock from '@images/icons/lock.svg';

export const Slogan = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-grey);
  height: 30px;
  font-size: 14px;
  @media (max-width: 475px) {
    font-size: 13px;
  }
  @media (max-width: 400px) {
    height: 26px;
    font-size: 12px;
  }
  @media (max-width: 400px) {
    font-size: 11px;
  }
`;

export const Wrapper = styled.div`
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img.attrs({
  src: logo,
  alt: 'The Next Closet logo',
})`
  height: 20px;
`;

export const SecuredCheckout = styled.div`
  ${screen.smMin`
    position: absolute;
    right: 30px;
  `}
`

export const Lock = styled.img.attrs({
  src: lock,
})`
  height: 20px;
  margin: -5px 10px 0px;
  ${screen.xsMax`
    height: 15px;
  `}
`;
