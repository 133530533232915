import styled from 'styled-components';

import { Theme } from '../../../services/theme';
import screen from '../../StyledGlobal/mediaQueries';

export const BrandLetterWrapper = styled.div`
  max-width: 1150px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 100px auto;
  margin-bottom: 40px;
  padding-top: 20px;
  border-top: 2px solid ${Theme.colors.mediumGrey};
  ${screen.mdMax`width: 90%;`}
  ${screen.xsMax`
    top: 70px;
    grid-template-columns: 50px auto;
    width: 85%;
    margin: 0 10% 0 5%;
  `}
  @media (max-width: 500px) {
    width: 80%;
    margin: 0 15% 0 5%;
  }
`;

export const LetterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
  a {
    margin-bottom: 15px;
  }
`;

export const BrandLetter = styled.div`
  font-size: 26px;
  font-weight: bold;
`;

export const Icon = styled.img`
  margin-left: 5px;
  height: 20px;
`;
