$(() => {
  $('.topnav .main a[data-target]').click(e => {
    e.preventDefault();
    const $this = $(this);
    if ($('.topnav .active').is($this)) {
      $this.removeClass('active');
      $this.blur();
      $('.topnav').removeClass('open');
    } else {
      $('.topnav').addClass('open');
      $('.topnav .active').removeClass('active');
      $this.addClass('active');
    }

    const $new = $('.topnav').find($(this).data('target'));
    $new.toggle();
    return $new.siblings('.sub').hide();
  });

  $('.topnav .main .locale').click(e => {
    e.preventDefault();
    const $this = $(this);
    return $this.toggleClass('open');
  });

  $('.topnav .main .locale a').click(e => {
    let locale;
    if ((locale = $(this).data('locale'))) {
      Cookies.set('lang', locale);
      return e.stopPropagation();
    }
  });

  let carousel = document.getElementById('banner-carousel');
  let startX = 0; // Variable to store initial touch position

  carousel.addEventListener('touchstart', function(e) {
      startX = e.touches[0].clientX;
  }, false);
  let direction = 0; // Variable to store swipe direction

  carousel.addEventListener('touchmove', function(e) {
      let touchX = e.touches[0].clientX;
      direction = touchX > startX ? 'right' : 'left';
  }, false);
  carousel.addEventListener('touchend', function() {
    let element = carousel.querySelector('my-element');
    if (direction === 'right') {
      carousel.querySelector('.left').click();
    } else if (direction === 'left') {
      carousel.querySelector('.right').click();
    }
    startX = 0;
    direction = 0;
  }, false);

});
