import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const language = window.I18n;
const translationNl = require('./languages/i18n-nl.json');
const translationEn = require('./languages/i18n-en.json');
const translationBe = require('./languages/i18n-be.json');

const resources = {
  nl: { translation: translationNl },
  en: { translation: translationEn },
  nl_be: { translation: translationBe },
};

i18n.use(initReactI18next).init({
  initImmediate: false,
  resources,
  lng: language,
  fallbackLng: 'nl',
  // debug: true,
});

export default i18n;
