// import React from 'react';
import { screen, fireEvent } from '@testing-library/react';

import initialData from './initialFilterData';
import setup from './initialFilterSetup';
import * as productFilterActions from '../../productFilterSlice';

test('Should open and use the brands filter', () => {
  setup(initialData);

  const fetchProducts = jest.spyOn(productFilterActions, 'fetchProducts').mockReturnValue(() => {});

  const brandButton = screen.getByText('Brand');
  fireEvent.click(brandButton);
  expect(screen.getAllByText('Gucci')).toHaveLength(3); // also the breadcrumb and the remove filter button

  expect(screen.queryByText('Dresses')).toBeFalsy();
  const guessButton = screen.getByText('Guess');
  fireEvent.click(guessButton);

  // screen.getByRole('button')

  const viewItemsButton = screen.getByText('Bekijk items');
  fireEvent.click(viewItemsButton);

  expect(fetchProducts).toHaveBeenCalledTimes(1);
  // expect(screen.getByRole('button', { name: /guess/i })).toBeTruthy(); // remove filter button
});

test('Should search a specific brand inside the filter', () => {
  setup(initialData);

  // Open the brand filter
  const brandButton = screen.getByText('Brand');
  fireEvent.click(brandButton);

  const searchInput = screen.getByPlaceholderText('Zoek..');
  fireEvent.change(searchInput, { target: { value: 'Gucc' } });
  expect(screen.getAllByText('Gucci')).toHaveLength(3); // also the breadcrumb and the remove filter button
  expect(screen.queryByText('Guess')).toBeFalsy();

  // lowercase check
  fireEvent.change(searchInput, { target: { value: 'gue' } });
  expect(screen.getByText('Guess')).toBeTruthy();
  expect(screen.getAllByText('Gucci')).toHaveLength(2);
});

// describe('Selecting brand filter', () => {
//   beforeEach(() => {
//     setup(initialData);
//     const brandButton = screen.getByText('Brand');
//     fireEvent.click(brandButton);
//     const guessButton = screen.getByText('Guess');
//     fireEvent.click(guessButton);
//     const tommyButton = screen.getByText('Tommy Hilfiger');
//     fireEvent.click(tommyButton);

//     const viewItemsButton = screen.getByText('Bekijk items');
//     fireEvent.click(viewItemsButton);
//   });

//   test('Should show the delete button for Guess', () => {
//     expect(screen.getByText('Guess')).toBeTruthy();
//   });

//   test('Should be able to remove a selected filter', () => {
//     const deleteButton = screen.getByText('Guess');
//     fireEvent.click(deleteButton);
//     expect(screen.queryByText('Guess')).toBeFalsy();
//     expect(screen.getByRole('button', { name: 'Tommy Hilfiger' })).toBeTruthy();
//   });

//   test('Should delete all selected filters', () => {
//     const deleteAll = screen.getByText('Wis selectie');
//     fireEvent.click(deleteAll);
//     expect(screen.queryByText('Guess')).toBeFalsy();
//     expect(screen.queryByText('Tommy Hilfiger')).toBeFalsy();
//   });
// });
