import React, { FC } from 'react';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';

import * as S from './styled/StyledPartners';
import translateComponent from '../helpers/translateI18n';
import useWindowWidth from '../helpers/hooks/useWindowWidth';

const sliderStyle = {
  width: '100%',
};

const OurPartners: FC = () => {
  const translate = translateComponent('Partners');

  const width = useWindowWidth();
  const totalSlides = 4;
  let slidesNum = 3;

  const getSlideContent = (data: { slideindex: number }) => {
    switch (data.slideindex) {
      case 0:
        return (
          <a href="https://treesforall.nl/" target="_blank" rel="noreferrer">
            <S.IconContainer>
              <S.Trees4AllImg />
            </S.IconContainer>
            <span>{translate('treesForAll')}</span>
          </a>
        );

      case 1:
        return (
          <a href="http://www.dressforsuccess.nl/" target="_blank" rel="noreferrer">
            <S.IconContainer>
              <S.Dress4SuccessImg />
            </S.IconContainer>
            <span>{translate('dressForSuccess')}</span>
          </a>
        );

      case 2:
        return (
          <a href="https://www.circle-economy.com/" target="_blank" rel="noreferrer">
            <S.IconContainer>
              <S.CircleEconomyImg />
            </S.IconContainer>
            <span>{translate('circleEconomy')}</span>
          </a>
        );
      case 3:
        return (
          <a href="https://www.resharestore.nl/" target="_blank" rel="noreferrer">
            <S.IconContainer>
              <S.ReshareImg />
            </S.IconContainer>
            <span>{translate('reshareStore')}</span>
          </a>
        );
    }
  };

  const buildSlides = () => {
    const slides = [];

    for (let idx = 0; idx < totalSlides; idx++) {
      slides.push(
        <Slide style={innerSlideStyle} index={idx} key={idx.toString()}>
          <S.CarouselCard>{getSlideContent({ slideindex: idx })}</S.CarouselCard>
        </Slide>
      );
    }

    return slides;
  };

  const resetNumberOfSlidesByScreenType = () => {
    if (width < 780) {
      slidesNum = 1;
    } else if (width < 1400) {
      slidesNum = 2;
    }
  };

  const getRefreshedScreenSlidesNum = (): number => {
    resetNumberOfSlidesByScreenType();
    return slidesNum;
  };

  const innerSlideStyle = {
    paddingBottom: '220px',
  };

  return (
    <>
      <S.Container>
        <S.CarouselWrapper>
          <CarouselProvider
            totalSlides={totalSlides}
            style={sliderStyle}
            visibleSlides={getRefreshedScreenSlidesNum()}
          >
            <Slider>{buildSlides()}</Slider>

            <S.Nav>
              <S.BackButton />
              <S.NextButton />
            </S.Nav>
          </CarouselProvider>
        </S.CarouselWrapper>
      </S.Container>
    </>
  );
};

export default OurPartners;
