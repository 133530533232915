import React from "react";

import * as S from '../styled/styledMobileNavMenu';

import { GroupProps, ItemProps } from "./NavigationOptions";

import Item from "./Item";

import { isGroup, isItem } from "./NavigationOptions";

const Group: React.FC<GroupProps> = ({
    name,
    values,
    newClass,
    ancestry
}) => {
    const nodeId = ancestry + '-' + name

    return (
        <S.NavItem nodeId={nodeId} label={name} className={newClass}>
            {
                values.map(item => {
                    if(isItem(item)) {
                        let entryOptions = item as ItemProps
                        return <Item key={nodeId} type="item" name={entryOptions.name} value={entryOptions.value} newClass="subgroup-item" />
                      } else if (isGroup(item)) {
                        let entryOptions = item as GroupProps
                        return <Group key={nodeId} ancestry={nodeId} type="group" name={entryOptions.name} values={entryOptions.values} newClass="nav-item-submenu group-subgroup-class" />
                      }
                })
            }
        </S.NavItem>
    )
}

export default Group;