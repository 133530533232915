import React from 'react';
import { useSelector } from 'react-redux';
import { ProductListItem } from '../ProductList/productListSlice';
import { ProductPageProduct } from '../../types/ProductPageTypes';

export const gtmProductSerializer = () => {
  const { display_price, price_original, type_en, rootType_en, brand, condition_en, id, product_properties_en, seller_type } =
    useSelector(
      (state: { productPage: { product: ProductPageProduct } }) => state.productPage.product
    );

  const itemColor = product_properties_en?.find((property) => property.name === 'kleur')?.value;
  const itemMaterial = product_properties_en?.find(
    (property) => property.name === 'materiaal'
  )?.value;

  return {
    item_name: id + ' ' + brand + ' ' + type_en,
    item_id: id,
    price: price_original,
    value: display_price,
    discount: price_original - display_price,
    item_category: rootType_en,
    item_category2: type_en,
    item_category3: brand,
    item_variant: itemColor,
    quantity: 1,
    item_seller_type: seller_type,
    item_material: itemMaterial,
    item_condition: condition_en,
  };
};

export const gtmProductListSerializer = ([index, item]: [string, ProductListItem]) => ({
  index: parseInt(index),
  item_name: item.id + ' ' + item.brand + ' ' + item.typeEN,
  item_id: item.id,
  price: item.priceWithoutCurrency,
  item_brand: item.brand,
  item_category: item.typeEN,
  item_variant: item.color,
  item_list_name: item.collections?.join(', '),
  item_seller_type: item.sellerType,
  item_material: item.material,
  item_condition: item.condition,
});
