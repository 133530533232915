import React, {Suspense} from 'react'

const Slider = React.lazy(() => import('./SlickSlider'));


function SlickSlider(props) {
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Slider {...props}/>
      </Suspense>
    </div>
  )
}

export default SlickSlider;
