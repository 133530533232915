import React, { Suspense, useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import EditorialCard from './EditorialCard';
import SlideArrow from '../SlickSlider/SliderArrow';

function EditorialSlider({ cards, fallbackImage }) {
  const getImage1x = card => (card.background1x ? card.background1x : fallbackImage);
  const getImage2x = card => (card.background2x ? card.background2x : fallbackImage);

  const responsive = {
    desktop: {
      breakpoint: { max: 10000, min: 992 },
      items: 6,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 991, min: 0 },
      items: 3,
      slidesToSlide: 2,
    }
  };
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div className="carousel-button-group">
        <SlideArrow className={currentSlide === 0 ? 'disable' : 'slide-arrow slide-arrow-prev'} onClick={() => previous()} />
        <SlideArrow className="slide-arrow slide-arrow-next" onClick={() => next()} />
      </div>
    );
  };

  return (
    <div className="editorial-section">
      <Carousel responsive={responsive} arrows={false} renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />} infinite={true} showDots={true}>
        {cards.map(card => (
          <div key={card.id} className='card-shadow'>
            <Suspense fallback={<div>Loading...</div>}>
              <EditorialCard
                title={card.name}
                background1x={getImage1x(card)}
                background2x={getImage2x(card)}
                link={card.link}
                text={card.text}
                targetBlank={card.targetBlank}
              />
            </Suspense>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
export default EditorialSlider;
