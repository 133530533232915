import React from "react";

import { SearchIconProps } from "./NavigationOptions";
import mobileSearchIcon from '@images/icons/mobile_search.svg';

const ExternalSearchIcon: React.FC<SearchIconProps> = ({
    setOpen
}) => {
    const handleClick = () => {
        setOpen(true);
        const inputField = document.getElementById('search');
        inputField!.focus();
    };

    return (
        <div id="mobile-search-icon-container">
            <img src={mobileSearchIcon} alt="Search" id="search-icon-external" onClick={handleClick} />
        </div>
    )
}

export default ExternalSearchIcon;