import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const nl = require('../src/languages/i18n-nl.json');

i18n.use(initReactI18next).init({
  initImmediate: false,
  preload: 'nl',
  fallbackLng: 'nl',
  lng: 'nl',
  resources: {
    nl: {
      translation: nl,
    },
  },

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
