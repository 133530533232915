import React, { useState } from 'react';
import ReactMarkDown from 'react-markdown';

import Modal from '../Modal/Modal';
import ModalText from '../Modal/ModalText';

import Repository from '../../../javascript/services/repository';
import { getAuthentication } from '../User/userSlice';
import translateComponent from '../helpers/translateI18n';
import * as S from './styled/styledRelistReturn';
import closeIcon from '@images/icons/close-x.svg';
import { setHeaders } from '../../services/helpers';
import { Provider, useSelector } from 'react-redux';
import store from '../../src/store';

interface MarkItemReceivedModalProps {
  isShowing: boolean;
  hide: () => void;
  id: string;
  lineItemState: 'in_progress' | 'delivered' | 'at_pickup_point' | 'buyer_confirmed_payout';
  sellerType: 'diy' | 'boutique' | 'premium' | 'premium_visible';
}

const ConfirmItemReceivedModal: React.FC<MarkItemReceivedModalProps> = ({
  isShowing,
  hide,
  id,
  lineItemState,
  sellerType,
}) => {
  const translate = translateComponent('RelistReturn');

  const [happiness, setHappiness] = useState<undefined | boolean>(undefined);
  const [itemState, setItemState] = useState(lineItemState);

  const userAuthentication = useSelector(getAuthentication);

  const fetchState = (id: string) => {
    Repository.getLineItem(id, { headers: setHeaders(userAuthentication) })
      .then(res => {
        setItemState(res.data.state);
      })
      .catch((err: any) => {
        console.error('Fetch Item Received Modal State', err);
      });
  };

  const changeState = (id: string, toState: string) => {
    userAuthentication &&
      Repository.updateLineItems(
        id,
        { to_state: toState },
        {
          headers: { ...setHeaders(userAuthentication) },
        }
      ).then(() => fetchState(id));
  };

  const handleTrustPilotRating = () => {
    window.open('https://nl.trustpilot.com/evaluate/www.thenextcloset.com', '_blank');
  };

  const happyModal = () => {
    return (
      <>
        <ModalText text={translate('niceToHearHappy')} />
        <ReactMarkDown>
          {translate('askTrustpilotRating', {
            url: translate('trustpilotUrl'),
            text: translate('trustpilot'),
            target: '_blank',
          })}
        </ReactMarkDown>
        <S.PrimaryButton onClick={handleTrustPilotRating}>{translate('ok')}</S.PrimaryButton>
      </>
    );
  };

  const unhappyModal = () => {
    return (
      <>
        <ReactMarkDown>
          {translate('notHappyWithpurchase', {
            target: '_blank',
            url: translate('feedbackFormUrl'),
            text: translate('feedbackForm'),
          })}
        </ReactMarkDown>
        <ModalText text={translate('relistInstructions')} />
        <S.PrimaryButton onClick={() => hide()}>{translate('ok')}</S.PrimaryButton>
      </>
    );
  };

  const chooseHappyOrUnhappyModal = () => {
    return (
      <>
        <ModalText text={translate('gaveDesignerItemNewLife')} />
        <ModalText text={translate('askHappyWithPurchase')} />
        {sellerType === 'diy' && <ModalText text={translate('transferMoneyToSeller')} />}
        <S.ButtonContainer>
          <S.SecondaryButton onClick={() => setHappiness(false)}>
            {translate('no')}
          </S.SecondaryButton>
          <S.SecondaryButton onClick={() => setHappiness(true)}>
            {translate('yes')}!
          </S.SecondaryButton>
        </S.ButtonContainer>
      </>
    );
  };

  const payOutSellerModal = (happiness: undefined | boolean, sellerType: string) => {
    if (sellerType !== 'diy' && happiness === true) {
      return happyModal();
    } else if (sellerType === 'diy' && happiness === true) {
      return changeState(id, 'buyer_confirmed_payout');
    } else if (happiness === false) {
      return unhappyModal();
    } else {
      return chooseHappyOrUnhappyModal();
    }
  };

  const confirmItemReceived = () => {
    return (
      <>
        <ModalText text={translate('confirmItemReceived')} />
        <S.PrimaryButton onClick={() => changeState(String(id), 'delivered')}>
          {translate('yes')}
        </S.PrimaryButton>
      </>
    );
  };

  const ModalBody = (
    <>
      <S.Icon src={closeIcon} alt="close icon" onClick={() => hide()} />
      <S.Container>
        {(itemState === 'in_progress' || itemState === 'at_pickup_point') && confirmItemReceived()}
        {itemState === 'delivered' && payOutSellerModal(happiness, sellerType)}
        {itemState === 'buyer_confirmed_payout' && happyModal()}
      </S.Container>
    </>
  );

  return <Modal isShowing={isShowing} hide={hide} body={ModalBody} id={String(id)} />;
};

const WrappedConfirmItemReceivedModal = (props: MarkItemReceivedModalProps): JSX.Element => (
  <Provider store={store()}>
    <ConfirmItemReceivedModal {...props} />
  </Provider>
);

export default WrappedConfirmItemReceivedModal;
