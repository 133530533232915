import styled from 'styled-components';
import { Theme } from '../../../services/theme';
import { FormControlLabel } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
`;

export const Notification = styled.div`
  padding: 8px 0;
  border-bottom: 2px solid ${Theme.colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled(FormControlLabel)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 16px;
  }
  color: black;
`;
