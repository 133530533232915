// autosave the user edit form

let timer;
let editFormData;

$(document).ready(() => {
	$('#user_edit_form').bind('keyup change', (event) => {
		editFormData = event;
		if (timer) {
			clearTimeout(timer);
		}

		timer = setTimeout(() => {
			$.ajax(editFormData.target.form.action, {
				method: editFormData.target.form.method,
				data: $(editFormData.target.form).serialize(),
			});

			$('#saved_message').fadeIn(300).delay(2000).fadeOut(300);
    }, 1000); // wait 1000 milliseconds before triggering even.
	});

	$('#user_edit_form_notification').bind('keyup change', (event) => {
		editFormData = event;
		if (timer) {
			clearTimeout(timer);
		}

		timer = setTimeout(() => {
			$.ajax(editFormData.target.form.action, {
				method: editFormData.target.form.method,
				data: $(editFormData.target.form).serialize(),
			});

			$('#saved_message').fadeIn(300).delay(2000).fadeOut(300);
    }, 1000); // wait 1000 milliseconds before triggering even.
	});

});
