import { screen } from '@testing-library/react';
import { ProductPage } from '../ProductPage';
import React from 'react';

import {
  DefaultProductPageProps,
  initialProductState,
  initialSellerState,
  initialUserState,
} from './initialProductPageData';
import setupTest from '../../../test/setupTest';

test('Doesnt crash if product is not yet fetched', () => {
  setupTest({}, <ProductPage {...DefaultProductPageProps} />);
  expect(screen.getByText(/loading/i)).toBeTruthy();
});

test('should render all child components if a product is fetched', async () => {
  await setupTest(
    {
      productPage: { product: initialProductState, user: initialSellerState },
      user: initialUserState,
    },
    <ProductPage {...DefaultProductPageProps} />
  );

  expect(screen.getByTestId('productImages')).toBeTruthy();
  expect(screen.getByTestId('productInfo')).toBeTruthy();
  expect(screen.getByTestId('sellerInfo')).toBeTruthy();
});

test('should display 5 breadcrumbs if product belongs to a subcategory', () => {
  setupTest(
    {
      productPage: { product: initialProductState, user: initialSellerState },
      user: initialUserState,
    },
    <ProductPage {...DefaultProductPageProps} />
  );
  expect(screen.getByRole('navigation', { name: 'breadcrumb' })).toBeTruthy();

  const rootBreadcrumb = screen.getByRole('link', { name: 'Home' });
  expect(rootBreadcrumb.href).toEqual('http://localhost/rootPath');

  const mainCategoryBreadcrumb = screen.getByRole('link', { name: 'Kleding' });
  expect(mainCategoryBreadcrumb.href).toEqual('http://localhost/ancestorCategoryPath');

  const subCategoryBreadcrumb = screen.getByRole('link', { name: 'Jurken & mantels' });
  expect(subCategoryBreadcrumb.href).toEqual('http://localhost/optionalSecondAncestorPath');

  const typeBreadcrumb = screen.getByTestId('breadcrumb-type');
  expect(typeBreadcrumb.href).toEqual('http://localhost/categoryPath');

  const brandBreadcrumb = screen.getByTestId('breadcrumb-brand');
  expect(brandBreadcrumb.href).toEqual('http://localhost/urlForBreadCrumbTypeAndBrandPath');
});
