import { screen, fireEvent, act } from '@testing-library/react';

async function goToStepTwo() {
  await act(() =>
    Promise.resolve(
      fireEvent.click(screen.getByText(/Ik heb bovenstaande gelezen en ga akkoord./i))
    )
  );
  await act(() => Promise.resolve(fireEvent.click(screen.getByTestId('stepOneNext'))));
}

async function goToStepThree(documentType: RegExp) {
  await goToStepTwo();
  const radioButtonDriversLicense = screen.getByText(documentType);
  await act(() => Promise.resolve(fireEvent.click(radioButtonDriversLicense)));
  await act(() => Promise.resolve(fireEvent.click(screen.getByTestId('stepTwoNext'))));
}

async function goToStepFour() {
  await goToStepThree(/paspoort/i);
  const file = new File(['(⌐□_□)'], 'chucknorris.png', { type: 'image/png' });
  const uploadFront = screen.getByTestId('fileFront');
  await act(() => Promise.resolve(fireEvent.change(uploadFront, { target: { files: [file] } })));
  const stepThreeNext = screen.getByTestId('stepThreeNext');
  await act(() => Promise.resolve(fireEvent.click(stepThreeNext)));
}

export { goToStepTwo, goToStepThree, goToStepFour };
