import styled from 'styled-components';
import screen from '../../StyledGlobal/mediaQueries';

export const Wrapper = styled.div`
  display: flex;
  font-family: ${props => props.theme.fonts.poppins};
  margin-top: 0;
  ${screen.smMax`
    flex-direction: column;
  `};
`;

export const ProductImagesWrapper = styled.div`
  width: 50%;
  border-bottom: none;
  position: relative;
  ${screen.smMax`
    width:100%;
  `};
`;

export const ProductInfoAndSellerWrapper = styled.div`
  width: 50%;
  border-bottom: none;
  background: #f9f8f4;
  ${screen.smMax`
    width:auto;
  `};
  ${screen.mdMin`
    zoom: 80%;
  `};
  ${screen.lgMin`
    zoom: 100%;
  `};
`;

