import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';

import DicountModalTrigger from '../DiscountModal/DiscountModalTrigger';
import Dropdown from './MyItemsCardDropdown';
import FlashMessage from '../FlashMessage/FlashMessage';


import * as S from './styled/styledMIC';
import infoIcon from '../../../assets/images/icons/info.svg';
import arrowDown from '../../../assets/images/icons/arrow_down_long.svg';
import sizeIcon from '../../../assets/images/icons/size--grey.svg';

const MyItemsCard = props => {
  const {
    image,
    timeToLowerPrice,
    brand,
    type,
    initialState,
    margin,
    id,
    size,
    remark,
    price,
    favorited,
    views,
    discountAmounts,
    shippingFee,
    updatePricePath,
    dropdownProperties,
    timeToExtend,
    expirationDate,
  } = props;

  const [priceState, setPriceState] = useState(price + shippingFee);
  const [itemState, setItemState] = useState(initialState);
  const [message, setMessage] = useState(null);
  const [timeToExtendState, setTimeToExtend] = useState(timeToExtend);
  const [timeToLowerPriceState, setTimeToLowerPrice] = useState(timeToLowerPrice);

  const { i18n } = useTranslation();

  const isSmall = window.innerWidth < 1200;

  const getMargin = (
    <S.Grey>{i18n.t('MIC__margin', { percentage: 100 - Math.round(margin) })}</S.Grey>
  );

  const generateToltipForRemark = remark ? (
    <>
      <div className="toolTipHover toolTip">
        <div className="toolTip__hover__container" />
        <div className="toolTip__triangle" />
        <img src={infoIcon} className="toolTip__icon" alt="" />
        <span className="js-toolTip__text toolTip__text">
          <div>
            <div>
              <b>Remark:</b> {remark}
            </div>
          </div>
        </span>
      </div>
    </>
  ) : null;

  const getViewsAndFavs = (
    <>
      <S.Views>
        <S.ViewsImage isSmall={isSmall} />
        <div>{views}</div>
      </S.Views>
      <S.Favs>
        <S.heartImage isSmall={isSmall} />
        <div>{favorited}</div>
      </S.Favs>
    </>
  );

  const getDropdown = (
    <Dropdown
      {...dropdownProperties}
      setItemState={setItemState}
      currentState={itemState[2]}
      price={price}
      discountAmounts={discountAmounts}
      shippingFee={shippingFee}
      type={type}
      brand={brand}
      updatePricePath={updatePricePath}
      id={id}
      setPriceState={setPriceState}
      timeToExtendState={timeToExtendState}
      setTimeToExtend={setTimeToExtend}
      setMessage={setMessage}
      timeToLowerPriceState={timeToLowerPriceState}
      setTimeToLowerPrice={setTimeToLowerPrice}
    />
  );

  const loadDiscountModalTrigger = (
    <DicountModalTrigger
      currentPrice={price}
      discountAmounts={discountAmounts}
      shippingFee={shippingFee}
      nameAndBrand={`${type} ${brand}`}
      updatePricePath={updatePricePath}
      id={id}
      icon={arrowDown}
      setPriceState={setPriceState}
      timeToLowerPriceState={timeToLowerPriceState}
      setTimeToLowerPrice={setTimeToLowerPrice}
      text={i18n.t('MIC__lower_price_now')}
      className="btn__DM__lowerNow"
    />
  );

  const showExtendDate =
    timeToExtendState && ['approved', 'pause'].includes(itemState[2]) ? (
      <div className="text--red">
        {i18n.t('MIC__expires_at', { expiration_date: expirationDate })}
      </div>
    ) : (
      ''
    );

  // show colored dot if not sold otherwise show sold
  const showState = itemState[1] === 'Sold' ? <S.Sold /> : <S.Dot state={itemState[1]} />;

  const showLowerNow = timeToLowerPriceState && !timeToExtendState ? loadDiscountModalTrigger : '';

  const checkPriceChange = priceState < price + shippingFee;

  // ugly way of hiding component on change. Future redux
  if (['deleted_diy', 'sold_invisible'].includes(itemState[2])) return null;

  return (
    <S.Wrapper>
      {message ? <FlashMessage text={message} duration={10000} containerClassName="MIC__FlashMessage"/> : ''}
      <S.Info>
        <S.ImageWrapper>
          <LazyLoad debounce={false} offsetVertical={250}>
            <S.Image src={image} alt={brand} />
          </LazyLoad>
        </S.ImageWrapper>
        <S.BrandType>
          <S.Truncate>{brand}</S.Truncate>
          <S.Type>{type}</S.Type>
          <S.StateSmall>
            {showState}
            {itemState[0]} {generateToltipForRemark}
          </S.StateSmall>
          <S.showSmall>
            {showExtendDate}
            {showLowerNow}
          </S.showSmall>
          {margin < 20 && margin !== null ? getMargin : ''}
        </S.BrandType>
        <S.InfoLarge>{id}</S.InfoLarge>
        <S.InfoLarge>{size || '-'}</S.InfoLarge>
        <S.PriceLarge priceChange={checkPriceChange}>€{priceState}</S.PriceLarge>
        <S.ViewsFavs>{getViewsAndFavs}</S.ViewsFavs>
        <div>
          <S.StateLarge>
            {showState}
            {itemState[0]} {generateToltipForRemark}
          </S.StateLarge>
          <S.hideSmall>
            {showExtendDate}
            {showLowerNow}
          </S.hideSmall>
        </div>
        {getDropdown}
      </S.Info>
      <S.InfoSmall>
        <S.Grey>
          <S.Pound />
          {id}
        </S.Grey>
        <S.Grey>
          <img src={sizeIcon} alt="size" />
          {size || '-'}
        </S.Grey>
        <S.PriceSmall priceChange={checkPriceChange}>€{priceState}</S.PriceSmall>
        {getViewsAndFavs}
      </S.InfoSmall>
    </S.Wrapper>
  );
};

MyItemsCard.propTypes = {
  image: PropTypes.string,
  brand: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  initialState: PropTypes.arrayOf(PropTypes.string).isRequired,
  margin: PropTypes.string,
  size: PropTypes.string,
  id: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  favorited: PropTypes.number.isRequired,
  views: PropTypes.number.isRequired,
  remark: PropTypes.string,
  timeToLowerPrice: PropTypes.bool,
  discountAmounts: PropTypes.arrayOf(PropTypes.number).isRequired,
  shippingFee: PropTypes.number.isRequired,
  updatePricePath: PropTypes.string.isRequired,
  dropdownProperties: PropTypes.shape({
    editPath: PropTypes.string.isRequired,
    pausePath: PropTypes.string.isRequired,
    deletePath: PropTypes.string.isRequired,
    refreshProductPath: PropTypes.string.isRequired,
    productPath: PropTypes.string.isRequired,
    unpausePath: PropTypes.string.isRequired,
    soldArchivedPath: PropTypes.string.isRequired,
    isPremium: PropTypes.bool.isRequired,
  }).isRequired,
  timeToExtend: PropTypes.bool.isRequired,
  expirationDate: PropTypes.string,
};

MyItemsCard.defaultProps = {
  margin: null,
  size: null,
  timeToLowerPrice: null,
  image: null,
  expirationDate: null,
  remark: null,
};

export default MyItemsCard;

// dont show type < sm
