const addItemToAnalytics = (i) =>
  dataLayer.push({
    ecommerce: {
      addItem: {
        id: i.id, // Transaction ID. Required.
        name: i.name, // Product name. Required.
        sku: i.sku, // SKU/code.
        category: i.category, // Category or variation.
        price: i.price, // Unit price.
        quantity: '1', // Quantity.
        dimension7: i.item_size,
      },
    },
  });

$(() => {
  if (
    (typeof gon !== 'undefined' && gon !== null ? gon.order : undefined) != null &&
    $('#trk-show-order').length &&
    window.tracked == null
  ) {
    window.tracked = true;
    if (gon.order.first) {
      dataLayer.push({
        eventCategory: 'Purchase',
        eventAction: 'First order',
        event: 'gaEvent',
      });
    }

    if (gon.items != null) {
      Array.from(gon.items).forEach((item) => {
        addItemToAnalytics(item);
      });
    }
    dataLayer.push({ 'ecommerce:send': 'ecommerce:send' });

    return typeof google_trackConversion === 'function' ? google_trackConversion() : undefined;
  }
});

$(() => {
  $('.trk-to-fav-from-prod-card').click(() => {
    dataLayer.push({
      eventCategory: 'Purchase',
      eventAction: 'Add to favs',
      eventLabel: 'Add to favs from ProductCard',
      event: 'gaEvent',
    });
    return true;
  });

  $(document).on('click', '#trk-add-to-shopping-bag', () => {
    dataLayer.push({
      eventCategory: 'Purchase',
      eventAction: 'Go to cart',
      eventLabel: 'Add to cart',
      event: 'gaEvent',
    });
    return true;
  });

  $('#trk-verzendpakket-button').click(() => {
    dataLayer.push({
      eventCategory: 'Verkopen',
      eventAction: 'Verzendpakket',
      eventLabel: 'Aangevraagd',
      event: 'gaEvent',
    });
    return true;
  });


  $('#trk-order-submit-button').click(() => {
    dataLayer.push({
      eventCategory: 'Verkopen',
      eventAction: 'Order',
      eventLabel: 'Nieuw',
      eventValue: $('#order_total_costs').val(),
      event: 'gaEvent',
    });
    return true;
  });
  
});
