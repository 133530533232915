import React from 'react';
import { render, cleanup } from '@testing-library/react';

import {  } from '../../test/testUtils';

import DropdownCard from './DropdownCard';

const defaultProps = {
  title: 'Collection title',
  image: 'image',
  imageL: 'image',
  link: 'https://www.thenextcloset.com',
  imageAlt: 'Image alt',
  id: 'id',
  width: '300px',
};

afterEach(cleanup);

const setup = (props = {}) => {
  const setupProps = { ...defaultProps, ...props }; // props overwrites defaultProps by doing this
  return render(<DropdownCard {...setupProps} />);
};


test('component renders but with NO content when no image supplied', () => {
  const { getByTestId } = setup({ image: '' }); // find by data-test-id
  expect(getByTestId('component-dropdown-card')).toBeTruthy(); // check if it exists
});

test('component renders with all content when image is supplied', () => {
  const { getByTestId } = setup();
  expect(getByTestId('component-dropdown-card')).toBeTruthy(); // renders
  expect(getByTestId('text')).toBeTruthy(); // has text
});
