import React, { FC } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import store from '../../src/store';
import axios from 'axios';
import Modal from '../Modal/Modal';
import ModalTitle from '../Modal/ModalTitle';
import ModalText from '../Modal/ModalText';
import translateI18n from '../helpers/translateI18n';
import * as S from './styled/styledCM';
import { setHeaders } from '../../services/helpers';
import { getAuthentication } from '../User/userSlice';
import Repository from '../../services/repository';

interface ConfirmModalProps {
  isShowing: boolean;
  hide: Function;
  id?: string;
  modalTitle?: string;
  modalText?: string;
  confirmLink: string;
  setItemState?: Function;
  useAxios?: boolean;
  dataMethod?: string;
  gtmEvent?: Record<string, unknown>;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  isShowing,
  hide,
  id = undefined,
  modalTitle = '',
  modalText = '',
  confirmLink,
  setItemState = null,
  useAxios = true,
  dataMethod,
  gtmEvent,
  helloRetailEvent
}) => {
  const translate = translateI18n('ConfirmModal');

  const csrf = document?.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
  const authentication = useSelector(getAuthentication);

  function handleConfirmed() {
    axios
      .get(confirmLink, { headers: { Accept: 'application/json', 'X-CSRF-TOKEN': csrf } })
      .then((res) => {
        //   update the state of the item in my items
        if (res.data.state && setItemState) {
          setItemState([res.data.state[0], res.data.state[1], res.data.state[2]]);
        }
        hide();
      });
  }

  const sendGtmEvent = () => {
    if (gtmEvent) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push(gtmEvent);
    }
  };

  const sendHelloRetailEvent = () => {
    if(typeof ADDWISH_PARTNER_NS !== "undefined" && helloRetailEvent) {
      setTimeout(() => {
        Repository.getCartProductsUrl({ headers: setHeaders(authentication) }).then((res) => {
          var data = res.data
          var email = Object.keys(data)[0]
          var items = data[Object.keys(data)[0]]
          var cartLink = data[Object.keys(data)[1]]

          ADDWISH_PARTNER_NS.api.cart.setCart({
            url: cartLink,
            urls: items,
            email: email
          }, 
          function() {
            console.log('Cart has been tracked');
          });
        });
      }, 3000)
    }
  }

  const ConfirmButton = () => {
    return <S.ConfirmButton onClick={handleConfirmed}>{translate('submit')}</S.ConfirmButton>;
  };

  const ConfirmLink = () => {
    return (
      <S.ConfirmLink href={confirmLink} data-method={dataMethod} onClick={() => { sendGtmEvent(); sendHelloRetailEvent(); }}>
        {translate('submit')}
      </S.ConfirmLink>
    );
  };

  const confirmModalBody = (
    <>
      <ModalTitle hide={hide} title={modalTitle} logo />
      <ModalText text={modalText} />

      <S.ButtonContainer>
        <S.CancelButton onClick={() => hide()}>{translate('cancel')}</S.CancelButton>{' '}
        {useAxios ? ConfirmButton() : ConfirmLink()}
      </S.ButtonContainer>
    </>
  );

  return <Modal isShowing={isShowing} hide={hide} id={id} body={confirmModalBody} />;
};

export default (props) => (
  <Provider store={store()}>
    <ConfirmModal {...props} />
  </Provider>
);
