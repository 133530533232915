import styled from 'styled-components';

export const Container = styled.div`
  margin-right: 40px;
  & * {
    font-family: "Poppins";
    font-size: 16px;
    color: var(--black-65);
  }
`;
