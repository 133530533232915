// import React from 'react';
import { screen } from '@testing-library/react';

import initialData from './initialFilterData';
import setup from  './initialFilterSetup';

test('should check if components are rendered', () => {
  setup(initialData)
  expect(screen.getByText(/sorteer op/i)).toBeTruthy()
  expect(screen.getByText(/shop prEmium/i)).toBeTruthy()
})
