import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { Button } from '@material-ui/core';

import DicountModalTrigger from '../DiscountModal/DiscountModalTrigger';
import ConfirmModalTrigger from '../ConfirmModal/ConfirmModalTrigger';

import * as S from './styled/styledMICD';

import threeDots from '@images/icons/three_dots.svg';
import penIcon from '@images/icons/pen2.svg';
import pauseIcon from '@images/icons/pause.svg';
import playIcon from '@images/icons/play.svg';
import trashIcon from '@images/icons/trash.svg';
import clockIcon from '@images/icons/clock.svg';
import donateIcon from '@images/icons/donate.svg';
import discountIcon from '@images/icons/discount.svg';
import infoIcon from '@images/icons/info.svg';

interface MyItemsCardDropdownProps {
  editPath: string;
  pausePath: string;
  deletePath: string;
  refreshProductPath: string;
  productPath: string;
  unpausePath: string;
  soldArchivedPath: string;
  setItemState: Function;
  currentState: string;
  isPremium: boolean;
  price: number;
  discountAmounts: number[];
  brand: string;
  type: string;
  shippingFee: number;
  id: number;
  updatePricePath: string;
  setPriceState: Function;
  timeToExtendState: boolean;
  setTimeToExtend: Function;
  setMessage: Function;
  timeToLowerPriceState: boolean;
  setTimeToLowerPrice: Function;
}

const MyItemsCardDropdown: React.FC<MyItemsCardDropdownProps> = ({
  editPath,
  pausePath,
  deletePath,
  refreshProductPath,
  productPath,
  unpausePath,
  soldArchivedPath,
  setItemState,
  isPremium,
  currentState,
  price,
  discountAmounts,
  shippingFee,
  type,
  brand,
  updatePricePath,
  id,
  setPriceState,
  timeToExtendState,
  setTimeToExtend,
  setMessage,
  timeToLowerPriceState,
  setTimeToLowerPrice,
}) => {
  const { i18n } = useTranslation();
  const translate = (text: string) => {
    return i18n.t(`MyItemsCardDropdown.${text}`);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: { currentTarget: React.SetStateAction<null> }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeState = async (path: string) => {
    axios
      .put(path, {}, { headers: { Accept: 'application/json' } })
      .then(res => {
        if (res.data.timeToExtend !== undefined) {
          setTimeToExtend(res.data.timeToExtend);
        }
        if (res.data.state) {
          setItemState([res.data.state[0], res.data.state[1], res.data.state[2]]);
        }
        if (res.data.message) {
          setMessage(res.data.message);
        }
        if (res.data.price) {
          setPriceState(res.data.price + shippingFee);
        }
      })
      .catch(e => {
        console.error('Something went wrong!', e);
      });
  };

  function askToConfirm(path: string) {
    const confirmAction = window.confirm(i18n.t('confirm_delete_item'));
    if (confirmAction) {
      changeState(path);
    }
  }

  const handleMenuClose = (needConfirm: boolean, path: string) => {
    handleClose();
    needConfirm ? askToConfirm(path) : changeState(path);
  };

  function dropdownItem(path: string, icon: string, text: string, needConfirm: boolean) {
    return (
      <S.MaterialMenuItem onClick={() => handleMenuClose(needConfirm, path)} key={`${id}-${text}`}>
        <img src={icon} alt="" />
        {translate(text)}
      </S.MaterialMenuItem>
    );
  }

  const edit = (
    <S.MaterialMenuItem onClick={() => window.open(editPath)} key={`${id}-edit`}>
      <img src={penIcon} alt="" />
      {translate('edit')}
    </S.MaterialMenuItem>
  );

  const donateProduct = (
    <S.MaterialMenuItem key={`${id}-donate`}>
      <a href="https://www.reshare.nl/Map" target="_blank" rel="noopener noreferrer">
        <img src={donateIcon} alt="" />
        {translate('donate')}
      </a>
    </S.MaterialMenuItem>
  );

  const getTrigger = currentState === 'pending_diy' ? infoIcon : threeDots;

  const discountModalTrigger = (price: number, shippingFee: number, currentState: string) => {
    return price > 20 && currentState === 'approved' ? (
      <S.MaterialMenuItem onClick={handleClose} key={`${id}-discount`}>
        <DicountModalTrigger
          currentPrice={price}
          discountAmounts={discountAmounts}
          shippingFee={shippingFee}
          nameAndBrand={`${type} ${brand}`}
          updatePricePath={updatePricePath}
          id={id}
          setPriceState={setPriceState}
          timeToLowerPriceState={timeToLowerPriceState}
          setTimeToLowerPrice={setTimeToLowerPrice}
          text={translate('give_discount')}
          icon={discountIcon}
        />
      </S.MaterialMenuItem>
    ) : null;
  };

  function loadConfirmModalTrigger(link: string) {
    return (
      <ConfirmModalTrigger
        id={id}
        icon={trashIcon}
        text={translate('delete')}
        confirmLink={link}
        setItemState={setItemState}
        modalText={i18n.t('confirm_delete_item')}
      />
    );
  }

  const premiumMenu = (
    <S.MaterialMenuItem onClick={handleClose} key={`${id}-toProduct`}>
      <a href={productPath} target="_blank" rel="noopener noreferrer">
        {translate('inspect')}
      </a>
    </S.MaterialMenuItem>
  );

  const diyMenu = [
    price > 20 &&
      currentState === 'approved' &&
      discountModalTrigger(price, shippingFee, currentState),

    currentState === 'approved' && dropdownItem(pausePath, pauseIcon, 'pause', false),

    currentState === 'pause' && dropdownItem(unpausePath, playIcon, 'unpause', false),

    ['in_review', 'change_required', 'approved', 'pause'].includes(currentState) && edit,

    ['in_review', 'change_required', 'rejected', 'approved', 'pause', 'expired'].includes(
      currentState
    ) && (
      <S.MaterialMenuItem onClick={handleClose} key={`${id}-delete`}>
        {loadConfirmModalTrigger(deletePath)}
      </S.MaterialMenuItem>
    ),

    currentState === 'sold' && (
      <S.MaterialMenuItem onClick={handleClose} key={`${id}-soldArchived`}>
        {loadConfirmModalTrigger(soldArchivedPath)}
      </S.MaterialMenuItem>
    ),

    (currentState === 'approved' && timeToExtendState) || currentState === 'expired'
      ? dropdownItem(refreshProductPath, clockIcon, 'refresh', false)
      : null,

    currentState === 'expired' && (
      <S.MaterialMenuItem onClick={handleClose} key={`${id}-expired`}>
        {donateProduct}
      </S.MaterialMenuItem>
    ),
    
    currentState === 'pending_diy' && (
      <S.MaterialMenuItem onClick={handleClose} key={`${id}-pending`}>
        {' '}
        {i18n.t('MIC__pendingInfo')}
      </S.MaterialMenuItem>
    ),
  ];

  return (
    <S.Wrapper>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <img src={getTrigger} />
      </Button>

      <S.MaterialMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isPremium ? premiumMenu : diyMenu}
      </S.MaterialMenu>
    </S.Wrapper>
  );
};

export default MyItemsCardDropdown;
