import styled from 'styled-components';
import img from './plus_circle_green.svg';

export const StyledField = styled.div`
  position: relative;
  display: ${props => (props.hidden ? 'none' : 'block')};
  margin: 6px;

  /* background: white; */
  background: ${props => (props.dragging ? '#edf1ed' : 'white')};
  background-image: url(${img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px;
  border: 2px dashed ${props => (props.dragging ? 'white' : '#edf1ed')};
  &:first-child {
    border: 3px solid ${props => (props.dragging ? 'white' : '#edf1ed')};
  }

  user-select: none;

  input {
    display: none;
  }

  img {
    position: absolute;

    max-width: 100%;
    height: 100%;
    object-fit: contain;

    pointer-events: none;
  }

  > div {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    > label {
      width: 100%;
      height: 100%;

      cursor: pointer;
    }
  }

  &:after {
    display: block;
    padding-bottom: 100%;

    content: '';
  }
  &:hover {
    cursor: pointer;
  }
`;

export const Label = styled.div`
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 2;

  padding: 2px 4px;

  font-size: 12px;

  background-color: #edf1ed;
  border-radius: 0px;
`;

export const Delete = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;

  margin: 0;
  padding: 2px 6px;

  font-size: 28px;
  line-height: 27px;

  background-color: #edf1ed;
  border: none;
  border-radius: 50%;
`;
