import { useCallback, useState } from 'react';

const useModal = (): { isShowing: boolean, toggle: () => void } => {
  const [isShowing, setIsShowing] = useState(false);
  
  const toggle = useCallback(() => {
    setIsShowing((showing) => !showing)
  }, [])

  return {
    isShowing,
    toggle,
  };
};

export default useModal;
