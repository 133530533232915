import styled from 'styled-components';
import { StyledRadio } from '../../StyledGlobal/StyledForm';
import { StyledPrimaryButton, StyledSecondaryButton } from '../../StyledGlobal/StyledButton';
import { StyledField } from '../../StyledGlobal/StyledForm';
import screen from '../../StyledGlobal/mediaQueries';

export const Title = styled.p`
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: 'Overpass';
  padding-bottom: 50px;
`;

interface paragraphProps {
  flex?: boolean;
}
export const Container = styled.div`
  display: flex;
  ${screen.xsMax`
  flex-direction: column-reverse;`}
`;

export const Wrapper = styled.div``;
export const ExampleIdImage = styled.img`
  width: 400px;
  margin: 60px 0 0 20px;
  ${screen.xsMax`
  margin: 20px 0 0 0;
  width: 300px;`}
`;

export const Paragraph = styled.div<paragraphProps>`
  margin-bottom: 25px;
  display: ${props => (props.flex ? 'flex' : 'block')};
`;

export const List = styled.ul`
  list-style: none;
`;

export const Link = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

export const Error = styled.div`
  color: var(--brand-warning);
  display: block;
  padding: 10px 0 15px;
`;

export const TermsContainer = styled.div`
  margin-bottom: 20px;
`;

export const RadioButtons = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Radio = styled(StyledRadio)`
  margin-right: 5px !important;
  margin-top: 1px !important;
`;

export const Label = styled.label`
  display: flex;
  margin-bottom: 14px;
`;

export const PrimaryButton = styled(StyledPrimaryButton)`
  width: 115px;
`;

export const SecondaryButton = styled(StyledSecondaryButton)`
  width: 115px;
`;

interface ButtonContainerProps {
  marginTop?: string;
}

export const ButtonContainer = styled.div<ButtonContainerProps>`
  width: 250px;
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.marginTop};
`;

export const FrontSideId = styled.div`
  margin-top: 35px;
`;

export const BackSideId = styled.div`
  margin-top: 25px;
`;

export const TextId = styled.p`
  font-weight: 800;
  margin-bottom: 15px !important;
`;

export const InfoText = styled.p`
  margin: 20px 0;
  font-size: 12px;
  font-style: italic;
`;

export const Bold = styled.p`
  font-weight: 800;
`;

export const FieldWrapper = styled.div`
  margin: 20px 0;
`;

export const Field = styled(StyledField)`
  width: 300px;
`;

export const SmallField = styled(StyledField)`
  width: 100px;
`;

export const DateFieldWrapper = styled.div``;

export const Date = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-between;
`;
