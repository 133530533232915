import React from 'react';
import { render, fireEvent } from '@testing-library/react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { I18nextProvider } from 'react-i18next';
import { act } from 'react-dom/test-utils';
import { Formik, Form } from 'formik';

import CountrySelect from '../CountrySelect';
import checkout, * as checkoutSlice from '../checkoutSlice';
import i18n from '../../../test/i18nForTest';
import { initialData } from './initialCheckoutData';

const makeFormEditable = jest.fn();

const calculateShippingCosts = jest
  .spyOn(checkoutSlice, 'calcShippingCosts')
  .mockReturnValue(() => {});

const setup = (data, name, calcShipping, shippingOrInvoice, country) => {
  const store = configureStore({ preloadedState: data, reducer: { checkout } });

  return render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Formik initialValues={{ [shippingOrInvoice]: { country } }}>
          <Form>
            <CountrySelect
              fieldName={name}
              calcShipping={calcShipping}
              makeFormEditable={makeFormEditable}
            />
          </Form>
        </Formik>
      </I18nextProvider>
    </Provider>
  );
};

test('For SHIPPING country the initial value is correct, its possible to change the country and shipping costs and functions are called', async () => {
  const { getByTestId, queryByTestId } = setup(
    initialData,
    'shipping.country',
    true,
    'shipping',
    'NL'
  );
  expect(queryByTestId('invoice.country')).toBeFalsy();
  const select = getByTestId('shipping.country');
  expect(select).toBeTruthy();
  expect(select.value).toBe('NL'); // initialValue = NL

  const promise = Promise.resolve(fireEvent.change(select, { target: { value: 'PT' } }));

  expect(select.value).toBe('PT');
  expect(makeFormEditable).toHaveBeenCalledTimes(1);
  expect(calculateShippingCosts).toHaveBeenCalledTimes(1);
  expect(calculateShippingCosts).toHaveBeenCalledWith('PT');

  await act(() => promise);
});

test('No country selected if there is no initial value', () => {
  const { getByTestId, queryByTestId } = setup(
    initialData,
    'shipping.country',
    true,
    'shipping',
    ''
  );
  expect(queryByTestId('invoice.country')).toBeFalsy();
  const select = getByTestId('shipping.country');
  expect(select).toBeTruthy();
  expect(select.value).toBe('');
});

test('For INVOICE country the initial value is correct, its possible to change the country and shipping costs and functions are called', async () => {
  const { getByTestId, queryByTestId } = setup(
    initialData,
    'invoice.country',
    false,
    'invoice',
    'NL'
  );
  expect(queryByTestId('shipping.country')).toBeFalsy();
  const select = getByTestId('invoice.country');
  expect(select).toBeTruthy();
  expect(select.value).toBe('NL'); // initialValue = NL

  const promise = Promise.resolve(fireEvent.change(select, { target: { value: 'PT' } }));

  expect(select.value).toBe('PT');
  expect(makeFormEditable).toHaveBeenCalledTimes(1);
  expect(calculateShippingCosts).not.toHaveBeenCalled();

  await act(() => promise);
});
