import styled from 'styled-components';

export const LineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Price = styled.div``;

export const Text = styled.p``;
