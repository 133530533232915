import * as yup from 'yup';

export const userSchemaSignUp = yup.object().shape({
  firstname: yup
    .string()
    .required('firstname_required')
    .matches(/^((?!@).)*$/, 'at_sign_not_allowed'),
  lastname: yup
    .string()
    .required('lastname_required')
    .matches(/^((?!@).)*$/, 'at_sign_not_allowed'),

  email: yup
    .string()
    .email('no_valid_email')
    .required('email_required'),
  confirmEmail: yup
    .string()
    .required()
    .label('Confirm email')
    .test('emails-match', '', function(value) {
      return this.parent.email === value;
    }),
  password: yup
    .string()
    .required('password_required')
    .min(6, 'min_6_characters'),
  terms_conditions_check: yup
    .boolean()
    .required('Required')
    .oneOf([true]),
});

export const userSchemaLogIn = yup.object().shape({
  email: yup
    .string()
    .email('no_valid_email')
    .required('email_required'),

  password: yup.string().required('password_required'),
});
