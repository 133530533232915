import React from 'react';
import { Provider } from 'react-redux';

import store from '../../../src/store';
import FilterButton from './FilterButton';
import SortButton from './SortButton';

const MobileFilterMenuContainer = () => {
  return (
    <Provider store={store()}>
      <SortButton />
      <FilterButton />
    </Provider>
  );
};

export default MobileFilterMenuContainer;
