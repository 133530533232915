import React, { useState, FC } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import './style/ImageLoader.scss';
import screen from '../StyledGlobal/mediaQueries';

interface Props {
  src: string;
  alt?: string;
  altSrc?: string;
  loadingClassName?: string;
  loadedClassName?: string;
}

const Image = styled.img`
  padding: 5%;
  ${screen.xsMax`
    padding: 0;
  `}
`;

// used to create nice transition while lazy loading images
const ImageLoader: FC<Props> = ({
  src,
  alt,
  loadingClassName = 'image-loading',
  loadedClassName = 'image-loaded',
  altSrc,
}) => {
  const [imageClass, setImageClass] = useState(loadingClassName);
  const [imgSrc, setImgSrc] = useState(src)

  const handleLoad = () => {
    setImageClass(loadedClassName);
  };

  const handleError = (e) => {
    e.stopPropagation()
    setImgSrc(altSrc)
  }

  const classes = classNames('PC_image', imageClass);

  return (
    <Image
      src={imgSrc}
      className={classes}
      onLoad={handleLoad}
      onError={handleError}
      alt={alt}
      id="trk-ClickOnProductCard"
    />
  );
};

export default ImageLoader;
