import ImageLoader from './ImageLoader';

export default ImageLoader;

// // used to create nice transition while lazy loading images
// import React from 'react';
// import './style/ImageLoader.scss';

// const _loaded = {};

// class ImageLoader extends React.Component {
//   // initial state: image loaded stage
//   state = {
//     loaded: _loaded[this.props.src],
//   };

//   // define our loading and loaded image classes
//   static defaultProps = {
//     className: '',
//     loadingClassName: 'img-loading',
//     loadedClassName: 'img-loaded',
//   };

//   // image onLoad handler to update state to loaded
//   onLoad = () => {
//     _loaded[this.props.src] = true;
//     this.setState(() => ({ loaded: true }));
//   };

//   render() {
//     let { className, loadedClassName, loadingClassName, ...props } = this.props;

//     className = `${className} ${this.state.loaded ? loadedClassName : loadingClassName}`;

//     return (
//       <img
//         src={this.props.src}
//         onClick={this.props.onClick}
//         className="PC_image"
//         onLoad={this.onLoad}
//         alt={this.props.alt}
//         id="trk-ClickOnProductCard"
//       />
//     );
//   }
// }

// export default ImageLoader;
