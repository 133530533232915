import styled from 'styled-components';
import {
  StyledPrimaryButton,
  StyledPrimaryLink,
  StyledSecondaryButton,
} from '../../StyledGlobal/StyledButton';

export const ConfirmButton = styled(StyledPrimaryButton)`
  width: 45%;
`;

export const ConfirmLink = styled(StyledPrimaryLink)`
  width: 45%;
`;

export const CancelButton = styled(StyledSecondaryButton)`
  width: 45%;
`;

export const ButtonContainer = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
