import React from 'react';
import { EditNavBarProps } from './UploadProductPageTypes';
import translateComponent from './../helpers/translateI18n';

import * as S from './styled/styledENB';

const EditNavBar: React.FC<EditNavBarProps> = ({ formProgress, setFormProgress }) => {
  const translate = translateComponent('EditNavBar');
  return (
    <S.Wrapper>
      <S.Step onClick={() => setFormProgress('stepOne')} active={formProgress === 'stepOne'}>
        <span>
          <S.DressIcon />
          {translate('stepOne')}
        </span>
      </S.Step>
      <S.Step onClick={() => setFormProgress('stepTwo')} active={formProgress === 'stepTwo'}>
        <span>
          <S.CameraIcon />
          {translate('stepTwo')}
        </span>
      </S.Step>
      <S.Step onClick={() => setFormProgress('stepThree')} active={formProgress === 'stepThree'}>
        <span>
          <S.PenIcon />
          {translate('stepThree')}
        </span>
      </S.Step>
      <S.Step onClick={() => setFormProgress('stepFour')} active={formProgress === 'stepFour'}>
        <span>
          <S.EuroIcon />
          {translate('stepFour')}
        </span>
      </S.Step>
    </S.Wrapper>
  );
};

export default EditNavBar;
