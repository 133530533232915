import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { InitialAddress, returnValueCouponCheck, CheckoutProduct } from './CheckoutTypes';

interface CheckoutSlice {
  returnValueCouponCheck: null | returnValueCouponCheck;
  discount: null | number;
  totalDiscount: number;
  shippingCosts: null | string;
  transactionFee: number,
  initialAddressData: InitialAddress;
  countries: null | { id: number; shipping_country_id: number; code: string }[];
  termsPath: string;
  coupon: string;
  totalAmount: number;
  products: CheckoutProduct[] | null;
  zipcodeApiPath: string;
  sellerCountry: string;
}

// Initial State
// check OrdersController#new method for all properties added to state
const initialState: CheckoutSlice = {
  returnValueCouponCheck: null,
  discount: null,
  totalDiscount: 0,
  shippingCosts: null,
  transactionFee: 0,
  totalAmount: 0,
  coupon: '',
  initialAddressData: {
    paymentMethod: '',
    creditcardType: '',
    itemIdsforPickup: [],
    shipping: {
      firstname: '',
      lastname: '',
      street: '',
      housenumber: '',
      housenumberAddition: '',
      zipcode: '',
      city: '',
      country: '',
      addressComplete: false,
      addressIsValidated: false,
    },
    invoice: {
      firstname: '',
      lastname: '',
      street: '',
      housenumber: '',
      housenumberAddition: '',
      zipcode: '',
      city: '',
      country: '',
      addressComplete: false,
    },
    shippingIsInvoice: false,
    createOrderPath: '',
  },
  countries: null,
  termsPath: '',
  products: null,
  zipcodeApiPath: '',
  sellerCountry: '',
};

// create slice
const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setReturnValueCouponCheck(state, action) {
      state.returnValueCouponCheck = action.payload;
    },
    setDiscount(state, action) {
      state.discount = action.payload;
    },
    setTotalDiscount(state, action) {
      state.totalDiscount = action.payload;
    },
    setShippingCosts(state, action) {
      state.shippingCosts = action.payload;
    },
    setInvoiceAddressComplete(state, action) {
      state.initialAddressData.invoice.addressComplete = action.payload;
    },
    setShippingIsInvoice(state, action) {
      state.initialAddressData.shippingIsInvoice = action.payload;
    },
    setAddressIsValidated(state, action) {
      state.initialAddressData.shipping.addressIsValidated = action.payload;
    },
    setItemsForPickUp(state, action) {
      state.initialAddressData.itemIdsforPickup = action.payload;
    },
  },
});

export const {
  setReturnValueCouponCheck,
  setDiscount,
  setTotalDiscount,
  setShippingCosts,
  setInvoiceAddressComplete,
  setShippingIsInvoice,
  setAddressIsValidated,
  setItemsForPickUp,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;

// thunk actions
export const getDiscount = (value: string) => async (dispatch: any, getState: any) => {
  const res = await axios.get(getState().checkout.couponPath, {
    headers: { Accept: 'application/json' },
    params: { code: value, shipping: getState().checkout.shippingCosts },
  });
  if (res.status === 200) {
    dispatch(setReturnValueCouponCheck({ message: res.data.message, valid: res.data.isValid })); // return the message and the validity of the coupon code
    if (res.data.isValid) {
      dispatch(setDiscount(Number(res.data.amount))); // update the price if the discount code is valid
      dispatch(setShippingCosts(res.data.shippingCosts)); // update shipping costs if the discount code is shipping type
    }
  }
};

export const calcShippingCosts = (shipCountry: string) => async (dispatch: any, getState: any) => {
  const res = await axios.get(getState().checkout.calcShippingPath, {
    headers: { Accept: 'application/json' },
    params: { shipCountry },
  });
  if (res) {
    dispatch(setShippingCosts(res.data.rawShippingCosts));
  }
};

export const getLineItemsForPickUp = createAsyncThunk(
  'checkout/getLineItemsForPickUp',
  async (
    {
      itemsForPickUp,
      productId,
    }: {
      itemsForPickUp: number[];
      productId: number;
    },
    { dispatch }
  ) => {
    const lineItemsForPickUp = itemsForPickUp.includes(productId)
      ? itemsForPickUp.filter((id: number) => id !== productId)
      : [...itemsForPickUp, productId];
    return dispatch(setItemsForPickUp(lineItemsForPickUp));
  }
);
