/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { getItemIds, getItems } from '../ProductList/productListSlice';
import { gtmProductListSerializer } from '../Analytics/gtmProductSerializer';
import useWindowidth from '../helpers/hooks/useWindowWidth';
import { StyledPrimaryLink, StyledSecondaryLink } from '../StyledGlobal/StyledButton';
import store from '../../src/store';
import ProductCard from './ProductCard';
import BannerText from '../global/BannerText';
import BreadcrumbsContainer from '../Filters/Breadcrumbs/BreadcrumbsContainer';
import RemoveFiltersContainer from '../Filters/DesktopFilterContainer/RemoveFiltersContainer';
import { ProductCardProps } from '../ProductCard/ProductCard';

import * as S from './styled/styledPPC';

import {
  getTotalProductsCount,
  getFilters,
  setSelectedFilters,
} from '../Filters/productFilterSlice';
import ProductCardTypeA from './ProductCardTypeA';

const MaxiumScreenSizeTablet = 992;

interface PropductCardConainerProps {
  products: ProductCardProps[];
  rowClass?: string;
  showCountMobile?: boolean;
}

const ProductCardContainer: React.FC<PropductCardConainerProps> = ({
  products,
  rowClass = null,
  showCountMobile = false,
}) => {
  const width = useWindowidth();
  const totalProductsCount = useSelector(getTotalProductsCount);
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector(getFilters);
  const itemIds = useSelector(getItemIds);
  const items = useSelector(getItems);

  useEffect(() => {
    // GTM Tracking
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        currency: 'EUR',
        items: items && Object.entries(items).map(gtmProductListSerializer),
        items_google_ads: items && Object.entries(items).map((product) => {
          return {
            item_id: product[1].id,
            google_business_vertical: 'retail'
          }
        })
      },
    });
  }, [items]);

  useEffect(() => {
    // Creates an array of { filterName, id } objects of all selected items
    const selected: { filterName: string; id: string }[] = [];
    Object.entries(filters)
      // Loop through the filters
      .forEach(([filterName, { items, itemIds }]) =>
        itemIds
          .filter((id) => items[id].selected) // Only pick 'selected' items
          // map to selected items to { filterName, id } objects
          .forEach((id) => {
            selected.push({
              filterName,
              id,
            });
          })
      );
    dispatch(setSelectedFilters(selected));
  }, []);

  const displayProducts = () => {
    return itemIds.map((id) => (
      <div className="col-xs-6 col-md-4" key={id ? id : null}>
        <ProductCardTypeA seller_country={undefined} {...items[id]} />
      </div>
    ));
  };

  const noProductsToShow = () => {
    return width < MaxiumScreenSizeTablet ? (
      <div className={'e404__wrapper'}>
        <BannerText
          buttonClassName="btn--primary e404__button"
          buttonText={i18n.t('PCC__no_products_button_1')}
          link={'/items'}
          bannerHeader={i18n.t('PCC__no_products_title')}
          bannerText={i18n.t('PCC__no_products_mobile')}
          className="flex--aligned e404__banner__text"
          buttonText2={i18n.t('PCC__no_products_button_2')}
          link2={'https://thenextcloset.com/collecties/most-liked-items'}
          buttonClassName2="btn--secondary e404__button"
        />
      </div>
    ) : (
      <>
        <S.NoProductsTextWrapper>
          <S.NoProductsTitle className={'h__f--l--b mb--20'}>
            {i18n.t('PCC__no_products_title')}
          </S.NoProductsTitle>
          <div className={'mb--40 lh--32'}>{i18n.t('PCC__no_products_desktop')}</div>
          <StyledPrimaryLink href="/items">{i18n.t('PCC__no_products_button_1')}</StyledPrimaryLink>
          <br />
          <br />
          <StyledSecondaryLink href="https://thenextcloset.com/collecties/most-liked-items">
            {i18n.t('PCC__no_products_button_2')}
          </StyledSecondaryLink>
        </S.NoProductsTextWrapper>
      </>
    );
  };

  return (
    <>
      {totalProductsCount <= 4 ? (
        <Helmet>
          <meta name="robots" content="noindex,follow" />
        </Helmet>
      ) : null}
      <div className="container-fluid" data-testid="productcard-container">
        {/* Breadcrumbs  and for product index pages are shown in filter for desktop */}
        {width < MaxiumScreenSizeTablet ?
          <S.RemoveFiltersContainerMobile>
            <RemoveFiltersContainer />
          </S.RemoveFiltersContainerMobile> :
          <BreadcrumbsContainer />
        }
        <div className={`row ${rowClass}`}>
          {products.length > 0 ? displayProducts() : noProductsToShow()}
        </div>
      </div>
    </>
  );
};

export default (props: PropductCardConainerProps) => (
  <Provider store={store()}>
    <ProductCardContainer {...props} />
  </Provider>
);
