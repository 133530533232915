import React from 'react';
import { Field, useFormikContext } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../src/store';

import * as S from './styled/StyledAddress';
import { calcShippingCosts } from './checkoutSlice';
import { InitialAddress } from './CheckoutTypes';

interface CountrySelectProps {
  calcShipping?: boolean;
  fieldName: string;
  makeFormEditable?: Function;
  fieldsDisabled?: boolean;
}

const CountrySelect: React.FC<CountrySelectProps> = ({
  calcShipping = false,
  fieldName,
  makeFormEditable = undefined,
  fieldsDisabled = false,
}) => {
  const i18n = useTranslation();
  const translate = (text: string) => {
    return i18n.t(`ShippingCountry.${text}`);
  };

  const dispatch = useDispatch();

  const countryCodes = useSelector((state: RootState) => state.checkout.countries);
  const { handleChange, values } = useFormikContext<InitialAddress>();

  const initialValue =
    fieldName === 'shipping.country' ? values.shipping.country : values.invoice.country;

  const calculateShipping = (e: { target: { value: any } }) => {
    dispatch(calcShippingCosts(e.target.value));
  };

  const countries =
    countryCodes &&
    countryCodes.map((c: { code: string }) => {
      return <Input value={c.code} name={translate(c.code)} key={c.code} />;
    });

  return (
    <S.SelectWrapper>
      <S.Label>{translate('country')}</S.Label>
      <Field
        value={initialValue}
        as={S.Select}
        name={fieldName}
        data-testid={fieldName}
        disabled={fieldsDisabled}
        onChange={(e: any) => {
          handleChange(e); // Formik standard onChange action
          // reacalulate shipping costs when changing country
          calcShipping && calculateShipping(e);
          makeFormEditable && makeFormEditable();
        }}
      >
        {/* add prompt to select country if the user has none yet / */}
        {initialValue ? '' : <Input value="" name={translate('select_country')} />}
        {countries}
      </Field>
    </S.SelectWrapper>
  );
};

interface InputProps {
  value: string;
  name: string;
}

const Input: React.FC<InputProps> = ({ value, name }) => {
  return <option value={value}>{name}</option>;
};

export default CountrySelect;
