const pauseItemsLink = document.getElementById('js-switchPauseItems');

if (pauseItemsLink) {
  pauseItemsLink.addEventListener('click', e => {
    const confirmation = confirm(pauseItemsLink.dataset.text);
    if (confirmation) {
      document.location = pauseItemsLink.dataset.link;
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  });
}
