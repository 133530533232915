import React, { CSSProperties } from 'react';
import Button from './Button';

interface BannerTextProps {
  link?: string,
  buttonClassName?: string,
  buttonText?: string,
  bannerHeader?: string,
  bannerText?: string,
  className?: string,
  buttonId?: string,
  link2?: string,
  buttonId2?: string,
  buttonClassName2?: string,
  buttonText2?: string,
  headerClassName?: string,
  style?: CSSProperties,
}

const BannerText: React.FC<BannerTextProps> = ({
  link = null,
  buttonClassName = null,
  buttonText = null,
  bannerHeader = null,
  bannerText = null,
  className = null,
  buttonId = null,
  link2 = null,
  buttonId2 = null,
  buttonClassName2 = null,
  buttonText2 = null,
  headerClassName = null,
  style = undefined,
}) => {
  const renderButton = buttonProps => <Button {...buttonProps} />;

  return (
    <div className={`banner__text flex flex--stacked ${className}`} style={style}>
      <div className={`banner__text__header h__f--l--b ${headerClassName}`}>{bannerHeader}</div>
      <div className="banner__text__text t__f">{bannerText}</div>
      {buttonText
        ? renderButton({ link, id: buttonId, className: buttonClassName, text: buttonText })
        : ''}
      {buttonText2
        ? renderButton({
            link: link2,
            id: buttonId2,
            className: buttonClassName2,
            text: buttonText2,
          })
        : ''}
    </div>
  );
};


export default BannerText;
