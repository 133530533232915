import styled from 'styled-components';
import screen from '../../StyledGlobal/mediaQueries';

export const Button = styled.button`
  z-index: 10000;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: black;
  display: flex;
  border: none;
  padding: 8px 17px;
  border-radius: 28px;
  ${screen.smMax`
    padding: 0;
    width: 30px;
    height:30px;
    border-radius: 50%;
    justify-content: center;
    align-items:center;
    right: 10px;
    bottom: 70px;
  `}
`;

export const Icon = styled.img`
  margin-bottom: 1px;
  height: 20px;
  width: 20px;
`;

export const Text = styled.p`
  color: white;
  margin: 0 0 0 5px;
  ${screen.smMax`display: none;`}
`;
