import React from 'react';
import { render, screen, fireEvent } from '@testing-library/react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { I18nextProvider } from 'react-i18next';
import { act } from 'react-dom/test-utils';

import { Checkout } from '../Checkout';
import checkout from '../checkoutSlice';
import i18n from '../../../test/i18nForTest';
import { initialData, initialDataEmptyShippingAddress } from './initialCheckoutData';

import * as zipcodeCheck from '../helpers/getAddress';

const setup = (data) => {
  const store = configureStore({ preloadedState: data, reducer: { checkout } });
  return render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Checkout />
      </I18nextProvider>
    </Provider>
  );
};

global.PostcodeNl = {
  AutocompleteAddress: class {
    // eslint-disable-next-line class-methods-use-this
    destroy() {}
  },
};

function getElements() {
  return {
    firstNameWrapper: screen.getByTestId('shipping.firstname.wrapper'),
    lastNameWrapper: screen.getByTestId('shipping.lastname.wrapper'),
    streetWrapper: screen.getByTestId('shipping.street.wrapper'),
    numberZipcodeWrapper: screen.getByTestId('shipping.numberZipcodeWrapper'),
    cityWrapper: screen.getByTestId('shipping.city.wrapper'),
    countryInput: screen.getByTestId('shipping.country'),
    submit: screen.getByTestId('submit'),

    firstNameInput: screen.getByTestId('shipping.firstname').getElementsByTagName('input')[0],
    lastNameInput: screen.getByTestId('shipping.lastname').getElementsByTagName('input')[0],
    streetInput: screen.getByTestId('shipping.street').getElementsByTagName('input')[0],
    housenumberInput: screen.getByTestId('shipping.housenumber').getElementsByTagName('input')[0],
    housenumberAdditionInput: screen
      .getByTestId('shipping.housenumberAddition')
      .getElementsByTagName('input')[0],
    zipcodeInput: screen.getByTestId('shipping.zipcode').getElementsByTagName('input')[0],
    cityInput: screen.getByTestId('shipping.city').getElementsByTagName('input')[0],
    shippingIsInvoice: screen.getByTestId('shippingIsInvoice'),
  };
}

describe('Tests for ShippingAddress when address fields are empty (new user)', () => {
  test('should only show firstname, lastname and country if initialValue of country === "" and correct fields for non-NL countries when changing country', async () => {
    setup(initialDataEmptyShippingAddress);
    const {
      firstNameWrapper,
      lastNameWrapper,
      streetWrapper,
      numberZipcodeWrapper,
      cityWrapper,
      countryInput,
    } = getElements();

    expect(firstNameWrapper.hidden).toBe(false);
    expect(countryInput.hidden).toBe(false);
    expect(lastNameWrapper.hidden).toBe(false);
    expect(streetWrapper.hidden).toBe(true);
    expect(numberZipcodeWrapper.hidden).toBe(true);
    expect(cityWrapper.hidden).toBe(true);

    await act(() => Promise.resolve(fireEvent.change(countryInput, { target: { value: 'FR' } })));

    //  every country that is NOT: NL, BE, DE, LU will give same result, all address fields shown
    expect(firstNameWrapper.hidden).toBe(false);
    expect(countryInput.hidden).toBe(false);
    expect(lastNameWrapper.hidden).toBe(false);
    expect(numberZipcodeWrapper.hidden).toBe(false);
    expect(streetWrapper.hidden).toBe(false);
    expect(cityWrapper.hidden).toBe(false);

    // TODO: mock autocompleteAdress API

    await act(() => Promise.resolve(fireEvent.change(countryInput, { target: { value: 'BE' } })));

    // Fields to be shown for BE
    expect(firstNameWrapper.hidden).toBe(false);
    expect(countryInput.hidden).toBe(false);
    expect(lastNameWrapper.hidden).toBe(false);
    expect(numberZipcodeWrapper.hidden).toBe(true);
    expect(streetWrapper.hidden).toBe(true);
    expect(cityWrapper.hidden).toBe(true);
  });

  test('should render ShippingAddress with all fields and throw errors for every field when submitting blank and no errors for shipping when fields are filled', async () => {
    window.scrollTo = jest.fn();

    function testNoErrors() {
      expect(screen.queryByText(/voornaam is verplicht/i)).toBeFalsy();
      expect(screen.queryByText(/achternaam is verplicht/i)).toBeFalsy();
      expect(screen.queryByText(/straat is verplicht/i)).toBeFalsy();
      expect(screen.queryByText(/huisnummer is verplicht/i)).toBeFalsy();
      expect(screen.queryByText(/postcode is verplicht/i)).toBeFalsy();
      expect(screen.queryByText(/stad is verplicht/i)).toBeFalsy();
      expect(screen.queryByText(/land is verplicht/i)).toBeFalsy();
    }

    setup(initialDataEmptyShippingAddress);
    const {
      firstNameInput,
      lastNameInput,
      streetInput,
      housenumberInput,
      housenumberAdditionInput,
      zipcodeInput,
      cityInput,
      countryInput,
      submit,
      shippingIsInvoice,
    } = getElements();

    // no errors
    testNoErrors();

    // all input fields empty
    expect(firstNameInput.value).toBe('');
    expect(lastNameInput.value).toBe('');
    expect(streetInput.value).toBe('');
    expect(housenumberInput.value).toBe('');
    expect(housenumberAdditionInput.value).toBe('');
    expect(zipcodeInput.value).toBe('');
    expect(cityInput.value).toBe('');
    expect(countryInput.value).toBe('');

    expect(shippingIsInvoice.value).toBe('false');

    await act(() => Promise.resolve(fireEvent.click(submit)));

    const errorFirstName = screen.getAllByText(/voornaam is verplicht/i);
    const errorLastName = screen.getAllByText(/achternaam is verplicht/i);
    const errorStreet = screen.getAllByText(/straat is verplicht/i);
    const errorHousenumber = screen.getAllByText(/huisnummer is verplicht/i);
    const errorZipcode = screen.getAllByText(/postcode is verplicht/i);
    const errorCity = screen.getAllByText(/stad is verplicht/i);
    const errorCountry = screen.getAllByText(/land is verplicht/i);

    expect(errorFirstName).toHaveLength(2); // 2 because error for invoice and shipping
    expect(errorLastName).toHaveLength(2);
    expect(errorStreet).toHaveLength(2);
    expect(errorHousenumber).toHaveLength(2);
    expect(errorZipcode).toHaveLength(2);
    expect(errorCity).toHaveLength(2);
    expect(errorCountry).toHaveLength(2);

    // Filling out address fields
    fireEvent.change(firstNameInput, { target: { value: 'firstName' } });
    fireEvent.change(lastNameInput, { target: { value: 'lastName' } });
    fireEvent.change(streetInput, { target: { value: 'street' } });
    fireEvent.change(housenumberInput, { target: { value: '11' } });
    fireEvent.change(housenumberAdditionInput, { target: { value: 'B' } });
    fireEvent.change(zipcodeInput, { target: { value: '1012ES' } });
    fireEvent.change(cityInput, { target: { value: 'Amsterdam' } });
    await act(() => Promise.resolve(fireEvent.change(countryInput, { target: { value: 'NL' } })));

    // asserting fields are filled
    expect(firstNameInput.value).toBe('firstName');
    expect(lastNameInput.value).toBe('lastName');
    expect(streetInput.value).toBe('street');
    expect(housenumberInput.value).toBe('11');
    expect(housenumberAdditionInput.value).toBe('B');
    expect(zipcodeInput.value).toBe('1012ES');
    expect(cityInput.value).toBe('Amsterdam');
    expect(countryInput.value).toBe('NL');

    // shipping address errors have disappeared, invoice address errors are still there
    await (() => {
      expect(errorFirstName).toHaveLength(1);
      expect(errorLastName).toHaveLength(1);
      expect(errorStreet).toHaveLength(1);
      expect(errorHousenumber).toHaveLength(1);
      expect(errorZipcode).toHaveLength(1);
      expect(errorCity).toHaveLength(1);
      expect(errorCountry).toHaveLength(1);
    });

    await act(() => Promise.resolve(fireEvent.click(shippingIsInvoice)));

    await (() => {
      expect(shippingIsInvoice.value).toBe('true');
      testNoErrors(); // invoice address errors are gone now as well
    });

    // TODO make it possible to submit
    // const ideal = screen.getByRole('radio', { name: 'iDEAL' });
    // fireEvent.change(ideal, { target: { checked: 'true' } });
    // await act(() => Promise.resolve(fireEvent.click(submit)));
  });
});

describe('tests for NL zipcode check and all associated actions', () => {
  test('should show correct fields for NL ', async () => {
    setup(initialDataEmptyShippingAddress);
    const {
      firstNameWrapper,
      lastNameWrapper,
      streetWrapper,
      numberZipcodeWrapper,
      cityWrapper,
      countryInput,
    } = getElements();

    expect(firstNameWrapper.hidden).toBe(false);
    expect(countryInput.hidden).toBe(false);
    expect(lastNameWrapper.hidden).toBe(false);
    expect(streetWrapper.hidden).toBe(true);
    expect(numberZipcodeWrapper.hidden).toBe(true);
    expect(cityWrapper.hidden).toBe(true);

    await act(() => Promise.resolve(fireEvent.change(countryInput, { target: { value: 'NL' } })));

    // Fields to be shown for NL
    expect(firstNameWrapper.hidden).toBe(false);
    expect(countryInput.hidden).toBe(false);
    expect(lastNameWrapper.hidden).toBe(false);
    expect(numberZipcodeWrapper.hidden).toBe(false);
    expect(streetWrapper.hidden).toBe(true);
    expect(cityWrapper.hidden).toBe(true);
  });

  test('should displayed retrieved address when zipcode API called with valid address', async () => {
    setup(initialDataEmptyShippingAddress);
    const { countryInput, housenumberInput, zipcodeInput } = getElements();

    await act(() => Promise.resolve(fireEvent.change(countryInput, { target: { value: 'NL' } })));

    const checkZipcodeButton = screen.getByText(/controleer/i);
    const mockZipcodeCheck = jest.spyOn(zipcodeCheck, 'zipCodeApiCall').mockReturnValue({
      data: {
        street: 'teststraat',
        city: 'Amsterdam',
        postcode: '1234AB',
        houseNumber: '12',
        houseNumberAddition: '',
        houseNumberAdditions: ['A', 'B', 'C'],
      },
    });

    expect(screen.queryByTestId('retrievedAddressDisplayed')).toBeFalsy(); // no address to display yet

    await act(() => Promise.resolve(fireEvent.click(checkZipcodeButton)));

    expect(mockZipcodeCheck).toHaveBeenCalledTimes(0); // only called if zipcode and housenumber have values

    fireEvent.change(housenumberInput, { target: { value: '12' } });
    fireEvent.change(zipcodeInput, { target: { value: '1234AB' } });

    await act(() => Promise.resolve(fireEvent.click(checkZipcodeButton)));

    expect(mockZipcodeCheck).toHaveBeenCalledTimes(1);

    expect(screen.getByTestId('retrievedAddressDisplayed')).toBeTruthy(); //retrieved address is displayed
    expect(screen.getByText(/kies toevoeging/i)).toBeTruthy(); // show the possible housenumber additions
    const houseNumberAdditions = screen.getAllByTestId('chooseHouseNumberAddition');
    expect(houseNumberAdditions.length).toBe(3); // show three options

    await act(() => Promise.resolve(fireEvent.click(houseNumberAdditions[0]))); // choose housenumber addition
    expect(screen.queryByText(/kies toevoeging/i)).toBeFalsy(); // not possible to pick another addition
    expect(screen.getByText(/12-A/i)).toBeTruthy(); // housenumber and addition displayed

    await act(() => Promise.resolve(fireEvent.click(screen.getByText(/reset/i))));

    expect(screen.queryByTestId('retrievedAddressDisplayed')).toBeFalsy(); // don't show address anymore after reset
    expect(housenumberInput.value).toBe(''); // input field cleared
    expect(zipcodeInput.value).toBe(''); // input field cleared
  });

  test('should be possible to enter address manually if address zipcode check fails', async () => {
    setup(initialDataEmptyShippingAddress);
    const { countryInput, housenumberInput, zipcodeInput, cityWrapper, streetWrapper } =
      getElements();

    await act(() => Promise.resolve(fireEvent.change(countryInput, { target: { value: 'NL' } })));

    const checkZipcodeButton = screen.getByText(/controleer/i);
    const mockZipcodeCheck = jest.spyOn(zipcodeCheck, 'zipCodeApiCall').mockReturnValue({});

    await act(() => Promise.resolve(fireEvent.click(checkZipcodeButton)));
    expect(mockZipcodeCheck).toHaveBeenCalledTimes(0); // not called because tried to submit with empty fields

    fireEvent.change(housenumberInput, { target: { value: '12' } });
    fireEvent.change(zipcodeInput, { target: { value: '1234AB' } });

    await act(() => Promise.resolve(fireEvent.click(checkZipcodeButton)));

    expect(mockZipcodeCheck).toHaveBeenCalledTimes(1);
    expect(screen.queryByTestId('retrievedAddressDisplayed')).toBeFalsy(); // zipcode check failed so no address to display

    const enterManually = screen.getByText('Voer adres handmatig in');
    expect(enterManually).toBeTruthy(); // button for entering manually present

    expect(streetWrapper.hidden).toBeTruthy(); // these fields are hidden when zipcodecheck
    expect(cityWrapper.hidden).toBeTruthy();

    await act(() => Promise.resolve(fireEvent.click(enterManually)));

    expect(streetWrapper.hidden).toBeFalsy(); // these fields show when entering manually
    expect(cityWrapper.hidden).toBeFalsy();
  });

  test('should show printed address when address is previously validated and make editable when clicked on edit', async () => {
    setup(initialData);
    expect(screen.getByTestId('printedShippingAddress')).toBeTruthy(); // address is printed
    const editAddressButton = screen.getByText(/bewerk adres/i);

    await act(() => Promise.resolve(fireEvent.click(editAddressButton)));

    expect(screen.queryByTestId('printedShippingAddress')).toBeFalsy(); // address is no longer printed
  });

  test('should show error if housenumberAddition is too long', async () => {
    setup(initialDataEmptyShippingAddress);
    const { housenumberAdditionInput, submit } = getElements();
    fireEvent.change(housenumberAdditionInput, { target: { value: 'This input it way too long' } });

    await act(() => Promise.resolve(fireEvent.click(submit)));
    // show error message
    expect(screen.getByText(/maximaal 15 tekens/i)).toBeTruthy();
  });
});
